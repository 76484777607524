import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "rc-pagination";
import { ReactComponent as LeftArrow } from '../../../assets/img/common/arrow.svg';
import VoteHistoryItem from "./VoteHistoryItem";
import styled from "styled-components";

const Styles = styled.div`
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    svg.arrow {
        path {
            stroke: #979797;
        }
    }

    li.rc-pagination-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
        // background: #2D82E8;
        border-radius: 50%;
    }

    li.rc-pagination-item-active a {
        color: #3060F6;
    }
    .tick-icon {
        height: 22px;
        width: auto;
    }
    @media (max-width: 890px) {
        .flex-wrap-custom {
            flex-wrap: wrap;
            > div {
                margin-left: 5px !important;
            }
        }
    }
`

const VotingHistory = ({ data, pageNumber, total, onChangePage }) => {
    const { t } = useTranslation();
    const [current, setCurrent] = useState(pageNumber);
    const [pageSize, setPageSize] = useState(5);

    const handleChangePage = (page, size) => {
        setCurrent(page);
        setPageSize(size);
        onChangePage(page, (page - 1) * size, size);
    };

    const onNext = () => {
        if (current * pageSize <= total) {
            handleChangePage(current + 1, 5);
        }
    };

    const onPrev = () => {
        if (current > 1) {
            handleChangePage(current - 1, 5);
        }
    };

    return (
        <Styles className="py-8 px-8 rounded-xl text-black">
            <div className="text-midBlue text-2xl font-semiBold">{t("Voting_History")}</div>
            {data && data.length === 0 && (
                <div className="flex items-center justify-center font-medium text-lg py-8">
                    {t("No_votes")}
                </div>
            )}
            {data.map((item, index) => {
                return (
                    <VoteHistoryItem
                        proposal={item.proposal}
                        support={item.support}
                        key={index}
                    />
                )
            })}
            {data && data.length !== 0 && (
                <div className="flex justify-end mt-6">
                    <div className="flex just-between align-center pagination space-x-4">
                        {(
                            <div className={`flex ${current > 1 ? '' : 'cursor-not-allowed'}`} onClick={onPrev}>
                                <LeftArrow className={'arrow'} height={'11px'} width={'auto'} />
                            </div>
                        )}
                        <Pagination
                            defaultCurrent={1}
                            defaultPageSize={5}
                            current={current}
                            pageSize={pageSize}
                            total={total}
                            onChange={handleChangePage}
                        />
                        {(
                            <div
                                className={`
                                     flex ml-0 ${(current * pageSize <= total) ? '' : 'cursor-not-allowed'}
                                `}
                                onClick={onNext}
                            >
                                <LeftArrow className={'transform rotate-180 arrow'} height={'11px'} width={'auto'} />
                            </div>
                        )}
                    </div>
                    {/* <Pagination
                        defaultCurrent={1}
                        defaultPageSize={5}
                        current={current}
                        pageSize={pageSize}
                        total={total}
                        onChange={handleChangePage}
                    />
                    <div className="flex just-between align-center space-x-12">
                        {current > 1 && (
                            <div className="flex space-x-4" onClick={onPrev}>
                                <img src={rightArrow} alt="" className={'transform rotate-180'} />
                                <div className="text-lg text-primary">Prev</div>
                            </div>
                        )}
                        {current * pageSize < total && (
                            <div className="flex space-x-4" onClick={onNext}>
                                <div className="text-lg text-primary">Next</div>
                                <img src={rightArrow} alt="" />
                            </div>
                        )}
                    </div> */}
                </div>
            )}
        </Styles>

    )
}


VotingHistory.defaultProps = {
    data: [],
    pageNumber: 1,
    total: 0
};


export default compose(withRouter)(VotingHistory);
