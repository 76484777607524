import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import styled from 'styled-components';

import Bg_gradient from '../../assets/img/Bg-gradient.svg';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(3, 7, 18, 1);
  color: #F9FAFB;
  min-height: 100vh;
  .main-layout-container {
    flex: 1;
    padding-top: 45px;
    padding-bottom: 45px;
    @media (max-width: 992px) {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .only-mobile {
    display: none;
    @media (max-width: 992px) {
      display: flex;
    }
  }
  .only-web {
    display: flex;
    @media (max-width: 992px) {
      display: none !important;
    }
  }

  .toast-container {
    background: #3B46F1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  .fill-white {
    path {
      fill: #ffffff;
    }
  }
`;

const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 224px;
  left: 0px;
  top: 0px;
  filter: blur(160px);
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  pointer-events: none;
`

function MainLayout({ children }) {
  return (
    <StyledContainer >
      <Header />
      <GradientBackground>
        <img src={Bg_gradient} alt='' />
      </GradientBackground>
      <main className='flex justify-center main-layout-container'>
        <div className='max-w-7xl w-full'>
          <ToastContainer
            autoClose={5000}
            transition={Zoom}
            hideProgressBar
            position={toast.POSITION.TOP_RIGHT}
          />
          {children}
        </div>
      </main>
      <Footer />
    </StyledContainer>
  );
}

export default MainLayout;
