import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useSelector } from 'react-redux';
import { useMultiFeeDistributionInfo, usedLpPrice } from 'hooks/useLpStaking';

const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .item-container {
    flex: 1;
    text-align: center;
    @media (max-width: 992px) {
      text-align: left;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      color: rgba(156, 163, 175, 1);
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        padding-left: 4px;
      }
    }
  }
  .divider {
    width: 1px;
    height: 72px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    @media (max-width: 992px) {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

function DashboardInfo() {
  const markets = useSelector((state) => state.account.setting.markets);
  const arsPrice = useSelector((state) => state.account.setting.arsPrice || 0);
  const ethPrice = useSelector((state) => state.account.setting.markets).find(e => e.underlyingSymbol === 'ETH')?.tokenPrice || 0;

  const { t } = useTranslation();
  const { dLpPrice } = usedLpPrice(arsPrice, ethPrice);
  const { dLpLocked } = useMultiFeeDistributionInfo();

  const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
  const [totalBorrow, setTotalBorrow] = useState(new BigNumber(0));

  useEffect(() => {
    if (markets?.length > 0) {
      const tempTS = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.totalSupplyUsd));
      }, 0);
      setTotalSupply(tempTS);
      const tempTB = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.totalBorrowsUsd));
      }, 0);
      setTotalBorrow(tempTB);
    } else {
      setTotalSupply(new BigNumber(0));
      setTotalBorrow(new BigNumber(0));
    }
  }, [markets]);

  return (
    <StyledContainer className='w-full'>
      <div className='item-container'>
        <h1 className='title'>
          {t("Market_Size")}
        </h1>
        <div className='desc'>
          ${getFormatStringFromBignumber(totalSupply.minus(totalBorrow))}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("dLP_locked")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(dLpLocked.times(dLpPrice).div(1e18))}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Total_Supply")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(totalSupply)}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Total_Borrow")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(totalBorrow)}
          {/* <span>USD</span> */}
        </div>
      </div>
    </StyledContainer>
  )
}

export default DashboardInfo;