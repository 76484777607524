import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connectAccount } from "../../core";
import { getEtherscanLink } from "../../utils";
import { useActiveWeb3React, useWeb3 } from "../../hooks";
import commaNumber from "comma-number";
import DelegationTypeModal from "./Modals/DelegationTypeModal";
import logo from '../../assets/icons/logo-compact.svg';
import styled from "styled-components";
import ConnectWalletButton from "components/common/ConnectWalletButton";
import AlertHelper from "components/common/AlertHelper";
import { getFormatStringFromBignumber } from "utilities/common";

const Styles = styled.div`
    // filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border-radius: 6px;

    
    @media (max-width: 1024px) {
        .voting-wallet-content {
            flex-direction: column;
        }
        .summary {
            max-width: none !important;
        }
    }
`

const format = commaNumber.bindWith(',', '.');

const VotingWallet = ({
    balance,
    settings,
    earnedBalance,
    delegateAddress,
    delegateStatus,
    votingWeight
}) => {
    const { requiredChainId, account } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingEarn, setIsLoadingEarn] = useState(false);

    useEffect(() => {
        if (!earnedBalance) {
            setIsLoadingEarn(true);
            return;
        }
        setIsLoadingEarn(false);
    }, [earnedBalance]);

    const getBefore = value => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(0, position + 5) : value;
    };

    const getAfter = value => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(position + 5) : null;
    };

    return (
        <Styles className="py-5 relative flex-1">
            <div className="text-midBlue text-2xl font-bold px-6 pb-8 border-b border-solid border-gray4">{t("Voting_Wallet")}</div>
            <div className={'flex flex-col voting-wallet-content mt-4'}>
                <div className={"flex-1 summary"}>
                    <div className="flex flex-col justify-center items-center">
                        <img src={logo} alt={'logo'} height={'auto'} width={'99px'} />
                        <div className="text-midBlue text-2xl font-bold mt-4">{t("ARS_Balance")}</div>
                        <div className="mt-5 text-gray3 text-4xl">
                            {
                                <>
                                    {getBefore(format(balance))}
                                    <span>{getAfter(format(balance))}</span>
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex justify-between items-start p-6">
                        <div className="text-midBlue text-lg font-bold">{t("ARS_Earned")}</div>
                        <div className="text-gray3 text-sm flex flex-col justify-center items-end">
                            <div className="flex justify-center items-center">
                                <span>
                                    {getFormatStringFromBignumber(earnedBalance.div(1e18))} ARS
                                </span>
                                <AlertHelper text={t('Collect_ARS_desc')} />
                            </div>
                            {/* {
                                account ? (
                                    <button
                                        className="flex justify-center items-center
                                        focus:outline-none bg-midBlue text-white py-3 px-6 rounded text-sm mt-6 w-full"
                                        onClick={handleCollect}
                                        disabled={+earnedBalance === 0 || isLoading}
                                    >
                                        {isLoading && <Loading size={'14px'} margin={'5px'} />}
                                        {t("Collect_ARS")}
                                    </button>
                                ) : (
                                    <ConnectWalletButton class={`bg-midBlue max-w-xs text-white w-full flex items-center justify-center px-2 py-2 
                                    text-md font-semibold mx-auto my-6 md:my-12 rounded`} />
                                )
                            } */}
                        </div>
                    </div>
                    <div className="flex justify-between items-center p-6">
                        <div className="text-midBlue text-lg font-bold">{t("Voting_Weight")}</div>
                        <div className="text-gray3 text-sm">{
                            <>
                                {getBefore(format(votingWeight))}
                                <span>{getAfter(format(votingWeight))}</span>
                            </>
                        }</div>
                    </div>
                </div>
                {delegateStatus && (
                    <div className="flex justify-between items-start p-6 border-t border-solid border-gray4">
                        <div className="text-midBlue text-lg font-bold">{t("Delegating_To")}</div>
                        <div className="text-gray3 text-sm flex flex-col justify-center items-end">
                            {
                                <a
                                    className="text-black no-underline focus:outline-none"
                                    href={getEtherscanLink(requiredChainId, delegateAddress, 'address')}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {delegateStatus === 'self'
                                        ? 'Self'
                                        : `${delegateAddress.substr(
                                            0,
                                            4
                                        )}...${delegateAddress.substr(
                                            delegateAddress.length - 4,
                                            4
                                        )}`}
                                </a>
                            }
                            <button
                                className="focus:outline-none bg-midBlue text-white py-3 px-6 rounded text-sm mt-6 w-full"
                                onClick={() => setIsOpenModal(true)}
                            >
                                {t("Change")}
                            </button>
                        </div>
                    </div>
                )}
                {
                    !delegateStatus &&
                    (
                        <div className="flex-1 p-6 setup-voting">
                            <div className="text-midBlue text-lg font-bold">{t("Setup_Voting")}</div>
                            <div className="text-sm mt-4 text-gray3">
                                {t("You_can_vote")}
                            </div>
                            <div className="flex justify-center mt-8">
                                {
                                    account ? (
                                        <button
                                            className="focus:outline-none bg-midBlue rounded text-white font-bold py-5 px-32 text-xl"
                                            onClick={() => {
                                                setIsOpenModal(true)
                                            }}
                                        >
                                            {t("Get_Started")}
                                        </button>
                                    ) : (
                                        <ConnectWalletButton class={`bg-midBlue max-w-xs text-white w-full flex items-center justify-center py-2 
                                        text-2xl font-semibold mx-auto my-6 md:my-12 rounded`} />
                                    )
                                }
                            </div>
                        </div>
                    )}
            </div>

            <DelegationTypeModal
                visible={isOpenModal}
                balance={balance}
                delegateStatus={delegateStatus}
                address={account ? account : ''}
                onCancel={() => setIsOpenModal(false)}
            />
        </Styles>
    )
}

const mapStateToProps = ({ account }) => ({
    settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(
    VotingWallet
);

// @todo: connect delegate modal
