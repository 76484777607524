import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactMarkdown from 'react-markdown';
import * as moment from "moment";

import { getVoteContract, methods } from "../../utilities/ContractService";
import VoteActionDetails from "./VoteActionDetails";
import BigNumber from "bignumber.js";
import ActionModal from "./Modals/ActionModal";
import toast from "../UI/Toast";
import { useActiveWeb3React, useWeb3 } from "../../hooks";
import Loading from "../UI/Loading";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import { getNativeToken } from "utils";

const Styles = styled.div`
.custom-border-color-lightGreen3 {
    border-color: #4FD000;
}
.custom-border-color-lightRed {
    border-color: #FF0100;
}
.custom-border-color-midBlue {
    border-color: #3B46F1;
}
`

const EmptyDataStyles = styled.div`
  width: 100%;
  overflow: auto;
  border: double 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white), 
                    linear-gradient(90deg, #002D74 0%, #000220 100.04%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex;
  justify-content: center;
`;

const Proposal = ({
    address,
    delegateAddress,
    proposal,
    votingWeight,
    history
}) => {
    const web3 = useWeb3();
    const { account, requiredChainId } = useActiveWeb3React();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [voteType, setVoteType] = useState('like');
    const [voteStatus, setVoteStatus] = useState('');
    const [showActionModal, setShowActionModal] = useState(false);
    const [executeLoading, setExecuteLoading] = useState(false);
    const [status, setStatus] = useState('pending');
    const voteContract = getVoteContract(requiredChainId);

    const getStatus = p => {
        if (p.state === 'Executed') {
            return 'Passed';
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return 'Failed';
        }
        return p.state;
    };

    const getStatusColor = p => {
        if (p.state === 'Executed') {
            return 'lightGreen3';
        }
        if (p.state === 'Active') {
            return 'midBlue';
        }
        if (p.state === 'Defeated') {
            return 'lightRed';
        }
        return 'midBlue';
    };

    const getRemainTime = item => {
        if (item.state === 'Active') {
            const diffBlock = item.endBlock - item.blockNumber;
            const duration = moment.duration(
                diffBlock < 0 ? 0 : diffBlock * 3,
                'seconds'
            );
            const days = Math.floor(duration.asDays());
            const hours = Math.floor(duration.asHours()) - days * 24;
            const minutes =
                Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
            return `${days > 0 ? `${days} ${days > 1 ? 'days' : 'day'},` : ''
                } ${hours} ${hours > 1 ? 'hrs' : 'hr'} ${days === 0 ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ''
                } left`;
        }
        if (item.state === 'Pending') {
            return `${moment(item.createdTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Active') {
            return `${moment(item.startTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Canceled' || item.state === 'Defeated') {
            return `${moment(item.endTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Queued') {
            return `${moment(item.queuedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Expired' || item.state === 'Executed') {
            return `${moment(item.executedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        return `${moment(item.updatedAt).format('MMMM DD, YYYY')}`;
    };


    const getIsHasVoted = useCallback(async () => {
        await methods.call(voteContract.methods.getReceipt, [proposal.id, address])
            .then(res => {
                setVoteStatus(res.hasVoted ? 'voted' : 'novoted');
            });
    }, [address, proposal]);

    useEffect(() => {
        if (address && proposal.id) {
            getIsHasVoted();
        }
    }, [address, proposal, getIsHasVoted]);

    const handleVote = async (support) => {
        setIsLoading(true);
        setVoteType(support);
        const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            voteContract.methods.castVote,
            [proposal.id, support === 'like' ? 1 : 0],
            address
        );
        if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setIsLoading(false);
            return
        }
        methods.send(
            voteContract.methods.castVote,
            [proposal.id, support === 'like' ? 1 : 0],
            address
        )
            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false);
            });
    };

    const getTitle = descs => {
        const index = descs.findIndex(d => d !== '');
        if (index !== -1) {
            return descs[index];
        }
        return '';
    };

    const votePercents = useMemo(() => {
        if (!proposal) {
            return {
                forVotes: 0,
                againstVotes: 0,
            }
        }

        const forVotes = new BigNumber(proposal?.forVotes || 0);
        const againstVotes = new BigNumber(proposal?.againstVotes || 0);
        const totalVotes = new BigNumber(proposal?.forVotes || 0).plus(proposal?.againstVotes || 0)

        if (totalVotes?.toNumber() === 0) {
            return {
                forVotes: 0,
                againstVotes: 0,
            }
        }

        return {
            forVotes: forVotes.dividedBy(totalVotes).times(100).toNumber(),
            againstVotes: againstVotes.dividedBy(totalVotes).times(100).toNumber(),
        }

    }, [proposal])


    const handleUpdateProposal = async (statusType) => {
        if (statusType === 'Queue') {
            setExecuteLoading(true);
            const isCheckGas = await methods.checkGasFee(
                web3,
                requiredChainId,
                voteContract.methods.queue,
                [proposal.id],
                account
            );
            if (!isCheckGas) {
                NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
                setExecuteLoading(false);
                return
            }
            methods.send(
                voteContract.methods.queue,
                [proposal.id],
                account
            )
                .then(() => {
                    setExecuteLoading(false);
                    setStatus('success');
                    toast.success({
                        title: t(`Proposal_list_updated_message`)
                    });
                })
                .catch(() => {
                    setExecuteLoading(false);
                    setStatus('failure');
                });
        } else if (statusType === 'Execute') {
            setExecuteLoading(true);
            const isCheckGas = await methods.checkGasFee(
                web3,
                requiredChainId,
                voteContract.methods.execute,
                [proposal.id],
                account
            );
            if (!isCheckGas) {
                NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
                setExecuteLoading(true);
                return
            }
            methods.send(
                voteContract.methods.execute,
                [proposal.id],
                account
            )
                .then(() => {
                    setExecuteLoading(false);
                    setStatus('success');
                    toast.success({
                        title: t(`Proposal_list_updated_message`)
                    });
                })
                .catch(() => {
                    setExecuteLoading(false);
                    setStatus('failure');
                });
        }
    };

    return (
        <Styles
            className="p-6"
        >
            {proposal.state === 'Active' ? (
                <div>
                    <div
                        className="text-midBlue text-2xl font-bold cursor-pointer"
                        onClick={() => history.push(`/vote/proposal/${proposal.id}`)}
                    >
                        <ReactMarkdown>{getTitle(proposal.description?.split('\n'))}</ReactMarkdown>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="flex space-x-4 mt-4">
                            <button
                                className={`focus:outline-none text-gray3 px-2 rounded text-14
                            border border-solid custom-border-color-${getStatusColor(proposal)}`}
                            >
                                {getStatus(proposal)}
                            </button>
                            <div className="text-black text-lg">
                                {proposal.id} - {proposal.state} {moment(proposal.createdAt).format('MMMM Do, YYYY')}
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <button
                                className="focus:outline-none px-4 py-0.5 text-lg bg-midBlue rounded text-white "
                                onClick={() => setShowActionModal(true)}
                            >
                                Actions
                            </button>
                            {proposal.state !== 'Executed' &&
                                proposal.state !== 'Defeated' &&
                                proposal.state !== 'Canceled' && (
                                    <div className="flex align-center just-center update-proposal-status">
                                        {proposal.state === 'Succeeded' && (
                                            <button
                                                className={`focus:outline-none px-4 py-0.5 text-xl bg-midBlue rounded text-white font-bold 
                                                    flex items-center justify-center`}
                                                disabled={isLoading || status === 'success'}
                                                onClick={() => handleUpdateProposal('Queue')}
                                            >
                                                {executeLoading && <Loading size={'18px'} margin={'8px'} />}
                                                {status === 'pending' || status === 'failure'
                                                    ? 'Queue'
                                                    : t('Queued')}
                                            </button>
                                        )}
                                        {proposal.state === 'Queued' && (
                                            <button
                                                className={`focus:outline-none px-4 py-0.5 text-xl bg-midBlue rounded text-white font-bold 
                                                 flex items-center justify-center`}
                                                disabled={
                                                    isLoading ||
                                                    status === 'success'
                                                }
                                                onClick={() => handleUpdateProposal('Execute')}
                                            >
                                                {executeLoading && <Loading size={'18px'} margin={'8px'} />}
                                                {status === 'pending' || status === 'failure'
                                                    ? 'Execute'
                                                    : t('Executed')}
                                            </button>
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="mt-4 border-b border-solid border-gray9 pb-8">
                        {voteStatus &&
                            voteStatus === 'novoted' &&
                            proposal.state === 'Active' &&
                            delegateAddress !==
                            '0x0000000000000000000000000000000000000000' && (
                                <>
                                    <VoteActionDetails
                                        title="For"
                                        percent={votePercents?.forVotes}
                                        disabled={
                                            votingWeight === '0' ||
                                            !proposal ||
                                            (proposal && proposal.state !== 'Active')
                                        }
                                        loading={isLoading && voteType === 'like'}
                                        onVote={() => {
                                            handleVote('like')
                                        }} />
                                    <VoteActionDetails
                                        title={t("Against")}
                                        percent={votePercents?.againstVotes}
                                        disabled={
                                            votingWeight === '0' ||
                                            !proposal ||
                                            (proposal && proposal.state !== 'Active')
                                        }
                                        loading={isLoading && voteType === 'dislike'}
                                        onVote={() => {
                                            handleVote('dislike')
                                        }} />
                                </>
                            )}
                    </div>

                    <ActionModal
                        proposal={proposal}
                        visible={showActionModal}
                        onClose={() => setShowActionModal(false)}
                    />
                </div>
            ) : (
                null
            )
            }
        </Styles>
    )
}

export default compose(withRouter)(Proposal);
