/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import styled from 'styled-components';

import arrowDown from '../../assets/img/header/down.svg';


const StyledContainer = styled.div`
  .selected-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    border: 1px solid #FFFFFF1A;
    gap: 16px;
    background: #FFFFFF1A;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    .selected-item-title {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .list-container {
    border: 1px solid #FFFFFF1A;
    border: 1px;
    border-radius: 4px;
    background: #000;
    z-index: 1;
    .items-sub {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
      .list-option {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        gap: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        cursor: pointer;
        &:hover {
          background: #FFFFFF1A;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .acitive-option {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
const ListboxOptions = styled(Listbox.Options)`
`;

const defaultOptions = [
  { name: 'usdt', logo: <img alt={'usdt'} src={'/images/coins/usdt.png'} style={{ width: 32, height: 32 }} /> },
];

function Select({
  options = defaultOptions,
  onChange,
  selectedProp,
}) {
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    if (selectedProp) {
      setSelected(selectedProp)
    }
  }, [selectedProp])

  return (
    <StyledContainer>
      <Listbox
        value={selected}
        onChange={(val) => {
          setSelected(val);
          if (onChange) {
            onChange(val);
          }
        }}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={`selected-item relative w-full focus:outline-none`}
              >
                <div className="selected-item-title">
                  {(selected?.logo || selected?.img) && (
                    <img alt={selected?.name} src={selected?.logo || selected?.img} />
                  )}
                  <div>{selected.name}</div>
                </div>
                <img src={arrowDown} alt='' />
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transform transition ease-in duration-200 focus:outline-none"
                leaveFrom="opacity-100 scale-100 "
                leaveTo="opacity-0 scale-95 "
              >
                <ListboxOptions
                  static
                  className={`absolute w-full list-container focus:outline-none`}
                >
                  <div className='items-sub'>
                    {options.map((option, optionIdx) => (
                      <Listbox.Option
                        key={optionIdx}
                        value={option}
                      >
                        <div className={`list-option ${option.name !== selected?.name ? '' : 'acitive-option'}`}>
                          {(option?.logo || option?.img) && (
                            <img alt={option?.name} src={option?.logo || option?.img} />
                          )}
                          <div>{option.name}</div>
                        </div>
                      </Listbox.Option>
                    ))}
                  </div>
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </StyledContainer>
  );
}

Select.defaultProps = {
  type: 'primary',
  width: 'w-56',
};

export default Select;
