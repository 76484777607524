import React, { useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useChefIncentivesInfo,
  useEligibilityDataProviderInfo,
  useMultiFeeDistributionAPRs,
  useMultiFeeDistributionInfo,
  useMultiFeeDistributionRewards,
  usedArsBalances,
  usedLpBalance,
  usedLpPrice
} from "hooks/useLpStaking";

import MainLayout from "layouts/MainLayout/MainLayout";
import Info from "./Info";
import Revenue from "./Revenue";
import LpOverview from "./LpOverview";
import VestOverview from "./VestOverview";
import LastOverview from "./LastOverview";
import Emission from "./Emission";
import BigNumber from "bignumber.js";
import MyStats from "./MyStats";

const VaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .emission-info-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

function Radiant() {
  const arsPrice = useSelector((state) => state.account.setting.arsPrice || 0);
  const ethPrice = useSelector((state) => state.account.setting.markets).find(e => e.underlyingSymbol === 'ETH')?.tokenPrice || 0;

  const [update, setUpdate] = useState(false);
  const { myDLpBalance, myDLpAllowance } = usedLpBalance(update);
  const {
    myDLpLocked,
    myUnlockable,
    myShare,
    dLpLocked,
    penaltyAmount,
    currentlyVesting,
    vested,
    vestingList,
    lockList,
    defaultLockIndex
  } = useMultiFeeDistributionInfo(update);
  const { autocompoundEnabled, autoRelockDisabled, claimableRewards } = useMultiFeeDistributionRewards(update);
  const { allPendingRewards, rewardsPerSecond } = useChefIncentivesInfo(update)
  const { dLpPrice } = usedLpPrice(arsPrice, ethPrice);
  const { chefArsBalance } = usedArsBalances(update);
  const { isEligibleForRewards, lockedUsdValue, requiredUsdValue } = useEligibilityDataProviderInfo(update);
  const { rewardData, lockedSupplyWithMultiplier, lockMultipliers } = useMultiFeeDistributionAPRs(claimableRewards);

  const tokenList = useMemo(() => {
    return [
      { price: 1, decimals: 6, },
      { price: 1, decimals: 6, },
      { price: ethPrice, decimals: 18, }
    ]
  }, [ethPrice]);

  const LockAprData = useMemo(() => {
    const lockedSupply = lockedSupplyWithMultiplier.times(dLpPrice).div(1e18);
    if (lockedSupply.isZero()) return [new BigNumber(0), new BigNumber(0), new BigNumber(0), new BigNumber(0)];

    let totalOneYearReward = new BigNumber(0);
    for (let index = 0; index < rewardData.length; index++) {
      if (new BigNumber(rewardData[index][0].hex).gte(Date.now() / 1000)) {
        totalOneYearReward = totalOneYearReward.plus(
          new BigNumber(rewardData[index][1].hex)
            .times(3600 * 24 * 365)
            .times(tokenList[index].price)
            .div(1e12)
            .div(10 ** tokenList[index].decimals)
        )
      }
    }

    const temp = [];
    for (let index = 0; index < lockMultipliers.length; index++) {
      temp.push(
        totalOneYearReward.times(100)
          .times(lockMultipliers[index])
          .div(lockedSupply)
      )
    }

    return temp;
  }, [rewardData, dLpPrice, tokenList]);

  const annualReward = useMemo(() => {
    let temp = new BigNumber(0);
    if (lockList.length > 0 && LockAprData.length > 0) {
      for (let index = 0; index < lockList.length; index++) {
        const multiplier = lockList[index].multiplier.toNumber();
        const amount = lockList[index].amount;
        switch (multiplier) {
          case 1:
            temp = temp.plus(amount.times(LockAprData[0]).div(100));
            break;
          case 4:
            temp = temp.plus(amount.times(LockAprData[1]).div(100));
            break;
          case 9:
            temp = temp.plus(amount.times(LockAprData[2]).div(100));
            break;
          case 25:
            temp = temp.plus(amount.times(LockAprData[3]).div(100));
            break;
          default:
            console.log('LP Lock multiplier is invalid.')
            break;
        }
      }
    }
    return temp;
  }, [lockList, LockAprData])

  return (
    <MainLayout>
      <VaultContainer>
        <div className="emission-info-container">
          <Emission
            update={update}
            setUpdate={setUpdate}
            isEligibleForRewards={isEligibleForRewards}
            lockedUsdValue={lockedUsdValue}
            requiredUsdValue={requiredUsdValue}
            dLpPrice={dLpPrice}
            myDLpLocked={myDLpLocked}
            lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
            LockAprData={LockAprData}
            annualReward={annualReward}
          />
          <Info
            myDLpLocked={myDLpLocked}
            myShare={myShare}
            dLpLocked={dLpLocked}
            dLpPrice={dLpPrice}
            autoRelockDisabled={autoRelockDisabled}
            lockList={lockList}
            annualReward={annualReward}
          />
        </div>
        <Revenue
          update={update}
          setUpdate={setUpdate}
          autocompoundEnabled={autocompoundEnabled}
          claimableRewards={claimableRewards}
        />
        <LpOverview
          update={update}
          setUpdate={setUpdate}
          defaultLockIndex={defaultLockIndex}
          myDLpBalance={myDLpBalance}
          myDLpAllowance={myDLpAllowance}
          autoRelockDisabled={autoRelockDisabled}
          lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
          LockAprData={LockAprData}
          myUnlockable={myUnlockable}
        />
        <VestOverview
          update={update}
          setUpdate={setUpdate}
          defaultLockIndex={defaultLockIndex}
          allPendingRewards={allPendingRewards}
          penaltyAmount={penaltyAmount}
          currentlyVesting={currentlyVesting}
          vested={vested}
        />
        <MyStats
          lockList={lockList}
          vestingList={vestingList}
          update={update}
          setUpdate={setUpdate}
        />
        <LastOverview
          chefArsBalance={chefArsBalance}
          rewardsPerSecond={rewardsPerSecond}
          arsPrice={arsPrice}
          dLpPrice={dLpPrice}
        />
      </VaultContainer>
    </MainLayout>
  )
}

export default Radiant;