import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import BigNumber from "bignumber.js";

import { useActiveWeb3React, useWeb3 } from 'hooks';
import { useChefIncentivesInfo, useClaimAllChef, useEligibilityDataProviderInfo } from "hooks/useLpStaking";
import { getComptrollerContract, methods } from 'utilities/ContractService';
import { getFormatStringFromBignumber } from '../../utilities/common';
import * as constants from '../../utilities/constants.js';

import routes from '../../routes/RouteMap';
import ArsImg from 'assets/img/portfolio/ars_half.svg';
import { getNativeToken, isCoreNetwork } from 'utils';
import Loading from 'components/UI/Loading';
import { NotificationManager } from 'react-notifications';
import AlertHelper from 'components/common/AlertHelper';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
  }
  .earn-container {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: linear-gradient(90deg, rgba(113, 156, 232, 0.022) 0%, rgba(0, 86, 239, 0.2) 100%);
    border: 1px solid #FFFFFF1A;
    .sub-container {
      display: flex;
      flex-direction: column;
      padding: 24px 0px 24px 24px;
      gap: 34px;
      @media (max-width: 992px) {
        gap: 24px;
        padding: 24px;
        flex: 1;
      }
      .desc {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;        
      }
      .vest-item-container {
        display: flex;
        align-items: center;
        gap: 24px;
        .vest-item {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .value {
            display: flex;
            align-items: flex-end;
            gap: 4px;
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            color: #9CA3AF;
            span {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
            }
          }
          .start-vesting {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px 10px 20px;
            border-radius: 4px;
            border: 1px solid #0EA5E9;
            background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            cursor: pointer;
            width: 150px;
            &:hover {
              box-shadow: 0px 4px 24px 4px #2566EC33;
            }
          }
        }
      }
    }
    img {
      height: 100%;
      width: auto;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  .emission-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    border-radius: 12px;
    justify: space-between;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #FFFFFF1A;
    .value-container {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;
        color: rgb(225, 0, 0);
      }
      .title-active {
        color: #34D399;
      }
      .desc {
        max-width: 230px;
        padding-top: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
        @media only screen and (max-width: 992px) {
          max-width: 100%;
        }
        .value1 {
          color: #F9FAFB;
        }
      }
      .value-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        padding-bottom: 4px;
      }
      .value-content {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;
        padding-bottom: 4px;
        span {
          font-size: 12px;
          line-height: 16px;
          color: #9CA3AF;
        }
      }
      .progress-bar {
        position: relative;
        width: 100%;
        height: 4px;
        border-radius: 999px;
        background-color: #FFFFFF33;
        .progress-active-bar {
          height: 4px;
          background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
          border-radius: 999px;
        }
      }
      .vault-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px;
        gap: 8px;
        border: 1px solid #FFFFFF1A;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
      }
      .mobile-active-button {
        @media only screen and (max-width: 992px) {
          background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        }
      }
    }
  }
`;


const AccountOverview = () => {
  const assetList = useSelector((state) => state.account.setting.assetList);
  const [update, setUpdate] = useState(false);

  const web3 = useWeb3();
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { allPendingRewards } = useChefIncentivesInfo(update);
  const { isEligibleForRewards, lockedUsdValue, requiredUsdValue } = useEligibilityDataProviderInfo();
  const { pending: claimAllPending, handleClaimAll } = useClaimAllChef();

  const [earnedBalance, setEarnedBalance] = useState(new BigNumber(0));
  const [collectLoading, setCollectLoading] = useState(false);

  const progressWidth = useMemo(() => {
    if (requiredUsdValue.eq(0)) return 0;
    if (lockedUsdValue.gte(requiredUsdValue.times(4))) return 100;
    return parseInt(lockedUsdValue.times(100).div(requiredUsdValue.times(4)).toString());
  }, [lockedUsdValue, requiredUsdValue]);

  const awayProgress = useMemo(() => {
    if (requiredUsdValue.eq(0)) return new BigNumber(100);
    if (lockedUsdValue.gte(requiredUsdValue.times(4))) return new BigNumber(0);
    return new BigNumber(100).minus(lockedUsdValue.div(requiredUsdValue).times(100))
  }, [lockedUsdValue, requiredUsdValue]);

  const getVoteInfo = async () => {
    const appContractCallContext = [{
      reference: 'appContract',
      contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
      abi: constants.CONTRACT_COMPTROLLER_ABI,
      calls: [
        { reference: 'arsInitialIndex', methodName: 'arsInitialIndex', methodParameters: [] },
        { reference: 'arsAccrued', methodName: 'arsAccrued', methodParameters: [account] },
      ],
      context: {}
    }]
    const appContractResults = await methods.ethMulticall(web3, appContractCallContext, requiredChainId);
    let arsInitialIndex = new BigNumber(0);
    let arsAccrued = new BigNumber(0);
    appContractResults.results.appContract.callsReturnContext.forEach((result) => {
      if (result.methodName === "arsInitialIndex") {
        arsInitialIndex = new BigNumber(result.returnValues[0].hex)
      } else if (result.methodName === "arsAccrued") {
        arsAccrued = new BigNumber(result.returnValues[0].hex)
      }
    })

    let aquariusEarned = new BigNumber(0);
    let assetValues = {};
    const earnedAssetList = assetList.filter(asset =>
      new BigNumber(asset.supplyBalance).isGreaterThan(0) || new BigNumber(asset.borrowBalance).isGreaterThan(0)
    )

    if (earnedAssetList.length > 0) {
      try {
        const contractCallContext = [{
          reference: 'appContract',
          contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
          abi: constants.CONTRACT_COMPTROLLER_ABI,
          calls: [],
          context: {}
        }]
        earnedAssetList.forEach(asset => {
          contractCallContext[0].calls.push(
            { reference: `${asset.id}-arsSupplierIndex`, methodName: 'arsSupplierIndex', methodParameters: [asset.atokenAddress, account] }
          )
          contractCallContext[0].calls.push(
            { reference: `${asset.id}-arsBorrowerIndex`, methodName: 'arsBorrowerIndex', methodParameters: [asset.atokenAddress, account] }
          )
        });
        const contractCallResults = await methods.ethMulticall(web3, contractCallContext, requiredChainId);

        for (const value of contractCallResults?.results.appContract.callsReturnContext) {
          const references = value.reference.split('-')
          assetValues[references[0]] = assetValues[references[0]] || {}
          assetValues[references[0]][references[1]] = new BigNumber(value.returnValues[0].hex)
        }
      } catch (err) {
        console.log(err)
      }

      earnedAssetList.forEach(asset => {
        const supplyIndex = new BigNumber(asset.arsSupplyIndex)
        let supplierIndex = assetValues[asset.id]?.arsSupplierIndex || new BigNumber(0)
        const supplierTokens = asset.aTokenBalance
        const initBorrowIndex = asset.arsBorrowIndex
        const borrowerIndex = assetValues[asset.id]?.arsBorrowerIndex || 0
        const borrowBalanceStored = asset.borrowBalanceStored
        const borrowIndex = asset.borrowIndex

        if (supplierIndex.isZero(0) && supplyIndex.isGreaterThan(0)) {
          supplierIndex = arsInitialIndex;
        }
        let deltaIndex = supplyIndex.minus(supplierIndex);
        const supplierDelta = new BigNumber(supplierTokens)
          .multipliedBy(deltaIndex)
          .dividedBy(1e36);

        aquariusEarned = aquariusEarned.plus(supplierDelta);
        if (+borrowerIndex > 0) {
          deltaIndex = new BigNumber(initBorrowIndex).minus(borrowerIndex);
          const borrowerAmount = new BigNumber(borrowBalanceStored)
            .multipliedBy(1e18)
            .dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
          aquariusEarned = aquariusEarned.plus(borrowerDelta);
        }
      })

      aquariusEarned = aquariusEarned.plus(arsAccrued);
      setEarnedBalance(aquariusEarned);
    }
  };

  const onClaimAll = async () => {
    const tx = await handleClaimAll();
    if (tx) {
      setUpdate(!update);
    }
  }

  const handleCollect = async () => {
    if (earnedBalance.gt(0)) {
      setCollectLoading(true);
      const appContract = getComptrollerContract(web3, requiredChainId);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        appContract.methods.claimArs,
        [account],
        account
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setCollectLoading(false);
        return
      }
      methods.send(
        appContract.methods.claimArs,
        [account],
        account
      )
        .then(() => {
          setCollectLoading(false);
          setUpdate(!update);
        })
        .catch(() => {
          setCollectLoading(false);
        });
    }
  };

  useEffect(() => {
    if (account && assetList.length > 0 && isCoreNetwork(requiredChainId)) {
      getVoteInfo();
    }
  }, [assetList.length, account, requiredChainId]);

  return (
    <StyledContainer className="w-full">
      <div className='earn-container flex-1 w-full'>
        <div className='sub-container'>
          <div className='desc'>{t("My_Earned")}</div>
          <div className='vest-item-container'>
            <div className='vest-item'>
              <div className='value'>
                {getFormatStringFromBignumber((allPendingRewards.dividedBy(1e18)))}
                <span>{'ARS'}</span>
              </div>
              <button className="start-vesting"
                type="button"
                disabled={!account || claimAllPending || allPendingRewards.eq(0)}
                onClick={onClaimAll}
              >
                {claimAllPending && <Loading size={'14px'} margin={'7px'} />}
                {t('Start_vesting')}
              </button>
            </div>
            {
              isCoreNetwork(requiredChainId) &&
              <div className='vest-item'>
                <div className='value'>
                  {getFormatStringFromBignumber((earnedBalance.dividedBy(1e18)))}
                  <span>{'ARS'}</span>
                  <AlertHelper text={t('ARS_Reward_Help')} />
                </div>
                <button className="start-vesting"
                  type="button"
                  disabled={!account || collectLoading || earnedBalance.eq(0)}
                  onClick={handleCollect}
                >
                  {collectLoading && <Loading size={'14px'} margin={'7px'} />}
                  {t('Collect')}
                </button>
              </div>
            }
          </div>
        </div>
        <img src={ArsImg} alt='' />
      </div>

      <div className="emission-container flex-1">
        <div className='value-container'>
          {
            isEligibleForRewards ? (
              <div className="flex-1">
                <div className='title title-active'>{t('Emissions_Active')}</div>
                <div className='desc'>
                  {t('Emissions_Active_Desc')}
                </div>
              </div>
            )
              : (
                <div className="flex-1">
                  <div className='title'>{t('Emissions_Inactive')}</div>
                  <div className='desc'>
                    {t('Emissions_Inactive_Desc_1')}
                    <span className='value1'> {getFormatStringFromBignumber(awayProgress, 0)}% </span>
                    {t('Emissions_Inactive_Desc_2')}
                  </div>
                </div>
              )
          }
          <div className="flex-1">
            <div className='value-desc'>{t('dLP_required_to')}</div>
            <div className='value-content'>
              ${getFormatStringFromBignumber(lockedUsdValue.div(1e8), 2, 2)}
              <span>/${getFormatStringFromBignumber(requiredUsdValue.div(1e8), 2, 2)}</span>
            </div>
            <div className='progress-bar'>
              <div className='progress-active-bar'
                style={{ width: `${progressWidth}%` }}
              />
            </div>
          </div>
        </div>
        <div className="flex-1" />
        <div className='value-container'>
          <Link to={{ pathname: "https://docs.aquarius.loan/lp-staking" }} target="_blank" className='vault-button mobile-active-button flex-1' >
            {t("Learn_More")}
          </Link>
          <Link className="vault-button flex-1" to={routes.stake.dlp}>
            {t("Zap_into_LP")}
          </Link>
        </div>
      </div>
    </StyledContainer >
  );
};

export default AccountOverview;
