import React from 'react';
import styled from 'styled-components';

const StyledConatiner = styled.div`
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(82, 82, 82, 0.2) 0%, rgba(124, 124, 124, 0) 100%);
  border: 0.5px solid #FFFFFF1A;
  .progress-line {
    position: absolute;
    border-right: 2px solid #2563EB;
    height: 12px;
    width: 80%;
    bottom: -0.5px;
  }
  .progress-active-bar {
    height: 12px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    border-radius: 20px;
  }
`;

function BorrowLimitProgress({
  percent,
}) {
  return (
    <StyledConatiner className='progress-bar'>
      <div className='progress-line' />
      <div className='progress-active-bar'
        style={{ width: `${percent > 100 ? 100 : percent}%` }}
      />
    </StyledConatiner>
  );
}

export default BorrowLimitProgress;
