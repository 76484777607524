import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import Modal from '../UI/Modal';
import Select from 'components/UI/Select';
import Loading from '../UI/Loading';
import ConnectWalletButton from './ConnectWalletButton';
import BorrowLimitProgress from 'components/UI/BorrowLimitProgress';

import { useActiveWeb3React, useWeb3 } from '../../hooks';
import { getNativeToken } from 'utils';
import * as constants from 'utilities/constants';
import { getBigNumber } from '../../utilities/common';
import { getAbepContract, getContract, getTokenContract, methods } from '../../utilities/ContractService';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
  }
  .input-container {
    display: flex;
    align-items: center;
    padding: 20px 24px 20px 24px;
    border-radius: 4px;
    border: 1px solid #FFFFFF33;
    background: #00000033;
    gap: 12px;
    @media (max-width: 992px) {
      padding: 10px 16px;
    }
    input {
      flex: 1;
      background: transparent;
      border: none;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      &:focus {
        outline: none;
      }
      ::placeholder {
        padding: 0 !important;
        color: #9CA3AF !important;
      }
      @media (max-width: 992px) {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
    .max-button {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: right;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  .balance-container {
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;
const PrimaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .apy-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .item {
      display: flex;
      aglin-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .divider {
    border-bottom: 1px solid #FFFFFF33;
  }
`;
const BorrowLimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #9CA3AF;
  .borrow-limit {
    display: flex;
    aglin-items: center;
    justify-content: space-between;
  }
`;
const StyledTab = styled.div`
  display: flex;
  border-bottom: 1px solid #FFFFFF1A;
  gap: 10px;
  .tab {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    text-align: center;
    cursor: pointer;
    .bottom-active {
      width: 100%;
      height: 2px;
      margin-top: 12px;
      border-radius: 4px 4px 0px 0px;
      background: #FFFFFF;
    }
  }
`
const ErrorMessage = styled.div`
  color: #F87171;
  height: 0px;
  text-align: right;
  width: 100%;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  algin-items: center;
  padding: 24px;
  border-top: 1px solid #FFFFFF1A;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 16px 24px;
    border-radius: 4px;
    border: 1px solid #0EA5E9;
    gap: 10px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    &:hover {
      box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }
`;

function BorrowRepayModal({
  open,
  onCloseModal,
  record,
  tab = 'borrow',
}) {
  const settings = useSelector((state) => state.account.setting);

  const { t } = useTranslation();
  const web3 = useWeb3();
  const { account, requiredChainId } = useActiveWeb3React();

  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedAsset, setSelectedAsset] = useState(record);

  const [isLoading, setIsLoading] = useState(false);
  const [showBorrowError, setShowBorrowError] = useState(false);
  const [amount, setAmount] = useState('');
  const [borrowBalance, setBorrowBalance] = useState(new BigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0));
  const [newBorrowBalance, setNewBorrowBalance] = useState(new BigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0));

  const [isLoadingRepay, setIsLoadingRepay] = useState(false);
  const [isEnabledRepay, setIsEnabledRepay] = useState(false);
  const [showRepayError, setShowRepayError] = useState(false);
  const [amountRepay, setAmountRepay] = useState('');
  const [allowanceAmount, setAllowanceAmount] = useState(new BigNumber(0));
  const [borrowBalanceRepay, setBorrowBalanceRepay] = useState(new BigNumber(0));
  const [borrowPercentRepay, setBorrowPercentRepay] = useState(new BigNumber(0));
  const [newBorrowBalanceRepay, setNewBorrowBalanceRepay] = useState(new BigNumber(0));
  const [newBorrowPercentRepay, setNewBorrowPercentRepay] = useState(new BigNumber(0));

  const updateInfo = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    if (new BigNumber(amount || 0).isZero()) {
      setBorrowBalance(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      const temp = totalBorrowBalance.plus(new BigNumber(amount || 0).times(tokenPrice));
      setBorrowBalance(totalBorrowBalance);
      setNewBorrowBalance(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(temp.div(totalBorrowLimit).times(100));
      }
    }
  }, [account, amount, selectedAsset]);

  const updateInfoRepay = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    if (new BigNumber(amountRepay || 0).isZero()) {
      setBorrowBalanceRepay(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercentRepay(new BigNumber(0));
        setNewBorrowPercentRepay(new BigNumber(0));
      } else {
        setBorrowPercentRepay(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercentRepay(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      const temp = totalBorrowBalance.minus(new BigNumber(amountRepay || 0).times(tokenPrice));
      setBorrowBalanceRepay(totalBorrowBalance);
      setNewBorrowBalanceRepay(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercentRepay(new BigNumber(0));
        setNewBorrowPercentRepay(new BigNumber(0));
      } else {
        setBorrowPercentRepay(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercentRepay(temp.div(totalBorrowLimit).times(100));
      }
    }
    if (new BigNumber(amountRepay || 0).isGreaterThan(BigNumber.minimum(selectedAsset.walletBalance, selectedAsset.borrowBalance))) {
      setShowRepayError(true);
    } else {
      setShowRepayError(false);
    }
  }, [account, amountRepay, selectedAsset]);


  const handleBorrow = async () => {
    const appContract = getAbepContract(web3, selectedAsset.id, requiredChainId);
    if (selectedAsset && account) {
      setIsLoading(true);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        appContract.methods.borrow,
        [
          new BigNumber(amount)
            .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
            .integerValue()
            .toString(10),
        ],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setIsLoading(false);
        return
      }
      methods.send(
        appContract.methods.borrow,
        [
          new BigNumber(amount)
            .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
            .integerValue()
            .toString(10),
        ],
        account,
      )
        .then(() => {
          setAmount('');
          setIsLoading(false);
          onCloseModal();
          NotificationManager.success(t('Borrow_successfully'));
        })
        .catch(() => {
          setIsLoading(false);
          NotificationManager.error(t('Tx_rejected'));
        });
    }
  };

  const handleMaxAmount = () => {
    if (currentTab === 'borrow') {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
      const safeMax = BigNumber.maximum(
        totalBorrowLimit.times(70).div(100).minus(totalBorrowBalance),
        new BigNumber(0),
      );

      setAmount(
        BigNumber.minimum(safeMax, selectedAsset.liquidity).div(tokenPrice).decimalPlaces(settings.decimals[selectedAsset.id].token).toString(10)
      );
    } else {
      if (selectedAsset.id === getNativeToken(requiredChainId).toLowerCase()) {
        NotificationManager.warning(t("Transaction_can_be_fail"));
      }
      setAmountRepay(BigNumber.minimum(selectedAsset.walletBalance, selectedAsset.borrowBalance).toString(10));
    }
  };

  const getBorrowMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    const max = BigNumber.maximum(
      totalBorrowLimit.minus(totalBorrowBalance),
      new BigNumber(0),
    );
    return (BigNumber.minimum(max, selectedAsset.liquidity).div(tokenPrice)).toFormat(2, BigNumber.ROUND_DOWN);
  };

  useEffect(() => {
    if (selectedAsset.liquidity) {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      if (
        (new BigNumber(amount || 0).isGreaterThan(new BigNumber(selectedAsset.liquidity).div(selectedAsset.tokenPrice))) ||
        newBorrowPercent.isGreaterThan(100) ||
        (!new BigNumber(selectedAsset.borrowCaps || 0).isZero() && amount.plus(selectedAsset.totalBorrows).isGreaterThan(selectedAsset.borrowCaps)) ||
        (amount && new BigNumber(amount).times(selectedAsset.tokenPrice).plus(totalBorrowBalance).gt(totalBorrowLimit))
      ) {
        setShowBorrowError(true);
      } else {
        setShowBorrowError(false);
      }
    }
  }, [amount, newBorrowPercent, selectedAsset])

  useEffect(() => {
    if (selectedAsset.atokenAddress && account) {
      updateInfo();
      updateInfoRepay();
    }
  }, [account, updateInfoRepay, updateInfo, selectedAsset]);

  useEffect(() => {
    if (open) {
      getAllowanceAmount();
      setCurrentTab(tab);
    } else {
      setAmount('');
      setAmountRepay('');
    }
  }, [open]);

  useEffect(() => {
    if (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
      if (!amountRepay || allowanceAmount.gte(amountRepay)) {
        setIsEnabledRepay(true);
      } else {
        setIsEnabledRepay(false);
      }
    } else {
      setIsEnabledRepay(true);
    }
  }, [amountRepay, allowanceAmount]);

  const getAllowanceAmount = async () => {
    if (selectedAsset && account && selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
      const tokenContract = getTokenContract(web3, selectedAsset.id, requiredChainId);
      const result = await methods.call(
        tokenContract.methods.allowance,
        [account, selectedAsset.atokenAddress],
      );
      setAllowanceAmount(new BigNumber(result || 0).dividedBy(new BigNumber(10).pow(settings.decimals[selectedAsset.id]?.token || 18)));
    }
  };

  const onApprove = async () => {
    if (selectedAsset && account && selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
      setIsLoadingRepay(true);
      const tokenContract = getTokenContract(web3, selectedAsset.id, requiredChainId);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        tokenContract.methods.approve,
        [
          selectedAsset.atokenAddress,
          new BigNumber(amountRepay)
            .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
            .integerValue()
            .toString(10)
        ],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setIsLoadingRepay(false);
        return
      }
      methods.send(
        tokenContract.methods.approve,
        [
          selectedAsset.atokenAddress,
          new BigNumber(amountRepay)
            .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
            .plus(new BigNumber(10).pow(Number(settings.decimals[selectedAsset.id].token) - 3))
            .integerValue()
            .toString(10)
        ],
        account,
      )
        .then(() => {
          getAllowanceAmount();
          setIsLoadingRepay(false);
          NotificationManager.success(t('Approve_successfully'));
        })
        .catch(() => {
          setIsLoadingRepay(false);
          NotificationManager.error(t('Tx_rejected'));
        });
    }
  };

  const handleRepayBorrow = async () => {
    const appContract = getAbepContract(web3, selectedAsset.id, requiredChainId);
    if (selectedAsset && account) {
      setIsLoadingRepay(true);
      try {
        const initialize = (close) => {
          if (close) {
            setAmountRepay('');
            onCloseModal();
            setCurrentTab('borrow');
          }

          setIsLoadingRepay(false);
        }

        if (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
          if (new BigNumber(amountRepay).eq(selectedAsset.borrowBalance)) {
            const isCheckGas = await methods.checkGasFee(
              web3,
              requiredChainId,
              appContract.methods.repayBorrow,
              [new BigNumber(2).pow(256).minus(1).toString(10)],
              account,
            );
            if (!isCheckGas) {
              NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
              initialize(false);
              return
            }
            methods.send(
              appContract.methods.repayBorrow,
              [new BigNumber(2).pow(256).minus(1).toString(10)],
              account,
            ).then(() => {
              initialize(true);
              NotificationManager.success(t('Repay_successfully'));
            }).catch(() => {
              initialize(false);
              NotificationManager.error(t('Tx_rejected'));
            });
          } else {
            const isCheckGas = await methods.checkGasFee(
              web3,
              requiredChainId,
              appContract.methods.repayBorrow,
              [
                new BigNumber(amountRepay)
                  .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
                  .integerValue()
                  .toString(10),
              ],
              account,
            );
            if (!isCheckGas) {
              NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
              initialize(false);
              return
            }
            methods.send(
              appContract.methods.repayBorrow,
              [
                new BigNumber(amountRepay)
                  .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
                  .integerValue()
                  .toString(10),
              ],
              account,
            ).then(() => {
              NotificationManager.success(t('Repay_successfully'));
              initialize(true);
            }).catch(() => {
              NotificationManager.error(t('Tx_rejected'));
              initialize(false);
            });
          }
        } else {
          const address = constants.CONTRACT_ABEP_ADDRESS[requiredChainId][getNativeToken(requiredChainId).toLowerCase()].address
          const contract = getContract(web3, requiredChainId, constants.CONTRACT_AETH_ABI, address);

          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            contract.methods.repayBorrow,
            [],
            account,
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));

            setIsLoadingRepay(false);
            return false;
          }

          await methods.sendWithValue(
            contract.methods.repayBorrow,
            [],
            account,
            new BigNumber(amountRepay)
              .times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token))
              .integerValue()
              .toString(10),
          ).then(() => {
            setIsLoadingRepay(false);
            setAmountRepay('');
            onCloseModal();
          }).catch(() => {
            setIsLoadingRepay(false);
            NotificationManager.error(t('Tx_rejected'));
          });
        }
      } catch (e) {
        setIsLoadingRepay(false);
      }
    }
  };

  const content = (
    <StyledContainer>
      <div className='content-container'>
        <StyledTab>
          <div className='tab' style={currentTab === 'borrow' ? {} : { color: '#9CA3AF' }}
            onClick={() => setCurrentTab('borrow')}
          >
            {t("Borrow")}
            <div className="bottom-active" style={currentTab === 'borrow' ? {} : { background: 'transparent' }} />
          </div>
          <div className='tab' style={currentTab === 'borrowRepay' ? {} : { color: '#9CA3AF' }}
            onClick={() => setCurrentTab('borrowRepay')}
          >
            {t("Repay_Borrow")}
            <div className="bottom-active" style={currentTab === 'borrowRepay' ? {} : { background: 'transparent' }} />
          </div>
        </StyledTab>
        <Select
          selectedProp={selectedAsset}
          options={settings.assetList || []}
          onChange={(item) => setSelectedAsset(item)}
        />
        <div>
          <div className="input-container">
            <input
              placeholder="0.0"
              value={currentTab === 'borrow' ? amount : amountRepay}
              onChange={event => {
                if (!event.target.value.length || Number(event.target.value) >= 0) {
                  if (currentTab === 'borrow') {
                    setAmount(event.target.value);
                  } else {
                    setAmountRepay(event.target.value);
                  }
                }
              }}
            />
            <div
              className="max-button"
              style={account ? {} : { cursor: 'not-allowed', opacity: 0.7 }}
              onClick={() => {
                handleMaxAmount()
              }}
            >
              {currentTab === 'borrow' ? t("SAFE_MAX") : t("MAX")}
            </div>
          </div>
          {
            currentTab === 'borrow' ?
              <div className='balance-container'>
                <div style={{ color: '#9CA3AF' }}>{t("Borrowable_Balance")}</div>
                <div>
                  {getBorrowMaxAmount()} {selectedAsset.symbol}
                </div>
              </div>
              :
              <div className='balance-container'>
                <div style={{ color: '#9CA3AF' }}>{t("Wallet_Balance")}</div>
                <div>
                  {new BigNumber(selectedAsset.walletBalance || 0).toFormat(2)} {selectedAsset.symbol}
                </div>
              </div>
          }
        </div>
        <PrimaryContainer>
          <div className="apy-items">
            <div className='item'>
              <div className='flex itmes-center'>
                <img src={selectedAsset.img} alt={selectedAsset.symbol} />
                <div>{t("Borrow_APY")}</div>
              </div>
              <div>
                {new BigNumber(selectedAsset.borrowApy).dp(2, 1).toString(10)}%
              </div>
            </div>
            <div className='item'>
              <div className='flex itmes-center'>
                <img src="/images/coins/ars.png" alt="ARS" />
                <div>{t("Distribution_APY")}</div>
              </div>
              <div>
                {new BigNumber(selectedAsset.borrowAdditionalApy).dp(2, 1).toString(10)}%
              </div>
            </div>
          </div>
          <div className='divider' />
          <BorrowLimitContainer>
            <div className='borrow-limit'>
              <div>{t("Current")}: ${borrowBalance.toFormat(2)}</div>
              <div>
                {t("Max")}{": "}
                <span style={{ color: '#F9FAFB' }}>
                  ${newBorrowPercent.toFormat(2)}
                </span>
              </div>
            </div>
            <BorrowLimitProgress
              percent={currentTab == 'borrow' ? newBorrowPercent.toNumber() : newBorrowPercentRepay.toNumber()}
            />
            <div className='borrow-limit'>
              <div>{t("Limit_Used")}</div>
              <div style={{ color: '#F9FAFB' }}>
                {currentTab == 'borrow' ? newBorrowPercent.toFormat(2) : newBorrowPercentRepay.toFormat(2)}%
              </div>
            </div>
            <div className='borrow-limit'>
              <div>{t("Borrow_Balance")}</div>
              <div style={{ color: '#F9FAFB' }}>
                ${currentTab == 'borrow' ? newBorrowBalance.toFormat(2) : newBorrowBalanceRepay.toFormat(2)}
              </div>
            </div>
            <div className='divider' />
            <div className='borrow-limit'>
              <div>{t("Currently_Borrowing")}</div>
              <div style={{ color: '#F9FAFB' }}>
                {new BigNumber(selectedAsset.borrowBalance || 0).toFormat(4)} {selectedAsset.symbol}
              </div>
            </div>
          </BorrowLimitContainer>
        </PrimaryContainer>
        {currentTab === 'borrow' && (
          <ErrorMessage>
            {showBorrowError ? `${t("You_can_borrow")} ${getBorrowMaxAmount()} ${selectedAsset.symbol} ${t("at_least")}` : ''}
          </ErrorMessage>
        )}
        {currentTab === 'borrowRepay' && (
          <ErrorMessage>
            {showRepayError ?
              `${t("You_can_repay")} ${BigNumber.minimum(selectedAsset.walletBalance, selectedAsset.borrowBalance).toFormat(2)}${selectedAsset.symbol
              }`
              :
              ''
            }
          </ErrorMessage>
        )}
      </div>
      {
        !account ? (
          <StyledButtonContainer>
            <ConnectWalletButton />
          </StyledButtonContainer>
        ) : (
          currentTab === 'borrow' ? (
            <StyledButtonContainer>
              <button
                disabled={
                  showBorrowError ||
                  isLoading ||
                  new BigNumber(amount || 0).isZero() ||
                  new BigNumber(amount).isGreaterThan(new BigNumber(selectedAsset.liquidity).div(selectedAsset.tokenPrice)) ||
                  newBorrowPercent.isGreaterThan(100) ||
                  (!new BigNumber(selectedAsset.borrowCaps || 0).isZero() &&
                    new BigNumber(amount).plus(selectedAsset.totalBorrows).isGreaterThan(selectedAsset.borrowCaps))
                }
                onClick={handleBorrow}
              >
                {isLoading && <Loading size={'18px'} margin={'8px'} />} {t("Borrow")}
              </button>
            </StyledButtonContainer>
          ) : (
            <StyledButtonContainer>
              {!isEnabledRepay && (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) ? (
                <button
                  disabled={isLoadingRepay || new BigNumber(amountRepay || 0).isZero()}
                  onClick={() => {
                    onApprove();
                  }}
                >
                  {isLoadingRepay && <Loading size={'18px'} margin={'8px'} />} {t("Enable")}
                </button>
              ) : (
                <button
                  disabled={
                    showRepayError ||
                    isLoadingRepay ||
                    new BigNumber(amountRepay || 0).isZero() ||
                    new BigNumber(amountRepay).isGreaterThan(
                      BigNumber.minimum(selectedAsset.walletBalance, selectedAsset.borrowBalance),
                    )
                  }
                  onClick={handleRepayBorrow}
                >
                  {isLoadingRepay && <Loading size={'18px'} margin={'8px'} />} {t("Repay_Borrow")}
                </button>
              )}
            </StyledButtonContainer>
          )
        )
      }
    </StyledContainer >
  );

  return (
    <Modal
      content={content}
      open={open}
      onCloseModal={() => {
        setAmountRepay();
        setAmount();
        onCloseModal();
      }}
      containerStyle={{
        maxWidth: 560,
      }}
    />
  );
}

export default BorrowRepayModal;
