import { getAddress } from "@ethersproject/address";

export { fillArray } from './fillArray';

export function isAddress(value) {
	try {
		return getAddress(value);
	} catch {
		return false;
	}
}

const EXPLORERS = {
	1116: 'https://scan.coredao.org',
	1115: 'https://scan.test.btcs.network',
	42161: 'https://arbiscan.io',
	421614: 'https://sepolia.arbiscan.io',
};

export function getEtherscanLink(chainId, data, type) {
	const prefix = `${EXPLORERS[chainId]}`;

	switch (type) {
		case "transaction": {
			return `${prefix}/tx/${data}`;
		}
		case "token": {
			return `${prefix}/token/${data}`;
		}
		case "block": {
			return `${prefix}/block/${data}`
		}
		case "address":
		default: {
			return `${prefix}/address/${data}`;
		}
	}
}

export function showTokenOnExplorer(address, chainId) {
	const url = getEtherscanLink(chainId, address, 'token');
	window.open(url, "_blank");
	return url;
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
	const parsed = isAddress(address);
	if (!parsed) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function isCoreNetwork(chainId) {
	if (chainId === 1115 || chainId === 1116) {
		return true;
	}
	return false;
}

export function isArbitrumNetwork(chainId) {
	if (chainId === 42161 || chainId === 421614) {
		return true;
	}
	return false;
}

export function getNativeToken(chainId) {
	if (chainId === 1115 || chainId === 1116) {
		return 'CORE';
	}
	return 'ETH';
}