import { createAction } from 'redux-actions';
import { createPromiseAction } from '../utils';

/**
 * Action Types
 */
export const SET_SETTING_REQUEST = '@account/SET_SETTING_REQUEST';
export const SET_CONNECT_WALLET_MODAL_OPEN = '@account/SET_CONNECT_WALLET_MODAL_OPEN';

export const GET_MARKET_HISTORY_REQUEST = '@account/GET_MARKET_HISTORY_REQUEST';
export const GET_GOVERNANCE_AQUARIUS_REQUEST =
  '@account/GET_GOVERNANCE_AQUARIUS_REQUEST';
export const GET_PROPOSALS_REQUEST = '@account/GET_PROPOSALS_REQUEST';

export const GET_PROPOSAL_BY_ID_REQUEST = '@account/GET_PROPOSAL_BY_ID_REQUEST';

export const GET_VOTERS_REQUEST = '@account/GET_VOTERS_REQUEST';

export const GET_VOTER_DETAIL_REQUEST = '@account/GET_VOTER_DETAIL_REQUEST';

export const GET_VOTER_HISTORY_REQUEST = '@account/GET_VOTER_HISTORY_REQUEST';

export const GET_VOTER_ACCOUNTS_REQUEST = '@account/GET_VOTER_ACCOUNTS_REQUEST';

/**
 * Action Creators
 */
export const accountActionCreators = {
  setSetting: createAction(SET_SETTING_REQUEST),
  setConnectWalletsOpen: createPromiseAction(SET_CONNECT_WALLET_MODAL_OPEN),
  getMarketHistory: createPromiseAction(GET_MARKET_HISTORY_REQUEST),
  getGovernanceAquarius: createPromiseAction(GET_GOVERNANCE_AQUARIUS_REQUEST),

  getProposals: createPromiseAction(GET_PROPOSALS_REQUEST),
  getProposalById: createPromiseAction(GET_PROPOSAL_BY_ID_REQUEST),
  getVoters: createPromiseAction(GET_VOTERS_REQUEST),
  getVoterDetail: createPromiseAction(GET_VOTER_DETAIL_REQUEST),
  getVoterHistory: createPromiseAction(GET_VOTER_HISTORY_REQUEST),
  getVoterAccounts: createPromiseAction(GET_VOTER_ACCOUNTS_REQUEST)
};
