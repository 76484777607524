import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as moment from "moment";
import { getVoteContract, methods } from "../../utilities/ContractService";
import { useActiveWeb3React, useWeb3 } from "../../hooks";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RouteMap from "../../routes/RouteMap";
import circleTick from "../../assets/icons/circleTick.svg";
import circleCross from "../../assets/icons/circleCrossRed.svg";
import { NotificationManager } from "react-notifications";
import { getNativeToken } from "utils";

const Styles = styled.div`
    margin: 0px 47px;
    padding: 11px 0px 21px 0px;
    border-bottom: 1px solid #EEEEEE;

    div.cell-container-first {
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    div.cell-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-left: 1px solid #717579;
        padding: 0px 0px 0px 45px;
    }

    .icon {
        height: 45px;
        width: 45px;
    }

    .custom-bg-color-lightGreen3 {
        background-color: #4FD000;
    }
    .custom-bg-color-lightRed {
        background-color: #FF0100;
    }
    .custom-bg-color-midBlue {
        background-color: #3B46F1;
    }
`

const ProposalOverview = ({
    address,
    delegateAddress,
    proposal,
    votingWeight,
    history
}) => {
    const web3 = useWeb3();
    const { requiredChainId } = useActiveWeb3React();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [voteType, setVoteType] = useState('like');
    const [voteStatus, setVoteStatus] = useState('');
    const voteContract = getVoteContract(requiredChainId);

    const getStatus = p => {
        if (p.state === 'Executed') {
            return 'Passed';
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return 'Failed';
        }
        return p.state;
    };

    const getStatusColor = p => {
        if (p.state === 'Executed') {
            return 'lightGreen3';
        }
        if (p.state === 'Active') {
            return 'midBlue';
        }
        if (p.state === 'Defeated') {
            return 'lightRed';
        }
        return 'midBlue';
    };

    const getRemainTime = item => {
        if (item.state === 'Active') {
            const diffBlock = item.endBlock - item.blockNumber;
            const duration = moment.duration(
                diffBlock < 0 ? 0 : diffBlock * 3,
                'seconds'
            );
            const days = Math.floor(duration.asDays());
            const hours = Math.floor(duration.asHours()) - days * 24;
            const minutes =
                Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;
            return `${days > 0 ? `${days} ${days > 1 ? 'days' : 'day'},` : ''
                } ${hours} ${hours > 1 ? 'hrs' : 'hr'} ${days === 0 ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ''
                } left`;
        }
        if (item.state === 'Pending') {
            return `${moment(item.createdTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Active') {
            return `${moment(item.startTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Canceled' || item.state === 'Defeated') {
            return `${moment(item.endTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Queued') {
            return `${moment(item.queuedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Expired' || item.state === 'Executed') {
            return `${moment(item.executedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        return `${moment(item.updatedAt).format('MMMM DD, YYYY')}`;
    };


    const getIsHasVoted = useCallback(async () => {
        await methods.call(voteContract.methods.getReceipt, [proposal.id, address])
            .then(res => {
                setVoteStatus(res.hasVoted ? 'voted' : 'novoted');
            });
    }, [address, proposal]);

    useEffect(() => {
        if (address && proposal.id) {
            getIsHasVoted();
        }
    }, [address, proposal, getIsHasVoted]);

    const handleVote = async (support) => {
        setIsLoading(true);
        setVoteType(support);
        const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            voteContract.methods.castVote,
            [proposal.id, support === 'like'],
            address
        );
        if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setIsLoading(false);
            return
        }
        methods.send(
                voteContract.methods.castVote,
                [proposal.id, support === 'like'],
                address
            )
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const getTitle = descs => {
        const index = descs.findIndex(d => d !== '');
        if (index !== -1) {
            return descs[index];
        }
        return '';
    };

    return (
        <Styles
            className="mt-2 flex justify-between p-6 cursor-pointer text-sm"
            onClick={() => history.push(RouteMap.vote.voteOverview.replace(":id", proposal.id))}
        >
            <div>
                <ReactMarkdown className={'text-2xl font-semibold'}>{getTitle(proposal.description?.split('\n'))}</ReactMarkdown>
                <div className="flex items-center justify-left mt-4">
                    <button
                        className={`focus:outline-none  text-white py-0.5 px-1.5 rounded text-lg
                            custom-bg-color-${getStatusColor(proposal)}`}
                    >
                        {getStatus(proposal)}
                    </button>
                    <span className="ml-5 text-secondary text-sm">
                        {`${proposal.id} - ${proposal.state} ${moment(proposal.createdAt).format('MMMM Do, YYYY')}`}
                    </span>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <span className="text-lg text-secondary mr-5">{getStatus(proposal)}</span>
                {proposal.state !== 'Defeated' ? <img src={circleTick} alt="" className="icon" /> : <img src={circleCross} alt="" className="icon" />}
            </div>
        </Styles>
    )
}

export default compose(withRouter)(ProposalOverview);
