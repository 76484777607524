import { useEffect, useState, useMemo, useCallback } from 'react';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React, useWeb3 } from './';
import * as constants from 'utilities/constants';
import moment from 'moment';
import { methods } from 'utilities/ContractService';

import useRefresh from './useRefresh';
import { useStakingContract, useERC20TokenContract } from './useContract';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { getNativeToken } from 'utils';

export const useStakingData = (forceUpdate) => {
  const { account, requiredChainId, } = useActiveWeb3React();
  const web3 = useWeb3();
  const [totalLocked, setTotalLocked] = useState(new BigNumber(0));
  const [totalStaked, setTotalStaked] = useState(new BigNumber(0));
  const [stakeAprWithoutPrice, setStakeAprWithoutPrice] = useState(0);
  const [lockAprInitial, setLockAprInitial] = useState(0);
  const [withdrawableBalance, setWithdrawableBalance] = useState(
    new BigNumber(0)
  );
  const [penaltyAmount, setPenaltyAmount] = useState(new BigNumber(0));
  const [unlockedBalance, setUnlockedBalance] = useState(new BigNumber(0));
  const [totalEarned, setTotalEarned] = useState(new BigNumber(0));
  const [vests, setVests] = useState([]);
  const [locks, setLocks] = useState([]);
  const [unlockable, setUnlockable] = useState(new BigNumber(0));
  const [fees, setFees] = useState([]);
  const [arsEmission, setArsEmission] = useState(new BigNumber(0));
  const [reserves, setReserves] = useState(new BigNumber(0));

  const { fastRefresh } = useRefresh();

  const calls = useMemo(() => {
    let temp = [
      {
        reference: 'lockedSupply',
        contractAddress: constants.STAKING_ADDRESS[requiredChainId],
        abi: constants.STAKING_ABI,
        calls: [
          {
            methodName: 'lockedSupply',
            methodParameters: []
          }
        ]
      },
      {
        reference: 'totalSupply',
        contractAddress: constants.STAKING_ADDRESS[requiredChainId],
        abi: constants.STAKING_ABI,
        calls: [
          {
            methodName: 'totalSupply',
            methodParameters: []
          }
        ]
      },
      {
        reference: 'rewardDataARS',
        contractAddress: constants.STAKING_ADDRESS[requiredChainId],
        abi: constants.STAKING_ABI,
        calls: [
          {
            methodName: 'rewardData',
            methodParameters: [constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address]
          }
        ]
      },
      {
        reference: 'rewardDataWCORE',
        contractAddress: constants.STAKING_ADDRESS[requiredChainId],
        abi: constants.STAKING_ABI,
        calls: [
          {
            methodName: 'rewardData',
            methodParameters: [constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].wcore.address]
          }
        ]
      },
      {
        reference: 'reservedWCORE',
        contractAddress: constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].wcore.address,
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'balanceOf',
            methodParameters: [constants.STAKING_ADDRESS[requiredChainId]]
          }
        ]
      }
    ];
    if (account) {
      temp = temp.concat([
        {
          reference: 'withdrawableBalance',
          contractAddress: constants.STAKING_ADDRESS[requiredChainId],
          abi: constants.STAKING_ABI,
          calls: [
            {
              methodName: 'withdrawableBalance',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'unlockedBalance',
          contractAddress: constants.STAKING_ADDRESS[requiredChainId],
          abi: constants.STAKING_ABI,
          calls: [
            {
              methodName: 'unlockedBalance',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'earnedBalances',
          contractAddress: constants.STAKING_ADDRESS[requiredChainId],
          abi: constants.STAKING_ABI,
          calls: [
            {
              methodName: 'earnedBalances',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'lockedBalances',
          contractAddress: constants.STAKING_ADDRESS[requiredChainId],
          abi: constants.STAKING_ABI,
          calls: [
            {
              methodName: 'lockedBalances',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'claimableRewards',
          contractAddress: constants.STAKING_ADDRESS[requiredChainId],
          abi: constants.STAKING_ABI,
          calls: [
            {
              methodName: 'claimableRewards',
              methodParameters: [account]
            }
          ]
        }
      ]);
    }

    return temp;
  }, [account]);

  const contract = useStakingContract(requiredChainId);

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        let data;
        let _unlockedBalance;

        if (!account) {
          data = await methods.ethMulticall(web3, calls, requiredChainId);
        } else {
          // eslint-disable-next-line no-undef
          [data, _unlockedBalance] = await Promise.all([
            methods.ethMulticall(web3, calls, requiredChainId),
            contract.methods.unlockedBalance(account).call({
              from: account
            })
          ]);
        }
        // console.log('data = ', data);

        const totalLockedAmount = new BigNumber(
          data.results.lockedSupply.callsReturnContext[0].returnValues[0].hex
        );
        const totalSupplyAmount = new BigNumber(
          data.results.totalSupply.callsReturnContext[0].returnValues[0].hex
        );

        const lockRewardPeriodFinish = Number(
          data.results.rewardDataARS.callsReturnContext[0].returnValues[0].hex
        );
        const lockRewardRate = new BigNumber(
          data.results.rewardDataARS.callsReturnContext[0].returnValues[1].hex
        );

        if (lockRewardPeriodFinish < moment().unix()) {
          setLockAprInitial(0);
        } else if (totalLockedAmount.gt(0)) {
          let _lockApr = lockRewardRate
            .times(3600 * 24 * 365)
            .div(totalLockedAmount)
            .times(100)
            .toNumber();
          setLockAprInitial(_lockApr);
        }

        // WCORE reward
        const stakingRewardPeriodFinish = Number(
          data.results.rewardDataWCORE.callsReturnContext[0].returnValues[0]
            .hex
        );
        const stakingRewardRate = new BigNumber(
          data.results.rewardDataWCORE.callsReturnContext[0].returnValues[1].hex
        );

        if (stakingRewardPeriodFinish < moment().unix()) {
          setStakeAprWithoutPrice(0);
        } else {
          let _stakingApr = stakingRewardRate
            .times(3600 * 24 * 365)
            .div(1e18) // WCORE decimal
            // .times(wcorePrice)
            .times(1e18)
            .times(100)
            .div(totalSupplyAmount)
            // .div(arsPrice)
            .toNumber();

          setStakeAprWithoutPrice(_stakingApr);
        }

        setTotalLocked(totalLockedAmount);
        setTotalStaked(totalSupplyAmount.minus(totalLockedAmount));
        setArsEmission(
          new BigNumber(
            data.results.rewardDataARS.callsReturnContext[0].returnValues[1].hex
          )
        );

        setReserves(
          new BigNumber(
            data.results.reservedWCORE.callsReturnContext[0].returnValues[0].hex
          ).div(1e18)
        );

        if (account) {
          setWithdrawableBalance(
            new BigNumber(
              data.results.withdrawableBalance.callsReturnContext[0].returnValues[0].hex
            )
          );
          setPenaltyAmount(
            new BigNumber(
              data.results.withdrawableBalance.callsReturnContext[0].returnValues[1].hex
            )
          );
          setUnlockedBalance(
            new BigNumber(
              data.results.unlockedBalance.callsReturnContext[0].returnValues[0].hex
            )
          );

          setTotalEarned(
            new BigNumber(
              data.results.earnedBalances.callsReturnContext[0].returnValues[0].hex
            )
          );
          const _earningsData = [];
          data.results.earnedBalances.callsReturnContext[0].returnValues[1].forEach(
            e => {
              _earningsData.push({
                amount: new BigNumber(e[0].hex),
                unlockTime: Number(e[1].hex)
              });
            }
          );
          setVests(_earningsData);

          const _locks = [];
          data.results.lockedBalances.callsReturnContext[0].returnValues[3].forEach(
            e => {
              _locks.push({
                amount: new BigNumber(e[0].hex),
                unlockTime: Number(e[1].hex)
              });
            }
          );
          setLocks(_locks);
          setUnlockable(
            new BigNumber(
              data.results.lockedBalances.callsReturnContext[0].returnValues[1].hex
            )
          );

          const _fee = [];
          for (let index = 0; index < data.results.claimableRewards.callsReturnContext[0].returnValues.length; index++) {
            _fee.push({
              address: data.results.claimableRewards.callsReturnContext[0].returnValues[index][0],
              amount: new BigNumber(data.results.claimableRewards.callsReturnContext[0].returnValues[index][1].hex)
            })
          }
          setFees(_fee)

          setUnlockedBalance(new BigNumber(_unlockedBalance));
        } else {
          setWithdrawableBalance(new BigNumber(0));
          setPenaltyAmount(new BigNumber(0));
          setUnlockedBalance(new BigNumber(0));
          setTotalEarned(new BigNumber(0));
          setVests([]);
          setLocks([]);
        }
      } catch (e) {
        console.error('Fetching staking data had error', e);
      }
    };

    fetchGlobalData();
  }, [fastRefresh, forceUpdate, methods.ethMulticall, account, contract, web3]);

  return {
    totalLocked,
    totalStaked,
    stakeAprWithoutPrice,
    lockAprInitial,
    withdrawableBalance,
    penaltyAmount,
    unlockedBalance,
    totalEarned,
    vests,
    locks,
    unlockable,
    fees,
    arsEmission,
    reserves
  };
};

export const useStakeCallback = (account) => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useStakingContract(requiredChainId);

  const handleStake = useCallback(
    async (amount, lock) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.stake,
          [
            amount, lock
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.stake(amount, lock).send({
          from: account
        });
        if (tx) {
          NotificationManager.success(lock ? t('Locked_successfully') : t('Staked_successfully'));
        } else {
          NotificationManager.error(
            `${lock ? t('Tx_rejected') : t('Tx_rejected')}.`
          );
        }
        setPending(false);
        return tx;
      } catch (e) {
        console.error('Stake had error :>> ', e);
        setPending(false);
        NotificationManager.error(
          `${lock ? t('Tx_rejected') : t('Tx_rejected')}.`
        );
        return false;
      }
    },
    [account, contract]
  );

  return { handleStake, pending };
};

export const useWithdrawCallback = account => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useStakingContract(requiredChainId);

  const handleWithdraw = useCallback(
    async amount => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.withdraw,
          [
            amount
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.withdraw(amount).send({
          from: account
        });
        if (tx) {
          NotificationManager.success(t('Withdraw_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        setPending(false);
        return tx;
      } catch (e) {
        console.error('Withdraw had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleWithdraw, pending };
};

export const useWithdrawExpiredLocksCallback = account => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useStakingContract(requiredChainId);

  const handleWithdrawExpiredLocks = useCallback(async () => {
    try {
      setPending(true);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        contract.methods.withdrawExpiredLocks,
        [],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setPending(false);
        return false;
      }
      const tx = await contract.methods.withdrawExpiredLocks().send({
        from: account
      });
      setPending(false);
      if (tx) {
        NotificationManager.success(t('Withdraw_locks_successfully'));
      } else {
        NotificationManager.error(t('Tx_rejected'));
      }
      return tx;
    } catch (e) {
      console.error('WithdrawExpiredLocks had error :>> ', e);
      setPending(false);
      NotificationManager.error(t('Tx_rejected'));
      return false;
    }
  }, [account, contract]);

  return { handleWithdrawExpiredLocks, pending };
};

export const useGetRewardCallback = account => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useStakingContract(requiredChainId);

  const handleGetReward = useCallback(async () => {
    try {
      setPending(true);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        contract.methods.getReward,
        [],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setPending(false);
        return false;
      }
      const tx = await contract.methods.getReward().send({
        from: account
      });
      if (tx) {
        NotificationManager.success(t('Claim_reward_successfully'));
      } else {
        NotificationManager.error(t('Tx_rejected'));
      }
      setPending(false);
      return tx;
    } catch (e) {
      console.error('GetReward had error :>> ', e);
      setPending(false);
      NotificationManager.error(t('Tx_rejected'));
      return false;
    }
  }, [account, contract]);

  return { handleGetReward, pending };
};

export const useArsApproveCallback = account => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();
  const [pending, setPending] = useState(false);

  const contract = useERC20TokenContract(requiredChainId, 'ars');

  const handleApprove = useCallback(async (amount) => {
    try {
      setPending(true);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        contract.methods.approve,
        [
          constants.STAKING_ADDRESS[requiredChainId],
          amount.toString(10)
        ],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setPending(false);
        return false;
      }
      const tx = await contract.methods.approve(
        constants.STAKING_ADDRESS[requiredChainId],
        amount.toString(10)
      )
        .send({
          from: account
        });
      setPending(false);
      if (tx) {
        NotificationManager.success(t('Approved_successfully.'));
      } else {
        NotificationManager.error(t('Tx_rejected'));
      }
      return tx;
    } catch (e) {
      console.error('Approve had error :>> ', e);
      setPending(false);
      NotificationManager.error(t('Tx_rejected'));
      return false;
    }
  }, [account, contract]);

  return { handleApprove, pending };
};
