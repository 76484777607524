import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom"
import { NotificationManager } from 'react-notifications';
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { useTranslation } from "react-i18next";
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Modal from '../UI/Modal';

import { useActiveWeb3React } from 'hooks';
import connectors, { CHAIN_IDS, connectorLocalStorageKey, installExtentionLinks } from '../../connectors';
import useCopyClipboard from '../../hooks/useCopyClipboard';

import { restService } from 'utilities';
import { getAddChainParameters } from 'chain';
import { getEtherscanLink, shortenAddress } from 'utils';

import logo from '../../assets/img/wallet/logo.svg';
import metaMask from '../../assets/img/wallet/metaMask.svg';
import bitkeep from '../../assets/img/wallet/bitkeep.svg';
import walletConnect from '../../assets/img/wallet/walletConnect.svg';
import coinbaseWallet from '../../assets/img/wallet/coinbaseWallet.svg';
import { ReactComponent as Arrow } from '../../assets/img/common/arrow.svg';
import { ReactComponent as Copy } from '../../assets/img/common/copy.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/common/close.svg'
import ExternalLinkIcon from '../../assets/img/common/externalLink.svg';

const Styles = styled.div`
  .settings-close-container {
    position: absolute;
    right: 16px;
    top: 16px;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    img {
      height: 60px;
      width: 60px;
    }
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  .logout-button {
    width: 300px;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px solid #0EA5E9
    gap: 10px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }

  div.wallet-container {
    margin: 20px 0px 0px 0px;
    width: 100%;
    overflow: hidden;
    border-bottom: solid 1px #E5E5E5;
  }
  div.subText {
    color: #657786;
    a {
      // color: #00296D;
      text-decoration: underline;
    }
  }
  .custom-width-N-border-bottom {
    width: 45%;
    border-bottom: 1px solid #0EA5E9;
  }
  svg.link, svg.copy {
    path {
      fill: #0EA5E9;
    }
  }
  .arrow-fill {
    transform: rotate(180deg);
    path {
      stroke: #FFFFFF33;
      stroke-width: 3px;
    }
  }
`

function ConnectWalletModal({ open, onCloseModal }) {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const tempReferral = queryParameters.get("ref");

  const { account, requiredChainId, connector, provider, isActive } = useActiveWeb3React();
  const { t } = useTranslation();
  const [isCopied, setCopied] = useCopyClipboard();

  const [pendingError, setPendingError] = useState();

  useEffect(() => {
    if (open) {
      setPendingError();
    }
  }, [open]);

  const setReferralRelation = async () => {
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `/v1/referralRelation?address=${account}&referral=${tempReferral}`,
      method: 'GET',
    });

    if (apiRequest?.data?.status) {
      const msgParams = JSON.stringify({
        types: {
          EIP712Domain: [{ name: "name", type: "string" }],
          "AquariusLoan": [
            { name: "Description", type: "string" },
            { name: "Code", type: "string" },
          ],
        },
        primaryType: "AquariusLoan",
        domain: {
          name: "Aquarius Loan",
        },
        message: {
          Description: "Please sign to update your active referral code.",
          Code: tempReferral,
        },
      });

      provider
        .send("eth_signTypedData_v4", [account, msgParams])
        .then(async (signature) => {
          const apiResponse = await restService({
            chainId: requiredChainId,
            api: `/v1/referralRelation`,
            method: 'POST',
            params: {
              address: account,
              referral: tempReferral,
              signature: signature
            }
          });
          if (apiResponse?.status) {
            NotificationManager.success(t('referral_updated_successfully'));
          }
        });
    }
  }

  useEffect(() => {
    if (account && isActive) {
      const lendingRisks = localStorage.getItem('LendingRisk') || {};
      if (!lendingRisks[account]) {
        onCloseModal();
      }
      if (tempReferral && tempReferral.length === 8) {
        setReferralRelation();
      }
    }
  }, [account])

  const tryActivation = async (provider) => {
    setPendingError();
    const tempConnector = provider.provider;

    if (CHAIN_IDS[localStorage.getItem('network') || 0] === requiredChainId && account) {
      return
    }

    if (tempConnector instanceof WalletConnect) {
      await tempConnector.activate(CHAIN_IDS[localStorage.getItem('network') || 0])
        .then(() => {
          window.localStorage.setItem(connectorLocalStorageKey, provider.id);
        })
        .catch((error) => {
          console.log(error);
          if (error?.code === 4001) {
            setPendingError({ desc: t('User_rejected') });
          } else {
            setPendingError({ desc: error?.message });
          }
        })
    } else {
      if (provider.id === 2 && (!window.bitkeep || !window.bitkeep.ethereum)) { // Bitkeep
        setPendingError({
          desc: t(installExtentionLinks[provider.id].desc),
          linkDesc: t(installExtentionLinks[provider.id].linkDesc),
          link: t(installExtentionLinks[provider.id].link),
        });
        return;
      }
      await tempConnector.activate(getAddChainParameters(CHAIN_IDS[localStorage.getItem('network') || 0]))
        .then(() => {
          window.localStorage.setItem(connectorLocalStorageKey, provider.id);
        })
        .catch((error) => {
          console.log({ error });
          if (error?.name === "NoMetaMaskError" || error?.name === 'r' || !window.ethereum) {
            setPendingError({
              desc: t(installExtentionLinks[provider.id].desc),
              linkDesc: t(installExtentionLinks[provider.id].linkDesc),
              link: t(installExtentionLinks[provider.id].link),
            });
          } else if (error?.code === 4001) {
            setPendingError({ desc: t('User_rejected') });
          } else {
            setPendingError({ desc: error?.message });
          }
        })
    }
  };

  const logout = async () => {
    window.localStorage.removeItem(connectorLocalStorageKey);
    if (account) {
      if (connector?.deactivate) {
        void connector?.deactivate()
      } else {
        void connector?.resetState()
      }
    }
  };

  const title = useMemo(() => {
    if (account) {
      return (
        <Styles className="flex flex-row justify-center items-center">
          <div className="settings-close-container" onClick={onCloseModal}>
            <CloseIcon />
          </div>
          <div className="modal-header">
            <img src={logo} alt={'logo'} />
            <div>{t("Your_Wallet")}</div>
          </div>
        </Styles>
      )
    } else {
      return (
        <Styles className="flex flex-col justify-center items-center">
          <div className="settings-close-container" onClick={onCloseModal}>
            <CloseIcon />
          </div>
          <div className="modal-header">
            <img src={logo} alt={'logo'} />
            <div>{t("CONNECT_WALLET")}</div>
          </div>
        </Styles>
      )
    }
  }, [account, t]);

  const content =
    account ? (
      <Styles className="px-14">
        <div className="py-7">
          <div className="flex flex-col items-center justify-between">
            <div className="text-lg sm:text-xl font-medium text-center mb-14">
              {account}
            </div>
            <div className="flex flex-row items-center justify-between w-full mb-14 text-lg font-medium">
              <a
                href={getEtherscanLink(requiredChainId, account, 'address')}
                className="flex items-center justify-between custom-width-N-border-bottom"
                target={'_blank'}
                rel={'noreferrer noopener'}
              >
                {t("View_on_Explorer")} &nbsp;
                <SVG src={ExternalLinkIcon} className={'link'} />
              </a>
              <div
                onClick={setCopied.bind(this, account)}
                className="cursor-pointer flex items-center justify-between custom-width-N-border-bottom"
              >
                {isCopied ? t('Copied') : t("Copy_Address")} &nbsp;
                <Copy className='copy' />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-stretch lg:items-center justify-center">
              <button
                className="logout-button"
                onClick={logout}
              >
                {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      </Styles>
    ) : (
      <Styles className="px-14 pb-14">
        <div className="flex flex-col justify-between">
          {
            connectors.map((connector, index) => (
              <div
                key={index}
                className="cursor-pointer wallet-container"
                onClick={tryActivation.bind(this, connector)}
              >
                <div className="flex justify-between items-center flex-row py-2 w-full">
                  <div className='flex justify-between items-center'>
                    <img className="w-12 h-12" src={[metaMask, bitkeep, walletConnect, coinbaseWallet][index]} alt="arrow" />
                    <div className="text-semibold text-xl font-bold mt-4 mb-4 ml-6">{t(connector.name)}</div>
                  </div>
                  <Arrow className="w-4 arrow-fill" />
                </div>
              </div>
            ))
          }
        </div>
        {pendingError ? (
          <div className="text-center mt-10">
            <div className='text-red'>
              {pendingError.desc}
            </div>
            {
              pendingError.link &&
              <a href={pendingError.link} target='_blank' rel="noreferrer" className='text-blue3'>
                <b>{pendingError.linkDesc}</b>
              </a>
            }
          </div>
        ) : (
          <div className="text-base text-center mt-10 subText">
            {t("CONNECTING_ATOKEN")}
            <a
              href="https://www.aquarius.loan/terms"
              className="ml-2"
              target="_blank"
              rel="noreferrer"
            >
              {t("Terms_of_Service")}
            </a>
          </div>
        )}
      </Styles>
    );

  return (
    <Modal
      title={title}
      content={content}
      open={open}
      onCloseModal={onCloseModal}
      containerStyle={{
        maxWidth: 560,
      }}
    />
  );
}

export default ConnectWalletModal;
