import config from "./config"

const CORE = {
  name: 'CORE',
  symbol: 'CORE',
  decimals: 18,
}
const ETH = {
  name: 'ETH',
  symbol: 'ETH',
  decimals: 18,
}

const TCORE = {
  name: 'Test CORE',
  symbol: 'tCORE',
  decimals: 18,
}
const TETH = {
  name: 'Test ETH',
  symbol: 'tETH',
  decimals: 18,
}

export const CHAINS = {
  // Mainnet
  1116: {
    urls: [config.coreWeb3Provider],
    name: 'Core Blockchain',
    nativeCurrency: CORE,
    blockExplorerUrls: ['https://scan.coredao.org/'],
  },
  42161: {
    urls: [config.arbitrumWeb3Provider],
    name: 'Arbitrum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io/'],
  },

  // Testnet
  1115: {
    urls: [config.coreWeb3TestProvider],
    name: 'Core Blockchain Testnet',
    nativeCurrency: TCORE,
    blockExplorerUrls: ['https://scan.test.btcs.network/'],
  },
  421614: {
    urls: [config.arbitrumWeb3TestProvider],
    name: 'Arbitrum Sepolia',
    nativeCurrency: TETH,
    blockExplorerUrls: ['https://sepolia.arbiscan.io/'],
  }
}

function isExtendedChainInformation(chainInformation) {
  return !!chainInformation.nativeCurrency
}

export function getAddChainParameters(chainId) {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

export const URLS = Object.keys(CHAINS).reduce(
  (accumulator, chainId) => {
    const validURLs = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
