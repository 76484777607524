/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import {
    useTable,
    useSortBy,
    useFilters,
    useGlobalFilter,
    usePagination,
    useExpanded,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import search from '../../assets/icons/search.svg';
import { useWindowSize } from 'hooks/useWindowSize';

const Styles = styled.div`
  width: 100%;
  padding-bottom: 50px;
  .continer-card {
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  .address-container {
    display: flex;
    justify-content: start;
    align-items: center;
    .green-div {
        bottom: 0;
        right: 0;
    }
  }
  .vertical .address-container {
    > div {
        flex: 1;
        .green-div {
            bottom: 0;
            left: 25px;
        }
    }
  }
  div.borderLeft {
      border-left: 1px solid #717579;
  }
  div.row-conatiner-head {
    background: rgba(151, 151, 151, 0.15);
    border-radius: 6px 6px 0px 0px;
  }
  div.row-conatiner {
    border-bottom: 1px solid #EEEEEE;
  }
  div.custom-border-bottom {
    border-bottom: 1px solid #EEEEEE;
  }
  div.custom-border-bottom-5 {
    border-bottom: 5px solid #EEEEEE;
  }
  table {
    color: #000;
    border-collapse: separate;
    border-spacing: 0 1em;
    overflow: auto;
    width: 800px;
    @media (min-width: 850px) {
      width: 100%;
    }

    tr {
      border-bottom: 1px solid #2b2b2b;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      //padding: 1rem !important;
      font-size: 1.12rem;
      font-weight: 500;
    }

    td {
      //padding: 0.8rem 2rem 0.8rem 0;
    }

    th,
    td {
      margin: 0;
      text-align: center;

      :last-child {
        border-right: 0;
      }
    }
  }
  .custom-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  @media (max-width: 415px) {
    .custom-text-xl {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .vertical .address-container {
        display: flex;
        align-items: end;
        flex-direction: column;
    }
  }
`;

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
        <div className="relative">
            <input
                className="border border-solid border-gray bg-transparent
                           rounded-3xl mt-1 w-full focus:outline-none font-bold px-3 py-2 text-black"
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder="Search"
            />
            <img className="w-4 absolute top-4 right-6" src={search} alt="search" />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function LeaderboardTable({ columns, data, onRowClick }) {
    const { t } = useTranslation();
    const [isTableHorizontal, setIsTableHorizontal] = useState(true);
    const { width } = useWindowSize() || {}
    useEffect(() => {
        if (width < 1024) {
            setIsTableHorizontal(false);
        } else {
            setIsTableHorizontal(true);
        }
    }, [width]);


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        [],
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded,
        usePagination,
    );

    // Render the UI for your table
    return (
        <div className={`relative w-full ${page.length ? 'continer-card' : ''}`}>
            <div className="w-full mt-12 overflow-auto">
                <div {...getTableProps()} className="">
                    <div {...getTableBodyProps()}>

                        {(isTableHorizontal && page.length !== 0) && <div className="cursor-pointer flex p-5 row-conatiner-head text-xl text-midBlue">
                            <div className={`flex`} style={{ flex: 0.5 }}>
                                <div className={`flex h-full items-start justify-center flex-col`}>
                                    {t("Rank")}
                                </div>
                            </div>
                            <div className={`flex`} style={{ flex: 5 }}>
                                <div className={`flex h-full items-start justify-center flex-col`}>

                                </div>
                            </div>
                            <div className={`flex justify-end items-center`} style={{ flex: 2 }}>
                                <div className={`flex h-full items-start justify-center flex-col`}>
                                    {t("Vote")}
                                </div>
                            </div>
                            <div className={`flex justify-end items-center`} style={{ flex: 2 }}>
                                <div className={`flex h-full items-start justify-center flex-col`}>
                                    Vote Weight
                                </div>
                            </div>
                            <div className={`flex justify-end items-center`} style={{ flex: 2 }}>
                                <div className={`flex h-full items-start justify-center flex-col`}>
                                    Proposals Voted
                                </div>
                            </div>
                        </div>}

                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <Fragment key={i}>
                                    {isTableHorizontal ? (<div {...row.getRowProps()} onClick={onRowClick.bind(this, row, i)} className="cursor-pointer flex p-5 row-conatiner">
                                        {row.cells.map((cell) => {
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <div {...cell.getCellProps()} className={`flex justify-end items-center`} style={{ flex: cell.column.id === 'address' ? 5 : (cell.column.id !== 'rank' ? 2 : 0.5) }}>
                                                    <div className={`flex h-full items-end justify-center flex-col`}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>) : (
                                        row.cells.map((cell, index) => {
                                            return (
                                                <div {...row.getRowProps()}
                                                    onClick={onRowClick.bind(this, row)}
                                                    key={index}
                                                    className={`flex justify-between items-center vertical custom-text-xl ${(index === (row.cells.length - 1)) ? "custom-border-bottom-5" : "custom-border-bottom"}`}>
                                                    {cell.column.Header !== '' && <div className="p-5">
                                                        {(typeof (cell.column.Header) === "string" ? (cell.column.Header) : (cell.column.Header()))}
                                                    </div>}
                                                    <div className={`p-5 ${cell.column.id === 'address' ? 'w-full' : ''} `} colSpan={cell.column.Header !== '' ? (1) : (2)}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

function App({ columns, data, tdClassName, onRowClick }) {
    return (
        <Styles>
            <LeaderboardTable columns={columns} data={data} tdClassName={tdClassName} onRowClick={onRowClick} />
        </Styles>
    );
}

export default App;
