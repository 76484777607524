import { withRouter } from "react-router-dom";
import { compose } from "redux";
import commaNumber from "comma-number";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import BigNumber from "bignumber.js";

import arrowUp from '../../../assets/icons/arrowUp.svg';
import arrowDown from '../../../assets/icons/arrowDown.png';
import styled from "styled-components";
import { useActiveWeb3React } from "../../../hooks";
import { getEtherscanLink } from "utils";

const Styles = styled.div`
    padding: 1.25rem 2rem;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    backdrop-filter: blur(4px);
    border-radius: 4px;
    .custom-text-size {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    @media (max-width: 920px) {
        padding: 1.25rem 10px;
        .custom-text-size {
            font-size: 12px;
        }
    }

    svg.user-icon {
        path {
            fill: #000000;
        }
    }

    .view-more-btn {
        background: #3060F6;
        border-radius: 10px;
    }
`
const format = commaNumber.bindWith(',', '.');

const Transactions = ({ address, transactions }) => {
    const { t } = useTranslation();
    const { requiredChainId } = useActiveWeb3React();
    const [data, setData] = useState([]);
    const getDate = timestamp => {
        const startDate = moment(timestamp * 1000);
        const curDate = moment(new Date());
        const duration = moment.duration(curDate.diff(startDate));

        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.asHours()) - days * 24;
        return `${days} ${t("days")}${hours ? `, ${hours}${t("hrs")}` : ''} ${t("ago")}`;
    };

    useEffect(() => {
        const tempData = [];
        transactions.forEach(tx => {
            if (tx.type === 'vote') {
                tempData.push({
                    action: tx.support ? t("Received_Votes") : t("Lost_Votes"),
                    age: getDate(tx.blockTimestamp),
                    result: format(
                        new BigNumber(tx.votes)
                            .div(new BigNumber(10).pow(18))
                            .dp(4, 1)
                            .toString(10)
                    ),
                    isReceived: tx.support
                });
            } else {
                tempData.push({
                    action:
                        tx.to.toLowerCase() === address.toLowerCase()
                            ? `${t("Received")} ARS`
                            : `${t("Sent")} ARS`,
                    age: getDate(tx.blockTimestamp),
                    result: format(
                        new BigNumber(tx.amount)
                            .div(new BigNumber(10).pow(18))
                            .dp(4, 1)
                            .toString(10)
                    ),
                    isReceived: tx.to.toLowerCase() === address.toLowerCase()
                });
            }
        });
        setData([...tempData]);
    }, [transactions, address]);

    const handleLink = () => {
        window.open(
            getEtherscanLink(requiredChainId, address, 'address'),
            '_blank'
        );
    };

    return (
        <Styles className="mt-5 text-black">
            <div className="text-2xl text-midBlue font-semiBold">{t("Transactions")}</div>
            <div
                className="flex justify-between
                          border-b border-solid border-gray4 pt-8 pb-2.5 text-2xl"
            >
                <div className="">{t("Action")}</div>
                <div className="">{t("Age")}</div>
                <div className="">{t("Result")}</div>
            </div>
            {data.length === 0 && (
                <div className="flex items-center justify-center font-medium text-lg py-8">
                    {t("No_Transactions")}
                </div>
            )}
            {data &&
                data.map((item, index) => (
                    <div key={index} className="grid grid-cols-3 border-b border-solid border-gray4 pt-6 pb-4 custom-text-size">
                        <div className="justify-self-start text-center">
                            {item.action}
                        </div>
                        <div className="text-center">{item.age}</div>
                        <div className="justify-end items-center flex w-full">
                            <div className="text-right mr-1">{item.result}</div>
                            {item.isReceived ? (
                                <img src={arrowUp} alt="" />
                            ) : (
                                <img src={arrowDown} alt="" style={{ height: '20px', width: '9px' }} />
                            )}
                        </div>
                    </div>
                ))}
            {data.length > 0 && (
                <div className="flex justify-center mt-5">
                    <button
                        className="focus:outline-none text-white text-lg py-2 px-14 view-more-btn"
                        onClick={handleLink}
                    >
                        {t("View_More")}
                    </button>
                </div>
            )}
        </Styles>
    )
}

export default compose(withRouter)(Transactions);
