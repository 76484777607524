import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTokenContract, getVoteContract, methods } from "../../utilities/ContractService";
import Web3 from "web3";
import { useActiveWeb3React, useWeb3 } from "../../hooks";
import { compose } from "redux";
import { connectAccount } from "../../core";
import toast from "../UI/Toast";
import RouteMap from "../../routes/RouteMap";
import Loading from "../UI/Loading";
import Proposal from "./Proposal";
import { useHistory } from "react-router-dom";
import { buttons } from "polished";
import ProposalModal from "./Modals/ProposalModal";
import styled from "styled-components";

const ContainerStyles = styled.div`
    // filter: drop-shadow(0px 0px 19px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
    border-radius: 6px;
`

const Styles = styled.div`

`

const EmptyDataStyles = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

const Proposals = ({
    isLoadingProposal,
    settings,
    votingWeight,
    pageNumber,
    proposals,
    total,
    onChangePage,
    onCreateProposal
}) => {
    const { account: address, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { t } = useTranslation();
    const [proposalModal, setProposalModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [proposalThreshold, setProposalThreshold] = useState(0);
    const [maxOperation, setMaxOperation] = useState(0);
    const [delegateAddress, setDelegateAddress] = useState('');
    const history = useHistory();
    const voteContract = getVoteContract(requiredChainId);


    useEffect(() => {
        if (address) {
            methods.call(voteContract.methods.proposalThreshold, []).then(res => {
                setProposalThreshold(+Web3.utils.fromWei(res, 'ether'));
            });
            methods.call(voteContract.methods.proposalMaxOperations, []).then(res => {
                setMaxOperation(res);
            });
        }
    }, [address]);

    useEffect(() => {
        if (
            address &&
            (delegateAddress === '' ||
                delegateAddress === '0x0000000000000000000000000000000000000000')
        ) {
            const tokenContract = getTokenContract(web3, 'ars', requiredChainId);
            methods.call(tokenContract.methods.delegates, [address])
                .then(res => {
                    setDelegateAddress(res);
                })
                .catch(() => { });
        }
    }, [address, address, delegateAddress]);

    const handleShowProposalModal = () => {
        if (+votingWeight < +proposalThreshold) {
            toast.error({
                title: `${t("You_cannot_create_proposal")} ${proposalThreshold} ARS ${t("at_least_dot")}`
            });
            return;
        }
        setIsLoading(true);
        methods.call(voteContract.methods.latestProposalIds, [address])
            .then(pId => {
                if (pId !== '0') {
                    methods.call(voteContract.methods.state, [pId]).then(status => {
                        if (status === '0' || status === '1') {
                            toast.error({
                                title: t("proposal_progressing_message")
                            });
                        } else {
                            setProposalModal(true);
                        }
                        setIsLoading(false);
                    });
                } else {
                    setProposalModal(true);
                    setIsLoading(false);
                }
            });
    };

    return (
        <ContainerStyles className="py-5 flex flex-col items-stretch flex-1 active-proposals">
            <div className="flex items-center justify-between border-b border-solid border-gray4">
                <div className="text-midBlue text-2xl font-bold px-6 pb-8">{t("Active_Proposals")}</div>
                {address && (
                    <button
                        className="focus:outline-none bg-midBlue rounded text-white font-bold py-3 px-6 text-sm mr-6"
                        onClick={handleShowProposalModal}
                    >
                        {t("Create_Proposal")}
                    </button>
                )}
            </div>
            <Styles className="flex flex-col items-stretch flex-grow relative mt-4">
                {isLoadingProposal && (
                    <div className="flex items-center justify-center py-16 flex-grow">
                        <Loading size={'36px'} margin={'0'} />
                    </div>
                )}
                {!isLoadingProposal && (
                    <>
                        {proposals && proposals.length !== 0 ? (
                            proposals.map(item => {
                                return (
                                    <Proposal
                                        proposal={item}
                                        votingWeight={votingWeight}
                                        delegateAddress={delegateAddress}
                                        address={address}
                                        key={item.id}
                                    />
                                );
                            })
                        ) : (
                            <EmptyDataStyles>
                                <div className="text-midBlue text-2xl font-bold p-20 flex justify-center">
                                    <span className="text-center  
                                        text-border title-text">
                                        {t("No_active_proposals")}
                                    </span>
                                </div>
                            </EmptyDataStyles>
                        )}
                    </>
                )}
            </Styles>
            <div className="p-6 mt-9">
                <div className="flex justify-center mt-4">
                    <button
                        className="focus:outline-none bg-midBlue rounded text-white font-bold py-5 px-32 text-xl"
                        onClick={() => history.push(RouteMap.vote.allProposals)}
                    >
                        {t("All_Proposals")}
                    </button>
                </div>
            </div>

            <ProposalModal
                address={address}
                visible={proposalModal}
                maxOperation={maxOperation}
                onCreateProposal={onCreateProposal}
                onCancel={() => setProposalModal(false)}
            />
        </ContainerStyles>
    )

}


const mapStateToProps = ({ account }) => ({
    settings: account.setting
});

export default compose(connectAccount(mapStateToProps, undefined))(Proposals);

