import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import styled from "styled-components";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import {
  useTable,
  useSortBy,
  useExpanded,
} from 'react-table';

import filter from '../../assets/img/common/filter.svg';
import AlertHelper from "components/common/AlertHelper";

const Styles = styled.div`
  width: 100%;
  overflow: auto;
  border-radius: 4px;
  
  table {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    thead {
      border: 1px solid rgba(31, 41, 55, 1);
      border-radius: 4px 4px 0px 0px;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(156, 163, 175, 1);
      tr {
        white-space:nowrap;
        th {
          padding: 12px 24px;
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          :last-child {
            text-align: right;
            justify-content: flex-end;
            display: flex;
          }
          .header-container {
            display: flex;
            align-items: center;
            gap: 12px;
            .menu-bg {
              border-radius: 4px;
              border: 1px solid #FFFFFF1A;
              background: #000;
              margin-top: 20px;
              margin-left: -200px;
            }
          }
        }
      }
    }
    tbody {
      border-radius: 0px 0px 4px 4px;
      tr {
        border: 1px solid rgba(31, 41, 55, 1);
        border-radius: 0px;
        td {
          padding: 12px 20px;
        }
        :last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
    }
  }
`;
const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  .filter {
    color: #F9FAFB;
    background: rgb(16, 125, 239);
    width: 70px;
    border-radius: 8px;
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
  .cancel {
    color: rgb(248, 73, 96);
    border: 2px solid rgb(248, 73, 96);
    border-radius: 8px;
    width: 70px;
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
`;
const BlockFilterContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  padding: 20px 20px;
  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #F9FAFB;
  }
  input {
    margin: 5px 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000;
    padding: 0px 5px;
    ::placeholder {
      padding: 0px 5px !important;
      color: #9CA3AF !important;
    }
    @media (max-width: 992px) {
      font-size: 12px;
    }
  }
`;


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;


function HistoryTable({ columns, data, block, setBlock, fromAddress, setFromAddress, toAddress, setToaddress }) {
  const { t } = useTranslation();

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn: 'rank',
      autoResetSortBy: false,
    },
    useSortBy,
    useExpanded,
  );


  return (
    <table className="">
      <thead className="text-xl">
        {[headerGroups[0]].map((headerGroup, index) => (
          <tr key={index}>
            {headerGroup.headers.map((column, index) => {
              return (
                <th
                  key={column.Header}
                >
                  <div className="header-container">
                    {column.render('Header')}
                    {index === 1 && (
                      <span>
                        <AlertHelper
                          text={t("History_method_help")}
                        />
                      </span>
                    )}
                    {index === 2 && (
                      <span>
                        <Menu as="span" className="flex">
                          {({ open }) => (
                            <>
                              <Menu.Button>
                                <img src={filter} alt='' />
                              </Menu.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className={`absolute z-10 w-56 focus:outline-none menu-bg`}>
                                  <BlockFilterContainer>
                                    <div className="title">{t("From")}</div>
                                    <input id="first" placeholder={t("Block_Number")} defaultValue={block?.from || ''} type="number" />
                                    <div className="title">{t("To")}</div>
                                    <input id="second" placeholder={t("Block_Number")} defaultValue={block?.to || ''} type="number" />
                                    <ButtonContainer>
                                      <button className="filter" onClick={() => {
                                        const from = document.getElementById('first')?.value;
                                        const to = document.getElementById('second')?.value;
                                        setBlock({ from, to });
                                      }}>
                                        <Menu.Item>{() => (<div>{t("Filter")}</div>)}</Menu.Item>
                                      </button>
                                      <button className="cancel" onClick={() => {
                                        document.getElementById("first").value = "";
                                        document.getElementById("second").value = "";
                                      }}>
                                        {t("Clear")}
                                      </button>
                                    </ButtonContainer>
                                  </BlockFilterContainer>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </span>
                    )}
                    {(index === 4 || index === 5) && (
                      <span>
                        <Menu as="span" className="flex">
                          {({ open }) => (
                            <>
                              <Menu.Button>
                                <img src={filter} alt='' />
                              </Menu.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className={`absolute z-10 w-56 focus:outline-none menu-bg`}>
                                  <BlockFilterContainer>
                                    <div className="title">{t("Address")}</div>
                                    <input id="first" placeholder={t("Search_by_address")}
                                      defaultValue={index === 4 ? fromAddress : toAddress} />
                                    <ButtonContainer>
                                      <button className="filter" onClick={() => {
                                        const address = document.getElementById('first')?.value;
                                        if (index === 4) {
                                          setFromAddress(address);
                                        } else {
                                          setToaddress(address);
                                        }
                                      }}>
                                        <Menu.Item>{() => (<div>{t("Filter")}</div>)}</Menu.Item>
                                      </button>
                                      <button className="cancel" onClick={() => {
                                        document.getElementById("first").value = "";
                                      }}>
                                        {t("Clear")}
                                      </button>
                                    </ButtonContainer>
                                  </BlockFilterContainer>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </span>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="text-lg">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // eslint-disable-next-line react/jsx-key
            <Fragment key={i}>
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()} className="horizontal">
                      <div
                        className={
                          cell.column.Header === 'Rank'
                            ? ''
                            : cell.column.Header === 'Supply'
                              ? ''
                              : ''
                        }
                      >
                        {cell.render('Cell')}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  )


}

function Application({ columns, data, block, setBlock, fromAddress, setFromAddress, toAddress, setToaddress }) {
  return (
    <Styles>
      <HistoryTable
        columns={columns}
        data={data}
        block={block}
        setBlock={setBlock}
        fromAddress={fromAddress}
        setFromAddress={setFromAddress}
        toAddress={toAddress}
        setToaddress={setToaddress}
      />
    </Styles>
  );
}

const App = React.memo(Application)

export default App;
