import Modal from "../../UI/Modal";
import ReactMarkdown from 'react-markdown';
import { ReactComponent as CloseWhite } from '../../../assets/icons/closeCirclePrimary.svg';
import styled from "styled-components";

const ActionStyles = styled.div`
a.close-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 45px;
    top: 22px;
    outline: none;
    svg {
        height: 24px;
        width: auto;
        circle {
          stroke: #000000;
        }
        path {
            fill: #000000;
        }
    }
}
`

const ActionModal = ({
    proposal,
    visible,
    onClose,

}) => {

    const getTitle = descs => {
        const index = descs.findIndex(d => d !== '');
        if (index !== -1) {
            return descs[index];
        }
        return '';
    };

    const title = (
        <ActionStyles
            className="flex justify-center items-center mt-6 px-4 sm:px-12"
        >
            <div className="text-4xl text-center text-black font-semiBold border-b border-gray4 pb-5 px-8 w-full">
                {getTitle(proposal.description?.split('\n'))}
            </div>
            <a href="#" className="cursor-pointer close-container" onClick={onClose}>
                <CloseWhite fill={'#fff'} className="close-icon" />
            </a>
        </ActionStyles>
    );

    const content = (
        <div className="py-6 mx-4 sm:mx-12 overflow-auto text-gray3">
            <div className="text-24">Actions</div>
            {(proposal.actions || []).map((s, idx) => (

                <div className="flex mt-3" key={idx}>
                    <p
                        className="text-base"
                    >
                        {s.title}
                    </p>
                </div>
            ))}
        </div>
    )

    return (
        <Modal
            title={title}
            content={content}
            open={visible}
            onCloseModal={onClose}
            width="max-w-900"
        />
    )
}

ActionModal.defaultProps = {
    proposal: {},
    visible: false,
    onClose: () => { }
}

export default ActionModal;
