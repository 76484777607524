import { useEffect, useMemo, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import styled from "styled-components";

import Layout from "../../layouts/MainLayout/MainLayout";
import Loading from "../../components/UI/Loading";
import DataTable from '../../components/common/DataTable';
import APYSparkline from "../../components/Aquarius/APYSparkline";

import { accountActionCreators, connectAccount } from "../../core";
import { promisify } from "../../utilities";
import { useWindowSize } from "hooks/useWindowSize";
import AquaMobileItem from "./AquaMobileItem";

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  @media (max-width: 992px) {
    gap: 32px;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      @media (max-width: 992px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .divider {
      height: 1px;
      background: #FFFFFF33
    }
  }
  .daily-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .daily-sub-container {
      display: flex;
      align-items: center;
      gap: 8px;
      .title {
        color: #9CA3AF;
      }
      .amount {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
      }
    }
  }
  .loading-conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    background: #FFFFFF0D;
    border-radius: 0 0 4px 4px;
  }
  .rank {
    color: #FFFFFF;
  }
  .coin-market {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #9CA3AF;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .per-day {
    color: #9CA3AF;
  }
  .supply-apy {
    color: #34D399;
    span {
      color: #9CA3AF;
    }
  }
  .graph {
    display: flex;
    justify-content: flex-end;
    .chart-class {
      height: 40px;
      width: 200px;
      @media (max-width: 992px) {
        width: 100%;
      }
    }
    @media (max-width: 992px) {
      flex-direction: column;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
  }
`

const Aquarius = ({ getMarketHistory }) => {
  const dailyAquarius = useSelector((state) => state.account.setting.dailyAquarius);
  const markets = useSelector((state) => state.account.setting.markets);

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [expandMarkets, setExpandMarkets] = useState([]);

  const getGraphData = async (asset, type, limit) => {
    let tempData = [];
    const res = await promisify(getMarketHistory, { asset, type, limit });
    tempData = res?.data?.result
      .map(m => {
        return {
          blockNumber: m?.blockNumber,
          createdAt: m.createdAt,
          borrowArsApy: +new BigNumber(m.borrowArsApy || 0).dp(8, 1).toFixed(4),
        };
      })
      .reverse();

    return tempData
  };

  const getARSInfo = async () => {
    const tempMarkets = [];

    for (let i = 0; i < markets.length; i += 1) {
      tempMarkets.push({
        underlyingSymbol: markets[i].underlyingSymbol,
        perDay: new BigNumber(markets[i].supplierDailyArs)
          .plus(new BigNumber(markets[i].borrowerDailyArs))
          .div(new BigNumber(10).pow(18))
          .dp(2, 1)
          .toString(10),
        supplyAPY: new BigNumber(markets[i].supplyAdditionalApy).dp(2, 1).toString(10),
        borrowAPY: new BigNumber(markets[i].borrowAdditionalApy).dp(2, 1).toString(10),
        supplyPerDay: new BigNumber(markets[i].supplierDailyArs)
          .div(new BigNumber(10).pow(18))
          .dp(2, 1)
          .toString(10),
        borrowPerDay: new BigNumber(markets[i].borrowerDailyArs)
          .div(new BigNumber(10).pow(18))
          .dp(2, 1)
          .toString(10),
      });
    }

    for (let i = 0; i < markets.length; i += 1) {
      const borrowGraph = await getGraphData(
        markets[i].address,
        process.env.REACT_APP_GRAPH_TICKER || null,
        5
      )
      tempMarkets[i].borrowArsApy = borrowGraph;
    }
    setExpandMarkets(tempMarkets);
  };

  useEffect(() => {
    if (markets.length !== expandMarkets.length) {
      getARSInfo();
    }
  }, [markets]);

  const columns = useMemo(() => {
    return [{
      Header: t('Rank'),
      accessor: 'rank',
      sortable: true,
      Cell: (props) => {
        return (
          <div className="rank">
            #{props.row?.index + 1}
          </div>
        );
      },
    },
    {
      Header: t("Coin_Market"),
      accessor: "underlyingSymbol",
      Cell: ({ value }) => {
        return (
          <div className="coin-market">
            <img
              src={`/images/coins/${value.toLowerCase()}.png`}
              alt={value}
            />
            <div>{value}</div>
          </div>
        )
      }
    },
    {
      Header: t('Per_Day'),
      accessor: 'perDay',
      sortable: true,
      Cell: ({ value }) => {
        return (
          <div className="per-day">{value} ARS</div>
        )
      }
    },
    {
      Header: t('Supply_APY'),
      accessor: 'supplyAPY',
      sortable: true,
      Cell: ({ value, row }) => {
        return (
          <div className="supply-apy">
            <div>
              {value}%
            </div>
            <span>
              {row.original.supplyPerDay} ARS
            </span>
          </div >
        )
      }
    },
    {
      Header: t('Borrow_APY'),
      accessor: 'borrowAPY',
      sortable: true,
      Cell: ({ value, row }) => {
        return (
          <div className="supply-apy">
            <div style={{ color: '#A392FF' }}>
              {value}%
            </div>
            <span>
              {row.original.borrowPerDay} ARS
            </span>
          </div>
        )
      }
    },
    {
      Header: t('Borrow_ARS_APY'),
      accessor: 'borrowArsApy',
      Cell: ({ value }) => {
        return (
          <div className="graph">
            {
              value &&
              <APYSparkline
                color={value.length > 0 && value[0].borrowArsApy >= 0 ? "green" : "red"}
                data={value}
              />
            }
          </div>
        )
      }
    }]
  }, [t, expandMarkets])

  return (
    <Layout>
      <ContainerStyles>
        <div className="title-container">
          <div className="daily-container">
            <h1 className="title">{t("Market_Distribution")}</h1>
            <div className="only-web daily-sub-container">
              <div className="title">{t("Daily_Distribution")}:</div>
              <div className="amount">{(new BigNumber(dailyAquarius).div(new BigNumber(10).pow(18))).toFormat(2)} ARS</div>
            </div>
          </div>
          <div className="divider" />
        </div>
        <div className="only-mobile daily-sub-container">
          <div className="title">{t("Daily_Distribution")}:</div>
          <div className="amount">{(new BigNumber(dailyAquarius).div(new BigNumber(10).pow(18))).toFormat(2)} ARS</div>
        </div>
        <div>
          {
            width > 992 ? (
              <DataTable
                columns={columns}
                data={expandMarkets}
              />
            ) : (
              <AquaMobileItem data={expandMarkets} />
            )
          }
          {
            expandMarkets.length === 0 &&
            <div className="loading-conatiner">
              <Loading size={'36px'} margin={'0'} className={'text-white'} />
            </div>
          }
        </div>
      </ContainerStyles>
    </Layout >
  )
}

Aquarius.defaultProps = {
  settings: {}
}


const mapDispatchToProps = dispatch => {
  const { getMarketHistory } = accountActionCreators;

  return bindActionCreators(
    { getMarketHistory },
    dispatch
  );
};

export default compose(
  connectAccount(mapDispatchToProps)
)(Aquarius);
