import BigNumber from 'bignumber.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .market-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background: #FFFFFF0D;
    .title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      text-align: left;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .divider {
      border-bottom: 1px solid #FFFFFF1A;
    }
    .desc-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
        .desc-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #F9FAFB;
        }
        .last-value {
          color: #A78BFA;
        }
        .last-value1 {
          color: #34D399;
        }
      }
    }
  }
`;

function MarketMobileItem({
  assetList,
  history
}) {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {
        assetList.map((asset, index) => {
          return (
            <div key={`market-${index}`} className='market-item'
              onClick={() => history.push(`/market/${asset.underlyingSymbol}`)}
            >
              <div className='title-container'>
                <img src={`/images/coins/${asset.underlyingSymbol.toLowerCase()}.png`} alt={asset.underlyingSymbol} />
                <div>{asset.underlyingSymbol}</div>
              </div>
              <div className='divider' />
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Supply')}</div>
                  <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.totalSupplyUsd))}</div>
                  <div className='last-value'>
                    {getFormatStringFromBignumber(new BigNumber(asset.totalSupplyUsd).div(asset.tokenPrice))} {asset.underlyingSymbol}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Borrow')}</div>
                  <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.totalBorrowsUsd))}</div>
                  <div className='last-value'>
                    {getFormatStringFromBignumber(new BigNumber(asset.totalBorrowsUsd).div(asset.tokenPrice))} {asset.underlyingSymbol}
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Supply_APY')}</div>
                  <div className="desc-value">{getFormatStringFromBignumber(new BigNumber(asset.supplyApy))}%</div>
                  <div className='last-value1'>
                    {getFormatStringFromBignumber(new BigNumber(asset.supplyAdditionalApy))}%
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Borrow_APY')}</div>
                  <div className="desc-value">{getFormatStringFromBignumber(new BigNumber(asset.borrowApy))}%</div>
                  <div className='last-value1'>
                    {getFormatStringFromBignumber(new BigNumber(asset.borrowAdditionalApy))}%
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Reserves')}</div>
                  <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.reserveUSD).times(asset.tokenPrice))}</div>
                  <div className='last-value'>
                    {getFormatStringFromBignumber(new BigNumber(asset.reserveUSD))} {asset.underlyingSymbol}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Liquidity')}</div>
                  <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.liquidity))}</div>
                  <div className='last-value'>
                    {getFormatStringFromBignumber(new BigNumber(asset.liquidity).div(asset.tokenPrice))} {asset.underlyingSymbol}
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('CF')}</div>
                  <div className="desc-value">
                    ${getFormatStringFromBignumber(new BigNumber(asset.collateralFactor).div(new BigNumber(10).pow(18)).times(100), 0)}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Utilize')}</div>
                  <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.utilization))}</div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Price')}</div>
                  <div className="desc-value">
                    ${getFormatStringFromBignumber(new BigNumber(asset.tokenPrice))}
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </StyledContainer>
  )
}

export default MarketMobileItem;