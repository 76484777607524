import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "rc-pagination";
import styled from "styled-components";
import BigNumber from "bignumber.js";

import DataTable from "components/common/DataTable";
import Loading from "components/UI/Loading";

import { useWindowSize } from "hooks/useWindowSize";
import { useActiveWeb3React } from "hooks";
import { getEtherscanLink } from "utils";
import { restService } from "utilities";

import noData from 'assets/img/common/no_history.svg';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  .table-container {
    .tx-container {
      display: flex;
      justify-content: flex-start;
      .tx-hash {
          color: rgb(16, 125, 239);
          font-size: 16px;
          text-align: left;
          @media (max-width: 992px) {
            font-size: 12px;
          }
      }
      @media (max-width: 992px) {
        justify-content: flex-end;
      }
    }
    .value-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 3px;
        }
        @media (max-width: 992px) {
          justify-content: flex-end;
          font-size: 12px;
        }
    }
  }
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NoAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background: #FFFFFF0D;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: rgb(157, 159, 167);
    text-align: center;
    @media (max-width: 992px) {
      font-size: 16px;
      max-width: 70%;
    }
  }
  img {
    @media (max-width: 992px) {
      height: 96px;
    }
  }
`;

const ReferralHistory = () => {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();

  const [markets, setMarkets] = useState([]);

  const [isLoading, setIsLoding] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrntPage] = useState(1);

  const getARSInfo = async ({
    limit = 10,
    offset = 0,
    from = '',
    bfrom = '',
    bto = ''
  }) => {
    setMarkets([]);
    if (!account) return;
    setIsLoding(true);
    const apiRequest = await restService({
      chainId: requiredChainId,
      // eslint-disable-next-line max-len
      api: `/v1/referral/rewardHistory?limit=${limit}&offset=${offset}&address=${account}&fromAddress=${from}&fromBlock=${bfrom}&toBlock=${bto}`,
      method: 'GET',
    });

    if (apiRequest?.data?.data) {
      setMarkets(apiRequest.data.data.rows || []);
      setTotalCount(apiRequest.data.data.count || 0);
    }
    setIsLoding(false);
  };

  useEffect(() => {
    getARSInfo({
      offset: (currentPage - 1) * 10,
    });
  }, [account, requiredChainId]);

  const updatePage = (value) => {
    setCurrntPage(value);
    getARSInfo({
      offset: (value - 1) * 10,
    });
  }

  const columns = useMemo(() => {
    if (width > 992) {
      return [
        {
          Header: t("Date"),
          accessor: 'blockTimestamp',
          Cell: ({ value }) => {
            let delta = Date.now() / 1000 - value;
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;
            var hours = Math.floor(delta / 3600) % 24;
            if (days > 0) {
              return (
                <div className="tx-container">
                  <div className="age">
                    {`${days} ${days > 1 ? t("days") : t("day")} ${hours} ${hours > 1 ? t("hrs") : t("hr")} ${t("ago")}`}
                  </div>
                </div>
              )
            } else {
              delta -= hours * 3600;
              var minutes = Math.floor(delta / 60) % 60;
              delta -= minutes * 60;
              var seconds = parseInt(delta % 60);
              if (hours > 0) {
                return (
                  <div className="tx-container">
                    <div className="age">
                      {`${hours} ${hours > 1 ? t("hrs") : t("hr")} ${minutes} ${minutes > 1 ? t("mins") : t("min")} ${t("ago")}`}
                    </div>
                  </div>
                )
              }
              return (
                <div className="tx-container">
                  <div className="age">
                    {`${minutes} ${minutes > 1 ? t("mins") : t("min")} ${seconds} ${seconds > 1 ? t("secs") : t("sec")} ${t("ago")}`}
                  </div>
                </div>
              )
            }
          }
        },
        {
          Header: t("From"),
          accessor: 'fromAddress',
          Cell: ({ value }) => {
            return (
              <div className="tx-container">
                <a className="tx-hash" href={getEtherscanLink(requiredChainId, value)} target="_blank" rel="noreferrer">
                  {value?.substring(0, 4)}...{value?.substring(38)}
                </a>
              </div>
            )
          }
        },
        {
          Header: t("Tier"),
          accessor: 'asReferrer',
          Cell: ({ value }) => {
            return (
              <div className="tx-container">
                <div>{value ? `${t('Tier1')} (10%)` : `${t('Tier2')} (5%)`}</div>
              </div>
            )
          }
        },
        {
          Header: t("Amount"),
          accessor: 'amount',
          sortable: true,
          Cell: ({ row }) => {
            const rowData = row.original;
            const amount = new BigNumber(rowData.amount).dividedBy(new BigNumber(1e18))
            return (
              <div className="value-container">
                <img src={`/images/coins/ars.png`} alt={rowData.symbol} />
                <div>{amount.toFormat(3)} {rowData.symbol}</div>
              </div>
            )
          }
        },
        {
          Header: t("Txn_Hash"),
          accessor: 'txHash',
          Cell: ({ value }) => {
            return (
              <div className="tx-container" style={{ justifyContent: 'flex-end' }}>
                <a className="tx-hash" href={getEtherscanLink(requiredChainId, value, 'transaction')} target="_blank" rel="noreferrer">
                  {value?.substring(0, 6)}...{value?.substring(60)}
                </a>
              </div>
            );
          },
        }]
    }
    return [
      {
        Header: t("From"),
        accessor: 'fromAddress',
        Cell: ({ value }) => {
          return (
            <div className="tx-container">
              <a className="tx-hash" href={getEtherscanLink(requiredChainId, value)} target="_blank" rel="noreferrer">
                {value?.substring(0, 4)}...{value?.substring(38)}
              </a>
            </div>
          )
        }
      },
      {
        Header: t("Amount"),
        accessor: 'amount',
        Cell: ({ row }) => {
          const rowData = row.original;
          const amount = new BigNumber(rowData.amount).dividedBy(new BigNumber(1e18))
          return (
            <div className="value-container">
              <img src={`/images/coins/ars.png`} alt={rowData.symbol} />
              <div>{amount.toFormat(3)} {rowData.symbol}</div>
            </div>
          )
        }
      },
      {
        Header: t("Txn_Hash"),
        accessor: 'txHash',
        Cell: ({ value }) => {
          return (
            <div className="tx-container" style={{ justifyContent: 'flex-end' }}>
              <a className="tx-hash" href={getEtherscanLink(requiredChainId, value, 'transaction')} target="_blank" rel="noreferrer">
                {value?.substring(0, 6)}...{value?.substring(60)}
              </a>
            </div>
          );
        },
      }]
  }, [width, t, requiredChainId])


  return (
    <ContainerStyles>
      <div className="table-container">
        <DataTable
          columns={columns}
          data={markets}
        />
        {
          (isLoading) ? (
            <NoAccountContainer>
              <Loading size={'36px'} margin={'0'} />
            </NoAccountContainer>
          ) : (!account) ? (
            <NoAccountContainer>
              <img src={noData} alt='' />
              <div className="title">{t("Connect_Wallet_transaction")}</div>
            </NoAccountContainer>
          ) : (!markets || markets.length === 0) ? (
            <NoAccountContainer>
              <img src={noData} alt='' />
            </NoAccountContainer>
          ) : null
        }
      </div>
      <div className="navigation-container">
        <Pagination
          current={currentPage}
          defaultCurrent={1}
          defaultPageSize={10}
          pageSize={10}
          total={totalCount}
          onChange={updatePage}
          prevIcon={() => <div>{'<'}</div>}
          nextIcon={() => <div>{'>'}</div>}
          jumpPrevIcon={() => <div>{'<<'}</div>}
          jumpNextIcon={() => <div>{'>>'}</div>}
        />
      </div>
    </ContainerStyles>
  )
}

export default ReferralHistory;
