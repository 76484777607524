import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import styled from "styled-components";
import { useSelector } from "react-redux";

const StyledContainer = styled.div`
	border-radius: 20px;
	@media only screen and (max-width: 992px) {
		overflow: hidden;
	}
	.title {
		padding: 12px 24px 12px 24px;
		border-radius: 4px 4px 0px 0px;
		gap: 32px;
		background: #FFFFFF1A;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		letter-spacing: -0.025em;
		text-align: center;
	}
	.content {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 32px;
		border-radius: 0px 0px 4px 4px;
		background: #FFFFFF0D;
		padding: 12px 20px 12px 20px;
		border-bottom: 1px solid #1F2937;
		.divider {
			width: 1px;
			height: 24px;
			background: #374151;
		}
	}
	.sub-content {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
	}
`

const MarketSummary = ({ marketInfo, currentAsset }) => {
	const decimals = useSelector((state) => state.account.setting.decimals);

	const { t } = useTranslation();

	return (
		<StyledContainer>
			<div className="title">
				{t("Markets_Rewards")}
			</div>
			<div className="content">
				<div>{t("PRICE")}</div>
				<div >
					${new BigNumber(marketInfo.underlyingPrice || 0)
						.div(
							new BigNumber(10).pow(
								18 + 18 - parseInt(decimals[currentAsset]?.token || 18, 10)
							)
						)
						.toFormat(4)
					}
				</div>
			</div>
			<div className="content">
				<div>{t("Market_Liquidity")}</div>
				<div >
					{new BigNumber(marketInfo.cash || 0)
						.div(new BigNumber(10).pow(decimals[currentAsset]?.token || 18))
						.toFormat(4)
					}
					{" "}
					{marketInfo.underlyingSymbol || ''}
				</div>
			</div>
			<div className="content mobile-content">
				<div className="sub-content">
					<div>{t("#_of_Suppliers")}</div>
					<div>
						{new BigNumber(marketInfo.supplierCount).toFormat(0)}
					</div>
				</div>
				<div className="divider" />
				<div className="sub-content">
					<div>{t("#_of_Borrowers")}</div>
					<div>
						{new BigNumber(marketInfo.borrowerCount).toFormat(0)}
					</div>
				</div>
			</div>
			<div className="content">
				<div>{marketInfo.underlyingSymbol} {t("Borrow_Cap")}</div>
				<div >
					{
						marketInfo.borrowCaps == 0 ? t('No_Limit') :
							'$' + new BigNumber(marketInfo.borrowCaps).times(marketInfo.tokenPrice).toFormat(2)
					}
				</div>
			</div>
			<div className="content">
				<div>{t("Interest_Paid/Day")}</div>
				<div>
					$
					{
						new BigNumber(marketInfo.supplierDailyArs)
							.plus(new BigNumber(marketInfo.borrowerDailyArs))
							.div(new BigNumber(10).pow(18))
							.multipliedBy(marketInfo.tokenPrice)
							.toFormat(2)
					}
				</div>
			</div>
			<div className="content">
				<div>{t("Reserves")}</div>
				<div>
					{`${new BigNumber(marketInfo.totalReserves || 0)
						.div(new BigNumber(10).pow(decimals[currentAsset]?.token || 18))
						.toFormat(4)} ${marketInfo.underlyingSymbol || ''}`}
				</div>
			</div>
			<div className="content">
				<div className="sub-content">
					<div>{t("Reserve_Factor")}</div>
					<div>
						{`${new BigNumber(marketInfo.reserveFactor || 0)
							.div(new BigNumber(10).pow(18))
							.multipliedBy(100)
							.toFormat(0)}%`}
					</div>
				</div>
				<div className="divider" />
				<div className="sub-content">
					<div>{t("Collateral_Factor")}</div>
					<div>
						{`${new BigNumber(marketInfo.collateralFactor || 0)
							.div(new BigNumber(10).pow(18))
							.times(100)
							.toFormat(0)}%`}
					</div>
				</div>
			</div>
			<div className="content">
				<div>a{marketInfo.underlyingSymbol} {t("Minted")}</div>
				<div>
					{new BigNumber(marketInfo.totalSupply2).toFormat(4)}
				</div>
			</div>
			<div className="content">
				<div>{t("Exchange_Rate")}</div>
				<div>
					{`1 ${marketInfo.underlyingSymbol || ''} = ${Number(
						new BigNumber(1)
							.div(
								new BigNumber(marketInfo.exchangeRate).div(
									new BigNumber(10).pow(
										18 +
										+parseInt(
											decimals[currentAsset || 'usdt']?.token || 18,
											10
										) -
										+parseInt(
											decimals[currentAsset || 'usdt']?.atoken || 18,
											10
										)
									)
								)
							)
							.toString(10)
					).toFixed(6)} ${marketInfo.symbol || ''}`}
				</div>
			</div>
		</StyledContainer>
	)
}

export default MarketSummary;
