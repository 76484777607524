import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import commaNumber from "comma-number";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import RouteMap from "../../../routes/RouteMap";
import Web3 from "web3";
import styled from "styled-components";

const Styles = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 4px;
`

const format = commaNumber.bindWith(',', '.');

const VoteCard = ({
    history,
    label,
    forNumber,
    againstNumber,
    type,
    addressNumber,
    emptyNumber,
    list,
    onViewAll
}) => {
    const { t } = useTranslation();
    const [isViewAll, setIsViewAll] = useState(true);
    const [forPercent, setForPercent] = useState(0);
    const [againstPercent, setAgainstPercent] = useState(0);

    useEffect(() => {
        const total = new BigNumber(forNumber).plus(new BigNumber(againstNumber));
        setForPercent(
            new BigNumber(forNumber * 100).div(total).isNaN()
                ? '0'
                : new BigNumber(forNumber * 100).div(total).toString(10)
        );
        setAgainstPercent(
            new BigNumber(againstNumber * 100).div(total).isNaN()
                ? '0'
                : new BigNumber(againstNumber * 100).div(total).toString(10)
        );
    }, [forNumber, againstNumber]);

    const handleAddLink = v => {
        history.push(RouteMap.vote.proposerOverview?.replace(':address', v));
    };

    const emptyList = [];
    if (emptyNumber > 0) {
        for (let i = 0; i < emptyNumber; i += 1) {
            emptyList.push(i);
        }
    }

    return (
        <Styles className="pb-8">
            <div className="flex flex-col items-center justify-center pt-5 pb-3 px-6 text-black">
                <div className="text-2xl font-semiBold text-gray3">{label}</div>
                <div className="text-6xl text-lightBlue mt-6">
                    {format(
                        new BigNumber(
                            Web3.utils.fromWei(
                                type === 'agree' ? forNumber : againstNumber,
                                'ether'
                            )
                        )
                            .dp(2, 1)
                            .toString(10)
                    )}
                </div>
            </div>
            <div className="flex justify-between border-b-2 border-solid border-gray4 pb-1.5 px-1 mx-5 mt-6 text-gray7">
                <div className="text-2xl font-bold">{addressNumber} {t("addresses")}</div>
                <div className="text-2xl font-bold">{t("Vote")}</div>
            </div>
            {list.map((l, index) => (
                <div
                    key={index}
                    className="flex justify-between cursor-pointer
                         border-b border-solid border-gray4 py-1.5 px-1 mx-5 text-gray5"
                    onClick={() => handleAddLink(l.label)}
                >
                    <div className="text-2xl font-bold">
                        {l.label
                            ? `${l.label.substr(0, 5)}...${l.label.substr(-4, 4)}`
                            : ''}
                    </div>
                    <div className="text-2xl font-bold">
                        {format(
                            new BigNumber(Web3.utils.fromWei(l.value, 'ether'))
                                .dp(8, 1)
                                .toString(10)
                        )}
                    </div>
                </div>
            ))}

            {emptyList.map(v => (
                <div
                    key={v}
                    className="flex justify-between border-b-2 border-solid border-gray4 py-1.5 px-1 mx-5 text-gray5"
                >
                    <div className="text-2xl font-bold">-</div>
                    <div className="text-2xl font-bold">-</div>
                </div>
            ))}

            {(
                <button
                    className={`${!(isViewAll && addressNumber > 3) ? '' : 'text-midBlue'} w-full text-2xl font-bold text-center mt-6 cursor-pointer`}
                    onClick={() => {
                        setIsViewAll(false);
                        onViewAll();
                    }}
                    disabled={!(isViewAll && addressNumber > 3)}
                >
                    {t("View_All")}
                </button>
            )}
        </Styles>
    )
}


VoteCard.defaultProps = {
    history: {},
    label: '',
    forNumber: '0',
    againstNumber: '0',
    type: 'agree',
    addressNumber: 0,
    emptyNumber: 0,
    list: []
};

export default compose(withRouter)(VoteCard);
