import moment from "moment";
import Markdown from 'react-markdown';
import { useTranslation } from "react-i18next";

const ProposalInfo = ({ proposalInfo }) => {
    const { t } = useTranslation();

    const getDescription = p => {
        if (p === 'Create Proposal') {
            return t('Create_Proposal');
        }
        return p;
    };

    const getStatus = p => {
        if (p.state === 'Executed') {
            return t('Passed');
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return t('Failed');
        }
        if (p.state === 'Expired') {
            return t("Expired")
        }
        return p.state;
    };


    const getStatusColor = p => {
        if (p.state === 'Executed') {
            return 'lightGreen';
        }
        if (p.state === 'Active') {
            return 'primaryLight';
        }
        if (p.state === 'Defeated') {
            return 'red';
        }
        return 'lightGray';
    };

    const getRemainTime = item => {
        if (item.state === 'Active') {
            const diffBlock = item.endBlock - item.blockNumber;
            const duration = moment.duration(
                diffBlock < 0 ? 0 : diffBlock * 3,
                'seconds'
            );
            const days = Math.floor(duration.asDays());
            const hours = Math.floor(duration.asHours()) - days * 24;
            const minutes =
                Math.floor(duration.asMinutes()) - days * 24 * 60 - hours * 60;

            return `${
                days > 0 ? `${days} ${days > 1 ? 'days' : 'day'},` : ''
            } ${hours} ${hours > 1 ? 'hrs' : 'hr'} ${
                days === 0 ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ''
            } left`;
        }
        if (item.state === 'Pending') {
            return `${moment(item.createdTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Active') {
            return `${moment(item.startTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Canceled' || item.state === 'Defeated') {
            return `${moment(item.endTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Queued') {
            return `${moment(item.queuedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        if (item.state === 'Expired' || item.state === 'Executed') {
            return `${moment(item.executedTimestamp * 1000).format('MMMM DD, YYYY')}`;
        }
        return `${moment(item.updatedAt).format('MMMM DD, YYYY')}`;
    };


    return (
        <div className="text-black">
            <div className="text-4xl text-midBlue font-bold text-center">
                <Markdown>{getDescription(proposalInfo.description?.split('\n')[0])}</Markdown>
            </div>
            <div className="flex items-center mt-8">
                <div className="text-gray3 text-2xl">
                    {`${proposalInfo.id} ${getStatus(proposalInfo)} ${moment(
                        proposalInfo.updatedAt
                    ).format('MMMM DD, YYYY')}`}
                </div>

                <div className={`rounded 
                     text-${getStatusColor(proposalInfo)} text-2xl ml-8`}>
                    •&nbsp;&nbsp;{getStatus(proposalInfo)}
                </div>
                <div className="text-gray3 text-2xl ml-3.5">{getRemainTime(proposalInfo)}</div>
            </div>
        </div>
    )
}

ProposalInfo.defaultProps = {
    proposalInfo: {}
};
export default ProposalInfo;
