import { accountActionCreators, connectAccount } from '../../../core';
import { bindActionCreators, compose } from 'redux';
import MarkdownIt from 'markdown-it';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Form, { Field, useForm } from 'rc-field-form';
import { encodeParameters, getArgs } from '../../../utilities/common';
import Modal from '../../UI/Modal';
import edit from '../../../assets/icons/edit.svg';
import closeCirclePrimary from '../../../assets/icons/closeCirclePrimary.svg';

import crossPrimary from '../../../assets/icons/crossPrimary.svg';
import { ReactComponent as CloseWhite } from '../../../assets/icons/closeCirclePrimary.svg';
import Loading from '../../UI/Loading';
import { getVoteContract, methods } from '../../../utilities/ContractService';
import { useActiveWeb3React, useWeb3 } from "../../../hooks";
import MdEditor from 'react-markdown-editor-lite';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { getNativeToken } from 'utils';

const Styles = styled.div`
  input.input-decoration {
    background: #EEEEEE;
    border-radius: 6px;
    backdrop-filter: blur(4px);
  }
  input.input-decoration-white {
    background: transparent;
    border: 1px solid #DADADA;
    border-radius: 6px;
    backdrop-filter: blur(4px);
  }
  input.input-decoration::placeholder {
    color: #00000050;
  }
  input.input-decoration-white::placeholder {
    color: #97979750;
  }
  a.close-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;
      svg {
          height: 24px;
          width: auto;
          circle {
            stroke: #000000;
          }
          path {
              fill: #000000;
          }
      }
  }
`
const ProposalStyles = styled.div`
    a.close-container {
      position: absolute;
      right: 1rem;
      top: 1rem;
      outline: none;
      background: transparent;
      svg {
        height: 26px;
          width: auto;
          circle {
            stroke: #000000;
            fill: transparent;
          }
          path {
              fill: #000000;
          }
      }
    }
`

const mdParser = new MarkdownIt();

const ProposalModal = ({ address, visible, maxOperation, onCancel, getProposals, onCreateProposal, ...props }) => {
  const web3 = useWeb3();
  const { requiredChainId } = useActiveWeb3React();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [activePanelKey, setActivePanelKey] = useState(['0']);
  const [editingActions, setEditingActions] = useState([]);
  const voteContract = getVoteContract(requiredChainId);

  const [formData, setFormData] = useState([
    {
      targetAddress: '',
      value: '',
      signature: '',
      callData: [],
    },
  ]);

  const [form] = useForm();

  useEffect(() => {
    // if (!visible) {
    //   if (form.__INTERNAL__?.name) {
    //     form.resetFields();
    //   }
    //   setIsLoading(false);
    //   setErrorMsg('');
    //   setDescription('');
    //   setFormData([
    //     {
    //       targetAddress: '',
    //       value: '',
    //       signature: '',
    //       callData: [],
    //     },
    //   ]);
    // }
  }, [visible, form]);

  const handleSubmit = async (formValues) => {
    const targetAddresses = [];
    const values = [];
    const signatures = [];
    const callDatas = [];
    if (description.trim().length === 0) {
      setErrorMsg('Description is required');
    } else {
      setErrorMsg('');
    }

    const proposalDescription = `${formValues?.title}\n${description}`;
    try {
      for (let i = 0; i < formData.length; i += 1) {
        const callDataValues = [];
        let callDataTypes = [];
        targetAddresses.push(formValues[`targetAddress${i}`]);
        let formVal = formValues[`val${i}`] ? formValues[`val${i}`] : 0;
        values.push(formVal);
        signatures.push(formValues[`signature${i}`]);
        callDataTypes = getArgs(formValues[`signature${i}`]);
        for (let j = 0; j < formData[i].callData.length; j += 1) {
          if (callDataTypes[j].toLowerCase() === 'bool') {
            callDataValues.push(formValues[`calldata_${i}_${j}`].toLowerCase() === 'true');
          } else if (callDataTypes[j].includes('[]')) {
            callDataValues.push(formValues[`calldata_${i}_${j}`].slice(1, -1).split(','));
          } else {
            callDataValues.push(formValues[`calldata_${i}_${j}`]);
          }
        }
        callDatas.push(encodeParameters(callDataTypes, callDataValues));
      }
    } catch (error) {
      setErrorMsg('Proposal parameters are invalid!');
      return;
    }
    setIsLoading(true);
    const isCheckGas = await methods.checkGasFee(
      web3,
      requiredChainId,
      voteContract.methods.propose,
      [targetAddresses, values, signatures, callDatas, proposalDescription],
      address,
    );
    if (!isCheckGas) {
      NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
      setIsLoading(false);
      return
    }
    methods.send(
      voteContract.methods.propose,
      [targetAddresses, values, signatures, callDatas, proposalDescription],
      address,
    )
      .then(() => {
        setErrorMsg('');
        setIsLoading(false);
        onCreateProposal();
        onCancel();
      })
      .catch(() => {
        setErrorMsg('Creating proposal is failed!');
        setIsLoading(false);
      });
  };

  const handleEditorChange = ({ text }) => {
    setDescription(text);
  };

  const handleAdd = (type, index) => {
    if (form.__INTERNAL__?.name) {
      form.resetFields();
    }
    if (type === 'next') {
      formData.splice(index + 1, 0, {
        targetAddress: '',
        value: '',
        signature: '',
        callData: [],
      });
    } else {
      formData.splice(index, 0, {
        targetAddress: '',
        value: '',
        signature: '',
        callData: [],
      });
    }
    setFormData([...JSON.parse(JSON.stringify(formData))]);
    setActivePanelKey(type === 'next' ? index + 1 : index);
  };

  const handleRemove = (idx) => {
    setFormData([
      ...formData.filter((_f, index) => index < idx),
      ...formData.filter((_f, index) => index > idx),
    ]);
  };

  const handleParseFunc = (funcStr, idx) => {
    if ((funcStr || '').trim().replace(/^s+|s+$/g, '')) {
      const parsedStr = getArgs(funcStr);
      formData[idx].signature = funcStr;
      formData[idx].callData = [...parsedStr];
      setFormData([...formData]);
    }
  };

  const handleKeyUp = (type, idx, subIdx, v) => {
    if (type === 'targetAddress') {
      formData[idx].targetAddress = v;
    } else if (type === 'value') {
      formData[idx].value = v;
    } else if (type === 'calldata') {
      formData[idx].callData[subIdx] = v;
    }
    setFormData([...formData]);
  };

  const title = (
    <ProposalStyles
      className="flex justify-center items-center mt-6"
    >
      <div className="text-2xl text-center text-midBlue font-semiBold border-b border-gray3 pb-5 px-8 w-full">
        {t("Create_Proposal")}
      </div>
      <a href="#" className="cursor-pointer close-container" onClick={onCancel}>
        <CloseWhite className="close-icon" />
      </a>
    </ProposalStyles>
  );

  const content = (
    <Form
      name={"form1"}
      form={form}
      onFinishFailed={(errorInfo) => {
        setErrorMsg(errorInfo.errorFields[0].errors[0]);
      }}
      onFinish={handleSubmit}
      className={"mt-8"}
    >
      <Styles className="overflow-auto">
        <div className="">
          <div className="w-full px-14">
            <div className="text-2xl font-bold text-midBlue pb-4">
              {t('Proposal_Description')}
            </div>
            <div className="mt-6">
              <div className="text-lg text-midBlue font-bold">
                {t('Title')}
              </div>
              <Field name={'title'}>
                <input
                  type="text"
                  className="w-full focus:outline-none rounded-md font-bold py-3 sm:pl-4 
                    pr-4 text-black mt-2 mb-4 input-decoration"
                  placeholder={t('Add_a_New_aToken')}
                />
              </Field>
            </div>
            <div className="mt-4">
              <div className="text-lg text-midBlue font-bold">{t("Overview")}</div>
              <MdEditor
                style={{
                  background: '#EEEEEE',
                  // border: '1px solid #FF9800',
                  borderRadius: '6px',
                  fontWeight: '700',
                  overflow: 'hidden',
                  color: 'black',
                  height: 280,
                  marginTop: '0.5rem',
                }}
                value={description}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleEditorChange}
                placeholder={t('Thorough_description_of_all_changes')}
              />
            </div>
          </div>

          <div className="w-full mt-10 px-14">
            <div className="text-xl text-midBlue font-bold border-b border-solid border-lightGray pb-2 mt-8 md:mt-0">
              {t('Actions')}
            </div>
            <div className="mt-8 flex flex-col">
              {formData.map((f, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="flex items-center justify-between rounded bg-gray4 py-4 px-5 mt-3">
                      <div className="text-18 text-gray3">{t('Action')} {index + 1}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          if (isLoading) {
                            return
                          }
                          if (editingActions.includes(index)) {
                            const newEditingActions = editingActions.filter((a) => a !== index);
                            setEditingActions(newEditingActions);
                            if (index > 0) {
                              handleRemove(index);
                            }
                          } else {
                            setEditingActions((prevState) => [...prevState, index]);
                          }
                        }}
                      >
                        {!editingActions.includes(index) || index === 0 ? (
                          <img src={edit} alt="" />
                        ) : index > 0 ? (
                          <img src={closeCirclePrimary} alt="" />
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`px-5 py-6 border border-midBlue rounded-md mt-3 
                        ${editingActions.includes(index) ? 'block' : 'hidden'}
                        `}
                    >
                      <div className="relative border-b border-solid border-white flex justify-start items-center">
                        <span className={"text-midBlue text-lg font-bold pb-3"}>
                          {t('Edit_Action')}
                        </span>
                        <a href="#" className="cursor-pointer close-container" onClick={() => {
                          if (isLoading) {
                            return
                          }
                          const newEditingActions = editingActions.filter((a) => a !== index);
                          setEditingActions(newEditingActions);
                          if (index > 0) {
                            handleRemove(index);
                          }
                        }}>
                          <CloseWhite className="close-icon" />
                        </a>
                      </div>
                      <Field
                        name={`targetAddress${index}`}
                        rules={[
                          { required: true, message: t('Address_is_required') },
                          {
                            whitespace: true,
                            message: t('This_field_can_not_empty'),
                          },
                        ]}
                      >
                        <input
                          placeholder={t('Address')}
                          disabled={isLoading}
                          onChange={(e) =>
                            handleKeyUp('targetAddress', index, null, e.target.value)
                          }
                          type="text"
                          className="input-decoration-white w-full focus:outline-none
                            font-bold py-3 px-5 text-gray3 mt-2 text-lg"
                        />
                      </Field>
                      <Field
                        name={`signature${index}`}
                        rules={[
                          { required: true, message: t('Signature_is_required') },
                          {
                            whitespace: true,
                            message: t('This_field_can_not_empty'),
                          },
                        ]}
                      >
                        <input
                          placeholder="assumeOwnership(address,string,uint256)"
                          onChange={(e) => handleParseFunc(e.target.value, index)}
                          disabled={isLoading}
                          type="text"
                          className="input-decoration-white w-full focus:outline-none
                            font-bold py-3 px-5 text-gray3 mt-2 text-lg"
                        />
                      </Field>
                      {f.callData.map((c, cIdx) => {
                        return (
                          <Field
                            key={cIdx}
                            name={`calldata_${index}_${cIdx}`}
                            rules={[
                              {
                                required: true,
                                message: t('Calldata_is_required'),
                              },
                              {
                                whitespace: true,
                                message: t('This_field_can_not_empty'),
                              },
                            ]}
                          >
                            <input
                              type="text"
                              placeholder={`${c}`}
                              disabled={isLoading}
                              className="input-decoration-white w-full focus:outline-none
                                font-bold py-5 px-4 text-gray3 mt-2 text-lg"
                              onChange={(e) => handleKeyUp('calldata', index, cIdx, e.target.value)}
                            />
                          </Field>
                        );
                      })}

                      {formData.length < +maxOperation && (
                        <div className="flex justify-center items-center space-x-4 mt-6">
                          {index !== 0 && (
                            <button
                              disabled={isLoading}
                              type={'button'}
                              className="bg-midBlue focus:outline-none py-2 px-16
                                rounded text-base text-white"
                              onClick={() => handleAdd('previous', index)}
                            >
                              {t('Add_to_previous')}
                            </button>
                          )}
                          <button
                            disabled={isLoading}
                            type={'button'}
                            className="bg-midBlue focus:outline-none py-2 px-12
                              rounded text-base text-white"
                            onClick={() => handleAdd('next', index)}
                          >
                            {t('Add_to_next')}
                          </button>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>

        {errorMsg && (
          <div className="flex justify-center items-center text-center text-red mt-8 ">
            {errorMsg}
          </div>
        )}
        <div className="flex justify-center mt-8 md:mt-0">
          <button
            className={`${isLoading || formData.length > maxOperation || description.trim().length === 0 ? "bg-gray5" : "bg-midBlue"} 
              focus:outline-none py-2 px-12 mt-6
              flex flex-row items-center justify-center
              rounded text-xl font-bold text-white w-full`}
            style={{ maxWidth: 320 }}
            disabled={
              isLoading || formData.length > maxOperation || description.trim().length === 0
            }
            type={'submit'}
          >
            {isLoading && <Loading size={'18px'} margin={'8px'} />} {t("Create_Proposal")}
          </button>
        </div>
      </Styles>
    </Form>
  );

  return (
    <Modal
      title={title}
      content={content}
      open={visible}
      onCloseModal={onCancel}
      width="max-w-4xl"
      containerClass={"pb-5"}
    />
  );
};

ProposalModal.defaultProps = {
  visible: false,
  address: '',
  maxOperation: 0,
  onCancel: () => { },
};

const mapDispatchToProps = (dispatch) => {
  const { getProposals } = accountActionCreators;

  return bindActionCreators(
    {
      getProposals,
    },
    dispatch,
  );
};

export default compose(connectAccount(undefined, mapDispatchToProps))(ProposalModal);
