import { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';

import Loading from 'components/UI/Loading';
import Switch from 'components/UI/SwitchComponent';
import AlertHelper from 'components/common/AlertHelper';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useAutoCompound, useClaimAll, useSelfCompound } from 'hooks/useLpStaking';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from 'hooks';

const StyledConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  gap: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media only screen and (max-width: 992px) {
    }
    .info {
      display: flex;
      gap: 12px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;
      }
      .tokens {
        display: flex;
        gap: 12px;
        @media only screen and (max-width: 992px) {
        }
        .token-container {
          display: flex;
          align-items: center;
          padding: 4px 8px 4px 4px;
          border-radius: 999px;
          gap: 4px;
          background: #FFFFFF1A;
          img {
            width: 20px;
            height: 20px;
          }
          .token-value {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.025em;
          }
        }
      }
      @media only screen and (max-width: 992px) {
      }
    }
    .total-value {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      color: #9CA3AF;
    }
    
    .option-container {
      display: flex;
      gap: 0px;
      .title {
        font-size: 14px;
        padding: 2px 10px 0 0;
        @media only screen and (max-width: 992px) {
          font-size: 12px;
          padding: 2px 10px 0 0;
        }
      }
      .switch {
        @media only screen and (max-width: 992px) {
          padding: 3px 5px;
        }
      }
      .alert {
        padding-top: 2px;
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      padding: 10px 16px 10px 16px;
      border-radius: 4px;
      border: 1px solid #FFFFFF1A;
      gap: 8px;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .button1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      padding: 10px 16px 10px 16px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      gap: 8px;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
`;

function Revenue({
  update,
  setUpdate,
  claimableRewards,
  autocompoundEnabled,
}) {
  const wethPrice = useSelector((state) => state.account.setting.markets).find(e => e.underlyingSymbol === 'ETH')?.tokenPrice || 0;

  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { pending: autoCompoundPending, handleAutoCompound } = useAutoCompound();
  const { pending: claimPending, handleClaim } = useClaimAll();
  const { pending: selfCompoundPending, handleSelfCompound } = useSelfCompound();

  const tokenPriceList = useMemo(() => {
    return [
      1, 1, wethPrice
    ]
  }, [wethPrice]);

  const totalRevenue = useMemo(() => {
    let temp = new BigNumber(0);
    for (let index = 0; index < claimableRewards.length; index++) {
      temp = temp.plus(claimableRewards[index].amount.div(10 ** claimableRewards[index].decimals).times(tokenPriceList[index]));
    }
    return temp;
  }, [tokenPriceList, claimableRewards])

  const onAutoCompound = async () => {
    if (!!account && !autoCompoundPending) {
      const tx = await handleAutoCompound(!autocompoundEnabled);
      if (tx) {
        setUpdate(!update);
      }
    }
  }

  const onClaimAll = async () => {
    const tx = await handleClaim();
    if (tx) {
      setUpdate(!update);
    }
  }

  const onSelfCompound = async () => {
    const tx = await handleSelfCompound();
    if (tx) {
      setUpdate(!update);
    }
  }

  return (
    <StyledConatiner>
      <div className='content-container'>
        <div className='info'>
          <div className='title'>
            {t('Total_Claimable')}
          </div>
          <div className='tokens'>
            {
              claimableRewards.map((reward, index) => {
                return (
                  <div className='token-container' key={index}>
                    <img src={`/images/coins/${reward.name.toLowerCase()}.png`} alt={'ars'} />
                    <div className='token-value'>
                      {reward.name} ${getFormatStringFromBignumber(reward.amount.div(10 ** reward.decimals).times(tokenPriceList[index]))}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='total-value'>
          ${getFormatStringFromBignumber(totalRevenue)}
        </div>
        {/* <div className='option-container'>
              <div className='title'>
                {t('Auto_compound')}
              </div>
              <div className='switch'>
                <Switch
                  inactiveStateColor={"bg-darkGray"}
                  activeStateColor={"bg-darkGray"}
                  slideClassName={"bg-lightGreen border-lightGreen"}
                  inactiveSlideClassName={'bgGrayGradient border-gray2'}
                  wrapperClassName="pt-1 pb-0"
                  style={'small'}
                  value={autocompoundEnabled}
                  onChange={onAutoCompound}
                />
              </div>
              <div className='alert'>
                <AlertHelper text={t('Automatically_compound')} />
              </div>
            </div> */}
      </div>
      <div className='button-container'>
        <button
          type="button"
          className={'button'}
          disabled={!account || claimPending || totalRevenue.eq(0)}
          onClick={onClaimAll}
        >
          {(claimPending) ? (
            <div className="flex justify-center items-center">
              <Loading size={'16px'} />
              {t('Pending')}
            </div>
          ) : (
            <>{t('Claim_All')}</>
          )}
        </button>
        <button
          type="button"
          className={'button1'}
          disabled={!account || selfCompoundPending || totalRevenue.eq(0)}
          onClick={onSelfCompound}
        >
          {(selfCompoundPending) ? (
            <div className="flex justify-center items-center">
              <Loading size={'16px'} margin={'7px'} />
              {t('Pending')}
            </div>
          ) : (
            <>{t('Compound_revenue')}</>
          )}
        </button>
      </div>
    </StyledConatiner>
  )
}

export default Revenue;