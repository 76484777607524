import BigNumber from 'bignumber.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import externalLink from "assets/img/common/external_link.svg";

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .history-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background: #FFFFFF0D;
    .title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      .token-icon {
        // width: 48px;
        height: 48px;
      }
      .title-sub-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;
        text-align: left;
        .desc {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;          
        }
      }
      .external-link {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        height: 48px;
      }
    }
    .divider {
      border-bottom: 1px solid #FFFFFF1A;
    }
    .desc-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;        
        color: #9CA3AF;
        .desc-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #F9FAFB;
        }
      }
    }
  }
`;

function HistoryMobileItem({
  data,
  getMethodColor,
}) {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {
        data.map((e, i) => {
          let delta = Date.now() / 1000 - e.blockTimestamp;
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          var minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          var seconds = parseInt(delta % 60);
          return (
            <div key={`hisotry-${i}`} className='history-item'>
              <div className='title-container'>
                <img className='token-icon' src={`/images/coins/${e.symbol?.toLowerCase()}.png`} alt={e.symbol} />
                <div className='title-sub-container'>
                  <div>
                    {new BigNumber(e.amount).dividedBy(new BigNumber(10).pow(e.decimal)).toFormat(5)} {e.symbol}
                  </div>
                  <div className='desc'>
                    {e.txHash?.substring(0, 10)}...
                  </div>
                </div>
                <div className='external-link'>
                  <img src={externalLink} alt='tx hash' />
                </div>
              </div>
              <div className='divider' />
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('From')}</div>
                  <div className="desc-value">
                    {e.from?.substring(0, 4)}...{e.from?.substring(38)}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('To')}</div>
                  <div className="desc-value">
                    {e.to?.substring(0, 4)}...{e.to?.substring(38)}
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Block')}</div>
                  <div className="desc-value">
                    {e.blockNumber}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Age')}</div>
                  <div className="desc-value">
                    {
                      days > 0 ?
                        `${days} ${days > 1 ? t("days") : t("day")} ${hours} ${hours > 1 ? t("hrs") : t("hr")} ${t("ago")}`
                        :
                        hours > 0 ?
                          `${hours} ${hours > 1 ? t("hrs") : t("hr")} ${minutes} ${minutes > 1 ? t("mins") : t("min")} ${t("ago")}`
                          :
                          `${minutes} ${minutes > 1 ? t("mins") : t("min")} ${seconds} ${seconds > 1 ? t("secs") : t("sec")} ${t("ago")}`
                    }
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Method')}</div>
                  <div className="flex justify-start">
                    <div className="method" style={getMethodColor(e.action)}>{e.action}</div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </StyledContainer>
  )
}

export default HistoryMobileItem;