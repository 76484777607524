import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import styled from 'styled-components';

import arrowDown from '../../../assets/img/common/arrow_down.svg';
import whitePlus from '../../../assets/img/common/ars_plus.svg';
import { useTokenApprove, useTokenBalance } from 'hooks/useLpStaking';
import { getFormatStringFromBignumber } from 'utilities/common';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React, useWeb3 } from 'hooks';
import { NotificationManager } from 'react-notifications';
import Loading from 'components/UI/Loading';
import { getLockZapContract, methods } from 'utilities/ContractService';
import { getNativeToken, isCoreNetwork } from 'utils';
import * as constants from 'utilities/constants';
import { useSelector } from 'react-redux';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 30px;
  .info-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    .header {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      color: #34D399;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
    .deposit {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      img {
        margin-right: 5px;
        height: 20px;
      }
    }
    .progress-bar {
      margin-top: 10px;
      position: relative;
      width: 100%;
      height: 4px;
      border-radius: 999px;
      background: #FFFFFF33;
      .progress-active-bar {
        height: 4px;
        border-radius: 999px;
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      }
    }
  }
  .item-container {
    padding: 16px;
    border: 1px solid #FFFFFF33;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF33;
    margin: 15px 0;
  }
  .desc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #9CA3AF;
    @media only screen and (max-width: 992px) {
      font-size: 12px;
    }
  }
  .continue-button-container {
    @media only screen and (max-width: 992px) {
    }
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 24px 16px 24px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
  .error-container {
    padding-top: 20px;
    .message {
      font-size: 14px;
      color: rgb(241, 53, 110);
    }
    @media only screen and (max-width: 992px) {
      padding-top: 10px;
      .message {
        font-size: 12px;
      }
    }
  }
`
const ItemContainer = styled.div`
  .sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabs {
      background-color: #3060F68F;
      display: flex;
      padding: 4px;
      border-radius: 10px;
      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 25px;
        border-radius: 8px;
        color: #ffffff8f;
        font-weight: 500;
        cursor: pointer;
      }
      .active-tab {
        background-color: #3060F6;
        color: #fff;
      }
    }
    .token {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      .value {
        font-weight: 700;
        font-size: 16px;
      }
    }
    .balance {
      display: flex;
      gap: 4px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
    .waiting {
      cursor: pointer;

    }
  }
  input {
    flex: 1;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.025em;
    @media only screen and (max-width: 992px) {
      width: 180px;
      font-size: 20px;
    }
    &:focus {
      outline: none;
    }
    ::placeholder {
      padding: 0 !important;
      color: #9CA3AF !important;
    }
  }
  .add-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    div {
      padding-top: 4px;
      font-size: 15px;
      font-weight: 500;
    }
  }
`
const TokenSelectorContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
  border-radius: 18px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  .zIndex {
    z-index: 1;
  }
  .flag-img-1 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .line {
    background-color: #ffffff23;
    width: 1px;
    height: 14px;
    margin: 0 10px 0 20px;
  }
  .arrow-down {
    margin: 0;
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  width: 120px;
  cursor: pointer;
  opacity: ${props => props.active ? '0.5' : '1'};
  cursor: ${props => props.active ? 'not-allowed' : 'pointer'};
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border: none;
  .flag-img-2 {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .token-name {
  }
`;


function ZapModalStep1({
  update,
  setUpdate,
  setStep,
  amount,
  setAmount,
  amountArs,
  setAmountArs,
  setTokenInfo,
  setNewZapInUsd,
  lockedUsdValue,
  requiredUsdValue,
}) {
  const currentNetworkId = useSelector((state) => state.application.currentNetworkId || 0);
  const arsPrice = useSelector((state) => state.account.setting.arsPrice || 0);
  const markets = useSelector((state) => state.account.setting.markets);

  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const web3 = useWeb3();
  // const contract = useLockZapContract(requiredChainId);

  const [selectedToken, setSelectedToken] = useState(0);
  const [isWrapSelected, setIsWrapSelected] = useState(false);
  // const [useWallet1, setUseWallet1] = useState(true);
  // const [useWallet2, setUseWallet2] = useState(true);
  const [errorList, setErrorList] = useState([]);

  const ZAP_TOKEN_LIST = useMemo(() => {
    if (isCoreNetwork(requiredChainId)) {
      return [
        {
          name: 'CORE',
          decimals: 18,
          address: constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].wcore.address
        }
      ]
    } else {
      return [
        {
          name: 'ETH',
          decimals: 18,
          address: constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].weth.address
        }, {
          name: 'USDT',
          decimals: 6,
          address: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdt.address
        }, {
          name: 'USDC.e',
          decimals: 6,
          address: (constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]['usdc.e'] || constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdc).address
        }
      ]
    }
  }, [requiredChainId]);

  const { tokenBalance, approvedTokenBalance } = useTokenBalance(ZAP_TOKEN_LIST[selectedToken].name.toLowerCase(), update);
  const { pending: approveTokenPending, handleApprove: handleTokenApprove } = useTokenApprove(ZAP_TOKEN_LIST[selectedToken].name.toLowerCase());

  const { tokenBalance: arsBalance, approvedTokenBalance: approvedArsBalance } = useTokenBalance('ars', update);
  const { pending: approvePending, handleApprove } = useTokenApprove('ars');
  // const [gasFee, setGasFee] = useState(new BigNumber(0));

  // useEffect(() => {
  //   const getGasFee = async () => {
  //     if (account && selectedToken === 0) {
  //       const gasPrice = constants.GAS_PRICE[requiredChainId];
  //       const gasEstimate = isCoreNetwork(requiredChainId) ?
  //         await contract.methods.zap(0, 0, 0).estimateGas({ from: account, value: '100000000000000000' })
  //         :
  //         await contract.methods.zap(ZAP_TOKEN_LIST[0].address, 0, 0, 0, 0).estimateGas({ from: account, value: '100000000000000000' });
  //       setGasFee(new BigNumber(gasPrice).times(gasEstimate));
  //     } else {
  //       setGasFee(new BigNumber(0));
  //     }
  //   }

  //   getGasFee();
  // }, [selectedToken, contract, account]);

  // const firstTokenBalance = useMemo(() => {
  //   if (selectedToken === 0) {
  //     console.log("Estimated Gas Fee: ", gasFee.div(1e18).toString(10));
  //     if (tokenBalance.gt(gasFee)) {
  //       return tokenBalance.minus(gasFee);
  //     }
  //     return new BigNumber(0);
  //   }
  //   return tokenBalance;
  // }, [tokenBalance, gasFee, selectedToken])

  const progressWidth = useMemo(() => {
    if (requiredUsdValue.eq(0)) return 0;
    if (lockedUsdValue.gte(requiredUsdValue.times(4))) return 100;
    return parseInt(lockedUsdValue.times(100).div(requiredUsdValue.times(4)).toString());
  }, [lockedUsdValue, requiredUsdValue]);

  const progressPercent = useMemo(() => {
    if (requiredUsdValue.eq(0)) return new BigNumber(0);
    return lockedUsdValue.times(100).div(requiredUsdValue.times(4));
  }, [lockedUsdValue, requiredUsdValue]);

  const needToApprove = useMemo(() => {
    return (new BigNumber(amountArs).times(1e18)).gt(approvedArsBalance);
  }, [amountArs, approvedArsBalance]);

  const needToApproveToken = useMemo(() => {
    if (selectedToken === 0) return false;
    return (new BigNumber(amount).times(10 ** ZAP_TOKEN_LIST[selectedToken].decimals)).gt(approvedTokenBalance);
  }, [amount, approvedTokenBalance]);

  const onUpdateFromFirstToken = async (_amount, _fromFirst = true) => {
    if (_amount === '' || !_amount) {
      setAmount('');
      setAmountArs('');
      return;
    }
    if (_fromFirst) {
      setAmount(_amount);
      setAmountArs('');
    } else {
      setAmountArs(_amount);
      const tokenContract = getLockZapContract(web3, requiredChainId);
      if (isCoreNetwork(requiredChainId)) {
        const result = new BigNumber(_amount).eq(0) ?
          '0'
          :
          await methods.call(
            tokenContract.methods.quoteFromToken,
            [new BigNumber(_amount).times(1e18).toString(10)],
          );
        setAmount(new BigNumber(result).div(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).toString(10));
      } else {
        const result = new BigNumber(_amount).eq(0) ?
          '0'
          :
          await methods.call(
            tokenContract.methods.quoteFromToken,
            [
              ZAP_TOKEN_LIST[selectedToken].address,
              new BigNumber(_amount).times(1e18).toString(10)
            ],
          );
        setAmount(new BigNumber(result).div(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).toString(10));
      }
    }
  }

  const onContinue = async () => {
    if (amount && new BigNumber(amount).gt(0)) {
      if (needToApproveToken) {
        const tx = await handleTokenApprove(new BigNumber(amount).times(10 ** ZAP_TOKEN_LIST[selectedToken].decimals));
        if (tx) {
          setUpdate(!update);
        }
      } else if (needToApprove && isWrapSelected) {
        const tx = await handleApprove(new BigNumber(amountArs).times(1e18));
        if (tx) {
          setUpdate(!update);
        }
      } else {
        const arsInUsd = new BigNumber(amountArs || 0).times(arsPrice);
        const tokenInUsd = new BigNumber(amount || 0)
          .times(markets.find(e => e.underlyingSymbol === ZAP_TOKEN_LIST[selectedToken].name)?.tokenPrice || 0);

        setTokenInfo(ZAP_TOKEN_LIST[selectedToken]);
        setNewZapInUsd(tokenInUsd.plus(arsInUsd))
        setStep(1);
      }
    }
  }

  useEffect(() => {
    const temp = [];
    if (new BigNumber(amount).times(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).gt(tokenBalance)) {
      temp.push(t('not_enough', { value: ZAP_TOKEN_LIST[selectedToken].name }));
    }
    if (isWrapSelected) {
      if (new BigNumber(amountArs).times(1e18).gt(arsBalance)) {
        temp.push(t('not_enough', { value: 'ARS' }));
      }
    }

    setErrorList(temp);
  }, [selectedToken, amount, tokenBalance, arsBalance, isWrapSelected])

  const TokenSelector = () => {
    if (isCoreNetwork(requiredChainId)) {
      return (
        <div className='token'>
          <img src={`/images/coins/${getNativeToken(requiredChainId).toLowerCase()}.png`} alt={'token'} />
          <div className={'value'}>{getNativeToken(requiredChainId)}</div>
        </div>
      )
    }
    return (
      <TokenSelectorContainer>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex w-full justify-center items-center px-4 py-2">
            <img className='flag-img-1' src={`/images/coins/${ZAP_TOKEN_LIST[selectedToken].name.toLowerCase()}.png`} alt='' />
            <div>{ZAP_TOKEN_LIST[selectedToken].name}</div>
            <div className='line'></div>
            <img className="arrow-down" src={arrowDown} alt='' />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute zIndex right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg
              ring-1 ring-black ring-opacity-5 focus:outline-none"
              style={{ zIndex: 1 }}
            >
              {
                ZAP_TOKEN_LIST.map((token, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <MenuItem active={index === selectedToken} className='rounded-md'
                        onClick={() => {
                          onUpdateFromFirstToken('', false);
                          setSelectedToken(index);
                          // onUpdateFromFirstToken(amountArs, false);
                        }}
                      >
                        <img className='flag-img-2' src={`/images/coins/${token.name.toLowerCase()}.png`} alt='' />
                        <div className='token-name'>{token.name}</div>
                      </MenuItem>
                    )}
                  </Menu.Item>
                ))
              }
            </Menu.Items>
          </Transition>
        </Menu>
      </TokenSelectorContainer>
    )
  }

  return (
    <ContainerStyles>
      <div className='info-container'>
        <div className='header'>{t('emissions_eligible')}</div>
        <div className='desc'>
          {t('When_the_value')}
        </div>
        <div className='deposit'>
          <img src={`/images/coins/dlp_${currentNetworkId}.png`} alt={'ars'} />
          <div>
            {t('out_of_0')}${getFormatStringFromBignumber(lockedUsdValue.div(1e8))} {t('out_of')}
            {' '}${getFormatStringFromBignumber(requiredUsdValue.div(1e8))} {t('deposits')}
          </div>
        </div>
        <div className='progress-bar'>
          <div className='progress-active-bar'
            style={{ width: `${progressWidth}%` }}
          />
        </div>
      </div>
      <ItemContainer className="item-container">
        <div className="sub-container">
          <input
            placeholder={(isWrapSelected) ? `${t('Waiting_on')} ARS...` : "0.0"}
            value={amount}
            readOnly={(isWrapSelected)}
            style={(isWrapSelected) ? { cursor: 'pointer' } : {}}
            onChange={event => {
              if (!event.target.value.length || Number(event.target.value) >= 0)
                onUpdateFromFirstToken(event.target.value, true);
            }}
            onClick={() => {
              if (isWrapSelected) {
                setIsWrapSelected(false);
                setAmountArs();
              }
            }}
          />
          {TokenSelector()}
        </div>
        <div className="sub-container">
          {/* <div className='tabs'>
            <div className={`tab ${useWallet1 ? 'active-tab' : ''}`}
              onClick={() => setUseWallet1(true)}
            >Wallet</div>
            <div className={`tab ${!useWallet1 ? 'active-tab' : ''}`}
              onClick={() => setUseWallet1(false)}
            >Borrow</div>
          </div> */}
          <div />
          <div className='balance'
            onClick={() => {
              NotificationManager.warning(t("Transaction_can_be_fail"));
              if (!isWrapSelected) {
                onUpdateFromFirstToken(tokenBalance.div(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).toString(), true);
              } else {
                setIsWrapSelected(false);
                onUpdateFromFirstToken(tokenBalance.div(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).toString(), true);
                // onUpdateFromFirstToken(wcoreBalance.div(1e18).toString(), true);
              }
            }}
          >
            {
              `${tokenBalance.div(10 ** ZAP_TOKEN_LIST[selectedToken].decimals).toFormat(2)} ${ZAP_TOKEN_LIST[selectedToken].name}`
            }
          </div>
        </div>
      </ItemContainer>
      {
        !isWrapSelected ? (
          <ItemContainer className="item-container">
            <div className="add-container"
              onClick={() => {
                setIsWrapSelected(true);
                setAmount('');
              }}
            >
              <img src={whitePlus} alt='' />
              <div>{t('Add_ARS_to_dLP')}</div>
            </div>
          </ItemContainer>
        ) : (
          <ItemContainer className="item-container">
            <div className="sub-container">
              <input
                placeholder="0.0"
                value={amountArs}
                onChange={event => {
                  if (!event.target.value.length || Number(event.target.value) >= 0)
                    onUpdateFromFirstToken(event.target.value, false);
                }}
              />
              <div className='token'>
                <img src={`/images/coins/ars.png`} alt={'ars'} />
                <div className={'value'}>ARS</div>
              </div>
            </div>
            <div className="sub-container">
              {/* <div className='tabs'>
                <div className={`tab ${useWallet2 ? 'active-tab' : ''}`}
                  onClick={() => setUseWallet2(true)}
                >Wallet</div>
                <div className={`tab ${!useWallet2 ? 'active-tab' : ''}`}
                  onClick={() => setUseWallet2(false)}
                >Borrow</div>
              </div> */}
              <div />
              <div className='balance'
                onClick={() => {
                  onUpdateFromFirstToken(arsBalance.div(1e18).toString(), false);
                }}
              >
                {arsBalance.div(1e18).toFormat(2)} ARS
              </div>
            </div>
          </ItemContainer>
        )
      }
      {
        errorList.length !== 0 && (
          <div className='error-container'>
            {
              errorList.map((error, index) => {
                return (
                  <div key={index} className='message'>
                    {error}
                  </div>
                )
              })
            }
          </div>
        )
      }

      <div className="continue-button-container" >
        <button
          type="button"
          disabled={!account || errorList.length > 0 || approvePending || approveTokenPending || !amount || new BigNumber(amount).eq(0)}
          onClick={onContinue}
        >
          {
            (approvePending || approveTokenPending) ? (
              <div className="flex justify-center items-center">
                <Loading size={'16px'} margin={'7px'} />
                {t('Pending')}
              </div>
            ) : (
              <>{
                (needToApproveToken) ?
                  `${t('Approve')} ${ZAP_TOKEN_LIST[selectedToken].name}`
                  :
                  needToApprove ? `${t('Approve')} ARS` : t('Continue')
              }</>
            )
          }
        </button>
      </div>
      <div className='divider' />
      <div>
        <div className='desc-container'>
          <div> {t('Total_supply_balance')}</div>
          <div>${getFormatStringFromBignumber(requiredUsdValue.times(20).div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div>{t('Required_locked_dLP')}</div>
          <div>${getFormatStringFromBignumber(requiredUsdValue.div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div>{t('Currently_locked')}</div>
          <div>${getFormatStringFromBignumber(lockedUsdValue.div(1e8))}</div>
        </div>
      </div>
    </ContainerStyles>
  )
}

export default ZapModalStep1;