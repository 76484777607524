import BigNumber from 'bignumber.js';
import SwitchComponent from 'components/UI/SwitchComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .market-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background: #FFFFFF0D;
    .title-container {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      text-align: left;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .divider {
      border-bottom: 1px solid #FFFFFF1A;
    }
    .desc-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
        .sub-item-container {
          display: flex;
          align-items: center;
          gap: 4px;
          .desc-value {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #F9FAFB;
          }
          img {
            width: 16px;
            height: 16px;
          }
          .sub-apy-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%);
            border: 1px solid;
            border-color: rgba(14, 165, 233, 0.5);
            padding: 4px;
            border-radius: 999px;
            gap: 4px;
            font-size: 12px;
            line-height: 16px;
            text-align: left;
          }
        }
      }
    }
  }
`;

function PortfolioMobileItem1({
  assetList,
  account,
  handleBorrowClickRow,
}) {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {
        assetList.map((asset, index) => {
          return (
            <div key={`market-${index}`} className='market-item'>
              <div className='title-container'>
                <img src={asset.img} alt={asset.symbol} />
                <div>{asset.symbol}</div>
              </div>
              <div className='divider' />
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t("Borrow_Balance")}</div>
                  <div className="sub-item-container">
                    <div className="desc-value">${getFormatStringFromBignumber(new BigNumber(asset.borrowBalance).times(asset.tokenPrice))}</div>
                  </div>
                  <div>{getFormatStringFromBignumber(new BigNumber(asset.borrowBalance))} {asset.symbol}</div>
                </div>
                <div className='item-container'>
                  {/* <div>{t("Total_Borrow")}</div>
                  <SwitchComponent
                    value={account ? asset.collateral : false}
                    onChange={() => handleToggleCollateral(asset)}
                  /> */}
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t("Borrow")} %</div>
                  <div className="sub-item-container">
                    <div className="desc-value">{getFormatStringFromBignumber(new BigNumber(asset.borrowApy))}%</div>
                    <div className='sub-apy-container'>
                      <img src={`/images/coins/ars.png`} alt={'ars'} />
                      <div>{getFormatStringFromBignumber(new BigNumber(asset.borrowAdditionalApy))}%</div>
                    </div>
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t("Borrow_Rewards")}</div>
                  <div className="sub-item-container">
                    <div className="desc-value">{getFormatStringFromBignumber(new BigNumber(asset.supplyReward))} ARS</div>
                  </div>
                </div>
              </div>
              <div className='divider' />
              <div className='action-container' style={account ? {} : { opacity: 0.5 }}>
                <div className="supply"
                  onClick={() => handleBorrowClickRow(asset, 'borrow')}
                >
                  {t("Borrow")}
                </div>
                <div className="borrow"
                  onClick={() => handleBorrowClickRow(asset, 'borrowRepay')}
                >
                  {t("Borrow_Repay")}
                </div>
              </div>
            </div>
          )
        })
      }
    </StyledContainer>
  )
}

export default PortfolioMobileItem1;