import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from "react-i18next";
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { BitgetWallet } from 'connectors/Bitkeep';

import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/closeWhite.svg';
import ConnectWalletModal from './ConnectWalletModal';
import { useActiveWeb3React } from '../../hooks';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NavDropdown from 'components/UI/NavDropdown';
import routes from '../../routes/RouteMap';
import config from 'config';
import arrowDown from '../../assets/img/header/down.svg';
import { CHAIN_IDS } from 'connectors';
import { switchNetwork } from 'connectors/addNetwork';
import { isCoreNetwork, shortenAddress } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { applicationActionCreators } from 'core';
import { getAddChainParameters } from 'chain';
import { useWindowSize } from 'hooks/useWindowSize';


const StyledConatiner = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  .link-item-container {
    gap: 32px;
  }
  .certik-solidproof-logo {
    background: #2B2044;
    border-radius: 4px;
    padding: 10px 8px;
    margin-right: 19px;
    img {
      width: 99px;
      height: auto;
    }
  }
  .solidproof-container {
    padding-right: 7px;
    margin-right: 7px;
    border-right: 1px solid #ffffff;
  }
  .menu-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: #9CA3AF;
    &:hover {
      color: #F9FAFB;
    }
    div {
      padding-top: 2px;
    }
  }
  .btn {
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .size-28 {
    width: 28px;
    height: 28px;
  }
  .menu-container {
    .menu-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 44px;
      width: 44px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #FFFFFF1A;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
    }
    .menu-popup {
      z-index: 1;
      position: fixed;
      background: #030712;
      top: 0px;
      left: 0;
      width: 100vw;
      height: 100vh;
      .sub-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 16px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
        .menu-item2 {
          padding: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1em;
          white-space: nowrap;
          text-align: center;
          color: #9CA3AF;
        }
      }
    }
  }
  .wallet-button {
    width: 140px;
    padding: 10px, 16px, 10px, 16px;
    font-size: 14px;
    line-height: 20px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    border: 1px solid #0EA5E9;
    border-radius: 4px;
    &:hover {
      box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }
`;
const LanguageContainer = styled.div`
  width: 90px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 999px;
  border: 1px solid #FFFFFF1A;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  .flag-img {
    height: 16px;
  }
  .lang-item {
    gap: 8px;
  }
  .lang-items {
    display: flex;
    z-index: 1;
    width: 90px;
    height: 88px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #FFFFFF1A;
    background: #030712;
    .items-sub {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  opacity: ${props => props.active ? '0.5' : '1'};
  cursor: ${props => props.active ? 'not-allowed' : 'pointer'};
  font-size: 14px;
  line-height: 20px;
  border: none;
`;

const NetworkSelectorContainer = styled.div`
  width: 144px;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #FFFFFF1A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  .network-img {
    margin-right: 4px;
  }
  .network-items {
    display: flex;
    z-index: 1;
    width: 144px;
    height: 88px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #FFFFFF1A;
    background: #030712;
    .items-sub {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;
const NetwokrMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 28px;
  width: 120px;
  opacity: ${props => props.active ? '0.5' : '1'};
  cursor: ${props => props.active ? 'not-allowed' : 'pointer'};
  font-size: 14px;
  line-height: 20px;
  border: none;
`;

const LANGUAGES = [
  { icon: '/images/lang/en.png', value: 'en' },
  { icon: '/images/lang/ch.png', value: 'ch' }
];
const NETWORKS = [
  { icon: '/images/network/arbitrum.svg', name: 'Arbitrum' },
  { icon: '/images/network/core.svg', name: 'Core DAO' },
]

function Navigation({ connectWalletsOpen, setConnectWalletsOpen }) {
  const currentChainId = useSelector(({ application }) => application.currentChainId) || 42161;
  const currentNetworkId = useSelector(({ application }) => application.currentNetworkId) || 0;

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { connector, account, requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();
  const location = useLocation();

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || config.defaultLanguage);

  useEffect(() => {
    if (currentChainId !== CHAIN_IDS[currentNetworkId]) {
      dispatch(applicationActionCreators.updateNetworkId({ selectedNetworkId: currentNetworkId }));
    }
  }, [currentChainId, currentNetworkId])

  const ConnectWallet = () => (
    <button
      className={`wallet-button focus:outline-none btn`}
      onClick={() => {
        setConnectWalletsOpen(true);
      }}
    >
      {account ? shortenAddress(account, 3) : t('Connect_Wallet')}
    </button>
  );

  const LanguageSelector = () => {
    return (
      <LanguageContainer>
        <Menu as="div" className="relative w-full inline-block text-left">
          <div>
            <Menu.Button className="flex w-full justify-center items-center lang-item">
              <img className='flag-img' src={`/images/lang/${selectedLanguage}.png`} alt='' />
              <div>{t(selectedLanguage)}</div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute lang-items">
              <div className='items-sub'>
                {
                  LANGUAGES.map((info, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <MenuItem active={info.value === selectedLanguage} className='rounded-md'
                          onClick={() => {
                            i18n.changeLanguage(info.value);
                            localStorage.setItem('language', info.value);
                            setSelectedLanguage(info.value);
                          }}
                        >
                          <img className='flag-img' src={info.icon} alt='' />
                          <div>{t(info.value)}</div>
                        </MenuItem>
                      )}
                    </Menu.Item>
                  ))
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </LanguageContainer>
    )
  }

  const NetworkSelector = () => {
    return (
      <NetworkSelectorContainer>
        <Menu as="div" className="relative w-full inline-block text-left">
          <div>
            <Menu.Button className="flex w-full justify-between items-center px-4">
              <div className='flex items-center'>
                <img className='network-img' src={NETWORKS[currentNetworkId].icon} alt='' />
                <div>{NETWORKS[currentNetworkId].name}</div>
              </div>
              <img src={arrowDown} alt='' />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute network-items">
              <div className='items-sub'>
                {
                  NETWORKS.map((network, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <NetwokrMenuItem active={index === Number(currentNetworkId)} className='rounded-md'
                          onClick={async () => {
                            if (connector instanceof MetaMask) {
                              const switched = await switchNetwork(CHAIN_IDS[index]);
                              if (switched) {
                                localStorage.setItem('network', index);
                                dispatch(applicationActionCreators.updateNetworkId({ selectedNetworkId: index }));
                                connector.activate(CHAIN_IDS[index]);
                              }
                            } else if (connector instanceof WalletConnect) {
                              connector.activate(CHAIN_IDS[index])
                                .then(() => {
                                  localStorage.setItem('network', index);
                                  dispatch(applicationActionCreators.updateNetworkId({ selectedNetworkId: index }));
                                  connector.activate(CHAIN_IDS[index]);
                                })
                                .catch((error) => {
                                  console.log(error);
                                })
                            } else if (connector instanceof CoinbaseWallet || connector instanceof BitgetWallet) {
                              connector.activate(getAddChainParameters(CHAIN_IDS[index]))
                                .then(() => {
                                  localStorage.setItem('network', index);
                                  dispatch(applicationActionCreators.updateNetworkId({ selectedNetworkId: index }));
                                  connector.activate(CHAIN_IDS[index]);
                                })
                                .catch((error) => {
                                  console.log(error);
                                })
                            } else {
                              localStorage.setItem('network', index);
                              dispatch(applicationActionCreators.updateNetworkId({ selectedNetworkId: index }));
                            }
                          }}
                        >
                          <img className='network-img' src={network.icon} alt='' />
                          <div>{network.name}</div>
                        </NetwokrMenuItem>
                      )}
                    </Menu.Item>
                  ))
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </NetworkSelectorContainer>
    )
  }

  return (
    <StyledConatiner className="flex-1">
      {
        width > 992 ? (
          <div className="flex justify-between items-center">
            <div className='flex justify-between items-center link-item-container'>
              <Link to="/" className={'menu-item'}
                style={location?.pathname?.includes('dashboard') ? { color: '#F9FAFB' } : {}}
              >
                <div>
                  {t("DASHBOARD")}
                </div>
              </Link>
              <Link to="/portfolio" className={'menu-item'}
                style={location?.pathname?.includes('portfolio') ? { color: '#F9FAFB' } : {}}
              >
                <div>
                  {t("PORTFOLIO")}
                </div>
              </Link>
              {
                (isCoreNetwork(requiredChainId)) ?
                  <NavDropdown
                    label={t("STAKING")}
                    options={[
                      { label: t('STAKING_ARS'), link: routes.stake.ars },
                      { label: t('STAKING_LP'), link: routes.stake.dlp },
                    ]}
                    active={location?.pathname?.includes('staking')}
                  />
                  :
                  <Link to={routes.stake.dlp} className={'menu-item'}
                    style={location?.pathname?.includes('staking') ? { color: '#F9FAFB' } : {}}
                  >
                    <div>
                      {t("STAKING_LP")}
                    </div>
                  </Link>
              }
              <Link to={routes.market.index} className={'menu-item'}
                style={location?.pathname?.includes(routes.market.index) ? { color: '#F9FAFB' } : {}}
              >
                <div>
                  {t("MARKET")}
                </div>
              </Link>
              <NavDropdown
                label={t("MANAGE")}
                options={[
                  { label: t('REFERRAL'), link: routes.referral },
                  { label: t('HISTORY'), link: routes.history },
                  { label: t('LIQUIDATE'), link: routes.liquidate },
                  { label: t('REWARDS'), link: routes.aquarius },
                  { label: t('GOVERNANCE'), link: 'https://dao.aquarius.loan/', public: true },
                ]}
                active={
                  location?.pathname?.includes(routes.referral)
                  || location?.pathname?.includes(routes.history)
                  || location?.pathname?.includes(routes.liquidate)
                  || location?.pathname?.includes(routes.aquarius)
                }
              />
            </div>
            <div className='flex justify-between items-center gap-5'>
              {LanguageSelector()}
              {NetworkSelector()}
              <ConnectWallet />
            </div>
          </div>
        ) : (
          <div className="flex justify-end items-center gap-5">
            {NetworkSelector()}
            {LanguageSelector()}
            <div
              className='menu-container'
              onClick={() => { setShowMobileMenu(!showMobileMenu) }}
            >
              <div className='menu-icon'>
                <img src={menu} alt='menu' />
              </div>
              <div className={`menu-popup ${showMobileMenu ? 'block' : 'hidden'}`}>
                <div className='sub-menu-container'>
                  <div className='flex justify-end items-center w-full'>
                    <div className='menu-icon'>
                      <img src={close} alt='menu' />
                    </div>
                  </div>
                  <Link to="/" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('dashboard') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("DASHBOARD")}
                  </Link>
                  <Link to="/portfolio" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('portfolio') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("PORTFOLIO")}
                  </Link>
                  {
                    (isCoreNetwork(requiredChainId)) &&
                    <Link to={routes.stake.ars} className={'menu-item2 w-full'}
                      style={location?.pathname?.includes('portfolio') ? { color: '#F9FAFB' } : {}}
                    >
                      {t('STAKING_ARS')}
                    </Link>
                  }
                  <Link to={routes.stake.dlp} className={'menu-item2 w-full'}
                    style={location?.pathname?.includes(routes.stake.dlp) ? { color: '#F9FAFB' } : {}}
                  >
                    {t("STAKING_LP")}
                  </Link>
                  <Link to="/market" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('market') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("MARKET")}
                  </Link>
                  <Link to="/referral" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('referral') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("REFERRAL")}
                  </Link>
                  <Link to="/history" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('history') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("HISTORY")}
                  </Link>
                  <Link to="/liquidate" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('liquidate') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("LIQUIDATE")}
                  </Link>
                  <Link to="/aquarius" className={'menu-item2 w-full'}
                    style={location?.pathname?.includes('aquarius') ? { color: '#F9FAFB' } : {}}
                  >
                    {t("REWARDS")}
                  </Link>
                  <a href="https://dao.aquarius.loan/" className={'menu-item2 w-full'}>
                    {t("GOVERNANCE")}
                  </a>
                  <ConnectWallet />
                </div>
              </div>
            </div>
          </div>
        )
      }
      <ConnectWalletModal
        open={connectWalletsOpen}
        onCloseModal={() => setConnectWalletsOpen(false)}
      />
    </StyledConatiner>
  );
}

export default Navigation;
