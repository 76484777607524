import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActiveWeb3React, useWeb3 } from "../../../hooks";
import { getTokenContract, methods } from "../../../utilities/ContractService";
import Modal from "../../UI/Modal";

import { ReactComponent as TickGreen } from '../../../assets/icons/tickGreen.svg';
import { ReactComponent as CloseWhite } from '../../../assets/icons/closeCirclePrimary.svg';
import { ReactComponent as BigArrow } from '../../../assets/img/common/arrow.svg';
import loadingModal from '../../../assets/img/common/loading_modal.svg'
import TickIcon from '../../../assets/icons/tickGreen.svg';
import DelegationVoting from "./DelegationVoting";
import styled from "styled-components";
import { getEtherscanLink, getNativeToken } from "utils";
import { NotificationManager } from "react-notifications";

const Styles = styled.div`
    padding: 60px 76px 20px 76px;
    
    @media (max-width: 500px) {
        padding: 100px 0px 0px 0px;
    }
    .back-arrow-container {
        left: 26px;
        top: 24px;
    }
    svg.back-arrow {
        path {
            stroke: #000;
        }
    }
    @media (max-width: 640px) {
        .back-arrow-container {
            left: 26px;
            top: 24px;
        }
    }
    a.delegation-close-container {
        position: absolute;
        right: 1rem;
        top: 1rem;
        outline: none;
        background: transparent;
        svg {
          height: 26px;
            width: auto;
            circle {
              stroke: #000000;
              fill: transparent;
            }
            path {
                fill: #000000;
            }
        }
    }
    div.modal-container {
        padding: 80px 76px;
    }
`
const DelegationTypeStyles = styled.div`
    svg.forward-arrow {
        path {
            stroke: #979797;
        }
    }
    a.delegation-close-container {
        position: absolute;
        right: 1rem;
        top: 1rem;
        outline: none;
        background: transparent;
        svg {
          height: 26px;
            width: auto;
            circle {
              stroke: #000000;
              fill: transparent;
            }
            path {
                fill: #000000;
            }
        }
    }

    .tick-green {
        background: #4fd000;
        height: auto;
        width: 20px;
        path {
            fill: #ffffff;
        }
    }
`

const DelegationTypeModal = ({
    visible,
    balance,
    delegateStatus,
    address,
    onCancel
}) => {
    const { t } = useTranslation();
    const { requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const [child, setChild] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [txnHash, setTxnHash] = useState('');

    const handleVoting = async (dAddress) => {
        setIsLoading(true);
        const tokenContract = getTokenContract(web3, 'ars', requiredChainId);
        const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            tokenContract.methods.delegate, [dAddress || address], address
        );
        if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setIsLoading(false);
            setChild('');
            onCancel();
            return
        }
        methods.send(tokenContract.methods.delegate, [dAddress || address], address)
            .then((res) => {
                setTxnHash(res.transactionHash);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setChild('');
                onCancel();
            });
    };


    const getBefore = value => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(0, position + 5) : value;
    };

    const getAfter = value => {
        const position = value.indexOf('.');
        return position !== -1 ? value.slice(position + 5) : null;
    };


    let title = (
        <DelegationTypeStyles
            className="flex justify-center items-start mt-6"
        >
            <div className="text-2xl font-semiBold border-b border-gray4 pb-5 px-8 w-full">
                {t("Choose_Delegation_Type")}
            </div>
            <a href="#" className="cursor-pointer delegation-close-container" onClick={onCancel}>
                <CloseWhite fill={'#fff'} className="close-icon" />
            </a>
        </DelegationTypeStyles>
    );

    let content = (
        <DelegationTypeStyles className="pb-8 overflow-auto">
            <div className="flex flex-col space-y-8">
                <div
                    className="cursor-pointer flex justify-between items-center py-6 px-4 sm:px-12"
                    onClick={e => {
                        if (delegateStatus === 'self') {
                            e.preventDefault();
                            return;
                        }
                        setChild('manual');
                        setTxnHash('');
                        handleVoting('');
                    }}
                >
                    <TickGreen className="tick-green" />
                    <div className="" style={{ maxWidth: '70%' }}>
                        <div className="text-midBlue font-bold text-2xl">{t("Manual_Voting")}</div>
                        <div className="text-gray3 text-lg mt-4">
                            {t("This_option_allows_you_to_vote_on_proposals_directly_from_your_connected_wallet")}
                        </div>
                    </div>
                    {delegateStatus !== 'self' ? (
                        <button className="mt-2 focus:outline-none">
                            <BigArrow className={"forward-arrow transform rotate-180"} />
                        </button>
                    ) : (
                        <span className="text-green text-2xl font-medium">{t("Active")}</span>
                    )}
                </div>
                <div
                    className="cursor-pointer flex justify-between items-center py-6 px-4 sm:px-12"
                    onClick={() => {
                        setChild('delegate');
                        setTxnHash('');
                    }}
                >
                    <TickGreen className="tick-green" />
                    <div className="" style={{ maxWidth: '70%' }}>
                        <div className="text-midBlue font-bold text-2xl">{t("Delegate_Voting")}</div>
                        <div className="text-gray3 text-lg mt-4">
                            {t("undelegate_message")}
                        </div>
                    </div>
                    {delegateStatus == 'self' ? (
                        <button className="mt-2 focus:outline-none">
                            <BigArrow className={"forward-arrow transform rotate-180"} />
                        </button>
                    ) : (
                        <span className="text-green text-2xl font-medium">{t("Active")}</span>
                    )}
                </div>
            </div>
        </DelegationTypeStyles>
    )



    if (child === 'manual') {
        title = (
            <Styles
                className="items-center flex justify-center px-8 relative border-b border-gray4"
            >
                <div className="cursor-pointer back-arrow-container absolute" onClick={() => {
                    setChild('')
                    setTxnHash('');
                }}>
                    <BigArrow className={"back-arrow"} />
                </div>
                <div className="text-4xl font-bold text-center">
                    {isLoading ? t('Transaction_Pending') : t('Transaction_Completed')}
                </div>
                <a href="#" className="cursor-pointer delegation-close-container" onClick={onCancel}>
                    <CloseWhite fill={'#fff'} className="close-icon" />
                </a>
            </Styles>
        )

        content = (
            <div className="p-6">
                <div className="text-2xl font-bold text-midBlue text-center">
                    {getBefore(balance)}
                    <span>{getAfter(balance)}</span>{' '}
                    {t("Votes")}
                </div>
                <div className="text-2xl font-bold text-gray3 text-center mt-6">
                    {t("Manual_Voting_From")}{' '}
                    <span className={"text-gray3"}>{`${address.substr(0, 4)}...${address.substr(address.length - 4, 4)}`}</span>
                </div>
                <div className="flex flex-col items-center mt-5">
                    {isLoading ? (
                        <img src={loadingModal} alt="loader" className="animate-spin" width={'50px'} height={"auto"} />
                    ) : (
                        <img className="" src={TickIcon} alt="Tick" width={'50px'} height={"auto"} />
                    )}
                    <div className="text-2xl font-bold text-gray3 mt-9">{t("Transaction_Broadcast")}</div>
                </div>
                <div className="flex justify-center mt-10">
                    <button
                        disabled={!txnHash}
                        className="focus:outline-none bg-midBlue rounded py-2 px-14 mb-2 text-xl font-bold text-white"
                        onClick={() => {
                            window.open(
                                getEtherscanLink(requiredChainId, txnHash, 'transaction'),
                                '_blank'
                            );
                        }}
                    >
                        {t("View_on_Explorer")}
                    </button>
                </div>
            </div>
        )
    } else if (child === 'delegate') {
        title = (
            <Styles
                className="items-center flex justify-center px-8 relative border-b border-gray4"
            >
                <div className="cursor-pointer back-arrow-container absolute" onClick={() => {
                    setChild('')
                    setTxnHash('');
                }}>
                    <BigArrow className={"back-arrow"} />
                </div>
                <div className="text-center text-4xl font-bold">{t("Delegate_Voting")}</div>
                <a href="#" className="cursor-pointer delegation-close-container" onClick={onCancel}>
                    <CloseWhite fill={'#fff'} className="close-icon" />
                </a>
            </Styles>
        )

        content = (
            <DelegationVoting
                isLoading={isLoading}
                onDelegate={handleVoting}
            />
        )
    }

    return (
        <Modal
            title={title}
            content={content}
            open={visible}
            onCloseModal={onCancel}
            containerClass={"modal-container"}
            afterCloseModal={() => {
                setChild('')
                setTxnHash('');
            }}
        />
    )
}

DelegationTypeModal.defaultProps = {
    address: '',
    balance: '0',
    visible: false,
    delegateStatus: '',
    onCancel: () => { }
};

export default DelegationTypeModal;
