import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as Copy } from '../../../assets/icons/copyBlue.svg';
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "../../UI/Toast";

const ProposerInfo = ({ address }) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="text-4xl font-bold text-midBlue">{t("Details")}</div>
            <div className="flex items-center space-x-8 mt-2">
                <div className="text-xl text-gray3">{address}</div>
                <CopyToClipboard
                    text={address}
                    onCopy={() => {
                        toast.success({
                            title: t(`Address_copied`)
                        });
                    }}
                >
                    <Copy className="copy-icon" />
                </CopyToClipboard>
            </div>
        </div>
    )
}


export default compose(withRouter)(ProposerInfo);
