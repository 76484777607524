import {
  Multicall,
} from 'ethereum-multicall';
import * as constants from './constants';
import { useWeb3 } from 'hooks';
import { getNativeToken } from 'utils';
import BigNumber from 'bignumber.js';

const TOKEN_ABI = {
  usdc: constants.ERC20_TOKEN_ABI,
  usdt: constants.ERC20_TOKEN_ABI,
  eth: constants.ERC20_TOKEN_ABI,
  weth: constants.ERC20_TOKEN_ABI,
  bnb: constants.ERC20_TOKEN_ABI,
  core: constants.ERC20_TOKEN_ABI,
  wcore: constants.ERC20_TOKEN_ABI,
  ars: constants.CONTRACT_ARS_TOKEN_ABI,
};

const call = (method, params) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    method(...params)
      .call()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const checkGasFee = async (web3, chainId, method, params, from, value = '0') => {
  try {
    const gasPrice = await web3.eth.getGasPrice();
    // const gasPrice = constants.GAS_PRICE[chainId];
    const gasEstimate = await method(...params).estimateGas({ from, value });
    const gasFee = new BigNumber(gasPrice).times(gasEstimate).times(2);
    const mainBalance = await web3.eth.getBalance(from);
    console.log("2 x Estimated Gas Fee: ", gasFee.div(1e18).toString(10));
    if (new BigNumber(mainBalance).gte(gasFee)) {
      return true;
    }
  } catch (e) {
    console.log(e)
  }
  return false;
}

const send = (method, params, from) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sendWithValue = (method, params, from, value) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from, value })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ethMulticall = (web3, contractCallContext, chainId = 42161) => {
  const multicall = new Multicall({
    multicallCustomContractAddress: constants.MULTICALL_ADDRESS[chainId],
    web3Instance: web3,
    tryAggregate: true
  });
  return multicall.call(contractCallContext);
};

export const getTokenContract = (web3, name, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    TOKEN_ABI[name] ? TOKEN_ABI[name] : constants.ERC20_TOKEN_ABI,
    constants.CONTRACT_TOKEN_ADDRESS[chainId][name || 'usdt']
      ? constants.CONTRACT_TOKEN_ADDRESS[chainId][name || 'usdt'].address
      : constants.CONTRACT_TOKEN_ADDRESS_TEMP[chainId][`w${getNativeToken(chainId).toLowerCase()}`].address,
  );
};

export const getAbepContract = (web3, name, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    (name !== getNativeToken(chainId).toLowerCase())
      ? constants.CONTRACT_ABEP_ABI
      : constants.CONTRACT_AETH_ABI,
    constants.CONTRACT_ABEP_ADDRESS[chainId][name || 'usdt']
      ? constants.CONTRACT_ABEP_ADDRESS[chainId][name || 'usdt'].address
      : constants.CONTRACT_ABEP_ADDRESS[chainId].usdt.address,
  );
};

export const getComptrollerContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.CONTRACT_COMPTROLLER_ABI,
    constants.CONTRACT_COMPTROLLER_ADDRESS[chainId],
  );
};

export const getVoteContract = (chainId = 42161) => {
  const web3 = useWeb3();
  return getContract(
    web3,
    chainId,
    constants.CONTRACT_VOTE_ABI,
    constants.CONTRACT_VOTE_ADDRESS[chainId],
  );
};

export const getInterestModelContract = (web3, address, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.CONTRACT_INTEREST_MODEL_ABI,
    address
  );
};

export const getContract = (web3, chainId, abi, address) => {
  const _web3 = web3 ?? useWeb3();
  return new _web3.eth.Contract(abi, address);
}

export const getBalance = (web3, address) => {
  return web3.eth.getBalance(address)
}

export const getStakingContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.STAKING_ABI,
    constants.STAKING_ADDRESS[chainId]
  );
};

export const getReferralContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.REFERRAL_ABI,
    constants.REFERRAL_ADDRESS[chainId]
  );
};

export const getMultiFeeDistributionContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.MULTI_FEE_DISTRIBUTION_ABI,
    constants.MULTI_FEE_DISTRIBUTION[chainId]
  );
};

export const getChefIncentivesControllerContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.CHEF_INCENTIVES_CONTROLLER_ABI,
    constants.CHEF_INCENTIVES_CONTROLLER[chainId]
  );
};

export const getLockZapContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.LOCK_ZAP_ABI[chainId],
    constants.LOCK_ZAP[chainId]
  );
};

export const getLiquidityZapContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.LIQUIDITY_ZAP_ABI,
    constants.LIQUIDITY_ZAP[chainId]
  );
};

export const getCompounderContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.COMPOUNDER_ABI,
    constants.COMPOUNDER[chainId]
  );
};

export const getLpContract = (web3, chainId = 42161) => {
  return getContract(
    web3,
    chainId,
    constants.ERC20_TOKEN_ABI,
    constants.DLP_ADDRESS[chainId]
  );
};

export const methods = {
  call,
  checkGasFee,
  send,
  sendWithValue,
  ethMulticall,
};
