import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import Loading from "components/UI/Loading";
import { getFormatStringFromBignumber } from "utilities/common";
import { useClaimAllChef, useExit, useLockZapInfo, useWithdraw, useZapFromVesting } from "hooks/useLpStaking";
import { useActiveWeb3React } from "hooks";
import { getNativeToken } from "utils";

const StyedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  gap: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  @media only screen and (max-width: 992px) {
    padding: 24px;
  }
  .title {
    padding: 0px 0px 16px 0px;
    gap: 10px;
    border-bottom: 1px solid #1F2937;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
  }
  .content-container {
    display: flex;
    gap: 32px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 24px;
    }
    .divider {
      border-right: 1px solid #1F2937;
      @media only screen and (max-width: 992px) {
        border-bottom: 1px solid #1F2937;
      }
    }
    .sub-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
    .first {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .second {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .title-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .value-container {
        display: flex;
        gap: 16px;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
        }
        .value {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.025em;
        }
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #FFFFFF1A;
        gap: 8px;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
        @media only screen and (max-width: 992px) {
        }
      }
      .zap-container {
        .warning {
          font-size: 12px;
          color: #A392FF;
          font-style: italic;
          text-align: right;
        }
        .pair-container {
          padding-top: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #9CA3AF;
          .value1 {
          }
          .value2 {
            font-weight: 600;
          }
        }
      }
    }
  }
`;

function VestOverview({
  update,
  setUpdate,
  defaultLockIndex,
  allPendingRewards,
  penaltyAmount,
  vested,
  currentlyVesting,
}) {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();

  const { coreValue } = useLockZapInfo(currentlyVesting);
  const { pending: claimAllPending, handleClaimAll } = useClaimAllChef();
  const { pending: exitPending, handleExit } = useExit();
  const { pending: zapPending, handleZapFromVesting } = useZapFromVesting();
  const { pending: withdrawPending, handleWithdraw } = useWithdraw();

  const onClaimAll = async () => {
    const tx = await handleClaimAll();
    if (tx) {
      setUpdate(!update);
    }
  }

  const onExit = async () => {
    const tx = await handleExit();
    if (tx) {
      setUpdate(!update);
    }
  }


  const onZap = async () => {
    const tx = await handleZapFromVesting(!defaultLockIndex ? 1 : defaultLockIndex, coreValue);
    if (tx) {
      setUpdate(!update);
    }
  }

  const onWithdraw = async () => {
    const tx = await handleWithdraw(vested);
    if (tx) {
      setUpdate(!update);
    }
  }

  return (
    <StyedContainer>
      <div className="title">
        {t('Vesting_overview')}
      </div>
      <div className="content-container">
        <div className="first">
          <div className="second">
            <div className="title-container">
              <div className="sub-title">
                {t('Ready_to_vest')}
              </div>
              <div className='value-container'>
                <img src={`/images/coins/ars.png`} alt={'ars'} />
                <div className={'value'}>
                  {getFormatStringFromBignumber(allPendingRewards.div(1e18))}
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                disabled={!account || claimAllPending || allPendingRewards.eq(0)}
                onClick={onClaimAll}
              >
                {(claimAllPending) ? (
                  <div className="flex justify-center items-center">
                    <Loading size={'16px'} margin={'7px'} />
                    {t('Pending')}
                  </div>
                ) : (
                  <>{t('Start_vesting')}</>
                )}
              </button>
              <div className="desc">
                {t('ARS_earned_from_lending')}
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="first">
          <div className="second">
            <div className="title-container">
              <div className="sub-title">
                {t('Currently_vesting')}
              </div>
              <div className='value-container'>
                <img src={`/images/coins/ars.png`} alt={'ars'} />
                <div className={'value'}>
                  {getFormatStringFromBignumber(currentlyVesting.div(1e18))}
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                disabled={!account || zapPending || currentlyVesting.eq(0)}
                onClick={onZap}
              >
                {(zapPending) ? (
                  <div className="flex justify-center items-center">
                    <Loading size={'16px'} margin={'7px'} />
                    {t('Pending')}
                  </div>
                ) : (
                  <>{t('Zap_into_LP')}</>
                )}
              </button>
              <div className="zap-container">
                <div className="warning">
                  {t('No_exit_penalty')}
                </div>
                <div className="pair-container">
                  <div className="value1">{t('Pair_width')}:</div>
                  <div className="value2">
                    {getFormatStringFromBignumber(coreValue.div(1e18))} {getNativeToken(requiredChainId)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            <button
              type="button"
              disabled={!account || exitPending || currentlyVesting.eq(0)}
              onClick={onExit}
            >
              {(exitPending) ? (
                <div className="flex justify-center items-center">
                  <Loading size={'16px'} margin={'7px'} />
                  {t('Pending')}
                </div>
              ) : (
                <>{t('Exit_early')}</>
              )}
            </button>
            <div className="zap-container">
              <div className="pair-container">
                <div className="value1">{t('Penalty')}:</div>
                <div className="value2">
                  {getFormatStringFromBignumber(penaltyAmount.div(1e18))} ARS
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="first">
          <div className="second">
            <div className="title-container">
              <div className="sub-title">
                {t('Vested')}
              </div>
              <div className='value-container'>
                <img src={`/images/coins/ars.png`} alt={'ars'} />
                <div className={'value'}>
                  {getFormatStringFromBignumber(vested.div(1e18))}
                </div>
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                disabled={!account || withdrawPending || vested.eq(0)}
                onClick={onWithdraw}
              >
                {(withdrawPending) ? (
                  <div className="flex justify-center items-center">
                    <Loading size={'16px'} margin={'7px'} />
                    {t('Pending')}
                  </div>
                ) : (
                  <>{t('Withdraw')}</>
                )}
              </button>
            </div>
          </div>
          <div className="desc">
            {t('ARS_that_has_completed')}
          </div>
        </div>
      </div>
    </StyedContainer>
  )
}

export default VestOverview;