import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import Layout from '../../layouts/MainLayout/MainLayout';
import DashboardInfo from './DashboardInfo';
import DataTable from '../../components/common/DataTable';
import SupplyWithdrawModal from '../../components/common/SupplyWithdrawModal';
import BorrowRepayModal from '../../components/common/BorrowRepayModal';

import { useActiveWeb3React } from '../../hooks';
import { fillArray, isCoreNetwork } from '../../utils';
import { getFormatStringFromBignumber } from '../../utilities/common';
import DashboardMobileItem from './DashboardMobileItem';
import { useWindowSize } from 'hooks/useWindowSize';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 992px) {
    gap: 32px;
  }
  .assets {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .apy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    img {
      width: 16px;
      height: 16px;
    }
    .sub-apy-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%);
      border: 1px solid;
      border-color: rgba(14, 165, 233, 0.5);
      padding: 4px;
      border-radius: 999px;
      gap: 4px;
    }
  }
  .total-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;
    span {
      color: rgba(156, 163, 175, 1);
    }
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    @media (max-width: 992px) {
      justify-content: space-between;
    }
    .supply {
      padding: 4px 16px 4px 16px;
      border-radius: 999px;
      border: 1px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media (max-width: 992px) {
        flex: 1;
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
      }
    }
    .borrow {
      padding: 4px 16px 4px 16px;
      border-radius: 999px;
      border: 1px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media (max-width: 992px) {
        flex: 1;
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
      }
    }
  }
`

function Dashboard() {
  const assetList = useSelector((state) => state.account.setting.assetList);
  const settingAccount = useSelector((state) => state.account.setting.account);

  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();

  const [selectedRecord, setSelectedRecord] = useState({});

  const [supplyWithdrawOpen, setSupplyWithdrawOpen] = useState(false);
  const [borrowRepayOpen, setBorrowRepayOpen] = useState(false);

  const marketColumns = React.useMemo(
    () => [
      {
        Header: t("Asset"),
        accessor: 'Asset',
      },
      {
        Header: `${t("Supply")} %`,
        accessor: 'SupplyApy',
      },
      {
        Header: `${t("Borrow")} %`,
        accessor: 'BorrowApy',
      },
      {
        Header: t("Total_Supply"),
        accessor: 'TotalSupply',
      },
      {
        Header: t("Total_Borrow"),
        accessor: 'TotalBorrow',
      },
      {
        Header: t("Actions"),
        accessor: 'Actions',
      },
    ],
    [t],
  );

  const loadingData = React.useMemo(
    () =>
      fillArray(
        {
          Asset: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-14 bg-lightGray w-full flex items-center px-3 py-3" />
            </div>
          ),
          SupplyApy: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-14 bg-lightGray w-full flex items-center px-3 py-3" />
            </div>
          ),
          BorrowApy: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-14 bg-lightGray w-full flex items-center px-3 py-3" />
            </div>
          ),
          TotalSupply: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-14 bg-lightGray w-full flex items-center px-3 py-3" />
            </div>
          ),
          TotalBorrow: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-14 bg-lightGray w-full flex items-center px-3 py-3" />
            </div>
          ),
          Actions: (
            <div className="h-13 flex items-center justify-center">
              <div className="animate-pulse rounded-lg w-18 bg-lightGray w-full flex items-center px-3 py-3 justify-end" />
            </div>
          ),
        },
        isCoreNetwork(requiredChainId) ? 4 : 10,
      ),
    [requiredChainId],
  );

  const allMarketData = React.useMemo(() => {
    if (assetList.length === 0) {
      return loadingData;
    }

    return assetList.map((asset) => {
      return {
        Asset: (
          <div className="assets" >
            <img src={asset.img} alt={asset.name} />
            <div>{asset.name}</div>
          </div>
        ),
        SupplyApy: (
          <div className="apy-container">
            <div className="">{getFormatStringFromBignumber(new BigNumber(asset.supplyApy))}%</div>
            <div className='sub-apy-container'>
              <img src={`/images/coins/ars.png`} alt={'ars'} />
              <div>{getFormatStringFromBignumber(new BigNumber(asset.supplyAdditionalApy))}%</div>
            </div>
          </div>
        ),
        BorrowApy: (
          <div className="apy-container">
            <div className="">{getFormatStringFromBignumber(new BigNumber(asset.borrowApy))}%</div>
            <div className='sub-apy-container'>
              <img src={`/images/coins/ars.png`} alt={'ars'} />
              <div>{getFormatStringFromBignumber(new BigNumber(asset.borrowAdditionalApy))}%</div>
            </div>
          </div>
        ),
        TotalSupply: (
          <div className="total-container">
            <div>
              ${getFormatStringFromBignumber(new BigNumber(asset.totalSupplyUsd))}
            </div>
            <span>
              {getFormatStringFromBignumber(new BigNumber(asset.totalSupplyUsd).div(asset.tokenPrice))} {asset.symbol}
            </span>
          </div>
        ),
        TotalBorrow: (
          <div className="total-container">
            <div>
              ${getFormatStringFromBignumber(new BigNumber(asset.totalBorrowsUsd))}
            </div>
            <span>
              {getFormatStringFromBignumber(new BigNumber(asset.totalBorrows))} {asset.symbol}
            </span>
          </div>
        ),
        Actions: (
          <div className='action-container'>
            <div className="supply"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => {
                if (account && settingAccount === account) {
                  handleSupplyClickRow(asset)
                }
              }}
            >
              {t("Supply")}
            </div>
            <div className="borrow"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => {
                if (account && settingAccount === account) {
                  handleBorrowClickRow(asset)
                }
              }}
            >
              {t("Borrow")}
            </div>
          </div>
        ),
      };
    });
  }, [assetList, settingAccount, account, t]);

  const handleSupplyClickRow = (row) => {
    setSelectedRecord(row);
    setSupplyWithdrawOpen(true);
  };

  const handleBorrowClickRow = (row) => {
    setSelectedRecord(row);
    setBorrowRepayOpen(true);
  };

  return (
    <Layout>
      <StyledContainer className='flex flex-col items-center justify-center'>
        <DashboardInfo />
        {
          width > 992 ?
            (
              <DataTable columns={marketColumns} data={allMarketData} />
            )
            :
            (
              <DashboardMobileItem
                assetList={assetList}
                account={account}
                settingAccount={settingAccount}
                handleSupplyClickRow={handleSupplyClickRow}
                handleBorrowClickRow={handleBorrowClickRow}
              />
            )
        }
      </StyledContainer>
      {
        supplyWithdrawOpen &&
        <SupplyWithdrawModal
          open={supplyWithdrawOpen}
          record={selectedRecord}
          onCloseModal={() => setSupplyWithdrawOpen(false)}
        />
      }
      {
        borrowRepayOpen &&
        <BorrowRepayModal
          open={borrowRepayOpen}
          record={selectedRecord}
          onCloseModal={() => setBorrowRepayOpen(false)}
        />
      }
    </Layout>
  );
}

export default Dashboard;
