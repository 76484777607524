/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CartesianGrid, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, LineChart } from "recharts";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import styled from "styled-components";

import { getInterestModelContract, methods } from "../../utilities/ContractService";
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import { CONTRACT_ABEP_ABI, CONTRACT_ABEP_ADDRESS, CONTRACT_INTEREST_MODEL_ABI } from "utilities/constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  .interest-title-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
  }
  .chart-title-container {
    display: flex;
    justify-content: center;
    algin-items: center;
    gap: 24px;
    .title-item {
      display: flex;
      gap: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      .suppy-round {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background: #7DD3FC;
      }
    }
  }
  .percent-wrapper {
    position: relative;
    width: 100%;
    border-radius: 10px;
    padding: 25px 0px 0px;
    .line {
      border-top: 1px solid #FFFFFF80;
    }
    .chart-line {
      position: absolute;
      border: 1px solid #FFFFFF1A;
      margin-top: 50px;
      height: 1px;
      width: 100%;
    }
    .ticker-line-static {
      position: absolute;
      width: 1px;
      height: 15px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    }
    .ticker-line {
      position: absolute;
      width: 1px;
      height: 160px;
      background: #FFFFFF;
    }
    .current-percent {
      position: absolute;
      p {
        margin-top: 20px;
        margin-left: -20px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .ticker-percent {
      position: absolute;
      top: 0px;
      margin-left: -18px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0em;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    }
    .custom-tooltip {
      display: flex;
      flex-direction: column;
      padding: 4px 8px 4px 8px;
      border-radius: 5px;
      border: 1px solid #FFFFFF1A;
      gap: 20px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
      font-weight: 400;
      font-size: 8px;
      line-height: 8px;
      letter-spacing: 0em;
      color: FFF;
      .custom-tooltip-item {
        display: flex;
        align-items: center;
        gap: 8px;
        .suppy-round {
          width: 12px;
          height: 12px;
          border-radius: 2px;
          background: #7DD3FC;
        }
      }
    }
  }

  .recharts-cartesian-grid {
    opacity: 0;
  }
  .recharts-responsive-container {
    .recharts-surface {
      // margin-top: 40px;
    }
  }
  @media only screen and (max-width: 992px) {
  }
`;

const InterestRateModel = ({ currentAsset }) => {
  const decimals = useSelector((state) => state.account.setting.decimals);
  const markets = useSelector((state) => state.account.setting.markets);

  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();

  const [isCalled, setIsCalled] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [tickerPos, setTickerPos] = useState(null);
  const [percent, setPercent] = useState(null);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [currentPos, setCurrentPos] = useState(0);
  const [maxY, setMaxY] = useState(0);

  const CustomizedAxisTick = ({ x, y }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16}>
        </text>
      </g>
    );
  };

  const getGraphData = async asset => {
    setIsCalled(true);
    const data = [];
    const marketInfo = markets.find(
      item => item.underlyingSymbol.toLowerCase() === asset.toLowerCase()
    );

    const abepCalls = [{
      reference: 'aTokenData',
      contractAddress: CONTRACT_ABEP_ADDRESS[requiredChainId][currentAsset].address,
      abi: CONTRACT_ABEP_ABI,
      calls: [{
        reference: 'interestRateModel',
        methodName: 'interestRateModel',
        methodParameters: []
      },
      {
        reference: 'getCash',
        methodName: 'getCash',
        methodParameters: []
      }]
    }]
    const abepCallResult = await methods.ethMulticall(web3, abepCalls, requiredChainId);

    const interestRateModel = abepCallResult.results.aTokenData.callsReturnContext[0].returnValues[0];
    const cash = new BigNumber(abepCallResult.results.aTokenData.callsReturnContext[1].returnValues[0].hex)
      .div(new BigNumber(10).pow(decimals[asset].token))

    const interestModelContract = getInterestModelContract(web3, interestRateModel, requiredChainId);

    const borrows = new BigNumber(marketInfo.totalBorrows2);
    const reserves = new BigNumber(marketInfo.totalReserves || 0).div(
      new BigNumber(10).pow(decimals[asset].token)
    );
    const currentUtilizationRate = borrows.div(
      cash.plus(borrows).minus(reserves)
    );

    const tempCurrentPercent = parseInt(
      +currentUtilizationRate.toString(10) * 100,
      10
    );
    setCurrentPercent(tempCurrentPercent || 0);
    const lineElement = document.getElementById('line');
    if (lineElement) {
      setCurrentPos((lineElement.clientWidth * tempCurrentPercent) / 100);
    }
    const urArray = [];
    for (let i = 1; i <= 100; i += 1) {
      urArray.push(i / 100);
    }
    
    const contractCallContext = [];
    urArray.map((ur, index) => {
      contractCallContext.push(
        {
          reference: `borrowRes${index}`,
          contractAddress: interestRateModel,
          abi: CONTRACT_INTEREST_MODEL_ABI,
          calls: [
            {
              methodName: 'getBorrowRate',
              methodParameters: [
                new BigNumber(1 / ur - 1)
                  .times(1e4)
                  .dp(0)
                  .toString(10),
                1e4,
                0
              ]
            }
          ]
        },
        {
          reference: `supplyRes${index}`,
          contractAddress: interestRateModel,
          abi: CONTRACT_INTEREST_MODEL_ABI,
          calls: [
            {
              methodName: 'getSupplyRate',
              methodParameters: [
                new BigNumber(1 / ur - 1)
                  .times(1e4)
                  .dp(0)
                  .toString(10),
                1e4,
                0,
                marketInfo.reserveFactor.toString(10)
              ]
            }
          ]
        }
      );
    });
    const results = await methods.ethMulticall(web3, contractCallContext, requiredChainId);
    const borrowRes = [];
    const supplyRes = [];
    urArray.map((ur, index) => {
      borrowRes.push(
        results.results[`borrowRes${index}`].callsReturnContext[0]
          .returnValues[0].hex
      );
      supplyRes.push(
        results.results[`supplyRes${index}`].callsReturnContext[0]
          .returnValues[0].hex
      );
    });

    urArray.forEach((ur, index) => {
      // supply apy, borrow apy
      const blocksPerDay = 28800;
      const daysPerYear = 365;
      const mantissa = 1e18;
      const supplyBase = new BigNumber(supplyRes[index])
        .div(mantissa)
        .times(blocksPerDay)
        .plus(1);
      const borrowBase = new BigNumber(borrowRes[index])
        .div(mantissa)
        .times(blocksPerDay)
        .plus(1);
      const supplyApy = supplyBase
        .pow(daysPerYear - 1)
        .minus(1)
        .times(100);
      const borrowApy = borrowBase
        .pow(daysPerYear - 1)
        .minus(1)
        .times(100);

      data.push({
        percent: ur,
        supplyApy: supplyApy.dp(2, 1).toString(10),
        borrowApy: borrowApy.dp(2, 1).toString(10)
      });
    });

    setMaxY(Number(data.slice(-1)[0].borrowApy) + 1);
    setGraphData(data);
  };

  useEffect(() => {
    setIsCalled(false);
  }, [currentAsset, requiredChainId]);

  useEffect(() => {
    if (
      currentAsset &&
      markets.length > 0 &&
      decimals &&
      !isCalled
    ) {
      getGraphData(currentAsset);
    }
  }, [isCalled, requiredChainId, markets, decimals]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length !== 0) {
      return (
        <div className="label custom-tooltip">
          <div className="custom-tooltip-item">
            <div className="suppy-round" />
            <div>{t("Supply_APY")}</div>
            <div>{`${new BigNumber(payload[0].value).dp(8, 1)}%`}</div>
          </div>
          <div className="label custom-tooltip-item">
            <div className="suppy-round" style={{ background: "#C4B5FD" }} />
            <div>{t("Borrow APY")}</div>
            <div>{`${new BigNumber(payload[1].value).dp(8, 1)}%`}</div>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleMouseMove = e => {
    const graphElement = document.getElementById('percent-wrapper');
    const lineElement = document.getElementById('line');
    if (graphElement && lineElement) {
      const x = e.pageX - graphElement.offsetLeft;
      const tempPercent = (x * 100) / lineElement.clientWidth;
      if (tempPercent >= 0 && tempPercent <= 100) {
        setPercent(parseInt(tempPercent, 10));
        setTickerPos(e.pageX - graphElement.offsetLeft);
      } else if (tempPercent < 0) {
        setPercent(0);
      } else if (tempPercent >= 100) {
        setPercent(100);
      }
      setCurrentPos((lineElement.clientWidth * currentPercent) / 100);
    }
  };

  return (
    <Wrapper className="">
      <div className="interest-title-container">
        <div>
          {t("Interest_Rate_Model")}
        </div>
        <span>
          {t("Utilization_vs_APY")}
        </span>
      </div>
      <div
        id="percent-wrapper"
        className="percent-wrapper"
        onMouseMove={handleMouseMove}
      >
        <div id="line" className="line" />
        <div id="line1" className="chart-line" style={{ marginTop: 40 }} />
        <div id="line2" className="chart-line" style={{ marginTop: 80 }} />
        <div id="line3" className="chart-line" style={{ marginTop: 120 }} />
        <div id="line4" className="chart-line" style={{ marginTop: 160 }} />
        {graphData.length !== 0 && (
          <div className="current-percent" style={{ left: currentPos }}>
            <p>{t("Current")}</p>
          </div>
        )}
        <div
          className="ticker-percent"
          style={{ left: tickerPos || currentPos }}
        >
          {percent === null ? currentPercent : percent} %
        </div>
        <div
          id="ticker-line-static"
          className="ticker-line-static"
          style={{ left: currentPos }}
        />
        <div
          id="ticker-line"
          className="ticker-line"
          style={!tickerPos ? { display: "none" } : { left: tickerPos }}
        />
        <ResponsiveContainer height={160}>
          <LineChart
            data={graphData}
            height={160}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <defs>
              <linearGradient id="barRedColor" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#A78BFA" />
                <stop offset="100%" stopColor="#A78BFA" />
              </linearGradient>
              <linearGradient id="barGreenColor" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#38BDF8" />
                <stop offset="100%" stopColor="#38BDF8" />
              </linearGradient>
            </defs>
            <CartesianGrid />
            <XAxis
              dataKey="percent"
              tickLine={false}
              axisLine={false}
              tick={<CustomizedAxisTick />}
            />
            <YAxis domain={[0, maxY]} hide />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Line
              type="monotone"
              dot={false}
              dataKey="borrowApy"
              stroke="url(#barRedColor)"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dot={false}
              dataKey="supplyApy"
              stroke="url(#barGreenColor)"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="chart-title-container">
        <div className="title-item">
          <div className="suppy-round"></div>
          <span>{t("Supply")}</span>
        </div>
        <div className="title-item">
          <div className="suppy-round" style={{ background: "#C4B5FD" }}></div>
          <span>{t("Borrow")}</span>
        </div>
      </div>
    </Wrapper>
  )
}

export default InterestRateModel;
