import { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import ZapModal from './ZapModal';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useActiveWeb3React } from 'hooks';
import BigNumber from 'bignumber.js';

const StyledConatiner = styled.div`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .left-container {
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      color: #F87171;
      @media only screen and (max-width: 992px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .title-active {
      color: #34D399;
    }
    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      max-width: 500px;
      .value1 {
        color: #F87171;
      }
      @media only screen and (max-width: 992px) {
        font-size: 14px;
        line-height: 20px; 
      }
    }
  }
  .zap-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 96px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 24px;
    }
    .value-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      .value-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
      }
      .value-content {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.025em;     
        span {
          font-size: 16px;
          color: #9CA3AF;
        }
      }
      .progress-bar {
        position: relative;
        width: 100%;
        height: 4px;
        border-radius: 999px;
        background: #FFFFFF33;
        .progress-active-bar {
          height: 4px;
          background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
          border-radius: 999px;
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #FFFFFF1A;
        gap: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }
      .active-background {
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        border: 1px solid #0EA5E9;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
      }
    }
  }
`;

function Emission({
  update,
  setUpdate,
  isEligibleForRewards,
  lockedUsdValue,
  requiredUsdValue,
  myDLpLocked,
  dLpPrice,
  lockedSupplyWithMultiplier,
  LockAprData,
  annualReward,
}) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();

  const [showZapModal, setShowZapModal] = useState(false);

  const progressWidth = useMemo(() => {
    if (requiredUsdValue.eq(0)) return 0;
    if (lockedUsdValue.gte(requiredUsdValue.times(4))) return 100;
    return parseInt(lockedUsdValue.times(100).div(requiredUsdValue.times(4)).toString());
  }, [lockedUsdValue, requiredUsdValue]);

  const awayProgress = useMemo(() => {
    if (requiredUsdValue.eq(0)) return new BigNumber(100);
    if (lockedUsdValue.gte(requiredUsdValue.times(4))) return new BigNumber(0);
    return new BigNumber(100).minus(lockedUsdValue.div(requiredUsdValue).times(100))
  }, [lockedUsdValue, requiredUsdValue]);

  return (
    <>
      <StyledConatiner>
        {
          isEligibleForRewards ? (
            <div className='left-container'>
              <h1 className='title title-active'>{t('Emissions_Active')}</h1>
              <div className='desc'>
                {t('Emissions_Active_Desc')}
              </div>
            </div>
          ) : (
            <div className='left-container'>
              <h1 className='title'>{t('Emissions_Inactive')}</h1>
              <div className='desc'>
                {t('Emissions_Inactive_Desc_1')}
                <span className='value1'> {getFormatStringFromBignumber(awayProgress, 0)}% </span>
                {t('Emissions_Inactive_Desc_2')}
              </div>
            </div>
          )
        }
        <div className='zap-container'>
          <div className='value-container'>
            <div className='value-desc'>{t('dLP_required_to')}</div>
            <div className='value-content'>
              ${getFormatStringFromBignumber(lockedUsdValue.div(1e8), 2, 2)}
              <span>/${getFormatStringFromBignumber(requiredUsdValue.div(1e8), 2, 2)}</span>
            </div>
            <div className='progress-bar'>
              <div className='progress-active-bar'
                style={{ width: `${progressWidth}%` }}
              />
            </div>
          </div>
          <div className='button-container'>
            <button
              type="button"
              className={'button active-background'}
              disabled={!account}
              onClick={() => {
                if (account) {
                  setShowZapModal(true);
                }
              }}
            >
              {t('Zap_into_LP')}
            </button>
            <button
              type="button"
              className={'button'}
              onClick={() => {
                window.open("https://docs.aquarius.loan/lp-staking", "_blank")
              }}
            >
              {t('Learn_More')}
            </button>
          </div>
        </div>
      </StyledConatiner>
      {
        showZapModal && (
          <ZapModal
            open={showZapModal}
            setOpen={setShowZapModal}
            update={update}
            setUpdate={setUpdate}
            lockedUsdValue={lockedUsdValue}
            requiredUsdValue={requiredUsdValue}
            dLpPrice={dLpPrice}
            myDLpLocked={myDLpLocked}
            lockedSupplyWithMultiplier={lockedSupplyWithMultiplier}
            LockAprData={LockAprData}
            annualReward={annualReward}
          />
        )
      }
    </>
  )
}

export default Emission;