import { ReactComponent as TickGreen } from '../../../assets/icons/tickGreenDash.svg';
import { ReactComponent as TickGray } from '../../../assets/icons/tickGrayDash.svg';
import lineBg from '../../../assets/img/vote/line-bg.svg';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const Styles = styled.div`
  // flex: 1;
  width: 25%;
  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  div.card-container {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    backdrop-filter: blur(4px);
    border-radius: 4px;
  }
  .lineBg {
    background-image: url(${lineBg});
    background-repeat: no-repeat;
    background-position: 2.5em;
    background-size: 1px 79%;
  }
`

const STATUSES = ['Pending', 'Active', 'Succeeded', 'Queued', 'Executed'];

const ProposalHistory = ({ proposalInfo }) => {
  const { t } = useTranslation();

  const getStepNumber = () => {
    if (proposalInfo.state === 'Defeated' || proposalInfo.state === 'Canceled') return 2;
    return STATUSES.findIndex((s) => s === proposalInfo.state);
  };

  return (
    <Styles className="py-10 mt-8">
      <div className="text-midBlue text-4xl font-bold">{t("Proposal_history")}</div>
      <div className="mt-8 lineBg">
        <div className={"flex items-center p-6 text-sm"}>
          <div className={"flex flex-1 items-center justify-start"}>
            {getStepNumber() >= 0 ? (
              <TickGreen />
            ) : (
              <TickGray />
            )}
            <div className='flex flex-col ml-3'>
              <span className={`text-2xl font-bold 
              ${(getStepNumber() >= 0 && proposalInfo.createdTimestamp) ? 'text-midBlue' : 'text-gray'}`}>
                {t("Created")}
              </span>
              <span className={"text-lg font-bold mt-1.5"}>
                {proposalInfo.createdTimestamp
                  ? moment(proposalInfo.createdTimestamp * 1000).format('LLL')
                  : ''}
              </span>
            </div>
          </div>
        </div>

        <div className={"flex items-center p-6 text-sm"}>
          <div className={"flex flex-1 items-center justify-start"}>
            {getStepNumber() >= 1 ? (
              <TickGreen />
            ) : (
              <TickGray />
            )}
            <div className='flex flex-col ml-3'>
              <span className={`text-2xl font-bold 
              ${(getStepNumber() >= 1 && proposalInfo.startTimestamp) ? 'text-midBlue' : 'text-gray'}`}>
                {t("Active")}
              </span>
              <span className={"text-lg font-bold mt-1.5"}>
                {proposalInfo.startTimestamp
                  ? moment(proposalInfo.startTimestamp * 1000).format('LLL')
                  : ''}
              </span>
            </div>
          </div>
        </div>

        <div className={"flex items-center p-6 text-sm"}>
          <div className={"flex flex-1 items-center justify-start"}>
            {proposalInfo.state === 'Canceled' ||
              proposalInfo.state === 'Defeated'
              ? (<TickGray />)
              : getStepNumber() >= 2
                ? (<TickGreen />)
                : (<TickGray />)
            }
            <div className='flex flex-col ml-3'>
              <span className={`text-2xl font-bold 
              ${proposalInfo.state === 'Canceled' ||
                  proposalInfo.state === 'Defeated'
                  ? 'text-gray'
                  : (getStepNumber() >= 2 && proposalInfo.endTimestamp)
                    ? 'text-midBlue'
                    : 'text-gray'
                }`}>
                {proposalInfo.state === 'Canceled' || proposalInfo.state === 'Defeated'
                  ? `${proposalInfo.state === 'Defeated' ? t('Failed') : t('Canceled')}`
                  : `${proposalInfo.state === 'Succeeded' ? t('Succeeded') : t('Succeed')}`}
              </span>
              <span className={"text-lg font-bold mt-1.5"}>
                {proposalInfo.endTimestamp
                  ? moment(proposalInfo.endTimestamp * 1000).format('LLL')
                  : `${proposalInfo.cancelTimestamp
                    ? moment(proposalInfo.cancelTimestamp * 1000).format('LLL')
                    : ''
                  }`}
              </span>
            </div>
          </div>
        </div>

        {proposalInfo.state !== 'Defeated' && proposalInfo.state !== 'Canceled' && (
          <div className={"flex items-center p-6 text-sm"}>
            <div className={"flex flex-1 items-center justify-start"}>
              {getStepNumber() >= 3
                ? (<TickGreen />)
                : (<TickGray />)
              }
              <div className='flex flex-col ml-3'>
                <span className={`text-2xl font-bold 
                ${(getStepNumber() >= 3 || proposalInfo.queuedTimestamp)
                    ? 'text-midBlue'
                    : 'text-gray'}`}>
                  {proposalInfo.state === 'Queued' ? t('Queued') : t('Queue')}
                </span>
                <span className={"text-lg font-bold mt-1.5"}>
                  {proposalInfo.queuedTimestamp
                    ? moment(proposalInfo.queuedTimestamp * 1000).format('LLL')
                    : ''}
                </span>
              </div>
            </div>
          </div>
        )}

        {proposalInfo.state !== 'Defeated' && proposalInfo.state !== 'Canceled' && (
          <div className={"flex items-center p-6 text-sm mt-5"}>
            <div className={"flex flex-1 items-center justify-start"}>
              {getStepNumber() >= 4
                ? (<TickGreen />)
                : (<TickGray />)
              }
              <div className='flex flex-col ml-3'>
                <span className={`text-2xl font-bold 
                ${(getStepNumber() >= 4)
                    ? 'text-lightGreen'
                    : 'text-gray'}`}>
                  <div className="tooltip relative">
                    <div className={`${(getStepNumber() >= 3 && proposalInfo.executedTimestamp)
                      ? 'text-midBlue'
                      : 'text-gray'} text-xl flex items-center`}>
                      {proposalInfo.state === 'Expired'
                        ? t('Expired')
                        : `${proposalInfo.state === 'Executed' ? t('Executed') : t('Execute')}`}
                      {
                        proposalInfo?.eta !== 0 && <img
                          className="ml-3 tooltip-label"
                          src={require('../../../assets/img/vote/info.svg').default}
                          alt=""
                        />
                      }
                      <span className="label" style={{ left: '-35%', bottom: '30px' }}>
                        {t("Executable_Date")}: {moment(proposalInfo?.eta * 1000).format('LLLL')}
                      </span>
                    </div>
                  </div>
                </span>
                <span className={"text-lg font-bold mt-1.5"}>
                  {proposalInfo.executedTimestamp
                    ? moment(proposalInfo.executedTimestamp * 1000).format('LLL')
                    : ''}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Styles>
  );
};

ProposalHistory.defaultProps = {
  proposalInfo: {},
};

export default ProposalHistory;
