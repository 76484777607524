import { withRouter } from "react-router-dom";
import { compose } from "redux";
import commaNumber from "comma-number";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const Styles = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    backdrop-filter: blur(4px);
    border-radius: 4px;

    svg.user-icon {
        path {
            fill: #000000;
        }
    }
    .custom-text-size {
        font-size: 3rem;
        line-height: 1;
    }
    @media (max-width: 890px) {
        .custom-text-size {
            font-size: 2rem;
            line-height: 1;
        }
    }
`

const format = commaNumber.bindWith(',', '.');

const Holding = ({ address, holdingInfo }) => {
    const { t } = useTranslation();
    return (
        <Styles className="col-span-3 py-5 px-8 text-black">
            <div className="text-2xl text-midBlue font-semiBold border-b border-solid border-gray4 pb-3">
                {t("Holding")}
            </div>
            <div className="flex justify-between items-center px-2.5 flex-wrap">
                <div className="border-b border-solid border-gray4 pt-6 pb-1.5 flex-1">
                    <div className="text-lg">Aquarius {t("Balance")}</div>
                    <div className="custom-text-size text-gray3 mt-6">{format(holdingInfo.balance || '0.0000')}</div>
                </div>
                <div className="border-b border-solid border-gray4 pt-6 pb-1.5 flex-1">
                    <div className="flex justify-between">
                        <div className="text-lg">{t("Votes")}</div>
                        <div className="text-lightRed text-lg">
                            {holdingInfo.delegateCount || 0}
                        </div>
                    </div>
                    <div className="text-gray3 mt-6 custom-text-size">
                        {format(holdingInfo.votes || '0.0000')}
                    </div>
                </div>
            </div>
            <div className="pb-2 mt-11 flex justify-end items-center">
                <div className="text-sm">{t("Delegating_To")}</div>
                <div className="text-lg text-lightBlue ml-5">
                    {holdingInfo.delegates !==
                        '0x0000000000000000000000000000000000000000' &&
                        holdingInfo.delegates !== address.toLowerCase()
                        ? t("Delegated")
                        : t("Undelegated")}
                </div>
            </div>
        </Styles>
    )
}



export default compose(withRouter)(Holding);
