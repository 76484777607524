import { useCallback, useState } from "react";
import { useActiveWeb3React, useWeb3 } from "hooks";
import { useReferralContract } from "./useContract";
import { useTranslation } from "react-i18next";
import { methods } from "utilities/ContractService";
import { NotificationManager } from "react-notifications";
import { getNativeToken } from "utils";

export const useReferralClaimCallback = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useReferralContract(requiredChainId);

  const handleClaim = useCallback(
    async (amount, nonce, code, v, r, s) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.claim,
          [
            account,
            amount,
            nonce,
            code,
            v,
            r,
            s
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.claim(
          account,
          amount,
          nonce,
          code,
          v,
          r,
          s
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Claim_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('ReferralClaim had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleClaim, pending };
};