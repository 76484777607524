export default {
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  appUrl: process.env.REACT_APP_URL || 'https://app.aquarius.loan',
  apiCoreUrl: process.env.REACT_APP_CORE_API,
  apiArbitrumUrl: process.env.REACT_APP_ARBITRAUM_API,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  walletConnnectID: process.env.REACT_APP_WALLET_CONNECT_ID,
  coreWeb3Provider: process.env.REACT_APP_CORE_WEB3_PROVIDER,
  coreWeb3TestProvider: process.env.REACT_APP_CORE_WEB3_TEST_PROVIDER,
  arbitrumWeb3Provider: process.env.REACT_APP_ARBITRAUM_WEB3_PROVIDER,
  arbitrumWeb3TestProvider: process.env.REACT_APP_ARBITRAUM_WEB3_TEST_PROVIDER,
};
