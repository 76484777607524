import React, {useMemo} from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

const ColorPalette = {
    'green': {
        stroke: "#2563EB",
        area: "#0EA5E9"
    },
    'red': {
        stroke: "#FD5353",
        area: "rgba(253, 83, 83, 0.15)"
    }
}

const APYSparkline = ({
    color,
    data
}) => {

    const selectedPalette = useMemo(() => {
        return ColorPalette[color] || ColorPalette['green'];
    }, [color])

    return (
        <div className="chart-class">
            <ResponsiveContainer>
                <AreaChart
                    data={data}
                    stroke={selectedPalette?.stroke}
                    strokeWidth={5}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <Area type="monotone" dataKey="borrowArsApy" stroke={selectedPalette?.stroke} fill={selectedPalette?.area} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

APYSparkline.defaultProps = {
    color: "green",
    data: []
}
export default APYSparkline;

