import { useEffect, useState, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { useActiveWeb3React, useWeb3 } from './';
import * as constants from 'utilities/constants';
import { methods } from 'utilities/ContractService';
import useRefresh from './useRefresh';

export const useBalance = (forceUpdate) => {
  const web3 = useWeb3();
  const { account, requiredChainId, } = useActiveWeb3React();
  const [arsBalance, setArsBalance] = useState(new BigNumber(0));
  const [arsStakingAllowance, setArsStakingAllowance] = useState(
    new BigNumber(0)
  );

  const { fastRefresh } = useRefresh();

  const calls = useMemo(() => {
    return [
      {
        reference: 'balance',
        contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address,
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'balanceOf',
            methodParameters: [account]
          }
        ]
      },
      {
        reference: 'allowance',
        contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address,
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'allowance',
            methodParameters: [account, constants.STAKING_ADDRESS[requiredChainId]]
          }
        ]
      }
    ];
  }, [account, requiredChainId]);

  useEffect(() => {
    async function checkBalance() {
      if (account) {
        const data = await methods.ethMulticall(web3, calls, requiredChainId);
        setArsBalance(
          new BigNumber(
            data.results.balance.callsReturnContext[0].returnValues[0].hex
          )
        );
        setArsStakingAllowance(
          new BigNumber(
            data.results.allowance.callsReturnContext[0].returnValues[0].hex
          )
        );
      } else {
        setArsBalance(new BigNumber(0));
        setArsStakingAllowance(new BigNumber(0));
      }
    }

    checkBalance();
  }, [account, requiredChainId, fastRefresh, methods.ethMulticall, web3, forceUpdate]);

  return {
    arsBalance,
    arsStakingAllowance
  };
};
