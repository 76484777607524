import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import SVG from 'react-inlinesvg';
import Modal from '../UI/Modal';
import { useWeb3React } from '@web3-react/core';
import ExternalLinkIcon from '../../assets/img/common/externalLink.svg';
import styled from 'styled-components';

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 40px;
  @media only screen and (max-width: 992px) {
    padding: 24px 16px;
  }
  .risk-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #9CA3AF;
  }
  .item-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item {
      display: flex;
      cursor: pointer;
      .input-container {
        margin: 5px 10px 0 0;
        input {
          width: 18px;
          height: 18px;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
      }
      .item-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
  }
  .link-item-container {
    .link-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg.link {
        width: 15px;
        path {
          fill: #0EA5E9;
        }
      }
      .item-text {
        padding-right: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #0EA5E9;
      }
    }
  }
  .button-container {
    display: flex;
    gap: 24px;
    @media only screen and (max-width: 992px) {
      width: 100%;
      justify-content: space-between;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 40px;
      border-radius: 4px;
      gap: 10px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
    .button1 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 40px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
  }
`

function LendingRiskModal() {
  const { t } = useTranslation();
  const { account, connector } = useWeb3React();

  const [showModal, setShowModal] = useState(false);
  const [lendingRisks, setLendingRiskModal] = useState(JSON.parse(localStorage.getItem('LendingRisk') || '{}'));

  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);


  useEffect(() => {
    let timer;
    if (account) {
      timer = setTimeout(() => {
        setShowModal(!lendingRisks[account]);
      }, 1000);
    } else {
      setShowModal(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [account])

  const continueWithRisk = async () => {
    setLendingRiskModal({ ...lendingRisks, [account]: true });
    localStorage.setItem('LendingRisk', JSON.stringify({ ...lendingRisks, [account]: true }))
    setShowModal(false);
  }

  const logout = async () => {
    // window.localStorage.removeItem(connectorLocalStorageKey);
    // if (account) {
    //   if (connector?.deactivate) {
    //     void connector?.deactivate()
    //   } else {
    //     void connector?.resetState()
    //   }
    // }

    setShowModal(false);
  };

  const content = () => {
    return (
      <Styles>
        <div className="risk-title">{t('Leding_Risk')}</div>
        <div className='item-container'>
          <div className='item'
            onClick={() => setCheck1(!check1)}
          >
            <div className='input-container'
            >
              <input type='checkbox' checked={check1} onChange={() => { }} />
            </div>
            <div className='item-text'>
              {t('Leding_Risk_1')}
            </div>
          </div>
          <div className='item'
            onClick={() => setCheck2(!check2)}
          >
            <div className='input-container'>
              <input type='checkbox' checked={check2} onChange={() => { }} />
            </div>
            <div className='item-text'>
              {t('Leding_Risk_2')}
            </div>
          </div>
          <div className='item'
            onClick={() => setCheck3(!check3)}
          >
            <div className='input-container'>
              <input type='checkbox' checked={check3} onChange={() => { }} />
            </div>
            <div className='item-text'>
              {t('Leding_Risk_3')}
            </div>
          </div>
          <div className='item'
            onClick={() => setCheck4(!check4)}
          >
            <div className='input-container'>
              <input type='checkbox' checked={check4} onChange={() => { }} />
            </div>
            <div className='item-text'>
              {t('Leding_Risk_4')}
            </div>
          </div>
        </div>
        <div className='link-item-container'>
          <div className='link-item'
            onClick={() => window.open('https://t.me/AquariusLoan', '_blank')}
          >
            <div className='item-text'>
              {t('Leding_Risk_5')}
            </div>
            <SVG src={ExternalLinkIcon} className={'link'} />
          </div>
          <div className='link-item'
            onClick={() => window.open('https://docs.aquarius.loan', '_blank')}
          >
            <div className='item-text'>
              {t('Leding_Risk_6')}
            </div>
            <SVG src={ExternalLinkIcon} className={'link'} />
          </div>
        </div>
        <div className="button-container">
          <button
            className="button"
            onClick={logout}
          >
            {t("Cancel")}
          </button>
          <div className='divider' />
          <button
            className="button1"
            disabled={!check1 || !check2 || !check3 || !check4}
            onClick={continueWithRisk}
          >
            {t("Continue")}
          </button>
        </div>
      </Styles>
    )
  }

  return (
    <Modal
      content={content()}
      open={showModal}
      onCloseModal={() => { }}
      containerStyle={{
        maxWidth: 650,
      }}
    />
  );
}

export default LendingRiskModal;
