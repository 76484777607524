import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import Modal from 'components/UI/Modal';
import Select from 'components/UI/Select';
import Loading from 'components/UI/Loading';
import BorrowLimitProgress from 'components/UI/BorrowLimitProgress';
import ConnectWalletButton from './ConnectWalletButton';

import { useActiveWeb3React, useWeb3 } from '../../hooks';
import { getNativeToken } from 'utils';
import * as constants from 'utilities/constants';
import { getBigNumber } from '../../utilities/common';
import {
  getAbepContract,
  getContract,
  getTokenContract,
  methods,
} from '../../utilities/ContractService';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
  }
  .input-container {
    display: flex;
    align-items: center;
    padding: 20px 24px 20px 24px;
    border-radius: 4px;
    border: 1px solid #FFFFFF33;
    background: #00000033;
    gap: 12px;
    @media (max-width: 992px) {
      padding: 10px 16px;
    }
    input {
      flex: 1;
      background: transparent;
      border: none;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;      
      &:focus {
        outline: none;
      }
      ::placeholder {
        padding: 0 !important;
        color: #9CA3AF !important;
      }
      @media (max-width: 992px) {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }
    .max-button {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: right;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  .balance-container {
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
  }
`;
const PrimaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .apy-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .item {
      display: flex;
      aglin-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .divider {
    border-bottom: 1px solid #FFFFFF33;
  }
`;
const BorrowLimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #9CA3AF;
  .borrow-limit {
    display: flex;
    aglin-items: center;
    justify-content: space-between;
  }
`;
const StyledTab = styled.div`
  display: flex;
  border-bottom: 1px solid #FFFFFF1A;
  gap: 10px;
  .tab {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    text-align: center;
    cursor: pointer;
    .bottom-active {
      width: 100%;
      height: 2px;
      margin-top: 12px;
      border-radius: 4px 4px 0px 0px;
      background: #FFFFFF;
    }
  }
`
const ErrorMessage = styled.div`
  color: #F87171;
  height: 0px;
  text-align: right;
  width: 100%;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  algin-items: center;
  padding: 24px;
  border-top: 1px solid #FFFFFF1A;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 16px 24px;
    border-radius: 4px;
    border: 1px solid #0EA5E9;
    gap: 10px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    &:hover {
      box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }
`;

function SupplyWithdrawModal({
  open,
  onCloseModal,
  record,
  tab = 'supply',
}) {
  const settings = useSelector((state) => state.account.setting);
  const { t } = useTranslation();

  const web3 = useWeb3();
  const { account, requiredChainId } = useActiveWeb3React();

  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedAsset, setSelectedAsset] = useState(record);

  const [isEnabled, setIsEnabled] = useState(false);
  const [allowanceAmount, setAllowanceAmount] = useState(new BigNumber(0));
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [borrowLimit, setBorrowLimit] = useState(new BigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0));
  const [newBorrowLimit, setNewBorrowLimit] = useState(new BigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0));

  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawBorrowLimit, setWithdrawBorrowLimit] = useState(new BigNumber(0));
  const [withdrawBorrowPercent, setWithdrawBorrowPercent] = useState(new BigNumber(0));
  const [withdrawNewBorrowLimit, setWithdrawNewBorrowLimit] = useState(new BigNumber(0));
  const [withdrawNewBorrowPercent, setWithdrawNewBorrowPercent] = useState(new BigNumber(0));

  const [showSupplyError, setShowSupplyError] = useState(false);
  const [showWithdrawError, setShowWithdrawError] = useState(false);

  const updateInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    const collateralFactor = getBigNumber(selectedAsset.collateralFactor);

    if (tokenPrice && amount) {
      const temp = totalBorrowLimit
        .plus(new BigNumber(amount).times(tokenPrice).times(collateralFactor));
      setNewBorrowLimit(BigNumber.maximum(temp, 0));
      setNewBorrowPercent(totalBorrowBalance.div(temp).times(100));
      if (totalBorrowLimit.isZero()) {
        setBorrowLimit(new BigNumber(0));
        setBorrowPercent(new BigNumber(0));
      } else {
        setBorrowLimit(totalBorrowLimit);
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else if (BigNumber.isBigNumber(totalBorrowLimit)) {
      setBorrowLimit(totalBorrowLimit);
      setNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    }
    if (new BigNumber(amount).gt(selectedAsset.walletBalance)) {
      setShowSupplyError(true);
    } else {
      setShowSupplyError(false);
    }
  }, [account, amount, selectedAsset]);

  const updateWithdrawInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    const collateralFactor = getBigNumber(selectedAsset.collateralFactor);
    if (tokenPrice && withdrawAmount) {
      const temp = selectedAsset?.collateral ? totalBorrowLimit.minus(
        new BigNumber(withdrawAmount).times(tokenPrice).times(collateralFactor),
      ) : totalBorrowLimit;
      setWithdrawNewBorrowLimit(temp);
      setWithdrawNewBorrowPercent(totalBorrowBalance.div(temp).times(100));
      if (totalBorrowLimit.isZero()) {
        setWithdrawBorrowLimit(new BigNumber(0));
        setWithdrawBorrowPercent(new BigNumber(0));
      } else {
        setWithdrawBorrowLimit(totalBorrowLimit);
        setWithdrawBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      setWithdrawBorrowLimit(totalBorrowLimit);
      setWithdrawNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setWithdrawBorrowPercent(new BigNumber(0));
        setWithdrawNewBorrowPercent(new BigNumber(0));
      } else {
        setWithdrawBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setWithdrawNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    }
  }, [account, withdrawAmount, selectedAsset]);

  const getAllowanceAmount = async () => {
    if (selectedAsset && account && selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
      const tokenContract = getTokenContract(web3, selectedAsset.id, requiredChainId);
      const result = await methods.call(
        tokenContract.methods.allowance,
        [account, selectedAsset.atokenAddress],
      );
      setAllowanceAmount(new BigNumber(result || 0).dividedBy(new BigNumber(10).pow(settings.decimals[selectedAsset.id]?.token || 18)));
    }
  };

  useEffect(() => {
    if (selectedAsset.atokenAddress && account) {
      updateInfo();
      updateWithdrawInfo();
    }
  }, [account, updateInfo, updateWithdrawInfo]);

  useEffect(() => {
    if (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
      if (!amount || allowanceAmount.gte(amount)) {
        setIsEnabled(true);
      } else {
        setIsEnabled(false);
      }
    } else {
      setIsEnabled(true);
    }
  }, [amount, allowanceAmount]);

  useEffect(() => {
    if (withdrawAmount && new BigNumber(withdrawAmount).isGreaterThan(selectedAsset.supplyBalance)
      || withdrawNewBorrowPercent.isGreaterThan(new BigNumber(100))) {
      setShowWithdrawError(true);
    } else {
      setShowWithdrawError(false);
    }
  }, [withdrawAmount, withdrawNewBorrowPercent])

  useEffect(() => {
    if (open) {
      getAllowanceAmount();
      setCurrentTab(tab);
    } else {
      setAmount('');
      setWithdrawAmount('');
    }
  }, [open]);

  const getWithdrawMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
    const { collateral } = selectedAsset;
    const supplyBalance = getBigNumber(selectedAsset.supplyBalance);
    const collateralFactor = getBigNumber(selectedAsset.collateralFactor);
    const liquidity = getBigNumber(selectedAsset.liquidity).div(tokenPrice).decimalPlaces(settings.decimals[selectedAsset.id].token);
    if (!collateral) {
      return BigNumber.minimum(liquidity, supplyBalance).toFormat(2, BigNumber.ROUND_DOWN);
    }
    const max = BigNumber.maximum(
      totalBorrowLimit
        .minus(totalBorrowBalance)
        .div(collateralFactor)
        .div(tokenPrice),
      new BigNumber(0),
    );
    return BigNumber.minimum(max, supplyBalance, liquidity).toFormat(2, BigNumber.ROUND_DOWN);
  }

  const onApprove = async () => {
    if (selectedAsset.id && account && (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase())) {
      setIsLoading(true);
      const tokenContract = getTokenContract(web3, selectedAsset.id, requiredChainId);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        tokenContract.methods.approve,
        [selectedAsset.atokenAddress, new BigNumber(amount).times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token)).toString(10)],
        account,
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setIsLoading(false);
        return
      }
      methods.send(
        tokenContract.methods.approve,
        [selectedAsset.atokenAddress, new BigNumber(amount).times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token)).toString(10)],
        account,
      )
        .then(() => {
          getAllowanceAmount();
          setIsLoading(false);
          NotificationManager.success(t('Approve_successfully'));
        })
        .catch(() => {
          setIsLoading(false);
          NotificationManager.error(t('Tx_rejected'));
        });
    }
  };

  const handleSupply = async () => {
    if (selectedAsset.id && account) {
      setIsLoading(true);
      if (selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) {
        const appContract = getAbepContract(web3, selectedAsset.id, requiredChainId);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          appContract.methods.mint,
          [new BigNumber(amount).times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token)).toString(10)],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setIsLoading(false);
          return
        }
        methods.send(
          appContract.methods.mint,
          [new BigNumber(amount).times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token)).toString(10)],
          account,
        )
          .then(() => {
            setAmount();
            setIsLoading(false);
            onCloseModal();
            NotificationManager.success(t('Supply_successfully'));
          })
          .catch(() => {
            setIsLoading(false);
            NotificationManager.error(t('Tx_rejected'));
          });
      } else {
        const address = constants.CONTRACT_ABEP_ADDRESS[requiredChainId][getNativeToken(requiredChainId).toLowerCase()].address
        const contract = getContract(web3, requiredChainId, constants.CONTRACT_AETH_ABI, address);

        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.mint,
          [],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));

          setIsLoading(false);
          return false;
        }

        await methods.sendWithValue(
          contract.methods.mint,
          [],
          account,
          new BigNumber(amount).times(new BigNumber(10).pow(settings.decimals[selectedAsset.id].token)).toString(10),
        ).then(() => {
          setIsLoading(false);
          setAmount();
          onCloseModal();
          NotificationManager.success(t('Supply_successfully'));
        })
          .catch(() => {
            setIsLoading(false);
            NotificationManager.error(t('Tx_rejected'));
          });
      }
    }
  };

  const handleWithdraw = async () => {
    const { id: assetId } = selectedAsset;
    const appContract = getAbepContract(web3, assetId, requiredChainId);
    if (assetId && account) {
      setIsWithdrawLoading(true);
      try {
        const initialize = (close) => {
          if (close) {
            setWithdrawAmount();
            onCloseModal();
            setCurrentTab('supply');
          }

          setIsWithdrawLoading(false);
        }
        if (new BigNumber(withdrawAmount).eq(selectedAsset.supplyBalance)) {
          const aTokenBalance = await methods.call(appContract.methods.balanceOf, [account]);
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            appContract.methods.redeem,
            [aTokenBalance],
            account,
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setIsLoading(false);
            return
          }
          methods.send(appContract.methods.redeem, [aTokenBalance], account)
            .then(() => {
              initialize(true);
              NotificationManager.success(t('Withdraw_successfully'));
            }).catch(() => {
              initialize(false);
              NotificationManager.error(t('Tx_rejected'));
            });
        } else {
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            appContract.methods.redeemUnderlying,
            [
              new BigNumber(withdrawAmount)
                .times(new BigNumber(10).pow(settings.decimals[assetId].token))
                .integerValue()
                .toString(10),
            ],
            account,
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setIsLoading(false);
            return
          }
          methods.send(
            appContract.methods.redeemUnderlying,
            [
              new BigNumber(withdrawAmount)
                .times(new BigNumber(10).pow(settings.decimals[assetId].token))
                .integerValue()
                .toString(10),
            ],
            account,
          ).then(() => {
            initialize(true);
            NotificationManager.success(t('Withdraw_successfully'));
          }).catch(() => {
            initialize(false);
            NotificationManager.error(t('Tx_rejected'));
          });
        }
      } catch (error) {
        setIsWithdrawLoading(false);
      }
    }
  };

  const handleMaxAmount = async () => {
    if (currentTab === 'supply') {
      if (selectedAsset.id === getNativeToken(requiredChainId).toLowerCase()) {
        NotificationManager.warning(t("Transaction_can_be_fail"));
      }
      setAmount(selectedAsset.walletBalance.toString(10));
    } else {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      const tokenPrice = getBigNumber(selectedAsset.tokenPrice);
      const { collateral } = selectedAsset;
      const supplyBalance = getBigNumber(selectedAsset.supplyBalance);
      const collateralFactor = getBigNumber(selectedAsset.collateralFactor);
      const liquidity = getBigNumber(selectedAsset.liquidity).div(tokenPrice);
      if (!collateral) {
        setWithdrawAmount(BigNumber.minimum(supplyBalance, liquidity).decimalPlaces(settings.decimals[selectedAsset.id].token).toString(10))
        return;
      }
      const safeMax = BigNumber.maximum(
        totalBorrowLimit
          .minus(totalBorrowBalance.div(70).times(100))
          .div(collateralFactor)
          .div(tokenPrice),
        new BigNumber(0),
      );
      setWithdrawAmount(BigNumber.minimum(safeMax, supplyBalance, liquidity).decimalPlaces(settings.decimals[selectedAsset.id].token).toString(10))
    }
  };

  const content = (
    <StyledContainer>
      <div className='content-container'>
        <StyledTab>
          <div className='tab' style={currentTab === 'supply' ? {} : { color: '#9CA3AF' }}
            onClick={() => setCurrentTab('supply')}
          >
            {t("Supply")}
            <div className="bottom-active" style={currentTab === 'supply' ? {} : { background: 'transparent' }} />
          </div>
          <div className='tab' style={currentTab === 'withdraw' ? {} : { color: '#9CA3AF' }}
            onClick={() => setCurrentTab('withdraw')}
          >
            {t("Withdraw")}
            <div className="bottom-active" style={currentTab === 'withdraw' ? {} : { background: 'transparent' }} />
          </div>
        </StyledTab>
        <Select
          selectedProp={selectedAsset}
          options={settings.assetList || []}
          onChange={(item) => setSelectedAsset(item)}
        />
        <div>
          <div className="input-container">
            <input
              placeholder="0.0"
              value={currentTab === 'supply' ? amount : withdrawAmount}
              onChange={event => {
                if (!event.target.value.length || Number(event.target.value) >= 0) {
                  if (currentTab === 'supply') {
                    setAmount(event.target.value);
                  } else {
                    setWithdrawAmount(event.target.value);
                  }
                }
              }}
            />
            <div
              className="max-button"
              style={account ? {} : { cursor: 'not-allowed', opacity: 0.7 }}
              onClick={() => {
                handleMaxAmount()
              }}
            >
              {currentTab === 'supply' ? t("MAX") : t("SAFE_MAX")}
            </div>
          </div>
          {
            currentTab === 'supply' ?
              <div className='balance-container'>
                <div style={{ color: '#9CA3AF' }}>{t("Wallet_Balance")}</div>
                <div>
                  {new BigNumber(selectedAsset.walletBalance || 0).toFormat(2)} {selectedAsset.symbol}
                </div>
              </div>
              :
              <div className='balance-container'>
                <div style={{ color: '#9CA3AF' }}>{t("Withdrawable_Amount")}</div>
                <div>
                  {getWithdrawMaxAmount()} {selectedAsset.symbol}
                </div>
              </div>
          }
        </div>
        <PrimaryContainer>
          <div className="apy-items">
            <div className='item'>
              <div className='flex itmes-center'>
                <img src={selectedAsset.img} alt={selectedAsset.symbol} />
                <div>{t("Supply_APY")}</div>
              </div>
              <div>
                {new BigNumber(selectedAsset.supplyApy).dp(2, 1).toString(10)}%
              </div>
            </div>
            <div className='item'>
              <div className='flex itmes-center'>
                <img src="/images/coins/ars.png" alt="ARS" />
                <div>{t("Distribution_APY")}</div>
              </div>
              <div>
                {new BigNumber(selectedAsset.supplyAdditionalApy).dp(2, 1).toString(10)}%
              </div>
            </div>
          </div>
          <div className='divider' />
          <BorrowLimitContainer>
            <div className='borrow-limit'>
              <div>{t("Current")}: ${borrowLimit.toFormat(2)}</div>
              {
                currentTab !== 'supply' && (
                  <div>
                    {t("Max")}{": "}
                    <span style={{ color: '#F9FAFB' }}>
                      ${newBorrowPercent.toFormat(2)}
                    </span>
                  </div>
                )
              }
            </div>
            <BorrowLimitProgress
              percent={currentTab == 'supply' ? newBorrowPercent.toNumber() : withdrawNewBorrowPercent.toNumber()}
            />
            <div className='borrow-limit'>
              <div>{t("Limit_Used")}</div>
              <div style={{ color: '#F9FAFB' }}>
                {currentTab == 'supply' ? newBorrowPercent.toFormat(2) : withdrawNewBorrowPercent.toFormat(2)}%
              </div>
            </div>
            <div className='borrow-limit'>
              <div>{t("Borrow_Limit")}</div>
              <div style={{ color: '#F9FAFB' }}>
                ${currentTab == 'supply' ? newBorrowLimit.toFormat(2) : withdrawNewBorrowLimit.toFormat(2)}
              </div>
            </div>
            <div className='divider' />
            <div className='borrow-limit'>
              <div>{t("Currently_Supplying")}</div>
              <div style={{ color: '#F9FAFB' }}>
                {new BigNumber(selectedAsset.supplyBalance || 0).toFormat(4)} {selectedAsset.symbol}
              </div>
            </div>
          </BorrowLimitContainer>
        </PrimaryContainer>
        {currentTab === 'supply' && (
          <ErrorMessage>
            {showSupplyError ? t('Insufficient_Balance') : ''}
          </ErrorMessage>
        )}
        {currentTab === 'withdraw' && (
          <ErrorMessage>
            {showWithdrawError ? `${t("You_can_withdraw")} ${getWithdrawMaxAmount()} ${selectedAsset.symbol} ${t("at_least")}` : ''}
          </ErrorMessage>
        )}
      </div>
      {
        !account ? (
          <StyledButtonContainer>
            <ConnectWalletButton />
          </StyledButtonContainer>
        ) : (
          currentTab === 'supply' ? (
            <StyledButtonContainer>
              {(!isEnabled && selectedAsset.id !== getNativeToken(requiredChainId).toLowerCase()) ? (
                <button
                  disabled={isLoading || new BigNumber(amount || 0).isZero()}
                  onClick={() => {
                    onApprove();
                  }}
                >
                  {isLoading && <Loading size={'18px'} margin={'8px'} />} {t("Enable")}
                </button>
              ) : (
                <button
                  disabled={showSupplyError || isLoading
                    || new BigNumber(amount || 0).isZero() || new BigNumber(amount).gt(selectedAsset.walletBalance)}
                  onClick={handleSupply}
                >
                  {isLoading && <Loading size={'18px'} margin={'8px'} />} {t("Supply")}
                </button>
              )}
            </StyledButtonContainer>
          ) : (
            <StyledButtonContainer>
              <button
                disabled={
                  showWithdrawError ||
                  isWithdrawLoading ||
                  new BigNumber(withdrawAmount || 0).isZero() ||
                  new BigNumber(withdrawAmount).isGreaterThan(selectedAsset.supplyBalance) ||
                  withdrawNewBorrowPercent.isGreaterThan(new BigNumber(100))
                }
                onClick={handleWithdraw}
              >
                {isWithdrawLoading && <Loading size={'18px'} margin={'8px'} />} {t("Withdraw")}
              </button>
            </StyledButtonContainer>
          )
        )
      }
    </StyledContainer>
  );

  return (
    selectedAsset && (
      <Modal
        content={content}
        open={open}
        onCloseModal={() => {
          setWithdrawAmount();
          setAmount();
          onCloseModal();
        }}
        containerStyle={{
          maxWidth: 560,
        }}
      />
    )
  );
}

export default SupplyWithdrawModal;
