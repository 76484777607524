import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import styled from 'styled-components';

import arrowDown from '../../assets/img/header/down.svg';

const Styles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;

  .option-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1em;
    margin: 10px 18px;
    color: #9CA3AF;
    &:hover {
      color: #F9FAFB;
    }
    .arrow-down {
      margin: 0 0 0 5px;
    }
  }

  .menu-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    border-radius: 4px;
    background: #030712;
    border: 1px solid #FFFFFF1A;
    .items-sub {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 12px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

export default function NavDropdown({
  label,
  options,
  active = false
}) {
  return (
    <Styles>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="text-white">
            <div className="option-container">
              <div
                style={active ? { color: '#F9FAFB' } : {}}
              >
                {label}
              </div>
              <img className="arrow-down" src={arrowDown} alt='' />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute menu-item-container" >
            <div className='items-sub'>
              {
                options.map((option, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => {
                      if (option?.public) {
                        return (
                          <a href={`${option.link}`} target='_blank' rel="noreferrer"
                            className={`${active ? 'text-blue3' : 'text-gray-900'}
                             group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {option.label}
                          </a>
                        )
                      }
                      return (
                        <Link to={`${option.link}`}
                          className={`${active ? 'text-blue3' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {option.label}
                        </Link>
                      )
                    }}
                  </Menu.Item>
                ))
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Styles>
  )
}