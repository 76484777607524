import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Portfolio from '../pages/Portfolio';
import Aquarius from '../pages/Aquarius';
import Market from '../pages/Market';
import MarketDetails from '../pages/MarketDetails';
import History from 'pages/History/index.js';
import Vesting from 'pages/Vest';
import Referral from 'pages/Referral';
import Radiant from 'pages/Radiant';
import Vote from '../pages/Vote';
import AllProposals from '../pages/Vote/AllProposals';
import VoteOverview from '../pages/Vote/VoteOverview';
import ProposerOverview from '../pages/Vote/ProposerOverview';
import Leaderboard from '../pages/Vote/Leaderboard';
import Liquidate from '../pages/Liquidate';
import NotFound from '../pages/NotFound';
import LendingRiskModal from 'components/common/LendingRiskModal';

import Web3ReactManager from '../components/common/Web3ReactManager';
import { useActiveWeb3React } from 'hooks';
import routes from './RouteMap';

import { isCoreNetwork } from 'utils';

const Routes = () => {
  const { requiredChainId } = useActiveWeb3React();

  return (
    <Web3ReactManager>
      <Router>
        <Switch>
          <Route exact path="/" render={(match) => <Redirect to={`${routes.dashboard}${match?.location?.search}`} />} />
          {
            !isCoreNetwork(requiredChainId) &&
            <Route exact path={routes.stake.ars} render={() => <Redirect to={routes.dashboard} />} />
          }

          <Route exact path={routes.dashboard} component={Dashboard} />
          <Route exact path={routes.portfolio} component={Portfolio} />

          <Route exact path={routes.stake.ars} component={Vesting} />
          <Route exact path={routes.stake.dlp} component={Radiant} />

          <Route exact path={routes.market.index} component={Market} />
          <Route exact path={routes.market.marketDetails} component={MarketDetails} />

          <Route exact path={routes.referral} component={Referral} />

          <Route exact path={routes.aquarius} component={Aquarius} />

          <Route exact path={routes.history} component={History} />

          <Route exact path={routes.liquidate} component={Liquidate} />

          {/* <Route exact path={routes.vote.index} component={Vote} />
          <Route exact path={routes.vote.allProposals} component={AllProposals} />
          <Route exact path={routes.vote.voteOverview} component={VoteOverview} />
          <Route exact path={routes.vote.proposerOverview} component={ProposerOverview} />
          <Route exact path={routes.vote.leaderboard} component={Leaderboard} /> */}

          <Redirect to={routes.dashboard} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      <LendingRiskModal />
    </Web3ReactManager>
  );
};

export default Routes;