import { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from 'redux';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import BigNumber from "bignumber.js";

import Layout from "../../layouts/MainLayout/MainLayout";

import { accountActionCreators, connectAccount } from "../../core";
// import { promisify } from "../../utilities";
import * as constants from "../../utilities/constants";
import { useActiveWeb3React } from "../../hooks";
import MarketInfo from "../../components/Market/MarketInfo";
// import MarketDetailsChart from "../../components/Market/MarketDetailsChart";
import MarketSummary from "../../components/Market/MarketSummary";

import back from "../../assets/img/common/market_back.svg"
import InterestRateModel from "components/Market/InterestRateModel";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  .sub-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 980px;
    .token-container {
      display: flex;
      flex-direction: column;
      padding: 16px 24px 16px 24px;
      border-radius: 12px;
      border: 1px solid #FFFFFF1A;
      gap: 12px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
      .back-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
      }
      .token {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.025em;
        @media only screen and (max-width: 992px) {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
        }
        img {
          width: 36px;
          height: 36px;
          @media only screen and (max-width: 992px) {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
    .info-container {
      display: flex;
      gap: 24px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
    }
  }
`

let timeStamp = 0;
const MarketDetails = ({
  match,
  history,
  settings,
  // getMarketHistory
}) => {
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();
  const [currentAsset, setCurrentAsset] = useState('');
  // const [data, setData] = useState([]);
  const [marketInfo, setMarketInfo] = useState();

  useEffect(() => {
    document.body.scrollTop = 0
  }, []);

  useEffect(() => {
    if (match.params && match.params.asset) {
      if (constants.CONTRACT_ABEP_ADDRESS[requiredChainId][match.params.asset.toLowerCase()]) {
        setCurrentAsset(match.params.asset.toLowerCase());
      } else {
        history.push('/market');
      }
    }
  }, [match, requiredChainId]);

  // const getGraphData = useCallback(
  //   async (asset, type) => {
  //     const tempData = [];
  //     await promisify(getMarketHistory, { asset, type }).then(res => {
  //       res.data.result.forEach(m => {
  //         tempData.push({
  //           createdAt: m.createdAt,
  //           supplyApy: +new BigNumber(m.supplyApy || 0).dp(8, 1).toString(10),
  //           borrowApy: +new BigNumber(m.borrowApy || 0).dp(8, 1).toString(10),
  //           totalSupply: +new BigNumber(m.totalSupply || 0)
  //             .dp(8, 1)
  //             .toString(10),
  //           totalBorrow: +new BigNumber(m.totalBorrow || 0)
  //             .dp(8, 1)
  //             .toString(10)
  //         });
  //       });
  //       setData([...tempData.reverse()]);
  //     });
  //   },
  //   [getMarketHistory]
  // );


  const getGovernanceData = useCallback(async () => {
    if (settings.markets && settings.markets.length > 0 && currentAsset) {
      const info = settings.markets.find(
        item => item.underlyingSymbol.toLowerCase() === currentAsset
      );
      setMarketInfo(info || {});
    }
  }, [settings.markets, currentAsset]);

  useEffect(() => {
    getGovernanceData();
  }, [getGovernanceData]);

  // useEffect(() => {
  //   if (timeStamp % 60 === 0 && currentAsset) {
  //     getGraphData(
  //       constants.CONTRACT_ABEP_ADDRESS[requiredChainId][currentAsset].address,
  //       '1day'
  //     );
  //   }
  //   timeStamp = Date.now();
  // }, [currentAsset, getGraphData]);


  // useEffect(() => {
  //   if (currentAsset) {
  //     getGraphData(
  //       constants.CONTRACT_ABEP_ADDRESS[requiredChainId][currentAsset].address,
  //       '1day'
  //     );
  //   }
  // }, [currentAsset]);

  const findTokenIcon = (symbol) => {
    const token = settings?.assetList?.find((token) => {
      return token.name === symbol
    })
    return token?.img || ''
  }

  return (
    <Layout>
      <StyledContainer>
        {
          marketInfo && (
            <div className="sub-container w-full">
              <div className="token-container">
                <div className="back-container"
                  onClick={() => history.goBack()}
                >
                  <img src={back} alt="" />
                  <h1>{t("All_Assets")}</h1>
                </div>
                <div className="token">
                  <img src={findTokenIcon(marketInfo.underlyingSymbol)} alt="" />
                  <div>
                    {marketInfo.underlyingSymbol}
                  </div>
                </div>
              </div>
              <InterestRateModel
                currentAsset={currentAsset}
              />
              {/* <MarketDetailsChart
                data={data}
              /> */}
              <div className="info-container">
                <MarketInfo
                  marketInfo={marketInfo}
                  marketType={'Borrow'}
                />
                <MarketInfo
                  marketInfo={marketInfo}
                  marketType={'Supply'}
                />
              </div>
              <MarketSummary
                marketInfo={marketInfo}
                currentAsset={currentAsset}
              />
            </div>
          )
        }
      </StyledContainer>
    </Layout >
  )
}


MarketDetails.defaultProps = {
  match: {},
  history: {},
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { getMarketHistory } = accountActionCreators;

  return bindActionCreators(
    {
      getMarketHistory
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(MarketDetails);
