import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import Layout from '../../layouts/MainLayout/MainLayout.js';
import PortfolioInfo from './PortfolioInfo.js';
import HealthInfo from './HealthInfo.js';
import AccountOverview from './AccountOverview.js';
import PortfolioMobileItem from './PortfolioMobileItem.js';
import PortfolioMobileItem1 from './PortfolioMobileItem1.js';
import DataTable from '../../components/common/DataTable.js';
import SwitchComponent from '../../components/UI/SwitchComponent.js';
import Loading from 'components/UI/Loading.js';

import { getNativeToken } from '../../utils/index.js';
import SupplyWithdrawModal from '../../components/common/SupplyWithdrawModal.js';
import BorrowRepayModal from '../../components/common/BorrowRepayModal.js';
import ConfirmTransactionModal from '../../components/common/ConfirmTransactionModal.js';
import toast from '../../components/UI/Toast.js';

import { useWindowSize } from 'hooks/useWindowSize.js';
import { useActiveWeb3React, useWeb3 } from '../../hooks';
import {
  getComptrollerContract,
  methods,
} from '../../utilities/ContractService.js';
import { getFormatStringFromBignumber } from '../../utilities/common.js';

import noData from 'assets/img/common/no_history.svg';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .table-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .table-title-container {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      .add-assets {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px 4px 8px;
        border-radius: 999px;
        border: 1px solid #FFFFFF1A;
        gap: 8px;
        background: #FFFFFF1A;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
      }
    }
  }
  .assets {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .balance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;
    span {
      color: rgba(156, 163, 175, 1);
    }
  }
  .apy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    img {
      width: 16px;
      height: 16px;
    }
    .sub-apy-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%);
      border: 1px solid;
      border-color: rgba(14, 165, 233, 0.5);
      padding: 4px;
      border-radius: 999px;
      gap: 4px;
    }
  }
  .reward-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
  .collateral-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    @media (max-width: 992px) {
      justify-content: space-between;
    }
    .supply {
      padding: 4px 16px 4px 16px;
      border-radius: 999px;
      border: 1px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media (max-width: 992px) {
        flex: 1;
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
      }
    }
    .borrow {
      padding: 4px 16px 4px 16px;
      border-radius: 999px;
      border: 1px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      @media (max-width: 992px) {
        flex: 1;
        padding: 8px 16px 8px 16px;
        border-radius: 4px;
      }
    }
  }
`

const NoAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background: #FFFFFF0D;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 992px) {
      max-width: 70%;
    }
  }
  img {
    @media (max-width: 992px) {
      height: 96px;
    }
  }
`;

function Portfolio() {
  const settingAccount = useSelector((state) => state.account.setting.account);
  const assetList = useSelector((state) => state.account.setting.assetList);

  const web3 = useWeb3();
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();

  const [suppliedAssets, setSuppliedAssets] = useState([]);
  const [borrowedAssets, setBorrowedAssets] = useState([]);

  const [isCollateralEnable, setIsCollateralEnable] = useState(true);
  const [isOpenCollateralConfirm, setIsCollateralConfirm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedTab, setSelectedTab] = useState('supply');
  const [supplyWithdrawOpen, setSupplyWithdrawOpen] = useState(false);
  const [borrowRepayOpen, setBorrowRepayOpen] = useState(false);

  const handleToggleCollateral = async (r) => {
    const appContract = getComptrollerContract(web3, requiredChainId);
    if (r && account && new BigNumber(r.borrowBalance || 0).isZero()) {
      if (!r.collateral) {
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          appContract.methods.enterMarkets,
          [[r.atokenAddress]],
          account
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setIsCollateralConfirm(false);
          return
        }
        setIsCollateralConfirm(true);
        setIsCollateralEnable(false);
        await methods.send(
          appContract.methods.enterMarkets,
          [[r.atokenAddress]],
          account
        )
          .then(() => {
            NotificationManager.success(t('Collateral_enabled'))
          })
          .catch((e) => {
            NotificationManager.error(t('Tx_rejected'))
            console.log(e)
          });
      } else if (+r.hypotheticalLiquidity['1'] > 0 || +r.hypotheticalLiquidity['2'] === 0) {
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          appContract.methods.exitMarket,
          [r.atokenAddress],
          account
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setIsCollateralConfirm(false);
          return
        }
        setIsCollateralConfirm(true);
        setIsCollateralEnable(true);
        await methods.send(
          appContract.methods.exitMarket,
          [r.atokenAddress],
          account
        )
          .then(() => {
            NotificationManager.success(t('Collateral_disabled'))
          })
          .catch(() => {
            NotificationManager.error(t('Tx_rejected'))
            console.log('transaction failed')
          });
      } else {
        toast.error({
          title: t(`Collateral_Required`),
          description: t(`Collateral_Required_Desc`),
        });
      }
      setIsCollateralConfirm(false);
    } else {
      toast.error({
        title: t(`Collateral_Required`),
        description: t(`Collateral_Required_Desc`),
      });
    }
  };

  const updateMarketTable = async () => {
    const tempSuppliedData = [];
    const tempBorrowedData = [];
    if (account) {
      for (let index = 0; index < assetList.length; index++) {
        if (new BigNumber(assetList[index].supplyBalance).gt(0)) {
          tempSuppliedData.push(assetList[index]);
        }
        if (new BigNumber(assetList[index].borrowBalance).gt(0)) {
          tempBorrowedData.push(assetList[index]);
        }
      }
    }

    setSuppliedAssets([...tempSuppliedData]);
    setBorrowedAssets([...tempBorrowedData]);
  };

  useEffect(() => {
    if (settingAccount && assetList.length > 0) {
      updateMarketTable();
    }
  }, [settingAccount, assetList, account]);

  const suppliedColumns = React.useMemo(
    () => [
      {
        Header: t("Asset"),
        accessor: 'Asset',
      },
      {
        Header: t("Supply_Balance"),
        accessor: 'SupplyBalance',
        sortable: true,
      },
      {
        Header: `${t("Supply")} %`,
        accessor: 'Apy',
        sortable: true,
      },
      {
        Header: t("Supply_Rewards"),
        accessor: 'SupplyRewards',
        sortable: true,
      },
      {
        Header: t("Collateral"),
        accessor: 'Collateral',
      },
      {
        Header: t("Actions"),
        accessor: 'Actions',
      },
    ],
    [t],
  );

  const borrowedColumns = React.useMemo(
    () => [
      {
        Header: t("Asset"),
        accessor: 'Asset',
      },
      {
        Header: t("Borrow_Balance"),
        accessor: 'BorrowBalance',
        sortable: true,
      },
      {
        Header: `${t("Borrow")} %`,
        accessor: 'Apy',
        sortable: true,
      },
      {
        Header: t("Borrow_Rewards"),
        accessor: 'BorrowRewards',
        sortable: true,
      },
      {
        Header: t("Actions"),
        accessor: 'Actions',
      },
    ],
    [t],
  );

  const supplyData = React.useMemo(() => {
    if (suppliedAssets.length === 0) return [];

    return suppliedAssets.map((asset) => {
      return {
        Asset: (
          <div className="assets" >
            <img src={asset.img} alt={asset.name} />
            <div>{asset.name}</div>
          </div>
        ),
        SupplyBalance: (
          <div className="balance-container">
            <div>
              ${getFormatStringFromBignumber(new BigNumber(asset.supplyBalance).times(asset.tokenPrice))}
            </div>
            <span>
              {getFormatStringFromBignumber(new BigNumber(asset.supplyBalance))} {asset.symbol}
            </span>
          </div>
        ),
        Apy: (
          <div className="apy-container">
            <div className="">{getFormatStringFromBignumber(new BigNumber(asset.supplyApy))}%</div>
            <div className='sub-apy-container'>
              <img src={`/images/coins/ars.png`} alt={'ars'} />
              <div>{getFormatStringFromBignumber(new BigNumber(asset.supplyAdditionalApy))}%</div>
            </div>
          </div>
        ),
        SupplyRewards: (
          <div className="reward-container">
            {getFormatStringFromBignumber(new BigNumber(asset.supplyReward).div(1e18))} ARS
          </div>
        ),
        Collateral: (
          <div className="collateral-container">
            <SwitchComponent
              value={account ? asset.collateral : false}
              onChange={() => handleToggleCollateral(asset)}
            />
          </div>
        ),
        Actions: (
          <div className='action-container'>
            <div className="supply"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => handleSupplyClickRow(asset, 'supply')}
            >
              {t("Supply")}
            </div>
            <div className="borrow"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => handleSupplyClickRow(asset, 'withdraw')}
            >
              {t("Withdraw")}
            </div>
          </div>
        ),
      };
    });
  }, [suppliedAssets, account]);


  const borrowData = React.useMemo(() => {
    if (borrowedAssets.length === 0) return [];

    return borrowedAssets.map((asset) => {
      return {
        Asset: (
          <div className="assets" >
            <img src={asset.img} alt={asset.name} />
            <div>{asset.name}</div>
          </div>
        ),
        BorrowBalance: (
          <div className="balance-container">
            <div>
              ${getFormatStringFromBignumber(new BigNumber(asset.borrowBalance).times(asset.tokenPrice))}
            </div>
            <span>
              {getFormatStringFromBignumber(new BigNumber(asset.borrowBalance))} {asset.symbol}
            </span>
          </div>
        ),
        Apy: (
          <div className="apy-container">
            <div className="">{getFormatStringFromBignumber(new BigNumber(asset.borrowApy))}%</div>
            <div className='sub-apy-container'>
              <img src={`/images/coins/ars.png`} alt={'ars'} />
              <div>{getFormatStringFromBignumber(new BigNumber(asset.borrowAdditionalApy))}%</div>
            </div>
          </div>
        ),
        BorrowRewards: (
          <div className="reward-container">
            {getFormatStringFromBignumber(new BigNumber(asset.borrowReward).div(1e18))} ARS
          </div>
        ),
        Actions: (
          <div className='action-container'>
            <div className="supply"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => handleBorrowClickRow(asset, 'borrow')}
            >
              {t("Borrow")}
            </div>
            <div className="borrow"
              style={account && settingAccount === account ? {} : { opacity: 0.5, cursor: 'not-allowed' }}
              onClick={() => handleBorrowClickRow(asset, 'borrowRepay')}
            >
              {t("Repay")}
            </div>
          </div>
        ),
      };
    });
  }, [borrowedAssets, account]);

  const handleSupplyClickRow = (row, tab) => {
    setSelectedTab(tab);
    setSelectedRecord(row);
    setSupplyWithdrawOpen(true);
  };

  const handleBorrowClickRow = (row, tab) => {
    setSelectedTab(tab);
    setSelectedRecord(row);
    setBorrowRepayOpen(true);
  };

  return (
    <Layout>
      <StyledContainer className='flex flex-col items-center justify-center'>
        <PortfolioInfo />
        <AccountOverview />
        <HealthInfo />
        <div className='table-container w-full'>
          <div className='table-title-container'>
            <div>{t("Supply_Tokens")}</div>
            {
              (account && settingAccount && assetList.length) &&
              <div className='add-assets'
                onClick={() => {
                  setSelectedTab('supply');
                  setSelectedRecord(assetList[0]);
                  setSupplyWithdrawOpen(true);
                }}
              >
                +{t("Add_Assets")}
              </div>
            }
          </div>
          <div>
            {
              width > 992 ? (
                <DataTable columns={suppliedColumns} data={supplyData} />
              ) : (
                <PortfolioMobileItem
                  assetList={suppliedAssets}
                  account={account}
                  handleSupplyClickRow={handleSupplyClickRow}
                  handleToggleCollateral={handleToggleCollateral}
                />
              )
            }
            {
              (!account) ? (
                <NoAccountContainer>
                  <img src={noData} alt='' />
                  <div className="title">{t("Connect_wallet_supply_tokens")}</div>
                </NoAccountContainer>
              ) : (!settingAccount && suppliedAssets.length === 0) ? (
                <NoAccountContainer>
                  <Loading size={'36px'} margin={'0'} />
                </NoAccountContainer>
              ) : (settingAccount && suppliedAssets.length === 0) ? (
                <NoAccountContainer>
                  <img src={noData} alt='' />
                  <div className="title">{t("No_Supply_Token_Found")}</div>
                </NoAccountContainer>
              ) : null
            }
          </div>
        </div>
        <div className='table-container w-full'>
          <div className='table-title-container'>
            <div>{t("Borrow_Tokens")}</div>
            {
              (account && settingAccount && assetList.length) &&
              <div className='add-assets'
                onClick={() => {
                  setSelectedTab('borrow');
                  setSelectedRecord(assetList[0]);
                  setBorrowRepayOpen(true);
                }}
              >
                +{t("Add_Assets")}
              </div>
            }
          </div>
          <div>
            {
              width > 992 ? (
                <DataTable columns={borrowedColumns} data={borrowData} />
              ) : (
                <PortfolioMobileItem1
                  assetList={suppliedAssets}
                  account={account}
                  handleBorrowClickRow={handleBorrowClickRow}
                />
              )
            }
            {
              (!account) ? (
                <NoAccountContainer>
                  <img src={noData} alt='' />
                  <div className="title">{t("Connect_wallet_borrow_tokens")}</div>
                </NoAccountContainer>
              ) : (!settingAccount && borrowedAssets.length === 0) ? (
                <NoAccountContainer>
                  <Loading size={'36px'} margin={'0'} />
                </NoAccountContainer>
              ) : (settingAccount && borrowedAssets.length === 0) ? (
                <NoAccountContainer>
                  <img src={noData} alt='' />
                  <div className="title">{t("No_Borrow_Token_Found")}</div>
                </NoAccountContainer>
              ) : null
            }
          </div>
        </div>
      </StyledContainer>
      {
        supplyWithdrawOpen &&
        <SupplyWithdrawModal
          open={supplyWithdrawOpen}
          tab={selectedTab}
          record={selectedRecord}
          onCloseModal={() => setSupplyWithdrawOpen(false)}
        />
      }
      {
        borrowRepayOpen &&
        <BorrowRepayModal
          open={borrowRepayOpen}
          tab={selectedTab}
          record={selectedRecord}
          onCloseModal={() => setBorrowRepayOpen(false)}
        />
      }
      {
        isOpenCollateralConfirm &&
        <ConfirmTransactionModal
          open={isOpenCollateralConfirm}
          onCloseModal={() => setIsCollateralConfirm(false)}
          isCollateralEnable={isCollateralEnable}
        />
      }
    </Layout>
  );
}

export default Portfolio;
