import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import styled from 'styled-components';

import MainLayout from "layouts/MainLayout/MainLayout";
import ReferralHistory from './ReferralHistory';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';

import { useActiveWeb3React } from "hooks";
import useCopyClipboard from 'hooks/useCopyClipboard';
import { restService } from "utilities";
import Loading from 'components/UI/Loading';
import { useReferralClaimCallback } from 'hooks/useReferral';
import AlertHelper from 'components/common/AlertHelper';
import config from 'config';

const VaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
	@media only screen and (max-width: 992px) {
    gap: 32px;
  }
  .first-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    @media (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .container {
    padding: 24px;
    border-radius: 12px;
    border: 1px solid #FFFFFF1A;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    .info {
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.025em;        
      }
      .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: #9CA3AF;
        padding-top: 4px;
      }
      @media (max-width: 992px) {
      }
    }
    .create-container {
      display: flex;
      justify-content: space-between;
      gap : 96px;
      @media (max-width: 992px) {
        flex-direction: column;
        gap : 24px;
      }
      .claim-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #0EA5E9;
        gap: 10px;
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
        @media (max-width: 992px) {
        }
      }
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.025em;
        @media (max-width: 992px) {
          font-size: 36px;
          line-height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: #9CA3AF;
          padding-left: 4px;
          @media (max-width: 992px) {
            padding-top: 7px;
          }
        }
      }
      .warning {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
        @media (max-width: 992px) {
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #FFFFFF33;
    }

    .display-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      @media (max-width: 992px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .item-container {
        display: flex;
        flex-direction: column;
        .item-title {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.025em;
          color: #9CA3AF;
          @media (max-width: 992px) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
          }
        }
        .item-content {
          margin-top: 4px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;          
          @media (max-width: 992px) {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: -0.025em;
          }
          span {
            padding-left: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            @media (max-width: 992px) {
              font-size: 12px;
              margin-top: 1px;
            }
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }
    }

    .referral-link-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 992px) {
        height: 70px;
        justify-content: space-around;
      }
      .title {
        font-size: 16px;
        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
      .link {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #9CA3AF;
        @media (max-width: 992px) {
        }
        span {
          width: 70px;
        }
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .icon {
            cursor: pointer;
            margin-left: 10px;
            svg {
              path {
                fill: #F9FAFB;
              }
              &:hover {
                path {
                  fill: #2563EB;
                }
              }
            }
            svg.copy {
              width: 20px;
              height: 20px;
            }
            svg.twitter {
              width: 18px;
              height: 18px;
            }
            @media (max-width: 992px) {
              svg.copy {
                width: 18px;
                height: 18px;
              }
              svg.twitter {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

function Referral() {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const [_, setCopied] = useCopyClipboard();
  const { handleClaim } = useReferralClaimCallback();

  const [myReferralCode, setMyReferralCode] = useState('');
  const [referralReward, setReferralReward] = useState(new BigNumber(0));
  const [claimedReward, setClaimedReward] = useState(new BigNumber(0));
  const [firstRef, setFirstRef] = useState(0);
  const [secondRef, setSecondRef] = useState(0);

  const [claimLoading, setclaimLoading] = useState(false);


  const getMyReferralCode = async () => {
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `/v1/referral?address=${account}`,
      method: 'GET',
    });

    if (apiRequest?.data?.data) {
      setMyReferralCode(apiRequest?.data?.data?.referral)
    }
  }

  const getReferralReward = async () => {
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `/v1/referral/reward?address=${account}`,
      method: 'GET',
    });

    if (apiRequest?.data?.data) {
      setReferralReward(new BigNumber(apiRequest?.data?.data?.reward || 0));
      setClaimedReward(new BigNumber(apiRequest?.data?.data?.claimedAmount || 0));
      setFirstRef(apiRequest?.data?.data?.referrer || 0);
      setSecondRef(apiRequest?.data?.data?.referrerOfReferrer || 0);
    }
  }

  useEffect(() => {
    if (account) {
      getMyReferralCode();
      getReferralReward()
    }
  }, [account, requiredChainId]);

  const onCreateReferral = async () => {
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `/v1/referral`,
      method: 'POST',
      params: {
        address: account
      }
    });
    if (apiRequest?.data?.data) {
      setMyReferralCode(apiRequest?.data?.data?.referral);
    } else if (apiRequest?.data?.message) {
      NotificationManager.error(t(apiRequest?.data?.message));
    }
  }

  const onClaim = async () => {
    if (!account) return;
    setclaimLoading(true);
    const apiRequest = await restService({
      chainId: requiredChainId,
      api: `/v1/referral/claim`,
      method: 'POST',
      params: {
        address: account
      }
    });
    if (apiRequest?.data?.data) {
      const res = apiRequest?.data?.data;
      const tx = await handleClaim(res.amount, res.nonce, res.code, res.v, res.r, res.s);
      if (tx) {
        getReferralReward();
      }
      setclaimLoading(false);
    } else if (apiRequest?.data?.message) {
      NotificationManager.error(t(apiRequest?.data?.message));
      setclaimLoading(false);
    }
  }

  return (
    <MainLayout>
      <VaultContainer className="">
        <div className="first-container">
          <div className="container">
            <div className='info'>
              <h1 className='title'>{t('Create_Referral')}</h1>
              <div className='desc'>{t('Referral_Reward_Info')}</div>
            </div>
            < div className='divider' />
            {
              myReferralCode ? (
                <div className='referral-link-container'>
                  <div className='title'>
                    {t('Your_referral_link')}
                  </div>
                  <div className='link'>
                    {`${config.appUrl}?ref=`}
                    <span>
                      {myReferralCode}
                    </span>
                    <div className='icon-container'>
                      <div className='icon'
                        onClick={() => {
                          setCopied(`${config.appUrl}?ref=${myReferralCode}`);
                          NotificationManager.success(`${t('share_referral')}`, t('referral_copy_successfully'));
                        }}
                      >
                        <Copy className="copy" />
                      </div>
                      <div className='icon'
                        onClick={() => {
                          window.open(
                            `https://twitter.com/intent/tweet?text=The first decentralized lending protocol for lenders and borrowers on Core blockchain.%0a%0a${config.appUrl}?ref=${myReferralCode}`,
                            '_blank'
                          )
                        }}
                      >
                        <Twitter className="twitter" />
                      </div>
                    </div>
                  </div>
                </div>
              )
                : (
                  <div className='create-container'>
                    <div className='warning'>
                      {t('No_Supply')}
                    </div>
                    <button className='claim-button'
                      disabled={!account}
                      onClick={onCreateReferral}
                    >
                      + {t('Create_referral_link')}
                    </button>
                  </div>
                )
            }
          </div>
          <div className="container">
            <div className="display-grid">
              <div className='item-container'>
                <div className='item-title'>
                  {t('Total_Referees')}
                  <AlertHelper text={t('Users_Referred_Alert')} />
                </div>
                <div className='item-content'>
                  {`${new BigNumber(firstRef).plus(secondRef).toFormat(0)}`}
                  <span>
                    {`(${t('Tier1')} - ${new BigNumber(firstRef).toFormat(0)} / ${t('Tier2')} - ${new BigNumber(secondRef).toFormat(0)})`}
                  </span>
                </div>
              </div>
              <div className='divider only-mobile' />
              <div className='item-container'>
                <div className='item-title'>
                  {t('Total_Rewards')}
                  <AlertHelper text={t('Claimable_Reward_Alert')} />
                </div>
                <div className='item-content'>
                  <img src={`/images/coins/ars.png`} alt={'ars'} />
                  {`${referralReward.plus(claimedReward).div(1e18).toFormat(2)} ARS`}
                </div>
              </div>
              <div className='divider only-mobile' />
              <div className='item-container'>
                <div className='item-title'>
                  {t('Total_Claimed')}
                  <AlertHelper text={t('Total_Claimed_Alert')} />
                </div>
                <div className='item-content'>
                  <img src={`/images/coins/ars.png`} alt={'ars'} />
                  {`${claimedReward.div(1e18).toFormat(2)} ARS`}
                </div>
              </div>
            </div>
            <div className='divider' />
            <div className='create-container'>
              <div className='title'>
                {referralReward.div(1e18).toFormat(4)}
                <span>
                  ARS {t('Available')}
                </span>
              </div>
              <button className='claim-button'
                disabled={referralReward.eq(0) || claimLoading}
                onClick={onClaim}
              >
                {claimLoading && <Loading size={'12px'} margin={'3px'} />}
                {t('Claim')}
              </button>
            </div>
          </div>
        </div>

        <ReferralHistory />
      </VaultContainer>
    </MainLayout>
  )
}

export default Referral;