import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import { getFormatStringFromBignumber } from 'utilities/common';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from 'hooks';
import { restService } from 'utilities';
import AlertHelper from 'components/common/AlertHelper';

const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .item-container {
    flex: 1;
    text-align: center;
    @media (max-width: 992px) {
      text-align: left;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      color: rgba(156, 163, 175, 1);
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        padding-left: 4px;
      }
    }
  }
  .divider {
    width: 1px;
    height: 72px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    @media (max-width: 992px) {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

function PortfolioInfo() {
  const totalSupplyBalance = useSelector((state) => state.account.setting.totalSupplyBalance);
  const totalBorrowBalance = useSelector((state) => state.account.setting.totalBorrowBalance);
  const totalAvailableCredit = useSelector((state) => state.account.setting.totalAvailableCredit);

  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();

  const [contributionScore, setContributionScore] = useState(new BigNumber(0));

  useEffect(() => {
    const getData = async () => {
      const apiRequest = await restService({
        chainId: requiredChainId,
        api: `/v1/score?address=${account}`,
        method: 'GET',
      });
      if (apiRequest?.data?.data) {
        setContributionScore(new BigNumber(apiRequest?.data?.data?.score || 0))
      }
    }
    if (account) {
      getData();
    } else {
      setContributionScore(new BigNumber(0));
    }
  }, [account])

  return (
    <StyledContainer className='w-full'>
      <div className='item-container'>
        <h1 className='title'>
          {t("Available_Credit")}
        </h1>
        <div className='desc'>
          ${getFormatStringFromBignumber(new BigNumber(totalAvailableCredit))}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Supply_Balance")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(new BigNumber(totalSupplyBalance))}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Borrow_Balance")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(new BigNumber(totalBorrowBalance))}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Contribution_Score")}
          <AlertHelper text={t('Contribution_Score_Alert')} />
        </div>
        <div className='desc'>
          {getFormatStringFromBignumber(contributionScore, 3)}
        </div>
      </div>
    </StyledContainer>
  )
}

export default PortfolioInfo;