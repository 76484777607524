import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useActiveWeb3React } from "hooks";
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { connectAccount } from 'core';
import { useStakingData, useWithdrawCallback } from 'hooks/useStaking';
import Modal from 'components/UI/Modal';
import Loading from 'components/UI/Loading';

import { ReactComponent as CloseIcon } from 'assets/img/common/close.svg'

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    padding: 0px 0px 16px 0px;
    gap: 10px;
    border-bottom: 1px solid #FFFFFF1A;
    @media (max-width: 992px) {
      font-size: 16px;
    }
    .settings-close-container {
      outline: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: 100%;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px solid #1F2937;
    background: #00000033;
    input {
      flex: 1;
      background: transparent;
      border: none;
      &:focus {
        outline: none;
      }
      ::placeholder {
        padding: 0 !important;
        color: #9CA3AF;
      }
    }
    .max-button {
      padding: 5px 10px;
      border-radius: 4px;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
  }


  .span- {
    margin-left: 8px;
  }

  .value {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .span-total {
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }

  .align-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .balance_row {
    font-size: 14px;
    font-weight: 600;
  }

  .info {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    p {
      color: #9CA3AF;
    }
    .span-ars {
      font-size: 12px;
    }
    .value {
      font-size: 14px;
    }
  }

  .confirm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    gap: 10px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    &:hover {
      box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }
`;

function PenaltyModal({ visible, onCancel, settings, update, setUpdate }) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const [arsPrice, setArsPrice] = useState(0);
  const [wcorePrice, setWcoreArsPrice] = useState(0);

  useEffect(() => {
    if (settings.arsPrice) {
      setArsPrice(Number(settings.arsPrice));
    }
  }, [settings.arsPrice]);

  useEffect(() => {
    const market = settings.markets.find(
      ele => ele.underlyingSymbol === 'CORE'
    );
    if (market) {
      setWcoreArsPrice(Number(market.tokenPrice));
    }
  }, [settings.markets]);

  const { unlockedBalance, totalEarned, withdrawableBalance } = useStakingData(
    arsPrice,
    wcorePrice,
    update
  );

  const { handleWithdraw, pending } = useWithdrawCallback(
    account
  );

  const [claimAmount, setClaimAmount] = React.useState('');

  const withdraw = async () => {
    if (pending) {
      return;
    }

    const tx = await handleWithdraw(
      new BigNumber(claimAmount).times(1e18).toString(10)
    );
    if (tx) {
      onCancel();
      setUpdate(!update);
    }
  };

  const content = (
    <ModalContent>
      <div className='modal-header'>
        <div>{t('Claim_with_penalty')}</div>
        <div className="settings-close-container" onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="modal-body">
        <div className="input-container mb-2">
          <div className="flex align-center space-between balance_row">
            <p className="amount">{t('Amount')}</p>
            <p className="balance">
              {t('Max_Claimable')} {withdrawableBalance.div(1e18).toFixed(3)}{' '}
              <span className="span-ars">ARS</span>
            </p>
          </div>
          <div className="flex align-center space-between">
            <div className="flex align-center">
              <input
                placeholder="0.00"
                value={claimAmount}
                onChange={event => {
                  if (
                    !event.target.value.length ||
                    Number(event.target.value) >= 0
                  )
                    setClaimAmount(event.target.value);
                }}
              />
            </div>
            <button
              type="button"
              className="max-button"
              onClick={() => {
                setClaimAmount(withdrawableBalance.div(1e18).toString(10));
              }}
            >
              {t('MAX')}
            </button>
          </div>
        </div>
        <div className="info">
          <p>{t('Released_ARS_Vested')}</p>
          <div className="flex">
            <span className="value">
              {unlockedBalance.div(1e18).toFixed(3)}
            </span>
            <span className="span-ars">&nbsp;ARS</span>
          </div>
        </div>
        <div className="info">
          <p>{t('Vesting_ARS')}</p>
          <div className="flex">
            <span className="value">{totalEarned.div(1e18).toFixed(3)}</span>
            <span className="span-ars">&nbsp;ARS</span>
          </div>
        </div>
        <div className="info">
          <p>{t('Early_Claimed_Penalty')}</p>
          <div className="flex">
            <span className="value">
              {Number(claimAmount) > unlockedBalance.div(1e18).toNumber()
                ? `-${(
                  Number(claimAmount) - unlockedBalance.div(1e18).toNumber()
                ).toFixed(3)}`
                : 0}
            </span>
            <span className="span-ars">&nbsp;ARS</span>
          </div>
        </div>
        <div className="info">
          <p>{t('You_Will_Receive')}</p>
          <div className="flex">
            <span className="value">{claimAmount}</span>
            <span className="span-ars">&nbsp;ARS</span>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="confirm-btn"
        onClick={withdraw}
        disabled={
          pending ||
          new BigNumber(Number(claimAmount)).eq(0) ||
          withdrawableBalance.lt(new BigNumber(claimAmount).times(1e18))
        }
      >
        {
          pending ? (
            <div className="flex justify-center items-center">
              <Loading size={'16px'} margin={'7px'} />
              {t('Pending')}
            </div>
          ) : (
            t('Confirm')
          )
        }
      </button>
    </ModalContent>
  )

  return (
    <Modal
      content={content}
      open={visible}
      onCloseModal={() => {
        onCancel();
      }}
      containerStyle={{
        maxWidth: 650,
      }}
    />
  );
}

PenaltyModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func
};

PenaltyModal.defaultProps = {
  visible: false,
  onCancel: () => { }
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

export default compose(
  withRouter,
  connectAccount(mapStateToProps, null)
)(PenaltyModal);
