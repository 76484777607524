import ReactMarkdown from 'react-markdown';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const Styles = styled.div`
//   flex: 1;
  width: 70%;
  @media (max-width: 992px) {
      width: 100%;
  }
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  .overflow-wrap-break-word {
    overflow-wrap: break-word;
  }
  h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgba(113,117,121,var(--tw-text-opacity));
  }
  a {
      color: #4fd000;
  }

`;
function LinkRenderer(props) {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    );
}

const ProposalDetails = ({ proposalInfo }) => {
    const { t } = useTranslation();
    return (
        <Styles className="rounded p-8 mt-8">
            <div>
                <div className="text-midBlue text-2xl font-bold">{t("Operation")}</div>
                <div className="flex flex-col space-y-1 mt-2 text-gray3 pl-8">
                    {(proposalInfo.actions || []).map((s, idx) => (
                        <ReactMarkdown
                            components={{ a: LinkRenderer }}
                            className="text-2xl overflow-wrap-break-word"
                            key={idx}
                        >
                            {s.title}
                        </ReactMarkdown>
                    ))}
                </div>
                <div className="text-midBlue text-2xl font-bold mt-6">{t("Description")}</div>
                <div className="text-gray3 text-2xl mt-4 pl-8">
                    <ReactMarkdown
                    >
                        {proposalInfo.description}
                    </ReactMarkdown>
                </div>
            </div>
        </Styles>
    )
}

ProposalDetails.defaultProps = {
    proposalInfo: {}
}

export default ProposalDetails;
