import { createAction } from "redux-actions";

/**
 * Action Types
 */
export const UPDATE_BLOCK_NUMBER = '@application/updateBlockNumber';
export const UPDATE_NETWORK_ID = '@application/updateNetworkId';


/**
 * Action Creators
 */
export const applicationActionCreators = {
	updateBlockNumber: createAction(UPDATE_BLOCK_NUMBER),
	updateNetworkId: createAction(UPDATE_NETWORK_ID),
};


