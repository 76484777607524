import { useActiveWeb3React } from "hooks";
import { Archive } from "react-feather";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { getFormatStringFromBignumber } from "utilities/common";
import { useIndividualExit } from "hooks/useLpStaking";
import NoHistory from "../../assets/img/common/no_history.svg"

const StyedContainer = styled.div`
  display: flex;
  gap: 16px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    .title {
      padding: 0px 0px 16px 0px;
      gap: 10px;
      border-bottom: 1px solid #1F2937;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .no-history-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        height: 112px;
      }
    }
    .history-container {
      gap: 16px;
      .history-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        padding-bottom: 16px;
        border-bottom: 1px solid #1F2937;
      }
      .history-items {
        height: 150px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 10px;
        .history-item {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #9CA3AF;
          .amount-container {
            display: flex;
            align-items: center;
            .multifier {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.2);
              padding: 0px 5px;
              width: 35px;
              border-radius: 3px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .history-container1 {
      .history-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        padding-bottom: 16px;
        border-bottom: 1px solid #1F2937;
      }
      .history-items {
        height: 150px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 10px;
        .history-item {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #9CA3AF;
          .penalty-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              color: #F87171;
              flex-basis: 60%;
            }
            svg {
              margin-left: 5px;
            }
          }
        }
      }
    }
`;

function MyStats({
  lockList,
  vestingList,
  update,
  setUpdate,
}) {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { pending: individualExitPending, handleIndividualExit } = useIndividualExit();

  const onIndividualExit = async (unlocktime) => {
    if (individualExitPending || !account) return;
    const tx = await handleIndividualExit(unlocktime);
    if (tx) {
      setUpdate(!update);
    }
  }

  const getTimeText = (timeStamp) => {
    const diff = parseInt(timeStamp - Date.now() / 1000)
    if (diff < 0) return '00:00';
    const days = diff / (24 * 3600)
    if (days >= 2) return `${parseInt(days)} ${t('days')}`
    if (days >= 1) return `${parseInt(days)} ${t('day')}`
    const hours = parseInt(diff / (3600))
    const mins = parseInt((diff - hours * 3600) / 60);
    return `${hours > 9 ? hours : `0${hours}`}:${mins > 9 ? mins : `0${mins}`}`
  }

  return (
    <StyedContainer>
      <div className='content-container'>
        <div className="title">
          {t('Lock_Stats')}
        </div>
        <div className="history-container">
          <div className="divider1" />
          <div className="history-header">
            <div>{t('Amount')}</div>
            <div>{t('Unlockable_in')}</div>
          </div>
          <div className="divider2" />
          <div className="history-items">
            {
              lockList.map((data, index) => {
                return (
                  <div key={index} className="history-item">
                    <div className="amount-container">
                      <div className="multifier">
                        {getFormatStringFromBignumber(data.multiplier, 0, 2)}x
                      </div>
                      {getFormatStringFromBignumber(data.amount.div(1e18))} LP
                    </div>
                    <div>
                      {getTimeText(data.unlockTime)}
                    </div>
                  </div>
                )
              })
            }
            {
              !lockList.length && (
                <div className="no-history-container">
                  <img src={NoHistory} alt="No Vest History" />
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="title">
          {t('Vest_Stats')}
        </div>
        <div className="history-container1">
          <div className="history-header">
            <div>{t('Amount')}</div>
            <div>{t('Unlockable_in')}</div>
            <div>{t('Penalty')}</div>
          </div>
          <div className="history-items">
            {
              vestingList.map((data, index) => {
                return (
                  <div key={index} className="history-item">
                    <div>
                      {getFormatStringFromBignumber(data.amount.div(1e18))} ARS
                    </div>
                    <div>
                      {getTimeText(data.unlockTime)}
                    </div>
                    <div className="penalty-container"
                      onClick={() => {
                        onIndividualExit(data.unlockTime);
                      }}
                    >
                      <span>
                        {getFormatStringFromBignumber(data.penalty.div(1e18))}
                      </span>
                      <Archive size={13} />
                    </div>
                  </div>
                )
              })
            }
            {
              !vestingList.length && (
                <div className="no-history-container">
                  <img src={NoHistory} alt="No Vest History" />
                </div>
              )
            }
          </div>
        </div>
      </div>

    </StyedContainer>
  )
}

export default MyStats;