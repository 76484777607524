import { useEffect, useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";

import { useActiveWeb3React } from "hooks";
import Layout from "../../layouts/MainLayout/MainLayout";
import Loading from "../../components/UI/Loading";
import HistoryTable from "../../components/History/HistoryTable";
import styled from "styled-components";
import { restService } from "utilities";
import { getEtherscanLink } from "utils";
import Pagination from "rc-pagination";
import { useWindowSize } from "hooks/useWindowSize";
import HistoryMobileItem from "./HistoryMobileItem";

import close from '../../assets/img/common/close.svg';
import noData from '../../assets/img/common/no_history.svg';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 992px) {
    gap: 32px;
  }
  .tx-container {
    display: flex;
    justify-content: flex-start;
    .tx-hash {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        color: #40C4FF;
        text-decoration: underline;
      }
      @media (max-width: 992px) {
        font-size: 12px;
      }
    }
    @media (max-width: 992px) {
      justify-content: flex-end;
    }
  }
  .method {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    padding: 4px 12px 4px 12px;
    border-radius: 999px;
    border: solid 1px #000;
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;   
      color: #F9FAFB;   
    }
  }
  .block-container {
    display: flex;
    justify-content: flex-start;
    .block-hash {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: #9CA3AF;
      &:hover {
        color: #F9FAFB;
        text-decoration: underline;
      }
      @media (max-width: 992px) {
      }
    }
  }
  .age {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #9CA3AF;
    &:hover {
      color: #F9FAFB;
    }
    @media (max-width: 992px) {
    }
  }
  .address {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #9CA3AF;
    text-align: left;
    &:hover {
      color: #F9FAFB;
      text-decoration: underline;
    }
    @media (max-width: 992px) {
    }
  }
  .value-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    img {
      height: 32px;
      margin-right: 8px;
    }
    @media (max-width: 992px) {
    }
  }
  .navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 3px;
  .filter-item {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #FFFFFF1A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    border-radius: 999px;
    padding: 3px 10px 3px 10px;
    .close {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #FFF;
      border-radius: 999px;
      cursor: pointer;
      margin-left: 8px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #FFFFFF1A;
  .tab-container {
    display: flex;
    gap: 24px;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
const NoAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background: #FFFFFF0D;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 6px 6px;
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: rgb(157, 159, 167);
    text-align: center;
  }
`;

const TabItem = styled.h1`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: ${props => props.active ? '#F9FAFB' : '#9CA3AF'};
  cursor: pointer;
  text-align: center;
  &:hover {
    color: #F9FAFB;
  }
  .bottom-active {
    width: 100%;
    height: 4px;
    margin-top: 4px;
    border-radius: 4px 4px 0px 0px;
    background: ${props => props.active ? '#FFFFFF' : 'transparent'};
  }
  @media (max-width: 992px) {
    flex: 1;
  }
`;

const History = () => {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();

  const [user, setUser] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [isLoading, setIsLoding] = useState(false);
  const [block, setBlock] = useState(null);
  const [fromAddress, setFromAddress] = useState(null);
  const [toAddress, setToaddress] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrntPage] = useState(1);

  const getARSInfo = async ({
    limit = 10,
    offset = 0,
    userAddress,
    from,
    to,
    bfrom,
    bto
  }) => {
    setIsLoding(true);
    setHistoryData([]);
    const apiRequest = await restService({
      chainId: requiredChainId,
      // eslint-disable-next-line max-len
      api: `/v1/user/history?limit=${limit}&offset=${offset}&userAddress=${userAddress}&fromAddress=${from}&toAddress=${to}&action=${''}&fromBlock=${bfrom}&toBlock=${bto}`,
      method: 'GET',
    });

    if (apiRequest?.data?.data) {
      setHistoryData(apiRequest.data.data.rows || []);
      setTotalCount(apiRequest.data.data.count)
    }
    setIsLoding(false);
  };

  useEffect(() => {
    getARSInfo({
      offset: (currentPage - 1) * 10,
      userAddress: user ? account : '',
      from: fromAddress || '',
      to: toAddress || '',
      bfrom: block?.from || '',
      bto: block?.to || '',
    });
  }, [account, requiredChainId]);

  const initeTab = (flag) => {
    setUser(flag);
    setBlock();
    setFromAddress();
    setCurrntPage(1);
    getARSInfo({
      offset: 0,
      userAddress: flag ? account : '',
      from: '',
      to: '',
      bfrom: '',
      bto: '',
    });
  };

  const updatePage = (value) => {
    setCurrntPage(value);
    getARSInfo({
      offset: (value - 1) * 10,
      userAddress: user ? account : '',
      from: fromAddress || '',
      to: toAddress || '',
      bfrom: block?.from || '',
      bto: block?.to || '',
    });
  }

  const updateBlock = (value) => {
    setCurrntPage(1);
    setBlock(value);
    getARSInfo({
      offset: 0,
      userAddress: user ? account : '',
      from: fromAddress || '',
      to: toAddress || '',
      bfrom: value?.from || '',
      bto: value?.to || '',
    });
  }

  const updateFromAddress = (value) => {
    setCurrntPage(1);
    setFromAddress(value);
    getARSInfo({
      offset: 0,
      userAddress: user ? account : '',
      from: value || '',
      to: toAddress || '',
      bfrom: block?.from || '',
      bto: block?.to || '',
    });
  }

  const updateToAddress = (value) => {
    setCurrntPage(1);
    setToaddress(value);
    getARSInfo({
      offset: 0,
      userAddress: user ? account : '',
      from: fromAddress || '',
      to: value || '',
      bfrom: block?.from || '',
      bto: block?.to || '',
    });
  }

  const getMethodColor = (method) => {
    if (!method) return { background: 'linear-gradient(180deg, rgba(233, 14, 14, 0.2) 0%, rgba(235, 156, 37, 0.2) 100%)', borderColor: '#0EE980' };
    if (method.toLowerCase().includes('repay'))
      return { background: 'linear-gradient(180deg, rgba(233, 198, 14, 0.2) 0%, rgba(219, 235, 37, 0.2) 100%)', borderColor: '#DBEB25' };
    if (method.toLowerCase().includes('supply'))
      return { background: 'linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%)', borderColor: '#2563EB' };
    if (method.toLowerCase().includes('borrow'))
      return { background: 'linear-gradient(180deg, rgba(14, 233, 128, 0.2) 0%, rgba(37, 235, 211, 0.2) 100%)', borderColor: '#25EBD3' };
    if (method.toLowerCase().includes('collect'))
      return { background: 'linear-gradient(180deg, rgba(233, 14, 14, 0.2) 0%, rgba(235, 156, 37, 0.2) 100%)', borderColor: '#0EE980' };
    if (method.toLowerCase().includes('stake'))
      return { background: 'linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%)', borderColor: '#0EA5E9' };
    if (method.toLowerCase().includes('redeem') || method.toLowerCase().includes('withdraw'))
      return { background: 'linear-gradient(180deg, rgba(233, 14, 14, 0.2) 0%, rgba(235, 156, 37, 0.2) 100%)', borderColor: '#E90E0E' };
    if (method.toLowerCase().includes('liquidated')) return { background: '#A8DF8E', borderColor: '#A8DF8E' };
    return { background: 'linear-gradient(180deg, rgba(233, 14, 14, 0.2) 0%, rgba(235, 156, 37, 0.2) 100%)', borderColor: '#0EE980' };
  }

  const columns = useMemo(() => {
    return [{
      Header: t("Txn_Hash"),
      accessor: 'txHash',
      Cell: ({ value }) => {
        return (
          <div className="tx-container">
            <a className="tx-hash" href={getEtherscanLink(requiredChainId, value, 'transaction')} target="_blank" rel="noreferrer">
              {value?.substring(0, 10)}...
            </a>
          </div>
        );
      },
    },
    {
      Header: t("Method"),
      accessor: "action",
      Cell: ({ value }) => {
        return (
          <div className="flex justify-start">
            <div className="method" style={getMethodColor(value)}>{value}</div>
          </div>
        )
      }
    },
    {
      Header: t("Block"),
      accessor: 'blockNumber',
      Cell: ({ value }) => {
        return (
          <div className="block-container">
            <a className="block-hash" href={getEtherscanLink(requiredChainId, value, 'block')} target="_blank" rel="noreferrer">
              {value}
            </a>
          </div>
        )
      }
    },
    {
      Header: t("Age"),
      accessor: 'blockTimestamp',
      Cell: ({ value }) => {
        let delta = Date.now() / 1000 - value;
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        var hours = Math.floor(delta / 3600) % 24;
        if (days > 0) {
          return (
            <div className="age">
              {`${days} ${days > 1 ? t("days") : t("day")} ${hours} ${hours > 1 ? t("hrs") : t("hr")} ${t("ago")}`}
            </div>
          )
        } else {
          delta -= hours * 3600;
          var minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          var seconds = parseInt(delta % 60);
          if (hours > 0) {
            return (
              <div className="age">
                {`${hours} ${hours > 1 ? t("hrs") : t("hr")} ${minutes} ${minutes > 1 ? t("mins") : t("min")} ${t("ago")}`}
              </div>
            )
          }
          return (
            <div className="age">
              {`${minutes} ${minutes > 1 ? t("mins") : t("min")} ${seconds} ${seconds > 1 ? t("secs") : t("sec")} ${t("ago")}`}
            </div>
          )
        }
      }
    },
    {
      Header: t("From"),
      accessor: 'from',
      Cell: ({ value }) => {
        return (
          <div className="flex justify-start">
            <a className="address" href={getEtherscanLink(requiredChainId, value)} target="_blank" rel="noreferrer">
              {value?.substring(0, 4)}...{value?.substring(38)}
            </a>
          </div>
        )
      }
    },
    {
      Header: t("To"),
      accessor: 'to',
      Cell: ({ value }) => {
        return (
          <div className="flex justify-start">
            <a className="address" href={getEtherscanLink(requiredChainId, value)} target="_blank" rel="noreferrer">
              {value?.substring(0, 4)}...{value?.substring(38)}
            </a>
          </div>
        )
      }
    },
    {
      Header: t("Value"),
      accessor: 'value',
      Cell: ({ row }) => {
        const rowData = row.original;
        const amount = new BigNumber(rowData.amount).dividedBy(new BigNumber(10).pow(rowData.decimal));
        return (
          <div className="value-container">
            <img src={`/images/coins/${rowData.symbol?.toLowerCase()}.png`} alt={rowData.symbol} />
            <div>{amount.toFormat(5)} {rowData.symbol}</div>
          </div>
        )
      }
    }]
  }, [t, requiredChainId])


  return (
    <Layout>
      <ContainerStyles>
        <HeaderContainer>
          <div className="tab-container">
            <TabItem active={!user} onClick={() => { initeTab(false) }}>
              {t("All_History")}
              <div className="bottom-active" />
            </TabItem>
            <TabItem active={user} onClick={() => { initeTab(true) }}>
              {t("User_History")}
              <div className="bottom-active" />
            </TabItem>
          </div>
          <FilterContainer>
            {
              (block?.from > 0 || block?.to > 0) &&
              <div className="filter-item">
                {t("Block")}:{block?.from > 0 ? ` ${t("From")} ${block.from}` : ''}{block?.to > 0 ? ` ${t("To")} ${block.to}` : ''}
                <div className="close"
                  onClick={() => updateBlock()}
                >
                  <img src={close} alt="close" />
                </div>
              </div>
            }
            {
              (fromAddress) &&
              <div className="filter-item">
                {t("From")}: {fromAddress?.substring(0, 4)}...{fromAddress?.substring(38)}
                <div className="close"
                  onClick={() => updateFromAddress()}
                >
                  <img src={close} alt="close" />
                </div>
              </div>
            }
            {
              (toAddress) &&
              <div className="filter-item">
                {t("To")}: {toAddress?.substring(0, 4)}...{toAddress?.substring(38)}
                <div className="close"
                  onClick={() => updateToAddress()}
                >
                  <img src={close} alt="close" />
                </div>
              </div>
            }
          </FilterContainer>
        </HeaderContainer>
        <div>
          {
            width > 992 ?
              <HistoryTable
                columns={columns}
                data={historyData}
                block={block}
                setBlock={updateBlock}
                fromAddress={fromAddress}
                setFromAddress={updateFromAddress}
                toAddress={toAddress}
                setToaddress={updateToAddress}
              />
              :
              <HistoryMobileItem data={historyData} getMethodColor={getMethodColor} />
          }
          {
            (user && !account) ? (
              <NoAccountContainer>
                <img src={noData} alt='' />
                <div className="title">{t("Connect_Wallet_transaction")}</div>
              </NoAccountContainer>
            ) : (isLoading) ? (
              <NoAccountContainer>
                <Loading size={'36px'} margin={'0'} />
              </NoAccountContainer>
            ) : (!historyData || historyData.length === 0) ? (
              <NoAccountContainer>
                <img src={noData} alt='' />
              </NoAccountContainer>
            ) :
              null
          }
        </div>
        <div className="navigation-container">
          <Pagination
            current={currentPage}
            defaultCurrent={1}
            defaultPageSize={10}
            pageSize={10}
            total={totalCount}
            onChange={updatePage}
            prevIcon={() => <div>{'<'}</div>}
            nextIcon={() => <div>{'>'}</div>}
            jumpPrevIcon={() => <div>{'<<'}</div>}
            jumpNextIcon={() => <div>{'>>'}</div>}
          />
        </div>
      </ContainerStyles>
    </Layout >
  )
}

export default History;
