import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
  .item-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    border: 1px
    gap: 4px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    border: 1px solid #FFFFFF1A;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      color: #9CA3AF;
      text-align: center;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      text-align: center;      
    }
  }
`;

function MarketInfo() {
  const markets = useSelector((state) => state.account.setting.markets);
  const { t } = useTranslation();

  const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
  const [totalBorrow, setTotalBorrow] = useState(new BigNumber(0));
  const [availableLiquidity, setAvailableLiquidity] = useState(new BigNumber(0));
  const [totalReserves, setTotalReserves] = useState(new BigNumber(0));
  const [totalSuppliers, setTotalSuppliers] = useState(new BigNumber(0));
  const [totalBorrowers, setTotalBorrowers] = useState(new BigNumber(0));

  useEffect(() => {
    if (markets?.length > 0) {
      const tempTS = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.totalSupplyUsd));
      }, 0);
      setTotalSupply(tempTS);
      const tempTB = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.totalBorrowsUsd));
      }, 0);
      setTotalBorrow(tempTB);
      const tempAL = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.liquidity));
      }, 0);
      setAvailableLiquidity(tempAL);
      const tempTSR = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.supplierCount));
      }, 0);
      setTotalSuppliers(tempTSR);
      const tempTBR = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.borrowerCount));
      }, 0);
      setTotalBorrowers(tempTBR);
      const tempRSV = (markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(new BigNumber(market.reserveUSD).times(market.tokenPrice));
      }, 0);
      setTotalReserves(tempRSV);
    }
  }, [markets]);

  return (
    <StyledContainer>
      <div className='item-container'>
        <h1 className='title'>
          {t("Total_Supply")}
        </h1>
        <div className='desc'>
          ${totalSupply.toFormat(2)}
        </div>
      </div>
      <div className='item-container'>
        <div className='title'>
          {t("Total_Borrow")}
        </div>
        <div className='desc'>
          ${totalBorrow.toFormat(2)}
        </div>
      </div>
      <div className='item-container'>
        <div className='title'>
          {t("Available_Liquidity")}
        </div>
        <div className='desc'>
          ${availableLiquidity.toFormat(2)}
        </div>
      </div>
      <div className='item-container'>
        <div className='title'>
          {t("Total_Reserves")}
        </div>
        <div className='desc'>
          ${totalReserves.toFormat(2)}
        </div>
      </div>
      <div className='item-container'>
        <div className='title'>
          {t("Total_Suppliers")}
        </div>
        <div className='desc'>
          {totalSuppliers.toFormat(0)}
        </div>
      </div>
      <div className='item-container'>
        <div className='title'>
          {t("Total_Borrowers")}
        </div>
        <div className='desc'>
          {totalBorrowers.toFormat(0)}
        </div>
      </div>
    </StyledContainer>
  )
}

export default MarketInfo;