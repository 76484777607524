import { useMemo } from "react";
import { useWeb3 } from "./index";
import {
	getTokenContract,
	getStakingContract,
	getReferralContract,
	getMultiFeeDistributionContract,
	getChefIncentivesControllerContract,
	getLockZapContract,
	getLiquidityZapContract,
	getCompounderContract,
	getLpContract
} from 'utilities/ContractService';

export const useStakingContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getStakingContract(web3, chainId), [web3, chainId]);
};

export const useReferralContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getReferralContract(web3, chainId), [web3, chainId]);
};

export const useMultiFeeDistributionContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getMultiFeeDistributionContract(web3, chainId), [web3, chainId]);
};

export const useCompounderContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getCompounderContract(web3, chainId), [web3, chainId]);
};

export const useChefIncentivesControllerContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getChefIncentivesControllerContract(web3, chainId), [web3, chainId]);
};

export const useLockZapContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getLockZapContract(web3, chainId), [web3, chainId]);
};

export const useLiquidityZapContract = (chainId = 42161) => {
	const web3 = useWeb3();
	return useMemo(() => getLiquidityZapContract(web3, chainId), [web3, chainId]);
};

export const useERC20TokenContract = (chainId, symbol) => {
	const web3 = useWeb3();
	return useMemo(() => getTokenContract(web3, symbol, chainId), [web3, symbol]);
}
export const useLpContract = (chainId) => {
	const web3 = useWeb3();
	return useMemo(() => getLpContract(web3, chainId), [web3, chainId]);
};