import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Navigation from '../../components/common/Navigation';
import logo from '../../assets/img/header/ars_logo.svg';
import { accountActionCreators, connectAccount } from '../../core';
import { bindActionCreators } from 'redux';

const StyledConatiner = styled.div`
  height: 72px;
  border-bottom: 1px solid #FFFFFF1A;
  border-radius: 0px 0px 12px 12px;
  background-color: #FFFFFF0D;
  padding: 16px 32px;
  gap: 48px;
  @media (max-width: 992px) {
    padding: 16px;
    gap: 16px;
  }
  .logo-container {
    gap: 12px;
    .logo-title {
      font-size: 16px;
      line-height: 28px;
    }
    .logo-icon {
      width: 32px;
      height: 32px;
    }
  }
`;

function Header({ connectWalletsOpen, setConnectWalletsOpen }) {

  return (
    <header>
      <StyledConatiner className='flex justify-start items-center'>
        <div className="flex items-center cursor-pointer">
          <Link to="/">
            <div className="flex items-center logo-container">
              <img className='logo-icon' src={logo} alt="" />
              <h2 className="only-web logo-title whitespace-nowrap">AQUARIUS LOAN</h2>
            </div>
          </Link>
        </div>
        <Navigation
          connectWalletsOpen={connectWalletsOpen}
          setConnectWalletsOpen={setConnectWalletsOpen}
        />
      </StyledConatiner>
    </header>
  );
}

const mapStateToProps = ({ account }) => ({
  connectWalletsOpen: account.connectWalletsOpen,
});

const mapDispatchToProps = (dispatch) => {
  const { setConnectWalletsOpen } = accountActionCreators;

  return bindActionCreators(
    {
      setConnectWalletsOpen,
    },
    dispatch,
  );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(Header);
