
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import Loading from "components/UI/Loading";
import Switch from "components/UI/SwitchComponent";
import {
  useAutoReLock,
  useLock,
  useReLock,
  useSetDefaultRelockTypeIndex,
  useWithdrawExpiredLocksForWithOptions,
  usedLpApprove
} from "hooks/useLpStaking";
import BigNumber from "bignumber.js";
import { NotificationManager } from "react-notifications";
import { getFormatStringFromBignumber } from "utilities/common";
import { useActiveWeb3React } from "hooks";
import { useSelector } from "react-redux";

const StyedContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #FFFFFF1A;
  gap: 16px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  @media only screen and (max-width: 992px) {
    padding: 24px;
  }
  .title {
    padding: 0px 0px 16px 0px;
    gap: 10px;
    border-bottom: 1px solid #1F2937;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
  }
  .input-conatiner {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px solid #1F2937;
    gap: 10px;
    background: #00000033;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    input {
      flex: 1;
      background: transparent;
      border: none;
      &:focus {
        outline: none;
      }
      ::placeholder {
        padding: 0 !important;
        color: #9CA3AF;
      }
    }
    .max-button {
    }
  }
  .content-container {
    display: flex;
    gap: 32px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 24px;
    }
    .divider {
      border-right: 1px solid #1F2937;
      @media only screen and (max-width: 992px) {
        border-bottom: 1px solid #1F2937;
      }
    }
    .dlp-first {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 23px;
      .balance {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.025em;
        color: #9CA3AF;
      }
      .lock-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        gap: 10px;
        background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
        &:hover {
          box-shadow: 0px 4px 24px 4px #2566EC33;
        }
      }
    }
    .dlp-second {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .tab-container {
        user-select: none;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 4px 0px 4px 0px;
        gap: 10px;
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 78px;
          height: 32px;
          padding: 6px;
          border-radius: 999px;
          border: 1px solid #FFFFFF1A;
          gap: 10px;
          cursor: pointer;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: #9CA3AF;
          white-space: nowrap;
          &:hover {
            box-shadow: 0px 4px 24px 4px #2566EC33;
          }
        }
        .active-tab {
          background: linear-gradient(180deg, rgba(14, 165, 233, 0.5) 0%, rgba(37, 99, 235, 0.5) 100%);
          color: #FFFFFF;
        }
      }
      .apr-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        .content {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
    .dlp-third {
      .sub-container {
        display: flex;
        justify-content: space-between;
      }
    }
    .dlp-fourth {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .withdraw-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .value-container {
          display: flex;
          gap: 16px;
          img {
            margin-right: 5px;
            // width: 25px;
            height: 32px;
          }
          .value {
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.025em;
          }
        }
        .button-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 12px;
          @media only screen and (max-width: 992px) {
            grid-template-columns: repeat(1, 1fr);
          }
          button {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #FFFFFF1A;
            gap: 8px;
            &:hover {
              box-shadow: 0px 4px 24px 4px #2566EC33;
            }
            @media only screen and (max-width: 992px) {
            }
          }
        }
      }
    }
    .sub-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
  }
`;

function LpOverview({
  update,
  setUpdate,
  defaultLockIndex,
  myDLpBalance,
  myDLpAllowance,
  myUnlockable,
  autoRelockDisabled,
  lockedSupplyWithMultiplier,
  LockAprData,
}) {
  const currentNetworkId = useSelector((state) => state.application.currentNetworkId || 0);
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();

  const { pending: approvePending, handleApprove } = usedLpApprove();
  const { pending: approveRelockPending, handleApprove: handleApproveRelock } = usedLpApprove();
  const { pending: lockPending, handleLock } = useLock();
  const { pending: reLockPending, handleReLock } = useReLock();
  const { pending: withdrawPending, handleWithdraw } = useWithdrawExpiredLocksForWithOptions();
  const { pending: autoReLockPending, handleAutoReLock } = useAutoReLock();
  const { pending: defaultIndexPending, handleSetDefaultRelockTypeIndex } = useSetDefaultRelockTypeIndex();

  const [lockAmount, setLockAmount] = useState('');

  const needToApprove = useMemo(() => {
    if (!lockAmount && new BigNumber(lockAmount).lte(0)) return false;
    return new BigNumber(lockAmount).times(1e18).gt(myDLpAllowance);
  }, [myDLpAllowance, lockAmount])

  const needToApproveForRelock = useMemo(() => {
    if (!lockAmount && new BigNumber(myUnlockable).lte(0)) return false;
    return new BigNumber(myUnlockable).gt(myDLpAllowance);
  }, [myDLpAllowance, myUnlockable])

  const onLock = async () => {
    const bigLockAmount = new BigNumber(lockAmount).times(1e18);
    if (bigLockAmount.gt(myDLpBalance)) {
      NotificationManager.error(t('Insufficient_Balance'));
      return;
    }
    if (needToApprove) {
      const tx = await handleApprove(bigLockAmount);
      if (tx) {
        setUpdate(!update);
      }

    } else {
      const tx = await handleLock(bigLockAmount, defaultLockIndex);
      if (tx) {
        setUpdate(!update);
        setLockAmount('');
      }
    }
  }

  const onReLock = async () => {
    if (needToApproveForRelock) {
      const tx = await handleApproveRelock(myUnlockable);
      if (tx) {
        setUpdate(!update);
      }
    } else {
      const tx = await handleReLock();
      if (tx) {
        setUpdate(!update);
      }
    }
  }

  const onWithdraw = async () => {
    const tx = await handleWithdraw();
    if (tx) {
      setUpdate(!update);
    }
  }

  const onAutoReLock = async () => {
    if (autoReLockPending || !account) return;
    const tx = await handleAutoReLock(autoRelockDisabled);
    if (tx) {
      setUpdate(!update);
    }
  }

  const onSetDefaultLockIndex = async (index) => {
    if (!account || defaultLockIndex === index || defaultIndexPending) return;
    const tx = await handleSetDefaultRelockTypeIndex(index);
    if (tx) {
      setUpdate(!update);
    }
  }

  const LockData = useMemo(() => {
    return [
      { title: `1 ${t('month')}` },
      { title: `3 ${t('months')}` },
      { title: `6 ${t('months')}` },
      { title: `12 ${t('months')}` },
    ]
  }, [t]);

  return (
    <StyedContainer>
      <div className="title">
        {t('dLP_overview')}
      </div>
      <div className="content-container">
        <div className="dlp-first">
          <div className="balance">
            {myDLpBalance.div(1e18).toFormat(2)}{' '}
            <span> LP</span>
          </div>
          <div className="input-conatiner">
            <input
              placeholder={t("Enter_amount")}
              value={lockAmount}
              onChange={event => {
                if (!event.target.value.length || Number(event.target.value) >= 0)
                  setLockAmount(event.target.value);
              }}
            />
            <button
              type="button"
              className="max-button"
              disabled={!account}
              onClick={() => {
                setLockAmount(myDLpBalance.div(1e18).toString());
              }}
            >
              {t('MAX')}
            </button>
          </div>
          <button
            type="button"
            className="lock-button"
            disabled={!account || lockPending || approvePending || !lockAmount || new BigNumber(lockAmount).eq(0)}
            onClick={onLock}
          >
            {(lockPending || approvePending) ? (
              <div className="flex justify-center items-center">
                <Loading size={'16px'} margin={'7px'} />
                {t('Pending')}
              </div>
            ) : (
              <>{needToApprove ? t('Approve') : t('Lock')}</>
            )}
          </button>
        </div>
        <div className="divider" />
        <div className="dlp-second">
          <div className="sub-title">
            {t('Default_lock_length')}
          </div>
          <div className="desc">
            {t('Change_made_to_default')}
          </div>
          <div className="tab-container">
            {
              LockData.map((data, index) => {
                return (
                  <div key={index} className={`tab ${defaultLockIndex === index ? 'active-tab' : ''}`}
                    onClick={() => onSetDefaultLockIndex(index)}
                  >
                    {data.title}
                  </div>
                )
              })
            }
          </div>
          <div className="apr-container">
            {
              LockAprData.map((data, index) => {
                return (
                  <div key={index} className="content">
                    {lockedSupplyWithMultiplier.isZero() ? '--%' : `${getFormatStringFromBignumber(data)}%`}
                  </div>
                )
              })
            }
          </div>
        </div>
        {/*
        <div className="dlp-third">
          <div className="sub-title">
            {t('Auto_relock')}: <span>{autoRelockDisabled ? t('Disabled') : t('Enabled')}</span>
          </div>
          <div className="sub-container">
            <div className="desc">
              {t('Automatically_relock_expired', { value: LockData[defaultLockIndex].title })}
            </div>
            <Switch
              inactiveStateColor={"bg-darkGray"}
              activeStateColor={"bg-darkGray"}
              slideClassName={"bg-lightGreen border-lightGreen"}
              inactiveSlideClassName={'bgGrayGradient border-gray2'}
              wrapperClassName="pt-1 pb-0"
              style={'small'}
              value={!autoRelockDisabled}
              onChange={onAutoReLock}
            />
          </div>
        </div> */}
        <div className="divider" />
        <div className="dlp-fourth">
          <div className="sub-title">
            {t('Redeemable_locks')}
          </div>
          <div className="desc">
            {t('Relock_your_dLP')}
          </div>
          <div className="withdraw-container">
            <div className='value-container'>
              <img src={`/images/coins/dlp_${currentNetworkId}.png`} alt={'ars'} />
              <div className={'value'}>
                {getFormatStringFromBignumber(myUnlockable.div(1e18))}
              </div>
            </div>
            <div className="button-container">
              <button
                type="button"
                disabled={!account || myUnlockable.lte(0) || reLockPending || approveRelockPending}
                onClick={onReLock}
              >
                {(reLockPending || approveRelockPending) ? (
                  <div className="flex justify-center items-center">
                    <Loading size={'16px'} margin={'7px'} />
                    {t('Pending')}
                  </div>
                ) : (
                  <>{needToApproveForRelock ? t('Approve_For_Lock') : t('Lock')}</>
                )}
              </button>
              <button
                type="button"
                disabled={!account || myUnlockable.lte(0) || withdrawPending}
                onClick={onWithdraw}
              >
                {(withdrawPending) ? (
                  <div className="flex justify-center items-center">
                    <Loading size={'16px'} margin={'7px'} />
                    {t('Pending')}
                  </div>
                ) : (
                  <>{t('Withdraw')}</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyedContainer>
  )
}

export default LpOverview;