import { withRouter } from "react-router-dom";
import { compose } from "redux";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import moment from "moment";

import circleCross from '../../../assets/icons/circleCross.svg';
import circleTick from '../../../assets/icons/circleTick.svg';
import Progress from "../../UI/BorrowLimitProgress";

const VoteHistoryItem = ({ proposal, support, history }) => {
    const { t } = useTranslation();
    const [forPercent, setForPercent] = useState(0);
    const [againstPercent, setAgainstPercent] = useState(0);

    useEffect(() => {
        const total = new BigNumber(proposal.forVotes).plus(
            new BigNumber(proposal.againstVotes)
        );
        setForPercent(
            new BigNumber(proposal.forVotes * 100).div(total).isNaN()
                ? '0'
                : new BigNumber(proposal.forVotes * 100).div(total).toString()
        );
        setAgainstPercent(
            new BigNumber(proposal.againstVotes * 100).div(total).isNaN()
                ? '0'
                : new BigNumber(proposal.againstVotes * 100).div(total).toString()
        );
    }, [proposal]);

    const getDescription = p => {
        if (p === 'Create Proposal') {
            return t('Create_Proposal');
        }
        return p;
    };

    const getStatus = p => {
        if (p.state === 'Executed') {
            return t('Passed');
        }
        if (p.state === 'Active') {
            return t('Active');
        }
        if (p.state === 'Defeated') {
            return t('Failed');
        }
        if (p.state === 'Expired') {
            return t("Expired")
        }
        return p.state;
    };


    const getStatusColor = p => {
        if (p.state === 'Executed') {
            return 'lightGreen3';
        }
        if (p.state === 'Active') {
            return 'midBlue';
        }
        if (p.state === 'Defeated') {
            return 'lightRed';
        }
        return 'midBlue';
    };

    return (
        <div
            className=""
            onClick={() => history.push(`/vote/proposal/${proposal.id}`)}
        >
            <div
                className="flex flex-row space-y-4 md:space-y-0 items-center justify-between
                            border-b-2 border-solid border-gray4 pt-14 pb-4 cursor-pointer"
            >
                <div className="">
                    <div className="text-2xl text-gray3">
                        <ReactMarkdown>
                            {getDescription(proposal.description.split('\n')[0])}
                        </ReactMarkdown>
                    </div>
                    <div className="flex space-x-3 text-base mt-2 flex-wrap-custom">
                        <div className="text-lightBlue text-lg">{proposal.id}</div>
                        <div className="text-gray3 text-lg">{getStatus(proposal)}</div>
                        <div className="text-lg">
                            {moment(proposal.createdAt).format('MMMM Do, YYYY')}
                        </div>
                        <div className={`text-${getStatusColor(proposal)} text-lg`}>
                            {getStatus(proposal)}
                        </div>
                    </div>
                </div>
                {/* <Progress
                    wrapperClassName="w-72"
                    percent={
                        support ? Number(forPercent) : Number(againstPercent)
                    }
                    color={
                        support ? "#4FD000" : "#fd5353"
                    }
                /> */}
                {support ? (
                    <img className="tick-icon" src={circleTick} alt="" />
                ) : (
                    <img className="tick-icon" src={circleCross} alt="" />
                )}
                <div className="font-bold text-2xl text-lightBlue">
                    {support ? t('For') : t('Against')}
                </div>
            </div>
        </div>
    )
}

export default compose(withRouter)(VoteHistoryItem);
