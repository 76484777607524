import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
import styled from 'styled-components';

import Bg_gradient from '../../assets/img/Bg-gradient.svg';

const Styles = styled.div`
  .modal-container {
    color: #F9FAFB;
    .modal-background-container {
      background: #000;
      border-radius: 12px;
      border: 1px solid #FFFFFF1A;
      @media only screen and (max-width: 992px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
    .modal-content-container {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
      border-radius: 12px;
    }
  }
`

const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 0px;
  left: 0px;
  top: 0px;
  filter: blur(160px);
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
  pointer-events: none;
`

export default function Modal({
  open,
  title,
  content,
  onCloseModal,
  containerStyle = { maxWidth: 800 } }
) {
  const cancelButtonRef = useRef();

  return (
    <div className="relative">
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={onCloseModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-75" />
          <Styles className="text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={'w-full inline-block relative modal-container text-left align-middle transition-all transform'}
                style={containerStyle}
              >
                <div className='modal-background-container'>
                  {/* <GradientBackground>
                  <img src={Bg_gradient} alt='' />
                </GradientBackground> */}
                  <div className='modal-content-container'>
                    {
                      title &&
                      <Dialog.Title as="h3">
                        {title}
                      </Dialog.Title>
                    }
                    {content}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Styles>
        </Dialog>
      </Transition>
    </div >
  );
}
