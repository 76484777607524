import BigNumber from 'bignumber.js';
import { useMultiFeeDistributionAPRs } from 'hooks/useLpStaking';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 30px;
  .info-container {
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .item-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .title {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
      }
      .value1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: -0.025em;
      }
      .value2 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-decoration: line-through;
      }
    }
  }
  .lock-container {
    .locks {
      display: grid;
      column-gap: 0.5em;
      row-gap: 0.5em;
      grid-template-columns: repeat(2, 1fr);
      padding: 25px 0;
      .lock {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #FFFFFF1A;
        border-radius: 10px;
        height: 100px;
        cursor: pointer;
        :hover {
          border-color: #0EA5E9;
        }
        .max {
          position: absolute;
          background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
          width: 100;
          top: -20px;
          width: calc(100% + 2px);
          height: 30px;
          display:flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: 600;
          border-radius: 10px 10px 0 0;
        }
        .title {
          font-size: 15px;
          font-weight: 600;
        }
        .apr {

        }
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF1A;
    margin: 15px 0;
  }
  .v-divider {
    width: 1px;
    background-color: #FFFFFF1A;
  }
  .desc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #9CA3AF;
  }
  .continue-button-container {
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 24px 16px 24px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
  }
`

function ZapModalStep2({
  setStep,
  selectedMuliplier,
  setSelectedMuliplier,
  dLpPrice,
  myDLpLocked,
  newZapInUsd,
  lockedUsdValue,
  requiredUsdValue,
  lockedSupplyWithMultiplier,
  LockAprData,
  annualReward,
}) {
  const { t } = useTranslation();

  const myDLpLockedInUsd = useMemo(() => {
    return myDLpLocked.times(dLpPrice).div(1e18);
  }, [myDLpLocked, dLpPrice]);

  const myAnnualRewardInUsd = useMemo(() => {
    return annualReward.times(dLpPrice).div(1e18);
  }, [annualReward, dLpPrice]);

  const myAnnualNewRewardInUsd = useMemo(() => {
    return myAnnualRewardInUsd.plus(newZapInUsd.times(LockAprData[3 - selectedMuliplier].div(100)));
  }, [myAnnualRewardInUsd, LockAprData, selectedMuliplier]);


  const LockData = useMemo(() => {
    return [
      { title: `12 ${t('months')}` },
      { title: `6 ${t('months')}` },
      { title: `3 ${t('months')}` },
      { title: `1 ${t('month')}` },
    ]
  }, [t]);

  return (
    <ContainerStyles className="pt-3 pb-6 px-8">
      <div className='info-container'>
        <div className='item-container'>
          <div className='title'>
            {t('Total_LP_locked')}
          </div>
          <div className='value1'>
            ${getFormatStringFromBignumber(myDLpLockedInUsd.plus(newZapInUsd))}
          </div>
          <div className='value2'>
            ${getFormatStringFromBignumber(myDLpLockedInUsd)}
          </div>
        </div>
        <div className='v-divider' />
        <div className='item-container'>
          <div className='title'>
            {t('Annual_rewards')}
          </div>
          <div className='value1'>
            ${getFormatStringFromBignumber(myAnnualNewRewardInUsd)}
          </div>
          <div className='value2'>
            ${getFormatStringFromBignumber(myAnnualRewardInUsd)}
          </div>
        </div>
      </div>
      <div className='divider' />
      <div className='lock-container'>
        <div>
          {t('Please_select_lock_duration')}
        </div>
        <div className='locks'>
          {
            LockData.map((data, index) => (
              <div
                key={index}
                className={`lock`}
                style={selectedMuliplier === index ? { borderColor: '#0EA5E9', color: '#0EA5E9' } : {}}
                onClick={() => setSelectedMuliplier(index)}
              >
                {index === 0 && <div className='max'>{t('Maximum_multiplier')}</div>}
                <div className='title'>{data.title}</div>
                <div className='apr'>
                  {lockedSupplyWithMultiplier.isZero() ? '--%' : `${getFormatStringFromBignumber(LockAprData[3 - index])}%`} APR
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="continue-button-container" >
        <button
          type="button"
          disabled={false}
          onClick={() => {
            setStep(2);
          }}
        >
          {t('Continue')}
        </button>
      </div>
      <div className='divider' />
      <div>
        <div className='desc-container'>
          <div> {t('Total_supply_balance')}</div>
          <div>${getFormatStringFromBignumber(requiredUsdValue.times(20).div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div>{t('Required_locked_dLP')}</div>
          <div>${getFormatStringFromBignumber(requiredUsdValue.div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div>{t('Currently_locked')}</div>
          <div>${getFormatStringFromBignumber(lockedUsdValue.div(1e8))}</div>
        </div>
      </div>
    </ContainerStyles >
  )
}

export default ZapModalStep2;