import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import ConnectWalletModal from "./ConnectWalletModal";

const ContainerStyles = styled.div`
  width: 100%;
  button {
    width: 100%;
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px solid #0EA5E9
    gap: 10px;
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
    }
  }
`

const ConnectWalletButton = () => {
  const { t } = useTranslation();
  const [connectWalletsOpen, setConnectWalletsOpen] = useState(false);
  return (
    <ContainerStyles>
      <button onClick={() => setConnectWalletsOpen(true)} >
        {t("Connect_Wallet")}
      </button>
      <ConnectWalletModal
        open={connectWalletsOpen}
        onCloseModal={() => setConnectWalletsOpen(false)}
      />
    </ContainerStyles>
  )
}

export default ConnectWalletButton;
