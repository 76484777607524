import BigNumber from 'bignumber.js';
import { useActiveWeb3React } from 'hooks';
import { useLockZap } from 'hooks/useLpStaking';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';
import { isCoreNetwork } from 'utils';

const ContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 30px;
  .info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .amount {
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 54px;
      font-weight: 900;
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: #9CA3AF;
    }
    .mark {
      margin-top: 5px;
      gap: 8px;
      font-size: 12px;
      color: rgb(3, 152, 85);
      background: rgba(3, 152, 85, 0.15);
      border: 1px solid rgba(5, 168, 46, 0.2);
      border-radius: 30px;
      padding: 5px 10px;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF1A;
    margin: 15px 0;
  }
  .v-divider {
    width: 1px;
    background-color: #FFFFFF1A;
  }
  .desc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    .title {
      font-size: 13px;
    }
    .value {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .continue-button-container {
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 24px 16px 24px;
      border-radius: 4px;
      border: 1px solid #0EA5E9;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
  }
`

function ZapModalStep3({
  update,
  setUpdate,
  setOpen,
  tokenInfo,
  amount,
  amountArs,
  selectedMuliplier,
  newZapInUsd,
  lockedUsdValue,
  requiredUsdValue,
  LockAprData,
}) {
  const { t } = useTranslation();
  const { account, requiredChainId } = useActiveWeb3React();
  const { pending, handleZapCore, handleZap } = useLockZap();

  const LockData = useMemo(() => {
    return [
      { title: `12 ${t('months')}` },
      { title: `6 ${t('months')}` },
      { title: `3 ${t('months')}` },
      { title: `1 ${t('month')}` },
    ]
  }, [t]);

  const onZap = async () => {
    if (amount && new BigNumber(amount).gt(0)) {
      let tx;
      if (isCoreNetwork(requiredChainId)) {
        tx = await handleZapCore(
          3 - selectedMuliplier,
          new BigNumber(amount).times(1e18),
          new BigNumber(amountArs || 0).times(1e18),
        );
      } else {
        tx = await handleZap(
          3 - selectedMuliplier,
          new BigNumber(amount).times(10 ** tokenInfo.decimals),
          new BigNumber(amountArs || 0).times(1e18),
          tokenInfo.address
        );
      }
      if (tx) {
        setUpdate(!update);
        setOpen(false);
      }
    }
  }

  return (
    <ContainerStyles className="pt-3 pb-6 px-8">
      <div className='info-container'>
        <div className='amount'>${getFormatStringFromBignumber(newZapInUsd.times(LockAprData[3 - selectedMuliplier].div(100)))}</div>
        <div className='desc'>{t('Your_new_annual_rewards')}</div>
        <div className='mark'>
          <div>{t('Eligible_for_emissions')}</div>
        </div>
      </div>
      <div className='divider' />
      <div>
        <div className='desc-container'>
          <div className='title'>{t('Total_deposit_value')}</div>
          <div className='value'>${getFormatStringFromBignumber(requiredUsdValue.times(20).div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div className='title'>{t('Required_locked_dLP')}</div>
          <div className='value'>${getFormatStringFromBignumber(requiredUsdValue.div(1e8))}</div>
        </div>
        <div className='desc-container'>
          <div className='title'>{t('Currently_locked')}</div>
          <div className='value'>${getFormatStringFromBignumber(lockedUsdValue.div(1e8))}</div>
        </div>
      </div><div className='divider' />
      <div>
        {/* <div className='desc-container'>
          <div className='title'>{t('Network_borrowed', { network: isCoreNetwork(requiredChainId) ? 'CORE' : 'Arbitrum' })}</div>
          <div className='value'>
            ${getFormatStringFromBignumber(new BigNumber(totalBorrowBalance))}
          </div>
        </div> */}
        <div className='desc-container'>
          <div className='title'>{t('ARS_used_from_wallet')}</div>
          <div className='value'>
            {getFormatStringFromBignumber(new BigNumber(amountArs || 0))}
          </div>
        </div>
        <div className='desc-container'>
          <div className='title'>{t('Zap_value')}</div>
          <div className='value'>
            ${getFormatStringFromBignumber(newZapInUsd)}
          </div>
        </div>
        <div className='desc-container'>
          <div className='title'>{t('Lock_duration')}</div>
          <div className='value'>{LockData[selectedMuliplier].title}</div>
        </div>
      </div>
      <div className="continue-button-container" >
        <button
          type="button"
          disabled={!account || !amount || new BigNumber(amount).eq(0) || pending}
          onClick={onZap}
        >
          {t('Zap_into_LP')}
        </button>
      </div>
    </ContainerStyles >
  )
}

export default ZapModalStep3;