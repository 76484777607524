import * as constants from './constants';
import BigNumber from 'bignumber.js';

const ethers = require('ethers');
const commaNumber = require('comma-number');

const format = commaNumber.bindWith(',', '.');

export const encodeParameters = (types, values) => {
  const abi = new ethers.utils.AbiCoder();
  return abi.encode(types, values);
};

export const getArgs = (func) => {
  // First match everything inside the function argument parens.
  const args = func.toString().match(/.*?\(([^)]*)\)/)
    ? func.toString().match(/.*?\(([^)]*)\)/)[1]
    : '';
  // Split the arguments string into an array comma delimited.
  return args
    .split(',')
    .map((arg) => {
      // Ensure no inline comments are parsed and trim the whitespace.
      return arg.replace(/\/\*.*\*\//, '').trim();
    })
    .filter((arg) => {
      // Ensure no undefined values are added.
      return arg;
    });
};

export const addToken = async (asset, decimal, chainId, type) => {
  let tokenAddress = '';
  let tokenSymbol = '';
  let tokenDecimals = 18;
  let tokenImage = '';

  tokenAddress =
    asset === 'wcore' ?
      constants.CONTRACT_TOKEN_ADDRESS_TEMP[chainId][asset].address
      : type === 'token'
        ? constants.CONTRACT_TOKEN_ADDRESS[chainId][asset].address
        : constants.CONTRACT_ABEP_ADDRESS[chainId][asset].address;
  tokenSymbol =
    type === 'token'
      ? asset.toUpperCase()
      : `a${(asset === 'btcb' ? 'btc' : asset).toUpperCase()}`;
  tokenDecimals = decimal || (type === 'token' ? 18 : 8);
  tokenImage =
    `${window.location.origin}/images/coins/${type === 'token' ? asset.toLowerCase() : `a${asset.toLowerCase()}`
    }.png`;

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      // eslint-disable-next-line no-console
      console.log('Thanks for your interest!');
    } else {
      // eslint-disable-next-line no-console
      console.log('Your loss!');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getBigNumber = (value) => {
  if (!value) {
    return new BigNumber(0);
  }
  if (BigNumber.isBigNumber(value)) {
    return value;
  }
  return new BigNumber(value);
};

export const currencyFormatter = (labelValue, rowValue, precision) => {
  let suffix = '';
  let unit = 1;
  const abs = Math.abs(Number(labelValue));
  if (rowValue === 'price') {
    const price = new BigNumber(abs).isLessThan(1) ? new BigNumber(abs).dp(4, 1) : new BigNumber(abs).dp(2, 1)
    return `$${new BigNumber(price).toString(10)}`;
  }
  else if (rowValue === 'reservesValue') {
    return `$${(new BigNumber(`${abs}`).dp(2, 1)) / 1.0e6}M`;
  } else {
    if (abs >= 1.0e9) {
      // Nine Zeroes for Billions
      suffix = 'B';
      unit = 1.0e9;
    } else if (abs >= 1.0e6) {
      // Six Zeroes for Millions
      suffix = 'M';
      unit = 1.0e6;
    } else if (abs >= 1.0e3) {
      // Three Zeroes for Thousands
      suffix = 'K';
      unit = 1.0e3;
    }
  }
  if (precision) {
    return `$${format(new BigNumber(`${abs / unit}`).dp(precision, 1))}${suffix}`;
  }
  return `$${format(new BigNumber(`${abs / unit}`).dp(2, 1))}${suffix}`;
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e9}`).dp(2, 1)
  //     )}B`
  //   : Math.abs(Number(labelValue)) >= 1.0e6
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e6}`).dp(2, 1)
  //     )}M`
  //   : Math.abs(Number(labelValue)) >= 1.0e3
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e3}`).dp(2, 1)
  //     )}K`
  //   : `$${format(new BigNumber(`${Math.abs(Number(labelValue))}`).dp(2, 1))}`;
};


export const getFormatStringFromBignumber = (bignumber, decimal = 2, smallDecimal = 4) => {
  if (bignumber === undefined || !BigNumber.isBigNumber(bignumber)) {
    return '---';
  } else if (bignumber.eq(0)) {
    return '0'
  } else if (bignumber.gte(1e12)) {
    return 'Infinity'
  } else if (bignumber.gte(1e9)) {
    return `${bignumber.div(1e9).toFormat(decimal)}B`;
  } else if (bignumber.gte(1e6)) {
    return `${bignumber.div(1e6).toFormat(decimal)}M`;
  } else if (bignumber.gte(1e3)) {
    return `${bignumber.div(1e3).toFormat(decimal)}K`;
  } else if (bignumber.lte(0.1)) {
    return `${bignumber.toFormat(smallDecimal)}`;
  } else {
    return `${bignumber.toFormat(decimal)}`;
  }
}