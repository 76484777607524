import SVG from 'react-inlinesvg';
import BigNumber from "bignumber.js";
import { useActiveWeb3React } from "hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { restService } from "utilities";
import { addToken, getFormatStringFromBignumber } from "utilities/common";
import ExternalLinkIcon from '../../assets/img/common/externalLink.svg';
import { getEtherscanLink, isArbitrumNetwork, isCoreNetwork } from 'utils';
import { MetaMask } from '@web3-react/metamask';
import metaMask from '../../assets/img/wallet/metaMask.svg';
import NoHistory from "../../assets/img/common/no_history.svg"

const StyedContainer = styled.div`
  display: flex;
  gap: 16px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    .title {
      padding: 0px 0px 16px 0px;
      gap: 10px;
      border-bottom: 1px solid #1F2937;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .no-history-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        height: 112px;
      }
    }
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .item {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        .item-header {
          color: #9CA3AF;
        }
        .item-content {
          font-weight: 600;
        }
        .token-add {
          padding-left: 5px;
          cursor: pointer;
        }
      }
    }
    .event-log-header {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      padding-bottom: 16px;
      border-bottom: 1px solid #1F2937;
      .time {
        flex-basis: 50%;
      }
      .log {
        flex-basis: 50%;
      }
    }
    .history {
      height: 100px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 10px;
      @media only screen and (max-width: 992px) {
        height: 200px;
      }
      .items {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        .time {
          flex-basis: 50%;
        }
        .log {
          flex-basis: 40%;
        }
        svg.link {
          width: 12px;
          cursor: pointer;
        }
      }
    }
  }
`;

function LastOverview({
  chefArsBalance,
  rewardsPerSecond,
  arsPrice,
  dLpPrice
}) {
  const { t } = useTranslation();
  const { connector, chainId, account, requiredChainId, isActive } = useActiveWeb3React();

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (account && requiredChainId && isActive) {
        const apiRequest = await restService({
          chainId: requiredChainId,
          api: `/v1/radiant/getHistory?limit=${1000}&offset=${0}&address=${account}`,
          method: 'GET',
        });

        if (apiRequest?.data?.data) {
          setLogs(apiRequest.data.data.rows || []);
        } else {
          setLogs([])
        }
      } else {
        setLogs([])
      }
    }

    fetchData();
    let updateTimer = setInterval(() => {
      fetchData();
    }, 10000);
    return (() => {
      if (updateTimer) {
        clearInterval(updateTimer);
      }
    });
  }, [account, requiredChainId])

  return (
    <StyedContainer>
      <div className='content-container'>
        <div className="title">
          {t('Protocol_Stats')}
        </div>
        <div className="item-container">
          <div className="item">
            <div className="item-header">{t('Rewards_Reserve')}:</div>
            <div className="item-content">{getFormatStringFromBignumber(chefArsBalance.div(1e18))} ARS</div>
          </div>
          <div className="item">
            <div className="item-header">{t('Reward_Rate')}:</div>
            <div className="flex justify-center items-center">
              <div className="item-content">{getFormatStringFromBignumber(rewardsPerSecond.div(1e18))}</div>
              {
                (isActive && connector instanceof MetaMask && (isCoreNetwork(chainId) || isArbitrumNetwork(chainId))) &&
                <div className='token-add'
                  onClick={() => {
                    addToken('ars', 18, chainId, 'token')
                  }}
                >
                  <img className="w-5" src={metaMask} alt="arrow" />
                </div>
              }
            </div>
          </div>
          <div className="item">
            <div className="item-header">{t('ARS_Price')}:</div>
            <div className="item-content">${getFormatStringFromBignumber(new BigNumber(arsPrice), 3)}</div>
          </div>
          <div className="item">
            <div className="item-header">{t('dLP_Price')}:</div>
            <div className="item-content">${getFormatStringFromBignumber(dLpPrice, 3)}</div>
          </div>
        </div>
      </div>
      <div className="content-container">
        <div className="title">
          {t('Activity_log')}
        </div>
        <div className="event-log-header">
          <div className="time">{t('Date')}</div>
          <div className="log">{t('Event')}</div>
        </div>
        <div className="history">
          {
            logs.map((log, index) => {
              return (
                <div key={index} className="items">
                  <div className="time">{new Date(log.blockTimestamp * 1000).toLocaleString()}</div>
                  <div className="log">{log.action}</div>
                  <div
                    onClick={() => {
                      window.open(getEtherscanLink(requiredChainId, log.txHash, 'transaction'), '_blank')
                    }}
                  >
                    <SVG src={ExternalLinkIcon} className={'link'} />
                  </div>
                </div>
              )
            })
          }
          {
            !logs.length && (
              <div className="no-history-container">
                <img src={NoHistory} alt="No Vest History" />
              </div>
            )
          }
        </div>
      </div>

    </StyedContainer>
  )
}

export default LastOverview;