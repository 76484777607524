import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

import ars from '../../assets/img/footer/ars.svg';
import medium from '../../assets/img/footer/medium.svg';
import telegram from '../../assets/img/footer/telegram.svg';
import twitter from '../../assets/img/footer/twitter.svg';
import github from '../../assets/img/footer/github.svg';
import { useActiveWeb3React } from 'hooks';
import { isCoreNetwork } from 'utils';

const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(3, 7, 18, 0) 0%, rgba(0, 86, 239, 0.1) 100%),
  linear-gradient(0deg, #030712, #030712);
  border-top: 1px solid rgba(31, 41, 55, 1);
  .container1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 80px;
    @media only screen and (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 50px 16px 0 16px;
    }
    .logo-container {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 992px) {
        justify-content: center;
        align-items: center;
        padding-bottom: 32px;
      }
      img {
        width: 96px;
        height: 96px;
      }
      .aquarius-loan {
        margin-top: 12px;
        font-family: Lato;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .link-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      @media only screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
      }
      .link-item {
        .link-title {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;    
        }
        .link-desc {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0em;
          color: rgba(148, 163, 184, 1);
        }
      }
    }
  }
  .container2 {
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0px 0px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      padding: 16px;
      gap: 32px;
      margin-top: 56px;
    }
    .social-container {
      display: flex;
      align-items: center;
      gap: 24px;
      .social-item {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: DM Sans;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0em;
      }
    }
    .copy-right {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0em;
      color: rgba(148, 163, 184, 1);
      display: flex;
      align-items: center;
      gap: 20px;
      @media only screen and (max-width: 992px) {
        text-align: center;
        justify-content: space-between;
      }
    }
  }
`

const Footer = () => {
  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();

  return (
    <footer>
      <StyledContainer className='w-full'>
        <div className='container1'>
          <div className='logo-container'>
            <img src={ars} alt='' />
            <div className='aquarius-loan'>AQUARIUS LOAN</div>
          </div>
          <div className='link-container'>
            <Link to={{ pathname: "https://docs.aquarius.loan/" }} target="_blank" className='link-item' >
              <div className='link-title'>Documentation</div>
              <div className='link-desc'>Learn more about Aquarius</div>
            </Link>
            <Link to={{ pathname: "https://dao.aquarius.loan/" }} target="_blank" className='link-item' >
              <div className='link-title'>Snapshots</div>
              <div className='link-desc'>Vote on live proposals</div>
            </Link>
            <Link to={{ pathname: "https://community.aquarius.loan/" }} target="_blank" className='link-item' >
              <div className='link-title'>Discourse</div>
              <div className='link-desc'>Discuss governance proposals</div>
            </Link>
            <Link to={{ pathname: "https://paladinsec.co/projects/aquarius-loan/" }} target="_blank" className='link-item' >
              <div className='link-title'>Security</div>
              <div className='link-desc'>Smart Contract Audit</div>
            </Link>
            <Link to={{ pathname: "https://status.aquarius.loan/" }} target="_blank" className='link-item' >
              <div className='link-title'>Status</div>
              <div className='link-desc'>Go to App</div>
            </Link>
            <Link to={{ pathname: "https://docs.aquarius.loan/terms-of-service" }} target="_blank" className='link-item' >
              <div className='link-title'>Legal</div>
              <div className='link-desc'>Disclaimer and terms</div>
            </Link>
          </div>
        </div>
        <div className='container2'>
          <div className='social-container'>
            <Link to={{ pathname: "https://medium.com/@Aquarius_loan" }} target="_blank" className='social-item' >
              <img src={medium} alt='medium' />
              <span>Medium</span>
            </Link>
            <Link to={{ pathname: "https://t.me/AquariusLoan" }} target="_blank" className='social-item' >
              <img src={telegram} alt='telegram' />
              <span>Telegram</span>
            </Link>
            <Link to={{ pathname: "https://twitter.com/AquariusLoan" }} target="_blank" className='social-item' >
              <img src={twitter} alt='twitter' />
              <span>Twitter</span>
            </Link>
            <Link to={{ pathname: "https://github.com/aquarius-loan" }} target="_blank" className='social-item' >
              <img src={github} alt='github' />
              <span>Github</span>
            </Link>
          </div>
          <div className='copy-right'>
            {t("All_Rights_Reserved")}
            {
              !isCoreNetwork(requiredChainId) &&
              <Link to={{ pathname: "https://chain.link/badge" }} target="_blank" className='ml-5' >
                <img src="https://chain.link/badge-market-data-white" alt="market data secured with chainlink" style={{ height: 30 }} />
              </Link>
            }
          </div>
        </div>
      </StyledContainer>
    </footer >
  );
};

export default Footer;
