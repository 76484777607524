import { combineReducers } from 'redux';
import {
  account,
  application,
} from '../modules';

const appReducer = combineReducers({
  account,
  application,
});

export default function rootReducer(state, action) {
  let finalState = appReducer(state, action);
  return finalState;
}
