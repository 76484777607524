import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/img/common/close.svg'
import { ReactComponent as BackIcon } from '../../../assets/img/common/modal_back.svg'
import { useTranslation } from 'react-i18next';

const ContainerStyles = styled.div`
  padding: 16px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close-container {
      outline: none;
      cursor: pointer;
    }
    .back-container {
      outline: none;
      cursor: pointer;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
  }
`

function ZapModalTitle({ setOpen, step, setStep }) {
  const { t } = useTranslation();

  return (
    <ContainerStyles>
      <div className='header-container'>
        <div className="back-container" style={step === 0 ? { opacity: 0.2, cursor: 'not-allowed' } : {}}
          onClick={() => {
            if (step > 0) {
              setStep(step - 1)
            }
          }}
        >
          <BackIcon fill={'#fff'} />
        </div>
        <div className={'title'}>{t('Zap_into_LP')}</div>
        <div className="close-container" onClick={() => { setOpen(false) }}>
          <CloseIcon fill={'#fff'} />
        </div>
      </div>
    </ContainerStyles>
  )
}

export default ZapModalTitle;