export default {
  dashboard: '/dashboard',
  portfolio: '/portfolio',
  stake: {
    ars: '/staking/ars',
    dlp: '/staking/lp'
  },
  market: {
    index: '/market',
    marketDetails: '/market/:asset',
  },
  referral: '/referral',
  aquarius: '/aquarius',
  history: '/history',
  liquidate: '/liquidate',
  pools: '/pools',
  trade: '/trade',
  // vote: {
  //   index: '/governance',
  //   allProposals: '/vote/all',
  //   voteOverview: `/vote/proposal/:id`,
  //   proposerOverview: '/vote/address/:address',
  //   leaderboard: '/vote/leaderboard',
  // },
};
