import BigNumber from 'bignumber.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import externalLink from "assets/img/common/external_link.svg";
import APYSparkline from 'components/Aquarius/APYSparkline';

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .history-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 20px 16px 20px;
    border-radius: 8px;
    background: #FFFFFF0D;
    .icon-container {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      .token-icon {
        width: 40px;
        height: 40px;
      }
    }
    .divider {
      border-bottom: 1px solid #FFFFFF1A;
    }
    .desc-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .item-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;        
        color: #9CA3AF;
        .desc-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #F9FAFB;
        }
      }
    }
  }
`;

function AquaMobileItem({
  data,
}) {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      {
        data.map((e, i) => {
          return (
            <div key={`hisotry-${i}`} className='history-item'>
              <div className='icon-container'>
                <img className='token-icon' src={`/images/coins/${e.underlyingSymbol?.toLowerCase()}.png`} alt={e.underlyingSymbol} />
                <div>
                  {e.underlyingSymbol}
                </div>
              </div>
              <div className='divider' />
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Rank')}</div>
                  <div className="desc-value">
                    {i > 9 ? i + 1 : `0${i + 1}`}
                  </div>
                </div>
                <div className='item-container'>
                  <div>{t('Per_Day')}</div>
                  <div className="desc-value">
                    {e.perDay}
                  </div>
                </div>
              </div>
              <div className='desc-container'>
                <div className='item-container'>
                  <div>{t('Supply_APY')}</div>
                  <div className="desc-value" style={{ color: '#34D399' }}>
                    {e.supplyAPY}%
                  </div>
                  <div>{e.supplyPerDay} ARS</div>
                </div>
                <div className='item-container'>
                  <div>{t('Borrow_APY')}</div>
                  <div className="desc-value" style={{ color: '#A392FF' }}>
                    {e.borrowAPY}%
                  </div>
                  <div>{e.borrowPerDay} ARS</div>
                </div>
              </div>
              <div className='divider' />
              <div className="graph">
                <div>{t('Borrow_ARS_APY')}</div>
                {
                  e.borrowArsApy &&
                  <APYSparkline
                    color={e.borrowArsApy.length > 0 && e.borrowArsApy[0].borrowArsApy >= 0 ? "green" : "red"}
                    data={e.borrowArsApy}
                  />
                }
              </div>
            </div>
          )
        })
      }
    </StyledContainer>
  )
}

export default AquaMobileItem;