import { useCallback, useEffect, useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { useActiveWeb3React, useWeb3 } from "hooks";
import useRefresh from "./useRefresh";
import {
  useChefIncentivesControllerContract,
  useCompounderContract,
  useERC20TokenContract,
  useLiquidityZapContract,
  useLockZapContract,
  useLpContract,
  useMultiFeeDistributionContract
} from "./useContract";
import { methods } from "utilities/ContractService";
import * as constants from 'utilities/constants';
import { getNativeToken, isCoreNetwork } from "utils";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

// ERC20 Toeken Info
export const useTokenBalance = (symbol, update) => {
  const { requiredChainId, account, isActive } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [tokenBalance, setTokenBalance] = useState(new BigNumber(0));
  const [approvedTokenBalance, setApprovedTokenBalance] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    if (account) {
      const temp = (symbol === `w${getNativeToken(requiredChainId).toLowerCase()}`) ?
        [
          {
            reference: 'balanceOf',
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId][symbol].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              {
                methodName: 'balanceOf',
                methodParameters: [account]
              }
            ]
          },
          {
            reference: 'allowance',
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId][symbol].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              {
                methodName: 'allowance',
                methodParameters: [account, constants.LOCK_ZAP[requiredChainId]]
              }
            ]
          },
        ]
        :
        [
          {
            reference: 'balanceOf',
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId][symbol].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              {
                methodName: 'balanceOf',
                methodParameters: [account]
              }
            ]
          },
          {
            reference: 'allowance',
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId][symbol].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              {
                methodName: 'allowance',
                methodParameters: [account, constants.LOCK_ZAP[requiredChainId]]
              }
            ]
          },
        ];
      return temp;
    }
    return []
  }, [account, requiredChainId, symbol])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        if (symbol === 'core' || symbol === 'eth') {
          const _tokenBalance = await web3.eth.getBalance(account);
          setTokenBalance(new BigNumber(_tokenBalance));
          setApprovedTokenBalance(new BigNumber(0));
        } else {
          // eslint-disable-next-line no-undef
          const [data] = await Promise.all([
            methods.ethMulticall(web3, calls, requiredChainId),
          ]);
          const _tokenBalance = new BigNumber(data.results.balanceOf.callsReturnContext[0].returnValues[0].hex)
          const _approvedTokenBalance = new BigNumber(data.results.allowance.callsReturnContext[0].returnValues[0].hex)

          setTokenBalance(_tokenBalance);
          setApprovedTokenBalance(_approvedTokenBalance);
        }
      } catch (e) {
        console.error('Fetching token balance data had error', e);
      }
    };

    if (account && isActive) {
      fetchGlobalData();
    }
  }, [web3, fastRefresh, account, update, symbol]);

  useEffect(() => {
    setTokenBalance(new BigNumber(0));
    setApprovedTokenBalance(new BigNumber(0));
  }, [symbol])

  return { tokenBalance, approvedTokenBalance }
}

export const useTokenApprove = (symbol = 'ars') => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useERC20TokenContract(requiredChainId, symbol);

  const handleApprove = useCallback(
    async (amount) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.approve,
          [
            constants.LOCK_ZAP[requiredChainId],
            amount.toString(10)
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.approve(
          constants.LOCK_ZAP[requiredChainId],
          amount.toString(10)
        )
          .send({
            from: account
          });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Approve_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Approve had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    }, [account, contract]);

  return { handleApprove, pending };
};

export const usedLpBalance = (update) => {
  const { requiredChainId, account, isActive } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [myDLpBalance, setMyDLpBalance] = useState(new BigNumber(0));
  const [myDLpAllowance, setMyDLpAllowance] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    if (account) {
      const temp = [
        {
          reference: 'balanceOf',
          contractAddress: constants.DLP_ADDRESS[requiredChainId],
          abi: constants.ERC20_TOKEN_ABI,
          calls: [
            {
              methodName: 'balanceOf',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'allowance',
          contractAddress: constants.DLP_ADDRESS[requiredChainId],
          abi: constants.ERC20_TOKEN_ABI,
          calls: [
            {
              methodName: 'allowance',
              methodParameters: [account, constants.MULTI_FEE_DISTRIBUTION[requiredChainId]]
            }
          ]
        },
      ];
      return temp;
    }
    return []
  }, [account, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);
        const _myDLpBalance = new BigNumber(data.results.balanceOf.callsReturnContext[0].returnValues[0].hex);
        const _myDLpAllowance = new BigNumber(data.results.allowance.callsReturnContext[0].returnValues[0].hex);

        setMyDLpBalance(_myDLpBalance);
        setMyDLpAllowance(_myDLpAllowance);
      } catch (e) {
        console.error('Fetching lp balance data had error', e);
      }
    };

    if (account && isActive) {
      fetchGlobalData();
    }
  }, [web3, fastRefresh, account, update])

  return { myDLpBalance, myDLpAllowance }
}

export const usedLpApprove = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useLpContract(requiredChainId);

  const handleApprove = useCallback(
    async (amount) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.approve,
          [
            constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
            amount.toString(10)
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.approve(
          constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          amount.toString(10)
        )
          .send({
            from: account
          });
        if (tx) {
          NotificationManager.success(t('Approve_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        setPending(false);
        return tx;
      } catch (e) {
        console.error('Approve had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    }, [account, contract]);

  return { handleApprove, pending };
};

// MultiFeeDistribution
export const useMultiFeeDistributionInfo = (update) => {
  const { requiredChainId, account } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [myDLpLocked, setMyDLpLocked] = useState(new BigNumber(0));
  const [myUnlockable, setMyUnlockable] = useState(new BigNumber(0));
  const [myShare, setMyShare] = useState(new BigNumber(0));
  const [dLpLocked, setDLpLocked] = useState(new BigNumber(0));
  const [currentlyVesting, setCurrentlyVesting] = useState(new BigNumber(0));
  const [penaltyAmount, setPenaltyAmount] = useState(new BigNumber(0));
  const [defaultLockIndex, setDefaultLockIndex] = useState(0);
  const [vested, setVested] = useState(new BigNumber(0));
  const [vestingList, setVestingList] = useState([]);
  const [lockList, setLockList] = useState([]);

  const calls = useMemo(() => {
    const temp = [
      {
        reference: 'lockedSupply',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'lockedSupply',
            methodParameters: []
          }
        ]
      },
      {
        reference: 'lockedSupplyWithMultiplier',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'lockedSupplyWithMultiplier',
            methodParameters: []
          }
        ]
      },
    ];

    if (account) {
      temp.push({
        reference: 'lockedBalances',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'lockedBalances',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'earnedBalances',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'earnedBalances',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'withdrawableBalance',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'withdrawableBalance',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'defaultLockIndex',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'defaultLockIndex',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'lockInfo',
        contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
        abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
        calls: [
          {
            methodName: 'lockInfo',
            methodParameters: [account]
          }
        ]
      })
    }

    return temp;
  }, [account, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);

        const _dLpLocked = new BigNumber(data.results.lockedSupply.callsReturnContext[0].returnValues[0].hex);
        setDLpLocked(_dLpLocked);

        if (account) {
          const _myDLpLocked = new BigNumber(data.results.lockedBalances.callsReturnContext[0].returnValues[2].hex);
          const _myUnlockable = new BigNumber(data.results.lockedBalances.callsReturnContext[0].returnValues[1].hex);
          const _myShare = new BigNumber(data.results.lockedBalances.callsReturnContext[0].returnValues[3].hex)
            .times(100)
            .div(data.results.lockedSupplyWithMultiplier.callsReturnContext[0].returnValues[0].hex);
          const _currentlyVesting = new BigNumber(data.results.earnedBalances.callsReturnContext[0].returnValues[0].hex)
          // .minus(data.results.earnedBalances.callsReturnContext[0].returnValues[1].hex)
          const _penaltyAmount = new BigNumber(data.results.withdrawableBalance.callsReturnContext[0].returnValues[1].hex);
          const _defaultLockIndex = new BigNumber(data.results.defaultLockIndex.callsReturnContext[0].returnValues[0].hex).toNumber();
          const _vested = new BigNumber(data.results.earnedBalances.callsReturnContext[0].returnValues[1].hex);

          setMyDLpLocked(_myDLpLocked);
          setMyUnlockable(_myUnlockable);
          setMyShare(_myShare.isNaN() ? new BigNumber(0) : _myShare);
          setCurrentlyVesting(_currentlyVesting);
          setPenaltyAmount(_penaltyAmount);
          setDefaultLockIndex(_defaultLockIndex);
          setVested(_vested)

          const _vestingReturn = data.results.earnedBalances.callsReturnContext[0].returnValues[2];
          const _vestingList = [];
          for (let index = 0; index < _vestingReturn.length; index++) {
            _vestingList.push({
              amount: new BigNumber(_vestingReturn[index][0].hex),
              unlockTime: new BigNumber(_vestingReturn[index][1].hex).toString(),
              penalty: new BigNumber(_vestingReturn[index][2].hex),
            })
          }
          setVestingList(_vestingList);
          const _lockReturn = data.results.lockInfo.callsReturnContext[0].returnValues;
          const _lockList = [];
          for (let index = 0; index < _lockReturn.length; index++) {
            _lockList.push({
              amount: new BigNumber(_lockReturn[index][0].hex),
              unlockTime: new BigNumber(_lockReturn[index][1].hex).toString(),
              multiplier: new BigNumber(_lockReturn[index][2].hex),
              duration: new BigNumber(_lockReturn[index][3].hex).toString(),
            })
          }
          setLockList(_lockList.sort((a, b) => {
            if (Number(a.unlockTime) <= Number(b.unlockTime)) {
              return -1;
            } else {
              return 1;
            }
          }));
        }
      } catch (e) {
        console.error('Fetching Multi Fee data had error', e);
      }
    };

    fetchGlobalData();
  }, [web3, fastRefresh, account, update])

  return { dLpLocked, myDLpLocked, myUnlockable, myShare, currentlyVesting, penaltyAmount, vested, vestingList, defaultLockIndex, lockList }
}

export const useMultiFeeDistributionRewards = (update) => {
  const { requiredChainId, account, isActive } = useActiveWeb3React();
  const tokenList = useMemo(() => {
    if (isCoreNetwork(requiredChainId)) {
      return [
        { name: 'USDT', decimals: 6, amount: new BigNumber(0), address: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdt.address },
        { name: 'USDC', decimals: 6, amount: new BigNumber(0), address: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdc.address },
        { name: 'WETH', decimals: 18, amount: new BigNumber(0), address: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].weth.address }
      ]
    } else {
      return [
        { name: 'USDT', decimals: 6, amount: new BigNumber(0), address: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdt.address },
        {
          name: 'USDC.e', decimals: 6, amount: new BigNumber(0),
          address: (constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]['usdc.e'] || constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].usdc).address
        },
      ]
    }
  }, [requiredChainId]);

  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [autocompoundEnabled, setAutocompoundEnabled] = useState(false);
  const [autoRelockDisabled, setAutoRelockDisabled] = useState(false);
  const [claimableRewards, setClaimableRewards] = useState(tokenList);

  const calls = useMemo(() => {
    if (account) {
      const temp = [
        {
          reference: 'claimableRewards',
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: [
            {
              methodName: 'claimableRewards',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'autocompoundEnabled',
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: [
            {
              methodName: 'autocompoundEnabled',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'autoRelockDisabled',
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: [
            {
              methodName: 'autoRelockDisabled',
              methodParameters: [account]
            }
          ]
        },
      ];
      return temp;
    }
    return []
  }, [account, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);

        setAutocompoundEnabled(data.results.autocompoundEnabled.callsReturnContext[0].returnValues[0])
        setAutoRelockDisabled(data.results.autoRelockDisabled.callsReturnContext[0].returnValues[0])

        const temp = [];
        const _rewards = data.results.claimableRewards.callsReturnContext[0].returnValues;
        for (let index = 1; index < tokenList.length + 1; index++) {
          temp.push({ ...tokenList[index - 1], address: _rewards[index][0], amount: new BigNumber(_rewards[index][1].hex) })
        }
        setClaimableRewards(temp);
      } catch (e) {
        console.error('Fetching MultiFee Reward data had error', e);
      }
    };

    if (account && isActive) {
      fetchGlobalData();
    }
  }, [web3, fastRefresh, account, update, tokenList])

  return { claimableRewards, autocompoundEnabled, autoRelockDisabled }
}

export const useMultiFeeDistributionAPRs = (tokenList) => {
  const { requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [lockedSupplyWithMultiplier, setLockedSupplyWithMultiplier] = useState(new BigNumber(0));
  const [lockMultipliers, setLockMultipliers] = useState([]);
  const [rewardData, setRewardData] = useState([]);

  const calls = useMemo(() => {
    if (tokenList && tokenList[0].address) {
      const temp = [
        {
          reference: 'lockedSupplyWithMultiplier',
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: [
            {
              methodName: 'lockedSupplyWithMultiplier',
              methodParameters: []
            }
          ]
        },
        {
          reference: 'getLockMultipliers',
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: [
            {
              methodName: 'getLockMultipliers',
              methodParameters: []
            }
          ]
        },
      ];

      const rewarDataCalls = [];
      for (let index = 0; index < tokenList.length; index++) {
        rewarDataCalls.push(
          {
            methodName: 'rewardData',
            methodParameters: [tokenList[index].address]
          })
      }
      temp.push(
        {
          reference: `rewardData`,
          contractAddress: constants.MULTI_FEE_DISTRIBUTION[requiredChainId],
          abi: constants.MULTI_FEE_DISTRIBUTION_ABI,
          calls: rewarDataCalls
        })
      return temp;
    }
    return []
  }, [tokenList, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);
        const _lockedSupplyWithMultiplier = new BigNumber(data.results.lockedSupplyWithMultiplier.callsReturnContext[0].returnValues[0].hex);

        const _rewardData = [];
        for (let index = 0; index < data.results.rewardData.callsReturnContext.length; index++) {
          _rewardData.push(data.results.rewardData.callsReturnContext[index].returnValues)
        }

        setLockedSupplyWithMultiplier(_lockedSupplyWithMultiplier);
        setLockMultipliers([
          new BigNumber(data.results.getLockMultipliers.callsReturnContext[0].returnValues[0].hex),
          new BigNumber(data.results.getLockMultipliers.callsReturnContext[0].returnValues[1].hex),
          new BigNumber(data.results.getLockMultipliers.callsReturnContext[0].returnValues[2].hex),
          new BigNumber(data.results.getLockMultipliers.callsReturnContext[0].returnValues[3].hex)
        ])
        setRewardData(_rewardData);
      } catch (e) {
        console.error('Fetching MultiFeeDistributionAPRs data had error', e);
      }
    };

    fetchGlobalData();
  }, [web3, fastRefresh])

  return { lockedSupplyWithMultiplier, lockMultipliers, rewardData }
}

export const useAutoCompound = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleAutoCompound = useCallback(
    async (enable) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.setAutocompound,
          [
            enable
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.setAutocompound(
          enable
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Set_auto_compound_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('AutoCompound had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleAutoCompound, pending };
};

export const useClaimAll = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleClaim = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.getAllRewards,
          [],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.getAllRewards(
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Claim_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('ClaimAll had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleClaim, pending };
};

export const useLock = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleLock = useCallback(
    async (amount, index) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.stake,
          [
            amount.toString(10), account, index
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.stake(
          amount.toString(10), account, index
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Set_lock_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleLock, pending };
};

export const useReLock = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleReLock = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.relock,
          [],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.relock(
        ).send({
          from: account
        });
        if (tx) {
          NotificationManager.success(t('Set_relock_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        setPending(false);
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleReLock, pending };
};

export const useWithdrawExpiredLocksForWithOptions = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleWithdraw = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.withdrawExpiredLocksForWithOptions,
          [
            account, 0, true
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.withdrawExpiredLocksForWithOptions(
          account, 0, true
        ).send({
          from: account
        });
        if (tx) {
          NotificationManager.success(t('Set_withdraw_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        setPending(false);
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleWithdraw, pending };
};

export const useWithdraw = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleWithdraw = useCallback(
    async (amount) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.withdraw,
          [
            amount.toString(10)
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.withdraw(
          amount.toString(10)
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Withdraw_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleWithdraw, pending };
};

export const useAutoReLock = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleAutoReLock = useCallback(
    async (enable) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.setRelock,
          [
            enable
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.setRelock(
          enable
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Set_auto_lock_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleAutoReLock, pending };
};

export const useExit = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleExit = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.exit,
          [
            false
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.exit(
          false
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Exit_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleExit, pending };
};

export const useIndividualExit = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleIndividualExit = useCallback(
    async (unlockTime) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.individualEarlyExit,
          [
            false, unlockTime.toString(10)
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.individualEarlyExit(
          false, unlockTime.toString(10)
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Exit_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleIndividualExit, pending };
};

export const useSetDefaultRelockTypeIndex = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useMultiFeeDistributionContract(requiredChainId);

  const handleSetDefaultRelockTypeIndex = useCallback(
    async (index) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.setDefaultRelockTypeIndex,
          [
            index
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.setDefaultRelockTypeIndex(
          index
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Set_default_lock_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleSetDefaultRelockTypeIndex, pending };
};

// ChefIncentivesController

export const useChefIncentivesInfo = (update) => {
  const { requiredChainId, account } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [allPendingRewards, setAllPendingRewards] = useState(new BigNumber(0));
  const [rewardsPerSecond, setRewardsPerSecond] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    const temp = [
      {
        reference: 'rewardsPerSecond',
        contractAddress: constants.CHEF_INCENTIVES_CONTROLLER[requiredChainId],
        abi: constants.CHEF_INCENTIVES_CONTROLLER_ABI,
        calls: [
          {
            methodName: 'rewardsPerSecond',
            methodParameters: []
          }
        ]
      }
    ];

    if (account) {
      temp.push({
        reference: 'allPendingRewards',
        contractAddress: constants.CHEF_INCENTIVES_CONTROLLER[requiredChainId],
        abi: constants.CHEF_INCENTIVES_CONTROLLER_ABI,
        calls: [
          {
            methodName: 'allPendingRewards',
            methodParameters: [account]
          }
        ]
      })
    }

    return temp;
  }, [account, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);
        const _rewardsPerSecond = new BigNumber(data.results.rewardsPerSecond.callsReturnContext[0].returnValues[0].hex);

        setRewardsPerSecond(_rewardsPerSecond);

        if (account) {
          const _allPendingRewards = new BigNumber(data.results.allPendingRewards.callsReturnContext[0].returnValues[0].hex);

          setAllPendingRewards(_allPendingRewards);
        }
      } catch (e) {
        console.error('Fetching ChefIncentivesInfo data had error', e);
      }
    };

    fetchGlobalData();
  }, [web3, fastRefresh, account, update])

  return { allPendingRewards, rewardsPerSecond }
}

export const useClaimAllChef = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useChefIncentivesControllerContract(requiredChainId);

  const handleClaimAll = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.claimAll,
          [
            account
          ],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.claimAll(
          account
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Claim_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('ClaimAllChef had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleClaimAll, pending };
};

// LockZap
export const useLockZapInfo = (currentlyVesting, update) => {
  const { requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [coreValue, setCoreValue] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    const temp = isCoreNetwork(requiredChainId) ? [{
      reference: 'quoteFromToken',
      contractAddress: constants.LOCK_ZAP[requiredChainId],
      abi: constants.LOCK_ZAP_ABI[requiredChainId],
      calls: [
        {
          methodName: 'quoteFromToken',
          methodParameters: [currentlyVesting.toString(10)]
        }
      ]
    }]
      : [{
        reference: 'quoteFromToken',
        contractAddress: constants.LOCK_ZAP[requiredChainId],
        abi: constants.LOCK_ZAP_ABI[requiredChainId],
        calls: [
          {
            methodName: 'quoteFromToken',
            methodParameters: [constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].weth.address, currentlyVesting.toString(10)]
          }
        ]
      }]
      ;

    return temp;
  }, [currentlyVesting, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);
        const _coreValue = new BigNumber(data.results.quoteFromToken.callsReturnContext[0].returnValues[0].hex);

        setCoreValue(_coreValue);
      } catch (e) {
        console.error('Fetching lockZap data had error', e);
      }
    };

    if (currentlyVesting.isZero()) {
      setCoreValue(new BigNumber(0))
    } else {
      fetchGlobalData();
    }
  }, [web3, fastRefresh, update, currentlyVesting])

  return { coreValue }
}

export const useZapFromVesting = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useLockZapContract(requiredChainId);

  const handleZapFromVesting = useCallback(
    async (index, value) => {
      try {
        let tx;
        setPending(true);
        if (isCoreNetwork(requiredChainId)) {
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            contract.methods.zapFromVesting,
            [
              index
            ],
            account,
            value.toString(10)
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setPending(false);
            return false;
          }
          tx = await contract.methods.zapFromVesting(
            index
          ).send({
            from: account,
            value: value.toString(10)
          });
          setPending(false);
        } else {
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            contract.methods.zapFromVesting,
            [
              constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].weth.address,
              0,
              index,
              0
            ],
            account,
            value.toString(10)
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setPending(false);
            return false;
          }
          tx = await contract.methods.zapFromVesting(
            constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].weth.address,
            0,
            index,
            0
          ).send({
            from: account,
            value: value.toString(10)
          });
          setPending(false);
        }
        if (tx) {
          NotificationManager.success(t('Zap_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('ZapFromVesting had error :>> ', e);
        NotificationManager.error(t('Tx_rejected'));
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleZapFromVesting, pending };
};

export const useLockZap = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useLockZapContract(requiredChainId);

  const handleZapCore = useCallback(
    async (index, mainAmount, arsAmount) => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.zap,
          [
            0,
            arsAmount.toString(10),
            index
          ],
          account,
          mainAmount.toString(10)
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.zap(
          0,
          arsAmount.toString(10),
          index
        ).send({
          from: account,
          value: mainAmount.toString(10)
        })
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Zap_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Zap had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  const handleZap = useCallback(
    async (index, mainAmount, arsAmount, tokenAddress) => {
      try {
        setPending(true);
        let tx;
        if ((tokenAddress === constants.CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].weth.address)) {
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            contract.methods.zap,
            [
              tokenAddress,
              0,
              arsAmount.toString(10),
              index,
              0,
            ],
            account,
            mainAmount.toString(10),
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setPending(false);
            return false;
          }
          tx = await contract.methods.zap(
            tokenAddress,
            0,
            arsAmount.toString(10),
            index,
            0
          ).send({
            from: account,
            value: mainAmount.toString(10)
          })
        } else {
          const isCheckGas = await methods.checkGasFee(
            web3,
            requiredChainId,
            contract.methods.zap,
            [
              tokenAddress,
              mainAmount.toString(10),
              arsAmount.toString(10),
              index,
              0
            ],
            account,
          );
          if (!isCheckGas) {
            NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
            setPending(false);
            return false;
          }
          tx = await contract.methods.zap(
            tokenAddress,
            mainAmount.toString(10),
            arsAmount.toString(10),
            index,
            0
          ).send({
            from: account,
          })
        }
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Zap_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Zap had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleZapCore, handleZap, pending };
};

// LiquidityZap
export const useLiquidityZap = () => {
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useLiquidityZapContract(requiredChainId);

  const handleZapETH = useCallback(
    async (value) => {
      try {
        setPending(true);
        const tx = await contract.methods.zapETH(
          account
        ).send({
          from: account,
          value: value.toString(10)
        });
        setPending(false);
        return tx;
      } catch (e) {
        console.error('ZapETH had error :>> ', e);
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  const handleZapStandard = useCallback(
    async (tokenAmount, coreAmount) => {
      try {
        setPending(true);
        const tx = await contract.methods.standardAdd(
          tokenAmount.toString(10), coreAmount.toString(10), account
        ).send({
          from: account,
        });
        setPending(false);
        return tx;
      } catch (e) {
        console.error('ZapStandard had error :>> ', e);
        setPending(false);
        return false;
      }
    },
    [account, contract]
  );

  return { handleZapETH, handleZapStandard, pending };
};

// EligibilityDataProvider
export const useEligibilityDataProviderInfo = (update) => {
  const { requiredChainId, account } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [isEligibleForRewards, setIsEligibleForRewards] = useState(false);
  const [lockedUsdValue, setLockedUsdValue] = useState(new BigNumber(0));
  const [requiredUsdValue, setRequiredUsdValue] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    const temp = [
    ];

    if (account) {
      temp.push({
        reference: 'isEligibleForRewards',
        contractAddress: constants.ELIGI_DATA_PROVIDER[requiredChainId],
        abi: constants.ELIGI_DATA_PROVIDER_ABI,
        calls: [
          {
            methodName: 'isEligibleForRewards',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'lockedUsdValue',
        contractAddress: constants.ELIGI_DATA_PROVIDER[requiredChainId],
        abi: constants.ELIGI_DATA_PROVIDER_ABI,
        calls: [
          {
            methodName: 'lockedUsdValue',
            methodParameters: [account]
          }
        ]
      })
      temp.push({
        reference: 'requiredUsdValue',
        contractAddress: constants.ELIGI_DATA_PROVIDER[requiredChainId],
        abi: constants.ELIGI_DATA_PROVIDER_ABI,
        calls: [
          {
            methodName: 'requiredUsdValue',
            methodParameters: [account]
          }
        ]
      })
    }

    return temp;
  }, [account, requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {

        if (account) {
          // eslint-disable-next-line no-undef
          const [data] = await Promise.all([
            methods.ethMulticall(web3, calls, requiredChainId),
          ]);
          const _allPendingRewards = data.results.isEligibleForRewards.callsReturnContext[0].returnValues[0];
          const _lockedUsdValue = new BigNumber(data.results.lockedUsdValue.callsReturnContext[0].returnValues[0].hex);
          const _requiredUsdValue = new BigNumber(data.results.requiredUsdValue.callsReturnContext[0].returnValues[0].hex);

          setIsEligibleForRewards(_allPendingRewards);
          setLockedUsdValue(_lockedUsdValue);
          setRequiredUsdValue(_requiredUsdValue)
        } else {
          setIsEligibleForRewards(false);
          setLockedUsdValue(new BigNumber(0));
          setRequiredUsdValue(new BigNumber(0))
        }
      } catch (e) {
        console.error('Fetching Eligibility data had error', e);
      }
    };

    fetchGlobalData();
  }, [web3, fastRefresh, account, update])
  return { isEligibleForRewards, lockedUsdValue, requiredUsdValue }
}


// dLP Info
export const usedLpPrice = (arsPrice, ethPrice) => {
  const { requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [dLpPrice, setdLpPrice] = useState(new BigNumber(0));

  const callsOnCore = useMemo(() => {
    const temp = [
      {
        reference: 'balanceOf',
        contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address,
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'balanceOf',
            methodParameters: [constants.DLP_ADDRESS[requiredChainId]]
          }
        ]
      },
      {
        reference: 'totalSupply',
        contractAddress: constants.DLP_ADDRESS[requiredChainId],
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'totalSupply',
            methodParameters: []
          }
        ]
      },
    ];

    return temp;
  }, [requiredChainId]);

  const callsOnArbitrum = useMemo(() => {
    const temp = [
      {
        reference: 'price',
        contractAddress: constants.BALANCER_POOL_HELPER[requiredChainId],
        abi: constants.BALANCER_POOL_HELPER_ABI,
        calls: [
          {
            methodName: 'getPrice',
            methodParameters: []
          }
        ]
      },
    ];

    return temp;
  }, [requiredChainId]);

  useEffect(() => {
    const fetchGlobalDataOnCore = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, callsOnCore, requiredChainId),
        ]);
        const _dLpPrice = new BigNumber(data.results.balanceOf.callsReturnContext[0].returnValues[0].hex)
          .times(2)
          .div(data.results.totalSupply.callsReturnContext[0].returnValues[0].hex)
          .times(arsPrice);

        setdLpPrice(_dLpPrice);
      } catch (e) {
        console.error('Fetching Core LP price data had error', e);
      }
    };
    const fetchGlobalDataOnArbitrum = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, callsOnArbitrum, requiredChainId),
        ]);
        const _arsPriceInEth = new BigNumber(data.results.price.callsReturnContext[0].returnValues[0].hex);
        const tempCalls = [
          {
            reference: 'lpPrice',
            contractAddress: constants.BALANCER_POOL_HELPER[requiredChainId],
            abi: constants.BALANCER_POOL_HELPER_ABI,
            calls: [
              {
                methodName: 'getLpPrice',
                methodParameters: [_arsPriceInEth.toString(10)]
              }
            ]
          },
        ]
        // eslint-disable-next-line no-undef
        const [data1] = await Promise.all([
          methods.ethMulticall(web3, tempCalls, requiredChainId),
        ]);
        const _dLpPrice = new BigNumber(data1.results.lpPrice.callsReturnContext[0].returnValues[0].hex)
          .times(ethPrice)
          .div(1e8);

        setdLpPrice(_dLpPrice);
      } catch (e) {
        console.error('Fetching Arbitrum LP price data had error', e);
      }
    };

    if (isCoreNetwork(requiredChainId)) {
      if (arsPrice && parseFloat(arsPrice) > 0) {
        fetchGlobalDataOnCore();
      }
    } else {
      if (ethPrice && parseFloat(ethPrice) > 0) {
        fetchGlobalDataOnArbitrum();
      }
    }
  }, [web3, fastRefresh, arsPrice, ethPrice])

  return { dLpPrice }
}

// Ars Info
export const usedArsBalances = (update) => {
  const { requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();
  const { fastRefresh } = useRefresh();

  const [chefArsBalance, setChefArsBalance] = useState(new BigNumber(0));

  const calls = useMemo(() => {
    const temp = [
      {
        reference: 'balanceOf',
        contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address,
        abi: constants.ERC20_TOKEN_ABI,
        calls: [
          {
            methodName: 'balanceOf',
            methodParameters: [constants.CHEF_INCENTIVES_CONTROLLER[requiredChainId]]
          }
        ]
      },
    ];

    return temp;
  }, [requiredChainId])

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        // eslint-disable-next-line no-undef
        const [data] = await Promise.all([
          methods.ethMulticall(web3, calls, requiredChainId),
        ]);
        const _chefArsBalance = new BigNumber(data.results.balanceOf.callsReturnContext[0].returnValues[0].hex);

        setChefArsBalance(_chefArsBalance);
      } catch (e) {
        console.error('Fetching ars balance data had error', e);
      }
    };

    fetchGlobalData();
  }, [web3, fastRefresh, update])

  return { chefArsBalance }
}

// Compounder
export const useSelfCompound = () => {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { requiredChainId, account } = useActiveWeb3React();
  const [pending, setPending] = useState(false);
  const contract = useCompounderContract(requiredChainId);

  const handleSelfCompound = useCallback(
    async () => {
      try {
        setPending(true);
        const isCheckGas = await methods.checkGasFee(
          web3,
          requiredChainId,
          contract.methods.selfCompound,
          [],
          account,
        );
        if (!isCheckGas) {
          NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
          setPending(false);
          return false;
        }
        const tx = await contract.methods.selfCompound(
        ).send({
          from: account
        });
        setPending(false);
        if (tx) {
          NotificationManager.success(t('Self_compound_successfully'));
        } else {
          NotificationManager.error(t('Tx_rejected'));
        }
        return tx;
      } catch (e) {
        console.error('Referral lock had error :>> ', e);
        setPending(false);
        NotificationManager.error(t('Tx_rejected'));
        return false;
      }
    },
    [account, contract]
  );

  return { handleSelfCompound, pending };
};