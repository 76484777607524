import { useMemo } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

import Layout from '../../layouts/MainLayout/MainLayout';
import MarketInfo from './MarketInfo';

import { useActiveWeb3React } from '../../hooks';
import { getFormatStringFromBignumber } from '../../utilities/common';
import DataTable from 'components/common/DataTable';
import MarketMobileItem from './MarketMobileItem';
import { useWindowSize } from 'hooks/useWindowSize';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  .assets {
    display: flex;
    align-items: center;
    gap: 4px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .supply {
    display: flex;
    flex-direction: column;
    span {
      color: #A78BFA;
    }
  }
  .supply-apy {
    display: flex;
    flex-direction: column;
    span {
      color: #34D399;
    }
  }
  .token-price {
    text-align: right;
  }
`

const Market = ({ history }) => {
  const markets = useSelector((state) => state.account.setting.markets);
  const decimals = useSelector((state) => state.account.setting.decimals);

  const { t } = useTranslation();
  const { requiredChainId } = useActiveWeb3React();
  const { width } = useWindowSize();
  const extendedMarkets = markets.map(market => {
    const decimal = market.underlyingSymbol ? decimals[market.underlyingSymbol.toLowerCase()]?.token : 18;
    market.reserveUSD = new BigNumber(market.totalReserves).div(new BigNumber(10).pow(decimal));
    market.utilization = new BigNumber(market.totalBorrowsUsd).div(market.totalSupplyUsd).times(100).dp(2, 1).toString(10);
    return market;
  });

  const columns = useMemo(() => {
    return [
      {
        Header: t('Assets'),
        accessor: 'underlyingSymbol',
        Cell: ({ value }) => {
          return (
            <div className="assets">
              <img
                src={`/images/coins/${value.toLowerCase()}.png`}
                alt={value}
              />
              <div>{value}</div>
            </div>
          );
        },
      },
      {
        Header: t('Supply'),
        accessor: 'totalSupplyUsd',
        Cell: ({ value, row }) => {
          return (
            <div className='supply'>
              <div>${getFormatStringFromBignumber(new BigNumber(value))}</div>
              <span>
                {getFormatStringFromBignumber(new BigNumber(value).div(new BigNumber(row?.original?.tokenPrice)))}
                {" "}
                {row?.original?.underlyingSymbol}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Supply_APY"),
        accessor: 'supplyApy',
        Cell: ({ value, row }) => {
          const apyValue = new BigNumber(value || 0).plus(row?.original?.supplyAdditionalApy)

          return (
            <div className='supply-apy'>
              <div >
                {getFormatStringFromBignumber(new BigNumber(row?.original?.supplyAdditionalApy))} %
              </div>
              <span>
                {getFormatStringFromBignumber(apyValue)} %
              </span>
            </div>
          );
        },
      },
      {
        Header: t('Borrow'),
        accessor: 'totalBorrowsUsd',
        Cell: ({ value, row }) => {
          return (
            <div className='supply'>
              <div>${getFormatStringFromBignumber(new BigNumber(value))}</div>
              <span>
                {getFormatStringFromBignumber(new BigNumber(value).div(new BigNumber(row?.original?.tokenPrice)))}
                {" "}
                {row?.original?.underlyingSymbol}
              </span>
            </div>
          );
        },
      },
      {
        Header: t('Borrow_APY'),
        accessor: 'borrowApy',
        Cell: ({ value, row }) => {
          const apyValue = new BigNumber(value || 0).plus(row?.original?.borrowAdditionalApy)

          return (
            <div className='supply-apy'>
              <div>
                {getFormatStringFromBignumber(new BigNumber(row?.original?.borrowAdditionalApy))} %
              </div>
              <span>
                {getFormatStringFromBignumber(apyValue)} %
              </span>
            </div>
          );
        },
      },
      {
        Header: t('CF'),
        accessor: 'collateralFactor',
        Cell: ({ value, row }) => {
          let collateralFactor = new BigNumber(value || 0)
            .div(new BigNumber(10).pow(18))
            .times(100)

          return (
            <div>
              {getFormatStringFromBignumber(collateralFactor, 0)}%
            </div>
          );
        },
      },
      {
        Header: t('Reserves'),
        accessor: 'reserveUSD',
        Cell: ({ value, row }) => {
          const reserveUSD = new BigNumber(value).times(row?.original?.tokenPrice);

          return (
            <div className='supply'>
              <div>
                ${getFormatStringFromBignumber(reserveUSD)}
              </div>
              <span>
                {getFormatStringFromBignumber(value)}
                {" "}
                {row?.original?.underlyingSymbol}
              </span>
            </div>
          );
        },
      },
      {
        Header: t('Liquidity'),
        accessor: 'liquidity',
        Cell: ({ value, row }) => {
          const liquidityTokens = new BigNumber(value).div(row?.original?.tokenPrice);
          return (
            <div className='supply'>
              <div>
                ${getFormatStringFromBignumber(new BigNumber(value))}
              </div>
              <span>
                {getFormatStringFromBignumber(liquidityTokens)}
                {" "}
                {row?.original?.underlyingSymbol}
              </span>
            </div>
          );
        },
      },
      {
        Header: t('Utilize'),
        accessor: 'utilization',
        Cell: ({ value }) => {
          return (
            <div>
              {getFormatStringFromBignumber(new BigNumber(value))}%
            </div>
          );
        },
      },
      {
        Header: t('Price'),
        accessor: 'tokenPrice',
        Cell: ({ value }) => {
          return (
            <div className='token-price'>
              ${getFormatStringFromBignumber(new BigNumber(value))}
            </div>
          );
        },
      },
    ];
  }, [requiredChainId, t]);

  return (
    <Layout>
      <StyledContainer>
        <MarketInfo />
        {
          width > 992 ?
            (
              <DataTable
                data={extendedMarkets}
                columns={columns}
                onRowClick={(row) => history.push(`/market/${row?.original?.underlyingSymbol}`)}
              />
            )
            :
            (
              <MarketMobileItem
                assetList={extendedMarkets}
                history={history}
              />
            )
        }
      </StyledContainer>
    </Layout>
  );
};

Market.defaultProps = {
  history: {},
};

export default compose(withRouter)(Market);
