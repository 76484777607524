import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactSpeedometer from "react-d3-speedometer"
import { useTranslation } from "react-i18next";
import BigNumber from 'bignumber.js';
import { getBigNumber, getFormatStringFromBignumber } from 'utilities/common';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from 'hooks';
import { useEligibilityDataProviderInfo } from 'hooks/useLpStaking';

const StyledContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .item-container {
    flex: 1;
    text-align: center;
    @media (max-width: 992px) {
      text-align: left;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      color: rgba(156, 163, 175, 1);
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        padding-left: 4px;
      }
    }
  }
  .item-container1 {
    flex: 1;
    height: 60px;
    position: relative;
    @media (max-width: 992px) {
      width: 100%;
    }
    .speed-container {
      position: absolute;
      right: 0;
      top: -30px;
      @media (max-width: 992px) {
        top: -100px;
      }
    }
  }
  .divider {
    width: 1px;
    height: 72px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    @media (max-width: 992px) {
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 52.89%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

function HealthInfo() {
  const arsPrice = useSelector((state) => state.account.setting.arsPrice || 0);
  const assetList = useSelector((state) => state.account.setting.assetList);
  const totalBorrowBalance = useSelector((state) => state.account.setting.totalBorrowBalance);
  const totalBorrowLimit = useSelector((state) => state.account.setting.totalBorrowLimit);

  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { isEligibleForRewards } = useEligibilityDataProviderInfo();

  const [netApy, setNetApy] = useState(new BigNumber(0));
  const [dailyEarn, setDatailyEarn] = useState(new BigNumber(0));
  const [healthFactor, setHealthFactor] = useState(0);

  useEffect(() => {
    if (account) {
      const borrowBalance = getBigNumber(totalBorrowBalance);
      const borrowLimit = getBigNumber(totalBorrowLimit);
      const total = BigNumber.maximum(borrowBalance, 0);
      const _factor = borrowBalance.isZero(0) ?
        new BigNumber(100)
        :
        new BigNumber(100).minus(borrowBalance.div(borrowLimit).times(100))
      setHealthFactor(parseInt(_factor.toNumber()));
    } else {
      setHealthFactor(0);
    }
  }, [totalBorrowBalance, totalBorrowLimit, account]);

  useEffect(() => {
    const updateNetAPY = async () => {
      let totalSum = new BigNumber(0);
      let totalSupplied = new BigNumber(0);
      let totalBorrowed = new BigNumber(0);

      assetList.forEach((asset) => {
        const {
          supplyBalance,
          borrowBalance,
          tokenPrice,
          supplyApy,
          borrowApy,
          supplyAdditionalApy,
          borrowAdditionalApy,
        } = asset;

        const supplyBalanceUSD = getBigNumber(supplyBalance).times(getBigNumber(tokenPrice));
        const borrowBalanceUSD = getBigNumber(borrowBalance).times(getBigNumber(tokenPrice));
        totalSupplied = totalSupplied.plus(supplyBalanceUSD);
        totalBorrowed = totalSupplied.plus(borrowBalanceUSD);

        const supplyApywithARS = getBigNumber(supplyApy).plus(getBigNumber(supplyAdditionalApy));
        const borrowApywithARS = getBigNumber(borrowAdditionalApy).minus(getBigNumber(borrowApy));

        let supplyAssetApy = supplyBalanceUSD.times(supplyApywithARS.div(100))
        let borrowAssetApy = borrowBalanceUSD.times(borrowApywithARS.div(100))
        supplyAssetApy = supplyAssetApy.isNaN() ? new BigNumber(0) : supplyAssetApy
        borrowAssetApy = borrowAssetApy.isNaN() ? new BigNumber(0) : borrowAssetApy
        const assetAPY = supplyAssetApy.plus(borrowAssetApy);
        totalSum = totalSum.plus(
          assetAPY.isNaN() ? 0 : assetAPY
        );
      });

      let apy;

      if (totalSum.isZero()) {
        apy = new BigNumber(0);
      } else if (totalSum.isNaN()) {
        apy = new BigNumber(Infinity);
      } else if (totalSum.isGreaterThan(0)) {
        apy = totalSupplied.isZero() ? 0 : totalSum.div(totalSupplied).times(100);
      } else {
        apy = totalBorrowed.isZero() ? 0 : totalSum.div(totalBorrowed).times(100);
      }

      setNetApy(apy);
    };

    updateNetAPY();
  }, [assetList]);

  useEffect(() => {
    let temp = new BigNumber(0);
    if (account && isEligibleForRewards && new BigNumber(arsPrice).gt(0)) {
      assetList.forEach((asset) => {
        if (new BigNumber(asset.activeSupplyUsd).gt(0)) {
          temp = new BigNumber(asset.supplierDailyArs)
            .times(asset.supplyBalance)
            .times(asset.tokenPrice)
            .div(asset.activeSupplyUsd)
            .plus(temp);
        }
        if (new BigNumber(asset.activeBorrowUsd).gt(0)) {
          temp = new BigNumber(asset.borrowerDailyArs)
            .times(asset.borrowBalance)
            .times(asset.tokenPrice)
            .div(asset.activeBorrowUsd)
            .plus(temp);
        }
      })
    }
    
    setDatailyEarn(temp.div(1e18).times(arsPrice));
  }, [account, isEligibleForRewards, arsPrice, assetList])

  return (
    <StyledContainer className='w-full'>
      <div className='item-container'>
        <div className='title'>
          {t("Net_APY")}
        </div>
        <div className='desc'>
          {getFormatStringFromBignumber(netApy, 2)}
          <span>%</span>
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Daily_Earnings")}
        </div>
        <div className='desc'>
          ${getFormatStringFromBignumber(dailyEarn)}
          {/* <span>USD</span> */}
        </div>
      </div>
      <div className='divider' />
      <div className='item-container'>
        <div className='title'>
          {t("Health_Factor")}
        </div>
        <div className='desc'>
          {healthFactor}
          {/* <span>/{getFormatStringFromBignumber(healthFactor, 0)}</span> */}
        </div>
      </div>
      <div className='item-container1'>
        <div className='speed-container'>
          <ReactSpeedometer
            value={healthFactor}
            maxValue={100}
            segments={10}
            maxSegmentLabels={1}
            startColor='#FB7185'
            endColor='#34D399'
            needleColor="white"
            needleHeightRatio={0.6}
            ringWidth={20}
            width={170}
            height={170}
          />
        </div>
      </div>
    </StyledContainer>
  )
}

export default HealthInfo;