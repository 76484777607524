import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from 'react-router-dom';
import { MetaMask } from '@web3-react/metamask';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useActiveWeb3React, useWeb3 } from "hooks";
import { bindActionCreators, compose } from 'redux';
import BigNumber from "bignumber.js";
import styled from 'styled-components';

import MainLayout from "layouts/MainLayout/MainLayout";
import { connectAccount } from 'core';
import {
  useArsApproveCallback,
  useGetRewardCallback,
  useStakeCallback,
  useStakingData,
  useWithdrawCallback,
  useWithdrawExpiredLocksCallback
} from "hooks/useStaking";
import { useBalance } from "hooks/useBalance";

import PenaltyModal from "components/common/PenaltyModal";
import VestInfo from "./VestInfo";
import { methods, getComptrollerContract } from "../../utilities/ContractService";
import * as constants from '../../utilities/constants';
import { addToken, getFormatStringFromBignumber } from 'utilities/common';
import Loading from "components/UI/Loading";
import { CONTRACT_TOKEN_ADDRESS, CONTRACT_TOKEN_ADDRESS_TEMP } from "utilities/constants";
import { getNativeToken, isCoreNetwork } from "utils";

import metaMask from '../../assets/img/wallet/metaMask.svg';
import NoHistory from "../../assets/img/common/no_history.svg"


const VaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .main-container {
    display: flex;
    gap: 16px;
    @media (max-width: 992px) {
      display: block;
    }
    .vertical-divider {
      border-right: 1px solid #1F2937;
    }
    .info-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      .info_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.025em;
        height: 40px;
        .subtitle {
          text-align: left;
          font-weight: 500;
        }
        .text {
          text-align: right;
        }
        .text1 {
          text-align: right;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #9CA3AF;
        }
        @media only screen and (max-width: 992px) {
          .subtitle {
            padding-left: 10px;
            font-size: 13px;
          }
          .text {
            font-weight: 600;
            font-size: 13px;
            line-height: 100%;
          }
        }
      }
    }
  }
  .card-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 16px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    .token-add {
      margin-left: 10px;
      cursor: pointer;
    }
    .title {
      padding: 0px 0px 16px 0px;
      gap: 10px;
      border-bottom: 1px solid #1F2937;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;    
      @media (max-width: 992px) {
      }
    }
    .divider {
      border-bottom: 1px solid #1F2937;
      padding-bottom: 16px;
    }
    .claim_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.025em;
      .subtitle {
        font-weight: 500;
      }
      .value {
      }
      .description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
      }
      img {
        height: 20px;
        padding-right: 10px;
      }
    }
    .no-history-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        height: 112px;
      }
    }
    .item-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 150px;
      overflow: auto;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        .title-container {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            height: 20px;
          }
        }
        .expired {
          color: #9CA3AF;
        }
      }
    }
    .stake-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .lock-text {
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9CA3AF;
      }
      .input-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 16px 10px 16px;
        border-radius: 4px;
        border: 1px solid #1F2937;
        background: #00000033;
        .balance_row {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
        }
        .input-sub {
          display: flex;
          gap: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          input {
            flex: 1;
            background: transparent;
            border: none;
            &:focus {
              outline: none;
            }
            ::placeholder {
              padding: 0 !important;
              color: #9CA3AF;
            }
          }
          .max-button {
            padding: 5px 10px;
            border-radius: 4px;
            gap: 10px;
            background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            &:hover {
              box-shadow: 0px 4px 24px 4px #2566EC33;
            }
          }
        }
      }
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 16px 10px 16px;
      border-radius: 4px;
      gap: 10px;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
    }
    .withdraw-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      border-radius: 4px;
      gap: 10px;    
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
      &:hover {
        box-shadow: 0px 4px 24px 4px #2566EC33;
      }
      font-weight: 600;
    }
  }
`;

function Vesting({ settings }) {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const { connector, account, requiredChainId, chainId, isActive } = useActiveWeb3React();

  const [arsPrice, setArsPrice] = useState(0);
  const [wcorePrice, setWcoreArsPrice] = useState(0);
  const [lockAmount, setLockAmount] = useState('');
  const [lockPending, setLockPending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [earnedBalance, setEarnedBalance] = useState(new BigNumber(0));

  const [stakeApr, setStakeApr] = useState(0);
  const [lockApr, setLockApr] = useState(0);

  const [collectLoading, setCollectLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimAllLoading, setClaimAllLoading] = useState(false);

  const {
    totalLocked,
    totalStaked,
    stakeAprWithoutPrice,
    lockAprInitial,
    unlockedBalance,
    penaltyAmount,
    locks,
    unlockable,
    fees,
    // totalEarned,
    vests,
    arsEmission,
    reserves
  } = useStakingData(update);
  const { arsBalance, arsStakingAllowance } = useBalance(update);
  const { handleStake, pending: stakePending } = useStakeCallback(account);
  const { handleApprove, pending: approvePending } = useArsApproveCallback(account);
  const { handleWithdraw, pending: withdrawPending } = useWithdrawCallback(account);
  const { handleGetReward, pending: getRewardPending } = useGetRewardCallback(account);
  const { handleWithdrawExpiredLocks, pending: withdrawExpiredLocksPending } = useWithdrawExpiredLocksCallback(account);

  const totalClaimableReward = useMemo(() => {
    if (fees.length === 0) return new BigNumber(0);
    let temp = new BigNumber(0);
    for (let index = 0; index < fees.length; index++) {
      if (fees[index].address === CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address) {
        temp = temp.plus(fees[index].amount.div(1e18).times(arsPrice))
      } else if (fees[index].address === CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].wcore.address) {
        temp = temp.plus(fees[index].amount.div(1e18).times(wcorePrice))
      }
    }
    return temp;
  }, [fees])

  const getVoteInfo = async () => {
    const myAddress = account;
    const appContractCallContext = [{
      reference: 'appContract',
      contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
      abi: constants.CONTRACT_COMPTROLLER_ABI,
      calls: [
        { reference: 'arsInitialIndex', methodName: 'arsInitialIndex', methodParameters: [] },
        { reference: 'arsAccrued', methodName: 'arsAccrued', methodParameters: [myAddress] },
      ],
      context: {}
    }]
    const appContractResults = await methods.ethMulticall(web3, appContractCallContext, requiredChainId);
    let arsInitialIndex = new BigNumber(0);
    let arsAccrued = new BigNumber(0);
    appContractResults.results.appContract.callsReturnContext.forEach((result) => {
      if (result.methodName === "arsInitialIndex") {
        arsInitialIndex = new BigNumber(result.returnValues[0].hex)
      } else if (result.methodName === "arsAccrued") {
        arsAccrued = new BigNumber(result.returnValues[0].hex)
      }
    })

    let aquariusEarned = new BigNumber(0);
    let assetValues = [];
    const earnedAssetList = settings.assetList.filter(asset =>
      new BigNumber(asset.supplyBalance).isGreaterThan(0)
      || new BigNumber(asset.borrowBalance).isGreaterThan(0)
    )

    if (earnedAssetList.length > 0) {
      try {
        const contractCallContext = [{
          reference: 'appContract',
          contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
          abi: constants.CONTRACT_COMPTROLLER_ABI,
          calls: [],
          context: {}
        }]
        earnedAssetList.forEach(asset => {
          contractCallContext[0].calls.push({
            reference: `${asset.id}_arsSupplierIndex`,
            methodName: 'arsSupplierIndex',
            methodParameters: [asset.atokenAddress, myAddress]
          })
          contractCallContext[0].calls.push({
            reference: `${asset.id}_arsBorrowerIndex`,
            methodName: 'arsBorrowerIndex',
            methodParameters: [asset.atokenAddress, myAddress]
          })
        });
        const contractCallResults = await methods.ethMulticall(web3, contractCallContext, requiredChainId);

        for (const value of contractCallResults?.results?.appContract?.callsReturnContext) {
          const references = value.reference.split('_')
          assetValues[references[0]] = assetValues[references[0]] || {}
          assetValues[references[0]][references[1]] = new BigNumber(value.returnValues[0].hex)
        }
      } catch (err) {
        console.log(err)
      }

      earnedAssetList.forEach(asset => {
        const supplyIndex = new BigNumber(asset.arsSupplyIndex)
        let supplierIndex = assetValues[asset.id]?.arsSupplierIndex || new BigNumber(0)
        const supplierTokens = asset.aTokenBalance
        const initBorrowIndex = asset.arsBorrowIndex
        const borrowerIndex = new BigNumber(assetValues[asset.id]?.arsBorrowerIndex || 0)
        const borrowBalanceStored = asset.borrowBalanceStored
        const borrowIndex = asset.borrowIndex

        if (supplierIndex.isZero(0) && supplyIndex.isGreaterThan(0)) {
          supplierIndex = arsInitialIndex;
        }
        let deltaIndex = supplyIndex.minus(supplierIndex);
        const supplierDelta = new BigNumber(supplierTokens)
          .multipliedBy(deltaIndex)
          .dividedBy(1e36);

        aquariusEarned = aquariusEarned.plus(supplierDelta);
        if (borrowerIndex.isGreaterThan(0)) {
          deltaIndex = new BigNumber(initBorrowIndex).minus(borrowerIndex);
          const borrowerAmount = new BigNumber(borrowBalanceStored)
            .multipliedBy(1e18)
            .dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
          aquariusEarned = aquariusEarned.plus(borrowerDelta);
        }
      })

      aquariusEarned = aquariusEarned.plus(arsAccrued)
      setEarnedBalance(aquariusEarned);
    } else {
      setEarnedBalance(new BigNumber(0));
    }
  };

  useEffect(() => {
    if (settings.arsPrice) {
      setArsPrice(Number(settings.arsPrice));
    }
  }, [settings.arsPrice]);

  useEffect(() => {
    const market = settings.markets.find(
      ele => ele.underlyingSymbol === 'CORE' || ele.underlyingSymbol === 'ETH'
    );
    if (market) {
      setWcoreArsPrice(Number(market.tokenPrice));
    }
  }, [settings.markets]);


  useEffect(() => {
    if (account) {
      if (settings?.assetList?.length > 0) {
        getVoteInfo();
      }
    } else {
      setEarnedBalance(new BigNumber(0));
    }
  }, [settings.assetList, account]);

  useEffect(() => {
    if (arsPrice > 0 && wcorePrice > 0) {
      const _stakeApr = stakeAprWithoutPrice * wcorePrice / arsPrice;
      setStakeApr(_stakeApr);
      setLockApr(lockAprInitial + _stakeApr);
    }
  }, [arsPrice, wcorePrice, lockAprInitial, stakeAprWithoutPrice])

  const isPending = () => {
    if (
      approvePending ||
      stakePending ||
      withdrawPending ||
      withdrawExpiredLocksPending ||
      getRewardPending
    ) {
      return true;
    }

    return false;
  };

  const isApprove = () => {
    if (
      arsStakingAllowance.eq(0) ||
      arsStakingAllowance.lt(new BigNumber(lockAmount).times(1e18))
    ) {
      return true;
    }

    return false;
  };


  const handleCollect = async () => {
    if (earnedBalance.gt(0)) {
      setCollectLoading(true);
      const appContract = getComptrollerContract(web3, requiredChainId);
      const isCheckGas = await methods.checkGasFee(
        web3,
        requiredChainId,
        appContract.methods.claimArs,
        [account],
        account
      );
      if (!isCheckGas) {
        NotificationManager.warning(t('Insufficient_Token_Balance', { token: getNativeToken(requiredChainId) }));
        setCollectLoading(false);
        return
      }
      methods.send(
        appContract.methods.claimArs,
        [account],
        account
      )
        .then(() => {
          setCollectLoading(false);
        })
        .catch(() => {
          setCollectLoading(false);
        });
    }
  };

  const stake = async lock => {
    if (isPending()) {
      return;
    }

    if (!account) {
      return;
    }

    if (lock) {
      setLockPending(true);
    }
    if (isApprove()) {
      const tx = await handleApprove(new BigNumber(lockAmount).times(1e18));
      if (tx) {
        setUpdate(!update);
      }
    } else {
      if (
        arsBalance.lt(
          new BigNumber(lockAmount).times(1e18)
        )
      ) {
        NotificationManager.error(t('Insufficient_balance_dot'));
        return;
      }

      const tx = await handleStake(
        new BigNumber(lockAmount)
          .times(1e18)
          .integerValue()
          .toString(10),
        lock
      );
      if (tx) {
        setUpdate(!update);
      }
    }
    if (lock) {
      setLockPending(false);
    }
  };

  const withdraw = useCallback(async () => {
    if (isPending()) {
      return;
    }
    if (unlockedBalance.eq(0)) {
      return;
    }

    if (!account) {
      return;
    }

    setClaimLoading(true);
    const tx = await handleWithdraw(unlockedBalance.toString(10));
    if (tx) {
      setUpdate(!update);
    }
    setClaimLoading(false);
  }, [handleWithdraw, unlockedBalance, account]);

  const getReward = async () => {
    if (isPending()) {
      return;
    }
    if (fees.length < 1) {
      return;
    }

    setClaimAllLoading(true);
    const tx = await handleGetReward();
    if (tx) {
      setUpdate(!update);
    }
    setClaimAllLoading(false);
  };

  const withdrawExpiredLocks = useCallback(async () => {
    if (isPending()) {
      return;
    }
    if (unlockable.eq(0)) {
      return;
    }
    setWithdrawLoading(true);
    const tx = await handleWithdrawExpiredLocks();
    if (tx) {
      setUpdate(!update);
    }
    setWithdrawLoading(false);
  }, [handleWithdrawExpiredLocks, unlockable]);


  const overview = [
    {
      name: t('Total_Staked'),
      value: [
        `$ ${totalLocked
          .div(1e18)
          .times(arsPrice)
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 2 })}`,
        `${totalLocked
          .div(1e18)
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 0 })} ARS`
      ]
    },
    {
      name: t('Total_Vested'),
      value: [
        `$ ${totalStaked
          .div(1e18)
          .times(arsPrice)
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 2 })}`,
        `${totalStaked
          .div(1e18)
          .toNumber()
          .toLocaleString('en-US', { maximumFractionDigits: 0 })} ARS`
      ]
    },
    {
      name: [t('Stake'), t('Earned'), 'WCORE + ARS'],
      value: `${lockApr.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })}
      %`,
    },
    {
      name: [t('Vesting'), t('Earned'), 'WCORE'],
      value: `${stakeApr.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })}
      %`,
    },
    {
      name: t('Reserve_Vault'),
      value: `${reserves.toNumber().toLocaleString('en-US', {
        maximumFractionDigits: 4
      })} WCORE`
    },
    {
      name: t('Penalty_ARS_Emission'),
      help: t('Penalty_ARS_Emission_help'),
      value: `${arsEmission
        .div(1e18)
        .toNumber()
        .toLocaleString('en-US', { maximumFractionDigits: 5 })}`
    }
  ];

  const input = [
    {
      name: t('Stake_ARS'),
      text: [
        t('Stake_ARS_desc1'),
        t('Stake_ARS_desc2'),
        t('Stake_ARS_desc3'),
      ],
      apr: `${lockApr.toLocaleString('en-US', { maximumFractionDigits: 0 })}%`
    }
  ];

  const withdrawData = [
    {
      name: t('Expired_Staked_ARS'),
      description: '',
      value: `${unlockable
        .div(1e18)
        .toNumber()
        .toLocaleString('en-US', { maximumFractionDigits: 3 })} ARS`,
      claim: t('Withdraw'),
      handler: withdrawExpiredLocks
    },
  ]

  const claim = [
    {
      name: t('Claimable_ARS'),
      description: t('Include_vested_ARS'),
      realValue: unlockedBalance,
      value: `${unlockedBalance
        .div(1e18)
        .toNumber()
        .toLocaleString('en-US', { maximumFractionDigits: 3 })} ARS`,
      claim: t('Claim'),
      isLoading: claimLoading,
      handler: withdraw
    },
    {
      name: t('ARS_in_Vesting'),
      description: t('ARS_claimed_penalty'),
      value: `${penaltyAmount
        .times(2)
        .div(1e18)
        .toNumber()
        .toLocaleString('en-US', { maximumFractionDigits: 3 })} ARS`,
      claim: ''
    },
    {
      name: t('Claim_all_ARS_above'),
      description: t('Early_Exit_Penalty'),
      realValue: penaltyAmount,
      value: `${penaltyAmount
        .div(1e18)
        .toNumber()
        .toLocaleString('en-US', { maximumFractionDigits: 3 })} ARS`,
      claim: t('Claim_with_penalty'),
      handler: () => setIsModalOpen(true)
    },
    {
      name: t('Total_Vested_ARS'),
      description: '',
      value: `${vests
        .reduce(
          (total, e) => total + e.amount.div(1e18).toNumber(),
          0
        )
        .toLocaleString('en-US', {
          maximumFractionDigits: 3
        })} ARS`
      ,
      claim: '',
    }
  ];

  return (
    <MainLayout>
      <VaultContainer>
        <div className="card-container">
          <div className="title">{t('Staking_Info')}</div>
          <VestInfo overview={overview} />
        </div>

        <div className="main-container">
          <div className="card-container">
            <div className="title">{t('ARS_Vests')}</div>
            {input.map((e, index) => (
              <div key={e.name} className="stake-container">
                <div>
                  {e.text.map((it, i) => (
                    <div key={i}>
                      <span className="lock-text">
                        {it}
                      </span>
                      <br />
                    </div>
                  ))}
                </div>
                {withdrawData.map((e, index) => (
                  <div key={e.name}>
                    <div className="claim_row">
                      <p className="subtitle">{e.name}</p>
                      <div className="claim_row">
                        <p className="value mr-2">{e.value}</p>
                        {e.claim && (
                          <button className="withdraw-button" onClick={e.handler}
                            disabled={unlockable.eq(0) || withdrawLoading}
                          >
                            {withdrawLoading && <Loading size={'12px'} margin={'3px'} />}
                            {e.claim}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="input-container">
                  <div className="balance_row">
                    <p>{t('Amount')}</p>
                    <p>
                      {t('Balance')}{' '}
                      {arsBalance.div(1e18).toFormat(2)}{' '}
                      <span>ARS</span>
                    </p>
                  </div>
                  <div className="input-sub">
                    <input
                      placeholder="0.00"
                      value={lockAmount}
                      onChange={event => {
                        if (
                          index === 0 &&
                          (!event.target.value.length ||
                            Number(event.target.value) >= 0)
                        )
                          setLockAmount(event.target.value);
                      }}
                    />
                    <button
                      type="button"
                      className="max-button"
                      onClick={() => {
                        setLockAmount(arsBalance.div(1e18).toString());
                      }}
                    >
                      {t('MAX')}
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  className={'button'}
                  disabled={approvePending ||
                    stakePending ||
                    lockPending ||
                    (account && arsBalance.lt(new BigNumber(lockAmount).times(1e18))) ||
                    new BigNumber(Number(lockAmount)).eq(0)
                  }
                  onClick={() => {
                    stake(true);
                  }}
                >
                  {(approvePending || stakePending || lockPending) ? (
                    <div className="flex justify-center items-center">
                      <Loading size={'14px'} margin={'7px'} />
                      {t('Pending')}
                    </div>
                  ) : (
                    <>{isApprove() ? t('Approve') : e.name}</>
                  )}
                </button>
              </div>
            ))}
            <div className="item-container">
              {locks.map((e, index) => (
                <div key={index} className="item" >
                  <div className="title-container">
                    <img src="/images/coins/ars.png" alt="" />
                    <p>
                      {e.amount
                        .div(1e18)
                        .toNumber()
                        .toLocaleString('en-US', {
                          maximumFractionDigits: 3
                        })}
                    </p>
                  </div>
                  <div className="expired">
                    {t('Expired_at')}{' '}
                    {moment.unix(e.unlockTime).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </div>
              ))}
              {!locks.length && (
                <div className="no-history-container">
                  <img src={NoHistory} alt="No Vest History" />
                </div>
              )}
            </div>
            <div className="claim_row">
              <p className="subtitle">{t('Claimable_Rewards')}</p>
              <p className="value">
                {t('Total')}{' '}
                {getFormatStringFromBignumber(totalClaimableReward)} USD
              </p>
            </div>
            <div>
              {
                fees.map((e, index) => {
                  if (e.address === CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address
                    || e.address === CONTRACT_TOKEN_ADDRESS_TEMP[requiredChainId].wcore.address
                  ) {
                    const isARS = e.address === CONTRACT_TOKEN_ADDRESS[requiredChainId].ars.address;
                    return (
                      <div key={index} className="claim_row">
                        <div className="subtitle flex items-center">
                          <img
                            src={isARS ? '/images/coins/ars.png' : '/images/coins/core.png'}
                            alt=""
                          />
                          <p>{isARS ? 'ARS' : 'WCORE'}</p>
                          {
                            (isActive && connector instanceof MetaMask && isCoreNetwork(chainId)) && (
                              <div className='token-add'
                                onClick={() => {
                                  addToken(isARS ? 'ars' : 'wcore', 18, chainId, 'token')
                                }}
                              >
                                <img src={metaMask} alt="arrow" />
                              </div>
                            )
                          }
                        </div>
                        <p className="value">
                          {getFormatStringFromBignumber(e.amount.div(1e18))}
                        </p>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className="flex justify-end">
              <button className="withdraw-button" onClick={getReward}
                disabled={claimAllLoading || !account || totalClaimableReward.lte(0)}
              >
                {claimAllLoading && <Loading size={'12px'} margin={'3px'} />}
                {t('Claim_All')}
              </button>
            </div>
          </div>

          <div className="card-container">
            <div className="title">{t('ARS_Vests')}</div>
            <div>
              <div className="claim_row">
                <p className="subtitle">{t('Collect_ARS')}</p>
                <div className="flex justify-center items-center">
                  <p className="value">
                    {getFormatStringFromBignumber(earnedBalance.div(1e18))} ARS
                  </p>
                </div>
              </div>
              <div className="claim_row">
                <p className="description"></p>
                <button className="withdraw-button" onClick={handleCollect}
                  disabled={!account || earnedBalance.isZero() || collectLoading}
                >
                  {collectLoading && <Loading size={'12px'} margin={'3px'} />}
                  {t('Collect_ARS')}
                </button>
              </div>
              <div className="divider" />
            </div>
            {claim.map((e, index) => (
              <div key={e.name}>
                <div className="claim_row">
                  <p className="subtitle">{e.name}</p>
                  <div className="flex justify-center items-center">
                    <p className="value">{e.value}</p>
                  </div>
                </div>
                <div className="claim_row">
                  <p className="description">{e.description}</p>
                  {e.claim && (
                    <button className="withdraw-button" onClick={e.handler}
                      disabled={e.realValue.eq(0) || e.isLoading}
                    >
                      {e.isLoading && <Loading size={'12px'} margin={'3px'} />}
                      {e.claim}
                    </button>
                  )}
                </div>
                {index !== claim.length - 1 && <div className="divider" />}
              </div>
            ))}
            <div className="item-container">
              {vests.map((e, index) => (
                <div key={index} className="item" >
                  <div className="title-container">
                    <img src="/images/coins/ars.png" alt="" />
                    <p>
                      {e.amount
                        .div(1e18)
                        .toNumber()
                        .toLocaleString('en-US', {
                          maximumFractionDigits: 3
                        })}
                    </p>
                  </div>
                  <div className="expired">
                    {t('Expired_at')}{' '}
                    {moment.unix(e.unlockTime).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </div>
              ))}
              {
                !vests.length && (
                  <div className="no-history-container">
                    <img src={NoHistory} alt="No Vest History" />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </VaultContainer>
      <PenaltyModal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        update={update}
        setUpdate={setUpdate}
      />
    </MainLayout>
  )
}

Vesting.defaultProps = {
  match: {},
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {

  return bindActionCreators(
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Vesting);