import { Web3ReactProvider } from '@web3-react/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { store } from './core';
import APIProvider from './APIProvider';
import React, { useEffect } from 'react';
import 'moment/locale/zh-cn';
import { libraries } from 'connectors';
import config from 'config';
import { RefreshContextProvider } from 'contexts/RefreshContext';

const Providers = (props) => {
  const { i18n } = useTranslation();
  let lang = localStorage.getItem('language') || config.defaultLanguage;

  useEffect(() => {
    i18n.changeLanguage(lang);
    if (lang === 'en') {
      moment.locale('en');
    } else {
      moment.locale('zh-cn');
    }
  }, [lang])

  return (
    <Web3ReactProvider connectors={libraries}>
      <RefreshContextProvider>
        <Provider store={store}>
          <Router>
            <APIProvider />
            {props.children}
          </Router>
        </Provider>
      </RefreshContextProvider>
    </Web3ReactProvider>
  );
};

export default Providers;
