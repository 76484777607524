import Switch from "react-switch";
import styled from 'styled-components';

const StyledContainer = styled.div`
  .react-switch-bg {
    border: 1px solid #FFFFFF1A !important;
    background: ${props => props.checked ?
    'linear-gradient(180deg, rgba(14, 165, 233, 0.3) 0%, rgba(37, 99, 235, 0.3) 100%)'
    :
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%)'
  } !important;
  }
  .react-switch-handle {
    background: ${props => props.checked ?
    'linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%)'
    :
    '#F9FAFB80'
  } !important;
  }
`;

export default function SwitchComponent({
  value = false,
  onChange = (val) => val
}) {
  return (
    <StyledContainer checked={value}>
      <Switch onChange={onChange} checked={value}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={22}
      />
    </StyledContainer>
  );
}
