import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import Modal from '../UI/Modal';
import AquariusLoader from '../../assets/img/common/loading_modal.svg';

import { ReactComponent as CloseIcon } from '../../assets/img/common/close.svg'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.025em;
    padding: 0px 0px 16px 0px;
    gap: 10px;
    border-bottom: 1px solid #FFFFFF1A;
    @media (max-width: 992px) {
      font-size: 16px;
    }
    .settings-close-container {
      outline: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 51px;
      height: 51px;
    }
  }
  .tx-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #9CA3AF;
  }
`

function WalletsModal({ open, onCloseModal, isCollateralEnable }) {
  const { t } = useTranslation();

  const content = (
    <Styles>
      <div className='modal-header'>
        <div>{t('Confirm_Transaction')}</div>
        <div className="settings-close-container" onClick={onCloseModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="loader">
        <img className="transaction-img animate-spin" src={AquariusLoader} alt="Loader" />
      </div>
      <div className="tx-desc">
        {`${!isCollateralEnable ? t("Enable_as_collateral") : t("Disable_as_collateral")}`}
      </div>
    </Styles>
  );

  return (
    <div>
      <Modal
        content={content}
        open={open}
        onCloseModal={onCloseModal}
        containerStyle={{
          maxWidth: 560,
        }}
      />
    </div>
  );
}

export default WalletsModal;
