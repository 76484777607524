import { accountActionCreators, connectAccount } from './core';
import { bindActionCreators } from 'redux';
import * as constants from './utilities/constants';
import {
  getComptrollerContract,
  getBalance,
  methods,
} from './utilities/ContractService';
import { promisify } from './utilities';
import { useActiveWeb3React, useWeb3 } from './hooks';
import { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { getNativeToken } from 'utils';

const disabledBorrowTokens = []
const disabledBorrowRepayTokens = []

const APIProvider = ({ settings, setSetting, getGovernanceAquarius }) => {
  const { account, requiredChainId } = useActiveWeb3React();
  const web3 = useWeb3();

  const setDecimals = () => {
    const decimals = {};

    const contractAddresses = Object.values(constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]).filter(item => {
      return item.id !== 'ars'
    });

    const contractDecimals =
      contractAddresses.map(item => {
        return [
          item.id,
          item.decimals,
          8,
        ]
      })

    contractDecimals.forEach(item => {
      decimals[`${item[0]}`] = {
        token: Number(item[1]),
        atoken: Number(item[2]),
        price: 18 + 18 - Number(item[1]),
      };
    });

    decimals.mantissa = +process.env.REACT_APP_MANTISSA_DECIMALS;
    decimals.comptroller = +process.env.REACT_APP_COMPTROLLER_DECIMALS;
    setSetting({ decimals });
  };

  const initSettings = async () => {
    setDecimals();
    setSetting({
      chainId: requiredChainId,
      account: '',
      assetList: [],
      totalLiquidity: '0',
      totalSupplyBalance: '0',
      totalBorrowBalance: '0',
      totalBorrowLimit: '0',
      markets: []
    });
  };

  const getMarkets = async () => {
    const res = await promisify(getGovernanceAquarius, {});
    if (!res.status || res.data.chainId !== requiredChainId) {
      return;
    }
    const filteredMarkets = res.data.markets.filter((market) => new BigNumber(market.collateralFactor).gt(0));
    // const filteredMarkets = res.data.markets; // test for new market

    setSetting({
      chainId: res.data.chainId,
      arsPrice: res.data.arsPrice,
      markets: filteredMarkets,
      dailyAquarius: res.data.dailyAquarius,
      totalArsDistributed: res.data.totalArsDistributed,
      remainedBalance: res.data.remainedBalance,
      totalLiquidity: res.data.totalLiquidity,
    });
  };

  const updateMarketInfo = async () => {
    if (!settings.decimals || !settings.markets) {
      return;
    }

    const appContract = getComptrollerContract(web3, requiredChainId);
    const assetsIn = account ? await methods.call(appContract.methods.getAssetsIn, [account]) : [];

    let totalSupplyBalance = new BigNumber(0);
    let totalBorrowBalance = new BigNumber(0);
    let totalBorrowLimit = new BigNumber(0);
    let totalAvailableCredit = new BigNumber(0);
    const assetList = [];

    const contractAddresses = Object.values(constants.CONTRACT_TOKEN_ADDRESS[requiredChainId]).filter(item => {
      return settings.decimals[item.id]
    });

    const ethBalance = account ? await getBalance(web3, account, requiredChainId) : 0

    let aBepContractData = {}
    if (account) {
      const aBepContractCallContext = contractAddresses.map(item => {
        return {
          reference: item.id,
          contractAddress: constants.CONTRACT_ABEP_ADDRESS[requiredChainId][item.id].address,
          abi: item.id !== getNativeToken(requiredChainId).toLowerCase()
            ? constants.CONTRACT_ABEP_ABI
            : constants.CONTRACT_AETH_ABI
          ,
          calls: [
            { reference: 'balanceOfUnderlying', methodName: 'balanceOfUnderlying', methodParameters: [account] },
            { reference: 'borrowBalanceCurrent', methodName: 'borrowBalanceCurrent', methodParameters: [account] },
            { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [account] },
            { reference: 'borrowBalanceStored', methodName: 'borrowBalanceStored', methodParameters: [account] },
          ],
          context: {}
        }
      })
      const aBepContractResults = await methods.ethMulticall(web3, aBepContractCallContext, requiredChainId);

      for (const [itemId, value] of Object.entries(aBepContractResults?.results)) {
        aBepContractData[itemId] = {
          supplyBalance: new BigNumber(value.callsReturnContext[0].returnValues[0].hex),
          borrowBalance: new BigNumber(value.callsReturnContext[1].returnValues[0].hex),
          balance: new BigNumber(value.callsReturnContext[2].returnValues[0].hex),
          borrowBalanceStored: new BigNumber(value.callsReturnContext[3].returnValues[0].hex),
        }
      }
    } else {
      contractAddresses.forEach(item => {
        aBepContractData[item.id] = {
          supplyBalance: new BigNumber(0),
          borrowBalance: new BigNumber(0),
          balance: new BigNumber(0),
          borrowBalanceStored: new BigNumber(0),
        }
      })
    }

    let tokenContractData = {}
    if (account) {
      const tokenContractCallContext = []
      contractAddresses.forEach(item => {
        if (item.id !== getNativeToken(requiredChainId).toLowerCase()) {
          tokenContractCallContext.push({
            reference: item.id,
            contractAddress: constants.CONTRACT_TOKEN_ADDRESS[requiredChainId][item.id].address,
            abi: constants.ERC20_TOKEN_ABI,
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [account] },
              {
                reference: 'allowance',
                methodName: 'allowance',
                methodParameters: [account, constants.CONTRACT_ABEP_ADDRESS[requiredChainId][item.id].address]
              },
            ],
            context: {}
          })
        }
      })
      const tokenContractResults = await methods.ethMulticall(web3, tokenContractCallContext, requiredChainId);
      for (const [itemId, value] of Object.entries(tokenContractResults?.results)) {
        tokenContractData[itemId] = {
          balance: new BigNumber(value.callsReturnContext[0].returnValues[0].hex),
        }
      }
    } else {
      contractAddresses.forEach(item => {
        tokenContractData[item.id] = {
          balance: new BigNumber(0),
        }
      })
    }

    let contractCallData = {}
    if (account) {
      const contractCallContext = [{
        reference: 'appContract',
        contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
        abi: constants.CONTRACT_COMPTROLLER_ABI,
        calls: [],
        context: {}
      }]
      for (const [itemId, value] of Object.entries(aBepContractData)) {
        contractCallContext[0].calls.push({
          reference: itemId,
          methodName: 'getHypotheticalAccountLiquidity',
          methodParameters: [account, constants.CONTRACT_ABEP_ADDRESS[requiredChainId][itemId].address, value.balance.toString(10), 0]
        })
      }

      const contractCallResults = await methods.ethMulticall(web3, contractCallContext, requiredChainId);

      contractCallResults?.results?.appContract?.callsReturnContext.forEach(item => {
        contractCallData[item.reference] = [
          new BigNumber(item.returnValues[0].hex).toNumber(),
          new BigNumber(item.returnValues[1].hex).toNumber(),
          new BigNumber(item.returnValues[2].hex).toNumber(),
        ]
      })
    } else {
      for (const [itemId, value] of Object.entries(aBepContractData)) {
        contractCallData[itemId] = [
          new BigNumber(0).toNumber(),
          new BigNumber(0).toNumber(),
          new BigNumber(0).toNumber(),
        ]
      }
    }

    let pendingRewardData = {}
    if (account) {
      const aTokenArray = []
      contractAddresses.forEach((item) => {
        aTokenArray.push(constants.CONTRACT_ABEP_ADDRESS[requiredChainId][item.id].address);
      });
      const pendingRewardCallContext = [{
        reference: 'pendingRewards',
        contractAddress: constants.CHEF_INCENTIVES_CONTROLLER[requiredChainId],
        abi: constants.CHEF_INCENTIVES_CONTROLLER_ABI,
        calls: [
          {
            reference: 'pendingSupplyRewards',
            methodName: 'pendingRewards',
            methodParameters: [account, aTokenArray, true]
          },
          {
            reference: 'pendingBorrowRewards',
            methodName: 'pendingRewards',
            methodParameters: [account, aTokenArray, false]
          },
        ],
        context: {}
      }];
      const pendingRewardResults = await methods.ethMulticall(web3, pendingRewardCallContext, requiredChainId);

      contractAddresses.forEach((item, index) => {
        pendingRewardData[item.id] = {
          supplyReward: new BigNumber(pendingRewardResults.results.pendingRewards.callsReturnContext[0].returnValues[index].hex).toString(10),
          borrowReward: new BigNumber(pendingRewardResults.results.pendingRewards.callsReturnContext[1].returnValues[index].hex).toString(10)
        }
      });
    } else {
      contractAddresses.forEach((item) => {
        pendingRewardData[item.id] = {
          supplyReward: 0,
          borrowReward: 0
        }
      });
    }

    contractAddresses.forEach((item, index) => {
      let market = settings.markets.find((ele) => {
        return item.address && ele.underlyingAddress
          ? ele.underlyingAddress.toLowerCase() === item.address.toLowerCase()
          : ele.underlyingSymbol.toLowerCase() === item.symbol.toLowerCase()
      });
      if (!market) return;

      const tokenDecimal = settings.decimals[item.id].token;
      const walletBalance = (item.id !== getNativeToken(requiredChainId).toLowerCase())
        ? tokenContractData[item.id].balance.div(new BigNumber(10).pow(tokenDecimal))
        : new BigNumber(ethBalance).div(new BigNumber(10).pow(tokenDecimal));
      const borrowBalance = aBepContractData[item.id].borrowBalance.div(new BigNumber(10).pow(tokenDecimal))
      const borrowBalanceStored = aBepContractData[item.id].borrowBalanceStored
      const aTokenBalance = aBepContractData[item.id].balance
      const percentOfLimit = new BigNumber(settings.totalBorrowLimit).isZero()
        ? '0'
        : borrowBalance
          .times(new BigNumber(market.tokenPrice || 0))
          .div(settings.totalBorrowLimit)
          .times(100)
          .dp(0, 1)
          .toString(10);

      const asset = {
        key: index,
        id: item.id,
        delist: false,
        disabledBorrow: disabledBorrowTokens.includes(item.symbol) ? true : false,
        disabledBorrowRepay: disabledBorrowRepayTokens.includes(item.symbol) ? true : false,
        img: `/images/coins/${(market.underlyingSymbol || item.symbol).toLowerCase()}.png`,
        name: market.underlyingSymbol || item.symbol,
        symbol: market.underlyingSymbol || item.symbol,
        tokenAddress: item.address,
        asymbol: market.symbol,
        atokenAddress: constants.CONTRACT_ABEP_ADDRESS[requiredChainId][item.id].address,
        supplyApy: market.supplyApy,
        borrowApy: market.borrowApy,
        supplyAdditionalApy: market.supplyAdditionalApy,
        borrowAdditionalApy: market.borrowAdditionalApy,
        collateralFactor: new BigNumber(market.collateralFactor).div(1e18).toString(),
        tokenPrice: market.tokenPrice,
        liquidity: market.liquidity,
        borrowCaps: market.borrowCaps,
        totalBorrows: market.totalBorrows2,
        totalBorrowsUsd: market.totalBorrowsUsd,
        supplyRate: market.supplyRate,
        borrowRate: market.borrowRate,
        totalSupply: market.totalSupply2,
        totalSupplyUsd: market.totalSupplyUsd,
        arsBorrowPerDay: market.arsBorrowPerDay,
        arsSupplyPerDay: market.arsSupplyPerDay,
        arsBorrowIndex: market.arsBorrowIndex,
        arsSupplyIndex: market.arsSupplyIndex,
        borrowIndex: market.borrowIndex,
        borrowBalanceStored: borrowBalanceStored.toString(),
        aTokenBalance,
        walletBalance,
        supplyBalance: aBepContractData[item.id].supplyBalance.div(new BigNumber(10).pow(tokenDecimal)).toString(),
        borrowBalance: borrowBalance.toString(),
        collateral: assetsIn.includes(constants.CONTRACT_ABEP_ADDRESS[requiredChainId][item.id].address),
        percentOfLimit,
        hypotheticalLiquidity: new BigNumber(contractCallData[item.id]).toString(),
        supplyReward: pendingRewardData[item.id].supplyReward,
        borrowReward: pendingRewardData[item.id].borrowReward,
        activeSupplyUsd: market.activeSupplyUsd,
        activeBorrowUsd: market.activeBorrowUsd,
        supplierDailyArs: market.supplierDailyArs,
        borrowerDailyArs: market.borrowerDailyArs,
      }
      assetList.push(asset);

      const supplyBalanceUSD = new BigNumber(asset.supplyBalance).times(asset.tokenPrice);
      const borrowBalanceUSD = new BigNumber(asset.borrowBalance).times(asset.tokenPrice);

      totalSupplyBalance = totalSupplyBalance.plus(supplyBalanceUSD);
      totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD);

      const tokenCreditUsd = supplyBalanceUSD.times(asset.collateralFactor)
      totalAvailableCredit = totalAvailableCredit.plus(tokenCreditUsd)

      if (asset.collateral) {
        totalBorrowLimit = totalBorrowLimit.plus(supplyBalanceUSD.times(asset.collateralFactor));
      }
    });

    const totalWalletBalanceUSD = assetList.reduce((prev, curr) => {
      return prev.plus(curr.walletBalance.times(curr.tokenPrice))
    }, new BigNumber(0))
    totalAvailableCredit = totalAvailableCredit.minus(totalBorrowBalance)

    setSetting({
      account,
      assetList,
      totalSupplyBalance: totalSupplyBalance.toString(),
      totalBorrowBalance: totalBorrowBalance.toString(),
      totalBorrowLimit: totalBorrowLimit.toString(),
      totalWalletBalanceUSD: totalWalletBalanceUSD.toString(),
      totalAvailableCredit: totalAvailableCredit.toString(),
    });
  };

  useEffect(() => {
    let updateTimer = setInterval(() => {
      getMarkets();
    }, 10000);
    return function cleanup() {
      if (updateTimer) {
        clearInterval(updateTimer);
      }
    };
  }, [requiredChainId]);

  useEffect(() => {
    updateMarketInfo();
  }, [settings.markets]);

  useEffect(async () => {
    if (setSetting.chainId !== requiredChainId) {
      initSettings();
    }
    await getMarkets();
  }, [requiredChainId]);

  return null;
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, getGovernanceAquarius } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      getGovernanceAquarius,
    },
    dispatch,
  );
};

export default connectAccount(mapStateToProps, mapDispatchToProps)(APIProvider);
