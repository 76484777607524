import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormatStringFromBignumber } from 'utilities/common';

const StyledConatiner = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .info_row {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
    }
    .value {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
      color: #9CA3AF;
    }
    .desc {
      font-size: 12px;
      color: #748088;
    }
    .value-container {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
        // width: 25px;
        height: 25px;
      }
    }
  }

  .info_row_special {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    gap: 8px;
    background: linear-gradient(180deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%);

    .title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.025em;
    }
    .value {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.025em;
      background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

function Info({
  myDLpLocked,
  myShare,
  dLpLocked,
  dLpPrice,
  autoRelockDisabled,
  lockList,
  annualReward
}) {
  const { t } = useTranslation();
  const totalSupplyBalance = useSelector((state) => state.account.setting.totalSupplyBalance);
  const totalBorrowBalance = useSelector((state) => state.account.setting.totalBorrowBalance);
  const currentNetworkId = useSelector((state) => state.application.currentNetworkId || 0);

  const averageMultiplier = useMemo(() => {
    if (lockList.length === 0) {
      return '0.00';
    } else {
      let multiplier = new BigNumber(0);
      let amount = new BigNumber(0);
      for (let index = 0; index < lockList.length; index++) {
        multiplier = multiplier.plus(lockList[index].multiplier.times(lockList[index].amount));
        amount = amount.plus(lockList[index].amount);
      }
      return (getFormatStringFromBignumber(multiplier.div(amount)));
    }
  }, [lockList])

  return (
    <StyledConatiner>
      <div className="info_row_special">
        <div className="title">
          {t("Annual_rewards")}
        </div>
        <div className='flex'>
          <div className={`value`}>
            ${getFormatStringFromBignumber(annualReward.times(dLpPrice).div(1e18))}
          </div>
        </div>
      </div>
      <div className="info_row">
        <div className="title">
          {t("Supply_Balance")}
        </div>
        <div className='flex'>
          <div className={`value`}>
            ${getFormatStringFromBignumber(new BigNumber(totalSupplyBalance))}
          </div>
        </div>
      </div>
      <div className="info_row">
        <div className="title">
          {t("Borrow_Balance")}
        </div>
        <div className={`value`}>
          ${getFormatStringFromBignumber(new BigNumber(totalBorrowBalance))}
        </div>
      </div>
      <div className="info_row">
        <div className="title">
          {t('Your_locked_dLP')}
        </div>
        <div className='value-container'>
          <img src={`/images/coins/dlp_${currentNetworkId}.png`} alt={'ars'} />
          <div className={`value`}>
            {getFormatStringFromBignumber(myDLpLocked.div(1e18))}
          </div>
        </div>
        <div className='desc'>
          {t('Auto_relock')}: {autoRelockDisabled ? t('Disabled') : t('Enabled')}
        </div>
      </div>
      <div className="info_row">
        <div className="title">
          {t('dLP_locked')}
        </div>
        <div className={`value`}>
          {getFormatStringFromBignumber(dLpLocked.div(1e18))}
        </div>
        <div className='desc'>
          {getFormatStringFromBignumber(dLpLocked.times(dLpPrice).div(1e18))} USD
        </div>
      </div>
      <div className="info_row">
        <div className="title">
          {t('Your_share')}
        </div>
        <div className={`value`}>
          {getFormatStringFromBignumber(myShare)}%
        </div>
        <div className='desc'>
          {t('Average_multiplier')}: {averageMultiplier}x
        </div>
      </div>
    </StyledConatiner>
  )
}

export default Info;