import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash'
import BigNumber from "bignumber.js";
import styled from "styled-components";

import { getFormatStringFromBignumber } from 'utilities/common';

const StyledContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 20px 24px 20px 24px;
	border-radius: 12px;
	border: 1px solid #FFFFFF1A;
	gap: 20px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
	.title-container {
		display: flex;
		flex-direction: column;
		gap: 4px;
		.title {
			font-size: 18px;
			font-weight: 500;
			line-height: 28px;
			letter-spacing: -0.025em;
			color: #9CA3AF;
			@media only screen and (max-width: 992px) {
				font-size: 16px;
				line-height: 24px;
			}
		}
		.value {
			font-size: 36px;
			font-weight: 500;
			line-height: 40px;
			letter-spacing: -0.025em;
			@media only screen and (max-width: 992px) {
				font-size: 24px;
				line-height: 32px;
			}
		}
	}
	.info-container1 {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0em;
			.title {
				color: #9CA3AF;
			}
		}
	}
`;

const MarketInfo = ({
	marketInfo,
	marketType,
}) => {
	if (!marketInfo.underlyingSymbol) return null;
	const { t } = useTranslation();

	const netRate = useMemo(() => {
		let value = 0
		if (marketType === 'Supply') {
			value = new BigNumber(
				+marketInfo.supplyApy < 0.01 ? 0.01 : marketInfo.supplyApy)
				.plus(
					new BigNumber(
						+marketInfo.supplyArsApy < 0.01
							? 0.01
							: marketInfo.supplyArsApy
					))
		} else {
			value = new BigNumber(
				marketInfo.borrowArsApy < 0.01
					? 0.01
					: marketInfo.borrowArsApy)
				.minus(
					+marketInfo.borrowApy < 0.01 ? 0.01 : marketInfo.borrowApy
				)
		}
		if (value.isGreaterThan(10000)) {
			return 'Infinity'
		}
		return value.dp(2, 1).toString(10)
	}, [marketInfo, marketType])

	const supplyApy = useMemo(() => {
		let value = 0
		if (marketType === 'Supply') {
			value = new BigNumber(
				+marketInfo.supplyApy < 0.01 ? 0.01 : marketInfo.supplyApy
			)
		} else {
			value = new BigNumber(
				+marketInfo.borrowApy < 0.01 ? 0.01 : marketInfo.borrowApy
			)
		}
		if (value.isGreaterThan(10000)) {
			return 'Infinity'
		}
		return value.dp(2, 1).toString(10)
	}, [marketInfo, marketType])

	const totalSupply = useMemo(() => {
		return new BigNumber(
			marketType === 'Supply'
				? marketInfo.totalSupplyUsd
				: marketInfo.totalBorrowsUsd
		)
	}, [marketInfo, marketType])

	const distributionApy = useMemo(() => {
		if (marketInfo.underlyingSymbol === 'CORE' && marketInfo.underlyingSymbol === 'ETH') {
			if (marketType === 'Supply') {
				return new BigNumber(marketInfo.supplyArsApy)
			} else {
				return new BigNumber(marketInfo.borrowArsApy)
			}
		} else {
			if (marketType === 'Supply') {
				return new BigNumber(marketInfo.supplyAdditionalApy)
			} else {
				return new BigNumber(marketInfo.borrowAdditionalApy)
			}
		}
	}, [marketInfo, marketType])

	return (
		<StyledContainer>
			<div className="title-container">
				<div className="title">{t(`Total_${marketType}`)}</div>
				<div className="value">
					$ {totalSupply.toFormat(2)}
				</div>
			</div>
			<div className="info-container1">
				<div className="item">
					<div className="title">
						{t("NET_Rate")}
					</div>
					<div>
						{netRate} %
					</div>
				</div>
				<div className="item">
					<div className="title">
						{t("Distribution_APR")}
					</div>
					<div>
						{getFormatStringFromBignumber(distributionApy)} %
					</div>
				</div>
				<div className="item">
					<div className="title">
						{t(_.startCase(marketType) + '_APR')}
					</div>
					<div>
						{supplyApy} %
					</div>
				</div>
			</div>
		</StyledContainer>
	)

}

export default MarketInfo;
