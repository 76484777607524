
import AlertHelper from 'components/common/AlertHelper';
import metaMask from '../../assets/img/wallet/metaMask.svg';
import { addToken } from 'utilities/common';
import { useActiveWeb3React } from '../../hooks';
import { isCoreNetwork } from 'utils';
import { MetaMask } from '@web3-react/metamask';

function VestInfo({ overview }) {
  const { connector, chainId, isActive } = useActiveWeb3React();

  return (
    <div className="main-container">
      <div className='info-container'>
        <div className="info_row">
          <div>
            <div className="subtitle">{overview[0].name}</div>
          </div>
          <div>
            <div className={`text`}>
              {overview[0].value[1]}
            </div>
            <div className={`text1`}>
              {overview[0].value[0]}
            </div>
          </div>
        </div>
        <div className="info_row">
          <div>
            <div className="subtitle">{overview[1].name}</div>
          </div>
          <div>
            <div className={`text`}>
              {overview[1].value[1]}
            </div>
            <div className={`text1`}>
              {overview[1].value[0]}
            </div>
          </div>
        </div>
        <div className="info_row">
          <div>
            <div className="subtitle">{overview[2].name[0]}
              <b> APR </b>
              <span className='text-green'>({overview[2].name[1]} {overview[2].name[2]})</span>
            </div>
          </div>
          <div>
            <p className={`text text-green`}>
              {overview[2].value}
            </p>
          </div>
        </div>
      </div>
      <div className="vertical-divider" />
      <div className='info-container'>
        <div className="info_row">
          <div>
            <div className="subtitle">{overview[3].name[0]}
              <b> APR </b>
              <span className='text-green'>({overview[3].name[1]} {overview[3].name[2]})</span>
            </div>
          </div>
          <div>
            <p className={`text text-green`}>
              {overview[3].value}
            </p>
          </div>
        </div>
        <div className="info_row">
          <div>
            <div className="subtitle">{overview[4].name}</div>
          </div>
          <div className='flex justify-center items-center'>
            <p className={`text`}>
              {overview[4].value}
            </p>
            {
              (isActive && connector instanceof MetaMask && isCoreNetwork(chainId)) &&
              <div className='token-add'
                onClick={() => {
                  addToken('wcore', 18, chainId, 'token')
                }}
              >
                <img className="w-5" src={metaMask} alt="arrow" />
              </div>
            }
          </div>
        </div>
        <div className="info_row">
          <div className='flex justify-center items-center'>
            <div className="subtitle">{overview[5].name}</div>
            <AlertHelper
              text={overview[5].help}
            />
          </div>
          <div>
            <p className={`text`}>
              {overview[5].value}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VestInfo;