import React, { useCallback, useEffect, useState } from 'react';
import { promisify } from "../../utilities";
import {
    getTokenContract,
    methods,
} from "../../utilities/ContractService";
import BigNumber from "bignumber.js";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { accountActionCreators, connectAccount } from "../../core";
import { useActiveWeb3React, useWeb3 } from "../../hooks";
import Layout from "../../layouts/MainLayout/MainLayout";
import VotingWallet from "../../components/vote/VotingWallet";
import Proposals from "../../components/vote/Proposals";
import styled from 'styled-components';
import * as constants from '../../utilities/constants';
import { useChefIncentivesInfo } from 'hooks/useLpStaking';
import Footer from 'layouts/MainLayout/Footer';

let timeStamp = 0;

const Styles = styled.div`
.active-proposals {
    margin-left: 1.25rem;
}
@media (max-width: 840px) {
    flex-direction: column;
    .active-proposals {
        margin-left: 0;
        margin-top: 1.25rem;
    }
}
`

const Vote = ({ settings, getProposals }) => {
    const { account, requiredChainId } = useActiveWeb3React();
    const web3 = useWeb3();
    const { allPendingRewards } = useChefIncentivesInfo();
    const [balance, setBalance] = useState(0);
    const [votingWeight, setVotingWeight] = useState(0);
    const [proposals, setProposals] = useState({});
    const [current, setCurrent] = useState(1);
    const [isLoadingProposal, setIsLoadingProposal] = useState(false);
    const [earnedBalance, setEarnedBalance] = useState(new BigNumber(0));
    // const [xaiMint, setXaiMint] = useState('0.00000000');
    const [delegateAddress, setDelegateAddress] = useState('');
    const [delegateStatus, setDelegateStatus] = useState('');

    const loadInitialData = useCallback(async () => {
        setIsLoadingProposal(true);
        await promisify(getProposals, {
            offset: 0,
            limit: 3
        })
            .then(res => {
                setIsLoadingProposal(false);
                setProposals(res.data);
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    }, [getProposals]);

    useEffect(() => {
        loadInitialData();
    }, [loadInitialData]);

    const handleChangePage = (pageNumber, offset, limit) => {
        setCurrent(pageNumber);
        setIsLoadingProposal(true);
        promisify(getProposals, {
            offset,
            limit
        })
            .then(res => {
                setProposals(res.data);
                setIsLoadingProposal(false);
            })
            .catch(() => {
                setIsLoadingProposal(false);
            });
    };

    const updateBalance = async () => {
        if (account) {
            const arsTokenContract = getTokenContract(web3, 'ars', requiredChainId, false);
            let weight = await methods.call(arsTokenContract.methods.getCurrentVotes, [
                    account
                ])
            weight = new BigNumber(weight)
                .div(1e18)
                .toString(10);
            setVotingWeight(weight);
            let temp = await methods.call(arsTokenContract.methods.balanceOf, [
                account
            ]);
            temp = new BigNumber(temp)
                .dividedBy(1e18)
                .dp(4, 1)
                .toString(10);
            setBalance(temp);
        } else {
            setBalance('0');
            setVotingWeight('0');
        }
    };

    const getVoteInfo = async () => {
        const myAddress = account;
        const appContractCallContext = [{
            reference: 'appContract',
            contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
            abi: constants.CONTRACT_COMPTROLLER_ABI,
            calls: [
                { reference: 'arsInitialIndex', methodName: 'arsInitialIndex', methodParameters: [] },
                { reference: 'arsAccrued', methodName: 'arsAccrued', methodParameters: [myAddress] },
            ],
            context: {}
        }]
        const appContractResults = await methods.ethMulticall(web3, appContractCallContext, requiredChainId);
        let arsInitialIndex = new BigNumber(0);
        let arsAccrued = new BigNumber(0);
        appContractResults.results.appContract.callsReturnContext.forEach((result) => {
            if (result.methodName === "arsInitialIndex") {
                arsInitialIndex = new BigNumber(result.returnValues[0].hex)
            } else if (result.methodName === "arsAccrued") {
                arsAccrued = new BigNumber(result.returnValues[0].hex)
            }
        })

        let aquariusEarned = new BigNumber(0);
        let assetValues = [];
        const earnedAssetList = settings.assetList.filter(asset =>
            new BigNumber(asset.supplyBalance).isGreaterThan(0)
            || new BigNumber(asset.borrowBalance).isGreaterThan(0)
        )

        if (earnedAssetList.length > 0) {
            try {
                const contractCallContext = [{
                    reference: 'appContract',
                    contractAddress: constants.CONTRACT_COMPTROLLER_ADDRESS[requiredChainId],
                    abi: constants.CONTRACT_COMPTROLLER_ABI,
                    calls: [],
                    context: {}
                }]
                earnedAssetList.forEach(asset => {
                    contractCallContext[0].calls.push({
                        reference: `${asset.id}_arsSupplierIndex`,
                        methodName: 'arsSupplierIndex',
                        methodParameters: [asset.atokenAddress, myAddress]
                    })
                    contractCallContext[0].calls.push({
                        reference: `${asset.id}_arsBorrowerIndex`,
                        methodName: 'arsBorrowerIndex',
                        methodParameters: [asset.atokenAddress, myAddress]
                    })
                });
                const contractCallResults = await methods.ethMulticall(web3, contractCallContext, requiredChainId);

                for (const value of contractCallResults?.results?.appContract?.callsReturnContext) {
                    const references = value.reference.split('_')
                    assetValues[references[0]] = assetValues[references[0]] || {}
                    assetValues[references[0]][references[1]] = new BigNumber(value.returnValues[0].hex)
                }
            } catch (err) {
                console.log(err)
            }

            earnedAssetList.forEach(asset => {
                const supplyIndex = new BigNumber(asset.arsSupplyIndex)
                let supplierIndex = assetValues[asset.id]?.arsSupplierIndex || new BigNumber(0)
                const supplierTokens = asset.aTokenBalance
                const initBorrowIndex = asset.arsBorrowIndex
                const borrowerIndex = new BigNumber(assetValues[asset.id]?.arsBorrowerIndex || 0)
                const borrowBalanceStored = asset.borrowBalanceStored
                const borrowIndex = asset.borrowIndex

                if (supplierIndex.isZero(0) && supplyIndex.isGreaterThan(0)) {
                    supplierIndex = arsInitialIndex;
                }
                let deltaIndex = supplyIndex.minus(supplierIndex);
                const supplierDelta = new BigNumber(supplierTokens)
                    .multipliedBy(deltaIndex)
                    .dividedBy(1e36);

                aquariusEarned = aquariusEarned.plus(supplierDelta);
                if (borrowerIndex.isGreaterThan(0)) {
                    deltaIndex = new BigNumber(initBorrowIndex).minus(borrowerIndex);
                    const borrowerAmount = new BigNumber(borrowBalanceStored)
                        .multipliedBy(1e18)
                        .dividedBy(borrowIndex);
                    const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
                    aquariusEarned = aquariusEarned.plus(borrowerDelta);
                }
            })

            aquariusEarned = aquariusEarned.plus(arsAccrued).plus(allPendingRewards)

            setEarnedBalance(aquariusEarned);
        } else {
            setEarnedBalance(new BigNumber(0));
        }

    };

    const updateDelegate = async () => {
        if (account && timeStamp % 3 === 0) {
            const tokenContract = getTokenContract(web3, 'ars', requiredChainId, false);
            methods.call(tokenContract.methods.delegates, [account])
                .then(res => {
                    setDelegateAddress(res);
                    if (res !== '0x0000000000000000000000000000000000000000') {
                        setDelegateStatus(
                            res === account ? 'self' : 'delegate'
                        );
                    } else {
                        setDelegateStatus('');
                    }
                })
                .catch(() => { });
        }
        timeStamp = Date.now();
    };

    useEffect(() => {
        updateBalance();
        updateDelegate();
        if (account) {
            if (settings?.assetList?.length > 0) {
                getVoteInfo();
            }
        } else {
            setEarnedBalance(new BigNumber(0));
        }
    }, [settings.assetList, account]);

    return (
        <div className="h-screen flex flex-col">
            <Layout
                mainClassName="pt-4"
                title={'Vote'}
                hideFooter={true}
                noBg={true}
                hideSummary={true}
            >
            </Layout>
            <Styles className="container my-20 flex">
                <VotingWallet
                    balance={balance !== '0' ? `${balance}` : '0.00000000'}
                    earnedBalance={earnedBalance}
                    votingWeight={votingWeight}
                    delegateAddress={delegateAddress}
                    delegateStatus={delegateStatus}
                />
                <Proposals
                    isLoadingProposal={isLoadingProposal}
                    pageNumber={current}
                    proposals={proposals?.result}
                    total={proposals.total || 0}
                    votingWeight={votingWeight}
                    onCreateProposal={() => { handleChangePage(1, 0, 3) }}
                    onChangePage={handleChangePage}
                />
            </Styles>
            <Footer />
        </div>
    )

}

const mapStateToProps = ({ account }) => ({
    settings: account.setting
});

const mapDispatchToProps = dispatch => {
    const { getProposals, setSetting } = accountActionCreators;

    return bindActionCreators(
        {
            getProposals,
            setSetting
        },
        dispatch
    );
};

export default compose(
    withRouter,
    connectAccount(mapStateToProps, mapDispatchToProps)
)(Vote);
